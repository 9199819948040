import React from 'react'
import Typography from '@mui/material/Typography'
import {
  responsiveText,
  StyledRoundBox,
} from 'src/components/Preview/Header/Styled'
import { formatNowDateTime } from './events'

export const Round = () => {
  const date = formatNowDateTime()
  return (
    <StyledRoundBox>
      <Typography
        variant={responsiveText('body1b', 'body2b', 'caption')}
        color="text.white"
      >
        {date} น.
      </Typography>
    </StyledRoundBox>
  )
}

export default Round
