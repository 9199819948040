import React, { useState } from 'react'
import _ from 'lodash'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import MediaBox from './components/MediaBox'
import CourseTitle from './components/CourseTitle'
import CourseAssignee from './components/CourseAssignee'
import InProgressContent from './components/InProgressContent'
import Content from './components/Content'
import {
  StyledWrapper,
  StyledCard,
  StyledContentBox,
  StyledTitleBox,
  StyledContainer,
} from './Styled'

const CourseCard = ({ course, keySection }) => {
  const [isHover, setHover] = useState(false)
  const isNotSm = isMediaWidthMoreThan('sm')
  const isInProgress = _.get(course, 'isInProgress', false)
  return (
    <StyledWrapper>
      {!isNotSm && <MediaBox course={course} />}
      <StyledCard
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <StyledContainer>
          {isNotSm && <MediaBox course={course} isHover={isHover} />}
          <StyledContentBox id="content">
            <StyledTitleBox id="title" isInProgress={isInProgress}>
              <CourseTitle course={course} keySection={keySection} />

              <CourseAssignee course={course} />

              {isInProgress && <InProgressContent course={course} />}

              <Content course={course} keySection={keySection} />
            </StyledTitleBox>
          </StyledContentBox>
        </StyledContainer>
      </StyledCard>
    </StyledWrapper>
  )
}

export default CourseCard
