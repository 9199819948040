import React from 'react'
import _ from 'lodash'
import { store } from 'src/App'
import { setSectionTimeUp } from 'src/redux/slices/eTesting'
import { openDialog } from 'src/redux/slices/alertDialog'
import { handleSubmitETesting } from '../../Footer/SubmitBtn/event'
import DialogContent from './DialogContent'

export const countdown = (props) => (dispatch) => {
  const { time, timer, setTime, submitProps } = props
  const { uuid, trainingPlan, eTesting } = submitProps
  const { hrs, mins, secs } = time

  if (hrs === 0 && mins === 0 && secs === 0) {
    clearInterval(timer)
  } else if (mins === 0 && secs === 0) {
    setTime([hrs - 1, 59, 59])
  } else if (secs === 0) {
    setTime([hrs, mins - 1, 59])
  } else {
    setTime([hrs, mins, secs - 1])
  }

  if (hrs === 0 && mins === 5 && secs === 1) {
    dispatch(openDialog({ type: 'content', content: <DialogContent /> }))
  }

  if (hrs === 0 && mins === 0 && secs === 1) {
    const answerETesting = _.get(eTesting, 'answerETesting', [])
    const body = {
      trainingPlanUuid: trainingPlan,
      answerETesting: answerETesting
        .filter((item) => item.isAnswered || item.type == 'FREE_TEXT')
        .map((item) => ({
          questionUuid: item.questionUuid,
          answerUuid: item.answerUuid,
          freetextAnswer: item.freetextAnswer,
          isValueAns: _.get(item, 'isValueAns', ''),
          sequenceList: _.get(item, 'sequenceList', []),
          matchingAns: _.get(item, 'matchingAns', []),
          fillAns: _.get(item, 'fillAns', []),
        })),
    }
    const payload = { uuid, body }

    if (eTesting.isModule) {
      const { course, selectedLesson, eLearningCourseBatchLearner } =
        store.getState().eLearningLearning
      payload.uuid = _.get(selectedLesson, 'eTestingUuid', '')
      payload.body['eLearningCourse'] = { id: course.id, uuid: course.uuid }
      payload.body['eLearningCourseBatchLearner'] = eLearningCourseBatchLearner
      payload.isModule = true
      delete payload.body.trainingPlanUuid
    }

    dispatch(setSectionTimeUp())
    setTimeout(() => {
      dispatch(handleSubmitETesting(payload))
    }, 5000)
  }
}
