import _ from 'lodash'
import { setQrExpired, stopEnrollLoading } from 'src/redux/slices/class/detail'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import {
  checkExpiryQRCodeClass,
  getCourseClassDetail,
} from 'src/services/class/detail'
import {
  handleOpenSuccessDialog,
  handleRegisterFail,
} from './ButtonAndAmount/events'
import { handleClickEnroll } from './ButtonAndAmount/events'
import { closeDialog, openDialog } from 'src/redux/slices/alertDialog'

export const fetchClassDetail = (props) => async (dispatch) => {
  const { params, socketId, enroll, qrExpired, history, secret } = props
  dispatch(startLoading())

  const courseClassDetail = await dispatch(
    getCourseClassDetail({ ...params, secretTrainingPlan: secret })
  )
  const courseDetail = _.get(
    courseClassDetail,
    'payload.data.courseDetail',
    null
  )
  const courseVersionUuid = _.get(courseDetail, 'uuid', null)
  const trainingPlanData = _.get(
    courseClassDetail,
    'payload.data.trainingPlanData',
    null
  )
  const canRegister = _.get(trainingPlanData, 'canRegister', null)
  const trainingPlanUuid = _.get(trainingPlanData, 'uuid', '')
  const enrollProps = {
    courseDetail,
    trainingPlanUuid,
    courseVersionUuid,
    secret,
  }
  const availableSeats = _.get(trainingPlanData, 'availableSeats', null)
  const registered = canRegister === 'WAITING_RESULT'

  if (
    canRegister === 'CAN_REGISTER' &&
    enroll === 'auto' &&
    !registered &&
    availableSeats > 0
  ) {
    dispatch(handleClickEnroll(enrollProps, socketId))
  } else if (availableSeats === 0 && !qrExpired && !registered)
    dispatch(handleNotHaveAvailableSeat(history, params))
  dispatch(stopLoading())
}

export const checkExpiryQRCode =
  (params, socketId, enroll, history, secret) => async (dispatch) => {
    const expiryOfQrCode = await dispatch(checkExpiryQRCodeClass(params))
    const expired = _.get(expiryOfQrCode, 'payload.data.expired', false)
    dispatch(setQrExpired(expired))
    if (expired) {
      dispatch(handleQrExpired(history, params))
    } else
      dispatch(
        fetchClassDetail({
          params: params,
          socketId: socketId,
          enroll: enroll,
          qrExpired: expired,
          history: history,
          secret: secret,
        })
      )
  }

export const handleSocketResponse =
  (res, classUuid, secret = null) =>
  (dispatch) => {
    if (_.isEmpty(res)) return
    const secretTrainingPlan = !_.isEmpty(secret) ? `?secret=${secret}` : ''
    dispatch(stopEnrollLoading())
    if (res.success) {
      const goToUploadFilePage = _.get(res, 'content.goToUploadFilePage', false)
      if (goToUploadFilePage) {
        window.location.href = `/upload-document/${classUuid}${secretTrainingPlan}`
      } else dispatch(handleOpenSuccessDialog(classUuid))
    } else {
      dispatch(handleRegisterFail(res.content))
    }
  }

export const handleQrExpired = (history, params) => (dispatch) => {
  const { uuid, trainingPlanUuid } = params

  const newUrl = `/course/${uuid}/class/${trainingPlanUuid}`

  dispatch(
    openDialog({
      iconType: 'error',
      title: 'ไม่สามารถสมัครเข้าอบรมได้',
      message: ['เนื่องจาก QR Code หมดอายุการใช้งานแล้ว'],
      buttonRight: {
        label: 'ตกลง',
        handleClick: () => {
          history.push(newUrl)
          dispatch(closeDialog())
        },
      },
    })
  )
}

export const handleNotHaveAvailableSeat = () => (dispatch) => {
  dispatch(
    openDialog({
      iconType: 'error',
      title: 'ไม่สามารถสมัครเข้าอบรมได้',
      message: ['เนื่องจากผู้สมัครอบรมครบตามจำนวนแล้ว'],
      buttonRight: {
        label: 'ตกลง',
        handleClick: () => {
          dispatch(closeDialog())
        },
      },
    })
  )
}
