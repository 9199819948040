import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import styled from '@mui/system/styled'

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignSelf: 'center',
}))

export const StyledButton = styled((props) => (
  <Button
    id="img-profile-btn"
    aria-haspopup="true"
    variant="outlined"
    size="m"
    {...props}
  />
))(({ theme }) => ({
  ':hover': {
    backgroundColor: theme.palette.primary?.main,
    color: theme.palette.primary?.contrast,
  },
  '& .MuiTypography-root': {
    maxWidth: '120px',
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  img: {
    marginRight: theme.spacing(1),
  },
}))

export const StyledMenu = styled((props) => (
  <Menu
    data-testid="btn-sub-menu"
    MenuListProps={{ 'aria-labelledby': 'basic-button' }}
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  zIndex: '1299 !important',
  '& .MuiPaper-root': {
    borderRadius: 0,
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette?.blue?.oceanBlue,
    color: theme.palette.primary?.contrast,
    width: '200px',
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      padding: theme.spacing(2),
      fontSize: theme.typography?.body1?.fontSize,
      fontWeight: theme.typography?.body1?.fontWeight,
    },
    '& .MuiDivider-root': {
      margin: 0,
      borderColor: theme.palette?.other?.divider,
    },
  },
}))
