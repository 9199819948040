const { createSlice } = require('@reduxjs/toolkit')

export const initialState = { isLoading: false, isLoading2: false }

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoading2: (state) => {
      state.isLoading2 = true
    },
    stopLoading2: (state) => {
      state.isLoading2 = false
    },
  },
})

export const { startLoading, stopLoading, startLoading2, stopLoading2 } =
  loadingSlice.actions

export default loadingSlice.reducer
