import _ from 'lodash'
import { setAnswered } from 'src/redux/slices/eTesting'

export const handleDefaultValue = (eTesting, uuid) => {
  return _.get(eTesting, 'answerETesting', []).filter(
    (item) => item.questionUuid == uuid
  )
}

export const handleFreeTextChange = (e, uuid) => (dispatch) => {
  const value = _.get(e, 'target.value', null)
  dispatch(setAnswered({ type: 'FREE_TEXT', uuid, value }))
}

export const handleSingleChange = (e, uuid) => (dispatch) => {
  const value = _.get(e, 'target.value', null)
  dispatch(setAnswered({ type: 'SINGLE', uuid, value }))
}

export const handleMultipleChange = (e, uuid) => (dispatch) => {
  const value = { checked: e.target.checked, value: e.target.value }
  dispatch(setAnswered({ type: 'MULTIPLE', uuid, value }))
}

export const handleTrueFalseChange = (e, uuid, answerUuid) => (dispatch) => {
  const value = _.get(e, 'target.value', '')
  if (_.isEmpty(value)) return
  dispatch(setAnswered({ type: 'TRUE_FALSE', uuid, value, answerUuid }))
}

export const handleDisableCheckbox = (question, answers, value) => {
  const maxAnswerNumber = _.get(question, 'maxAnswerNumber', 0)
  const answered = answers.filter((item) => item.isAnswered)
  const index = answers.findIndex((item) => item.answerUuid == value)
  return answered.length >= maxAnswerNumber && index == -1
}

export const handleMatchFillChange = (props, answers) => (dispatch) => {
  const { e, choice, uuid } = props
  const type = _.get(choice, 'questionType', '')
  const value = _.get(e, 'target.value', null)
  const newAnswer = [...answers]
  const answerIndex = answers.findIndex(
    (ans) => ans.questionUuid === choice.uuid
  )

  const objValue = { questionUuid: choice.uuid, answerUuid: value }
  if (type !== 'MATCHING') objValue.target = choice.target

  if (answerIndex === -1) newAnswer.push(objValue)
  else newAnswer[answerIndex] = objValue
  dispatch(setAnswered({ type, uuid, value: newAnswer }))
}
