import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import {
  StyledKeyword,
  StyledKeywordContainer,
  StyledMatchingContainer,
  StyledNumberBadge,
  StyledQuestion,
  StyledQuestionList,
  StyledSelect,
} from './Styled'
import { handleMatchFillChange } from '../events'

const Matching = ({ uuid, question, onViewState }) => {
  const [shuffledKeywords, setKeyword] = useState([])
  const { answer, questionResult, correctAnswer } = useSelector(
    (state) => ({
      answer: state.eTesting.answerETesting.find(
        (item) => item.questionUuid == uuid
      ),
      questionResult: state.eTesting.questionResult,
      correctAnswer: state.eTesting.correctAnswers.find(
        (item) => item.questionUuid == uuid
      ),
    }),
    shallowEqual
  )
  const choices = _.get(question, 'answers', [])
  const matchingAns = _.get(answer, 'matchingAns', [])

  const dispatch = useDispatch()

  useEffect(() => {
    setKeyword(_.shuffle(choices))
  }, [choices])

  return (
    <StyledMatchingContainer>
      <StyledKeywordContainer>
        {shuffledKeywords.map((data, index) => (
          <StyledKeyword
            selected={matchingAns.some((ans) => ans.answerUuid === data.uuid)}
            key={index}
          >
            <Typography>({index + 1})</Typography>
            <Typography id="title">{_.get(data, 'title', '')}</Typography>
          </StyledKeyword>
        ))}
      </StyledKeywordContainer>

      {choices
        .filter((choice) => choice.isCorrect && !_.isEmpty(choice.question))
        .map((choice, index) => {
          const selected = matchingAns.find(
            (ans) => ans.questionUuid === choice.uuid
          )
          const answer = _.get(correctAnswer, 'answers', []).find(
            (ans) => ans.answerUuid === choice.uuid
          )
          const isAnswer =
            selected?.questionUuid === selected?.answerUuid ||
            selected?.answerUuid === answer?.answerUuid
          return (
            <StyledQuestionList
              resultProps={{ onViewState, isAnswer, questionResult }}
              key={index}
            >
              <Box sx={{ display: 'flex', alignItems: 'start', gap: 2.5 }}>
                <Typography variant="body1b" minWidth={30}>
                  {_.get(question, 'sequence', 0)}.{index + 1}
                </Typography>
                <StyledQuestion>{_.get(choice, 'question', '')}</StyledQuestion>
              </Box>
              <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                <StyledSelect
                  onViewState={onViewState}
                  isAnswer={isAnswer}
                  value={_.get(selected, 'answerUuid', '')}
                  onChange={(e) => {
                    const props = { e, choice, uuid }
                    dispatch(handleMatchFillChange(props, matchingAns))
                  }}
                >
                  {shuffledKeywords.map((data, index) => {
                    const dataUuid = _.get(data, 'uuid', '')
                    const isSelectedItem = matchingAns.some(
                      (item) => item.answerUuid === dataUuid
                    )
                    return (
                      <MenuItem
                        sx={{ display: isSelectedItem ? 'none' : 'flex' }}
                        value={dataUuid}
                        key={index}
                      >
                        {index + 1}
                      </MenuItem>
                    )
                  })}
                </StyledSelect>
                {onViewState &&
                  questionResult.resultType == 'SHOW_TRUE_FALSE' && (
                    <StyledNumberBadge isAnswer={isAnswer}>
                      {shuffledKeywords.findIndex(
                        (kw) => kw.uuid === choice.uuid
                      ) + 1}
                    </StyledNumberBadge>
                  )}
              </Box>
            </StyledQuestionList>
          )
        })}
    </StyledMatchingContainer>
  )
}

export default Matching
