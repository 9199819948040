import React, { useMemo } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { Column } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { DisabledInput, StepTitle } from '..'
import BatchCalendar from './BatchCalendar'
import { StyledButton, StyledDivider, StyledWrapper } from './Styled'
import { handleBatchPeriod, handleResetBatch } from './events'

const Step1 = () => {
  const batch = useSelector(
    (state) => state.eLearningEnroll.body.batch,
    shallowEqual
  )
  const periodStartStr = useMemo(
    () => handleBatchPeriod(batch, 'periodStart'),
    [batch]
  )
  const periodEndStr = useMemo(
    () => handleBatchPeriod(batch, 'periodEnd'),
    [batch]
  )
  const dispatch = useDispatch()
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')

  return (
    <Column alignItems="center" pb={4}>
      <StepTitle
        label="เลือกรอบอบรมที่ต้องการ"
        description="กรุณาตรวจสอบและเพิ่มข้อมูลสำหรับการสมัครหลักสูตรอบรม OIC ให้ครบถ้วน หากข้อมูลไม่ถูกต้องกรุณาติดต่อเจ้าหน้าที่ KTAXA"
      />
      {!isLg && <StyledDivider />}

      <StyledWrapper>
        <BatchCalendar />
        {isLg && <Divider orientation="vertical" flexItem />}
        <Column sx={{ alignItems: 'space-between', gap: 2 }}>
          <Column gap={2}>
            <DisabledInput
              label="วันที่เริ่มอบรม"
              placeholder="กรุณาเลือกจากปฏิทิน"
              value={periodStartStr}
            />
            <DisabledInput
              label="วันที่จบอบรม"
              placeholder="กรุณาเลือกจากปฏิทิน"
              value={periodEndStr}
            />
            <Typography variant="body2" color="error">
              เรียนให้จบภายใน 7 วัน นับจากวันที่เริ่มอบรม
            </Typography>
          </Column>
          <StyledButton
            variant="outlined"
            color="error"
            size={isLg || !isNotSm ? 'm' : 'l'}
            disabled={_.isEmpty(batch)}
            onClick={() => dispatch(handleResetBatch())}
          >
            ยกเลิกเลือกรอบนี้
          </StyledButton>
        </Column>
      </StyledWrapper>
    </Column>
  )
}

export default Step1
