import React from 'react'
import Template from '../..//components/Template'
import BodyLocation from './Body'
import LocationHeader from './Header'

const Location = () => {
  return (
    <>
      <LocationHeader />
      <Template>
        <BodyLocation />
      </Template>
    </>
  )
}

export default Location
