import React from 'react'
import Typography from '@mui/material/Typography'
import { Column } from 'src/components/Layout/Styled'
import FaceLiveness from 'src/components/FaceLiveness'
import CameraReadyBox from './components/CameraReadyBox'
import CameraFrame from './components/CameraFrame'

const CameraDialog = ({
  field = 'faceImage',
  isOpen,
  image,
  onCapture,
  onOpenCamera,
  onError,
  children,
  isLiveness,
}) => {
  return (
    <Column p={2} gap="18px" alignItems="center">
      {children || (
        <Column gap={0.5} alignItems="center">
          <Typography variant="h5">ถ่ายรูปหน้าตรง</Typography>
          <Typography variant="body2b" color="primary">
            กรุณาเปิดกล้องแล้วถ่ายรูปในที่ที่มีแสงสว่างมากเพียงพอและถ่ายไม่ติดใบหน้าผู้อื่น
          </Typography>
        </Column>
      )}
      {isOpen ? (
        isLiveness ? (
          <FaceLiveness onCapture={onCapture} onError={onError} />
        ) : (
          <CameraFrame field={field} image={image} onCapture={onCapture} />
        )
      ) : (
        <CameraReadyBox field={field} onOpenCamera={onOpenCamera} />
      )}
    </Column>
  )
}

export default CameraDialog
