import _ from 'lodash'

export const checkOnFilter = (filterBody) => {
  const keyArr = _.keys(filterBody)
  const condition = keyArr
    .filter((key) => !exceptedFilterKey.includes(key))
    .some((key) => filterBody[key].length > 0 || filterBody[key] > 0)
  return condition
}

export const exceptedFilterKey = [
  'page',
  'limit',
  'order',
  'sortBy',
  'courseName',
]
