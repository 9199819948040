import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import ExpandLess from '@mui/icons-material/ExpandLess'
import Carousel from 'src/components/Carousel'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { handleSelector } from '../events'
import {
  StyledDisplayImg,
  StyledDisplayRoadMap,
  StyledDisplayText,
  StyledMoreLessButton,
  StyledRoadMapBg,
  StyledRoadMapBox,
  StyledRoadMapContainer,
  StyledRoadMapHeader,
} from './Styled'
import { handleAnimatedDots, handleRouterRoadMap } from './events'

const RoadMap = () => {
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')
  const [isShowAll, setShowAll] = useState(false)
  const [selectedNum, setSelectedNum] = useState(0)
  const roadMap = useSelector(
    (state) => handleSelector(state, 'roadMapInformation'),
    shallowEqual
  )
  const roadMapList = _.get(roadMap, 'roadMaps', [])
  const maxDisplay = useMemo(() => (isLg ? 8 : 6), [isLg])
  const displayRoadMap = useMemo(
    () => (isShowAll ? roadMapList : _.slice(roadMapList, 0, maxDisplay)),
    [roadMapList, isShowAll, maxDisplay]
  )
  const roadMapTap = _.chunk(roadMapList, maxDisplay)

  useEffect(() => {
    handleAnimatedDots(selectedNum)
  }, [selectedNum])

  return (
    <StyledRoadMapBg>
      <StyledRoadMapContainer id="roadmap">
        <StyledRoadMapHeader>
          <Typography variant={isLg ? 'h5' : 'body1b'}>
            {_.get(roadMap, 'subtitle', '')}
          </Typography>
          <Typography variant={isLg ? 'h3' : 'h5'}>
            {_.get(roadMap, 'title', '')}
          </Typography>
        </StyledRoadMapHeader>

        {isNotSm ? (
          <>
            <StyledRoadMapBox length={roadMapList.length}>
              {displayRoadMap.map((roadmap, index) => (
                <DisplayRoadMap key={index} data={roadmap}>
                  {roadmap.body}
                </DisplayRoadMap>
              ))}
            </StyledRoadMapBox>
            {roadMapList.length > maxDisplay && (
              <MoreLessButton isShowAll={isShowAll} setShowAll={setShowAll} />
            )}
          </>
        ) : (
          <Carousel onChange={(value) => setSelectedNum(value)}>
            {roadMapTap.map((roadMaps, key) => (
              <StyledRoadMapBox key={key} length={roadMapList.length}>
                {roadMaps.map((roadmap, index) => (
                  <DisplayRoadMap key={index} data={roadmap}>
                    {roadmap.body}
                  </DisplayRoadMap>
                ))}
              </StyledRoadMapBox>
            ))}
          </Carousel>
        )}
      </StyledRoadMapContainer>
    </StyledRoadMapBg>
  )
}

export default RoadMap

export const DisplayRoadMap = ({ data }) => {
  const history = useHistory()
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')
  const image = _.get(data, 'imgKey', null)
  const imageSrc = `${window.__env__.REACT_APP_FILE_URL}${image}`
  const dispatch = useDispatch()

  return (
    <StyledDisplayRoadMap
      data-testid="roadmap"
      onClick={() => dispatch(handleRouterRoadMap(data, history))}
    >
      <StyledDisplayImg>
        <img src={imageSrc} alt="icon" />
      </StyledDisplayImg>
      <StyledDisplayText>
        <Typography variant={isLg ? 'h4' : 'h6'}>
          {_.get(data, 'title', '')}
        </Typography>
        <Typography variant={isLg ? 'body1' : isNotSm ? 'body2' : 'caption'}>
          {_.get(data, 'description', '')}
        </Typography>
      </StyledDisplayText>
    </StyledDisplayRoadMap>
  )
}

export const MoreLessButton = ({ isShowAll, setShowAll }) => {
  const isLg = isMediaWidthMoreThan('lg')

  if (!isShowAll) {
    return (
      <StyledMoreLessButton
        data-testid="btn-more"
        size={isLg ? 'l' : 'm'}
        onClick={() => setShowAll(true)}
      >
        ดูหลักสูตรทั้งหมด
      </StyledMoreLessButton>
    )
  }

  return (
    <StyledMoreLessButton
      data-testid="btn-less"
      size={isLg ? 'l' : 'm'}
      onClick={() => setShowAll(false)}
      endIcon={<ExpandLess />}
    >
      ดูน้อยลง
    </StyledMoreLessButton>
  )
}
