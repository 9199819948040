import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState } from './model'
import { handleFilterTotal } from './events'

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.table.isLoading = true
    },
    stopLoading: (state) => {
      state.table.isLoading = false
    },
    setInitialTable: (state, { payload }) => {
      state.table.isHideHeadCell = _.get(payload, 'isHideHeadCell', false)
      state.table.isCheckBox = _.get(payload, 'isCheckBox', true)
      state.table.rows = _.get(payload, 'rows', [])
      state.table.allCount = _.get(payload, 'allCount', 0)
      state.table.headCells = _.get(payload, 'headCells', [])
      state.table.searchKey = _.get(payload, 'searchKey', '')
      state.table.tableHeadText = _.get(payload, 'tableHeadText', null)
      state.filter.status = _.get(payload, 'status', [])
      state.search.placeholder = _.get(payload, 'placeholder', '')
      state.handleSearch = _.get(payload, 'handleSearch', null)
      state.onDelete = _.get(payload, 'onDelete', null)
      state.onDeleted = _.get(payload, 'onDeleted', null)
      state.onDownload = _.get(payload, 'onDownload', null)
      state.onResendRequest = _.get(payload, 'onResendRequest', null)
      state.customStyle = _.get(payload, 'customStyle', {})
      state.onSelect = _.get(payload, 'onSelect', null)
      state.onSelectAll = _.get(payload, 'onSelectAll', null)

      state.table.isShowPagination = _.get(payload, 'isShowPagination', true)
      state.table.sxTable = _.get(payload, 'sxTable', {})
      state.table.isHideSearch = _.get(payload, 'isHideSearch', false)
      state.table.hiddenRowsPerPage = _.get(payload, 'hiddenRowsPerPage', false)
    },
    setInitialMore: (state, { payload }) => {
      state.table = {
        ...state.table,
        ...payload,
      }
    },
    setSearchText: (state, { payload }) => {
      state.search.searchText = payload
    },
    setToggleFilterDrawer: (state, { payload }) => {
      state.filter.isFilterDrawer = payload
    },
    setStatusFilter: (state, { payload }) => {
      state.filter.status = payload
    },
    setFilterProp: (state, { payload }) => {
      state.filter.filterProp = payload
    },
    setOther: (state, { payload }) => {
      state.filter.other = payload
    },
    setDefaultSort: (state, { payload }) => {
      state.table.defaultSort = payload
    },
    setOnQuickFilterByIndex: (state, { payload }) => {
      state.filter.quickFilter[payload.index] = payload.item
    },
    setOrder: (state, { payload }) => {
      state.table.order = payload.order
      state.table.sort = payload.sort
    },
    setSelected: (state, { payload }) => {
      state.table.selected = payload
    },
    setOnView: (state, { payload }) => {
      window.location.href = payload
    },
    setOnEdit: (state, { payload }) => {
      window.location.href = payload
    },
    setChangeRowsPerPage: (state, { payload }) => {
      state.table.rowsPerPage = payload.rowsPerPage
      state.table.limit = payload.limit
      state.table.tablePage = 0
      state.table.page = 1
    },
    setChangePage: (state, { payload }) => {
      state.table.page = payload.page
      state.table.tablePage = payload.tablePage
    },
    resetTablePage: (state) => {
      state.table.tablePage = 0
    },
    setCustomToolbar: (state, { payload }) => {
      state.customToolbar = payload
    },
    setFilterTotal: (state, { payload }) => {
      state.filter.filterTotal = handleFilterTotal(payload)
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setInitialTable,
  setInitialMore,
  setSearchText,
  setToggleFilterDrawer,
  setStatusFilter,
  setFilterProp,
  setOnQuickFilter,
  setDefaultSort,
  setOrder,
  setSelected,
  setOnView,
  setOnEdit,
  setChangeRowsPerPage,
  setChangePage,
  resetTablePage,
  setOnQuickFilterByIndex,
  setOther,
  setCustomToolbar,
  setFilterTotal,
} = tableSlice.actions

export default tableSlice.reducer
