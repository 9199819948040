import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { location } from 'src/utils/apiPath'

export const getContentLocation = createAsyncThunk(
  'home/getContentLocation',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(location)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
