import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import _ from 'lodash'

export default function BasicSelect(props) {
  const {
    name,
    dataTestid,
    boxSx,
    labelText,
    required,
    placeholder,
    disabled,
    textError,
    showTextError = true,
    options,
    handleChange,
    value,
    defaultValue,
    selectSx,
    handleClick,
    handleClose,
  } = props
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant="body2"
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>
      <Select
        name={name}
        onOpen={handleClick}
        onChange={handleChange}
        onClose={handleClose}
        value={value}
        displayEmpty
        disabled={disabled}
        inputProps={{
          id: 'uncontrolled-native',
          'data-testid': dataTestid,
        }}
        defaultValue={defaultValue}
        IconComponent={KeyboardArrowDownIcon}
        renderValue={(selectValue) => {
          if (!selectValue || selectValue.length === 0 || options === null) {
            if (!_.isNil(options) && !selectValue) {
              const findValue = options.find((item) => item.value === value)
              if (!findValue) {
                return (
                  <Box color="text.silver" sx={{ px: 1 }}>
                    {placeholder}
                  </Box>
                )
              }
              return <Box sx={{ px: 1 }}>{_.get(findValue, 'label', '')}</Box>
            }
            return (
              <Box color="text.silver" sx={{ px: 1 }}>
                {placeholder}
              </Box>
            )
          } else {
            const findValue = options.find((item) => item.value === selectValue)
            return <Box sx={{ px: 1 }}>{findValue?.label}</Box>
          }
        }}
        sx={selectSx || {}}
      >
        {options &&
          options.length > 0 &&
          options.map((item, index) => {
            return (
              <MenuItem
                key={`${item.label}-${index}`}
                value={item.value}
                name={item.label}
              >
                {item.labelText ? item.labelText : item.label}
              </MenuItem>
            )
          })}
      </Select>
      {showTextError && (
        <>
          {textError ? (
            <Typography sx={{ height: '20px' }} variant="body2" color="error">
              {textError}
            </Typography>
          ) : (
            <Box sx={{ height: '20px' }}>{textError}</Box>
          )}
        </>
      )}
    </Box>
  )
}
