import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import IconButton from '@mui/material/IconButton'

import { StyledBox } from '../../Styled'

export const StudentStatus = {
  CANCEL: 'CANCEL',
  CANCEL_BY_SELF: 'CANCEL_BY_SELF',
  CANCEL_BY_ADMIN: 'CANCEL_BY_ADMIN',
  CANCEL_EXAMINATION: 'CANCEL_EXAMINATION',
  CANCEL_BY_MANAGER: 'CANCEL_BY_MANAGER',
}

const Index = ({ row, rowIndex }) => {
  const { onDelete } = useSelector((state) => state.table2)

  const name = _.get(row, 'examType', '') + ' ' + _.get(row, 'examDateTime', '')

  const status = _.get(row, 'examStatus', '')
  const userType = _.get(row, 'userType', '')

  return (
    <StyledBox>
      {![
        StudentStatus.CANCEL,
        StudentStatus.CANCEL_BY_ADMIN,
        StudentStatus.CANCEL_BY_MANAGER,
        StudentStatus.CANCEL_BY_SELF,
        StudentStatus.CANCEL_EXAMINATION,
      ].includes(status) && (
        <IconButton
          data-testid={`btn-exam-delete-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onDelete(row.uuid, name, userType)}
        >
          <DeleteTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default Index
