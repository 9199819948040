import React from 'react'
import _ from 'lodash'

import Typography from '@mui/material/Typography'
import InsertInvitationOutlined from '@mui/icons-material/InsertInvitationOutlined'

import { isMediaWidthMoreThan, convertDate } from 'src/utils/lib'
import { Column, Row } from 'src/components/Layout/Styled'
import { StyledCard, StyledLabelIcon, StyledRowFooter } from './Styled'
import ContainedButton from './ContainedButton'
import Divider from '@mui/material/Divider'

const ClassCard = ({ data, index }) => {
  const isSm = isMediaWidthMoreThan('sm')
  const isMediaLg = isMediaWidthMoreThan('lg')

  const dateRange =
    convertDate(_.get(data, 'periodStart', '')) +
    ' - ' +
    convertDate(_.get(data, 'periodEnd', ''))

  const fontWeight = isSm && isMediaLg ? 'h6' : 'h5'
  const fontSize = isSm && isMediaLg ? '28px' : '24px'
  const iconSize = isSm && isMediaLg ? '24px' : '20px'
  const subject = _.get(data, 'eLearningCourse.subject', '')
  const name = _.get(data, 'eLearningCourse.nameForLearner', '')
    ? _.get(data, 'eLearningCourse.nameForLearner', '')
    : _.get(data, 'eLearningCourse.name', '')

  return (
    <StyledCard index={index + 1}>
      <Row id="button" sx={{ justifyContent: 'space-between' }}>
        <Column sx={{ width: 'auto', gap: '18px' }}>
          <Typography
            variant={fontWeight}
            sx={{
              fontSize: fontSize,
            }}
          >
            {name}
          </Typography>
          {subject && (
            <Typography
              sx={{
                fontSize: fontSize,
                color: '#5f5f5f',
              }}
            >
              {'วิชา ' + subject}
            </Typography>
          )}
          <StyledRowFooter>
            <StyledLabelIcon>
              {<img src="/icon/vector.svg" fontSize={iconSize} />}
              <Typography
                maxWidth={110}
                variant="body1"
                sx={{
                  fontSize: '20px',
                  color: '#333333',
                }}
              >
                {' รอบอบรมที่ '}
                {_.get(data, 'sequence', 1)}
              </Typography>
            </StyledLabelIcon>
            <StyledLabelIcon>
              <InsertInvitationOutlined
                color="text.secondary"
                fontSize={iconSize}
              />
              <Typography
                maxWidth={185}
                sx={{
                  fontSize: '20px',
                  color: '#333333',
                }}
              >
                {dateRange}
              </Typography>
            </StyledLabelIcon>
          </StyledRowFooter>
        </Column>
        <ContainedButton data={data} />
      </Row>
      <Divider />
    </StyledCard>
  )
}

export default ClassCard
