import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 176,
  background: `url('/image/background.svg')`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  gap: theme.spacing(6),
  marginTop: theme.spacing(9),
}))

export const StyledHeaderText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  margin: 'auto 30px',
  '& .MuiTypography-root#body': {
    width: '620px',
    wordBreak: 'break-all',
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiTypography-root#body': {
      width: '335px',
    },
  },
}))
