import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { assessmentField, sectionName } from 'src/constants/eEvaluation'
import { StyledContent } from '../Styled'
import RatingCard from '../RatingCard'
import QuestionCard from '../QuestionCard'
import { StyledContainer } from './Styled'

export const AssessmentCard = ({ assessmentKey, isOne }) => {
  const { key, keyField, section } = assessmentKey
  const keyProps = { keyField, keyAssessment: key }
  return (
    <StyledContainer>
      {(key == 0 || isOne) && (
        <Box sx={{ mb: 2 }}>
          {key == 0 && <Typography variant="h4">ตอนที่ {section}</Typography>}
          <Typography variant="h5">{sectionName[keyField]}</Typography>
        </Box>
      )}
      <StyledContent>
        <RatingCard keyProps={keyProps} />
      </StyledContent>
    </StyledContainer>
  )
}

export const QuestionAssessmentCard = ({ assessmentKey, isOne }) => {
  const { key, keySub, type, section } = assessmentKey
  const keyProps = {
    keyField: assessmentField.question,
    keyAssessment: key,
    keySub,
  }

  return (
    <StyledContainer>
      {(key == 1 || isOne) && (
        <Box sx={{ mb: 2 }}>
          {key == 1 && !keySub && (
            <Typography variant="h4">ตอนที่ {section}</Typography>
          )}
          <Typography variant="h5">แบบประเมิน / แบบสอบถามอื่นๆ</Typography>
        </Box>
      )}
      <StyledContent>
        {type == 'RATING' ? (
          <RatingCard keyProps={keyProps} />
        ) : (
          <QuestionCard
            key={`question-no-${key}`}
            keyQuestion={key}
            keySub={keySub}
          />
        )}
      </StyledContent>
    </StyledContainer>
  )
}
