import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'

export const StyledSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  '& > .MuiBox-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
  wordWrap: 'break-word',
  '& > span.condition': {
    color: theme.palette?.text?.lightGray,
  },
}))

export const StyledDivider = styled(Divider)(() => ({
  span: { paddingLeft: 0 },
  '::before': { width: 0 },
  '::after': { width: '100%' },
}))
