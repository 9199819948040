import React from 'react'
import _ from 'lodash'
import { formatData } from './events'
import Typography from '@mui/material/Typography'

const ActionStatusExam = ({ row, sx }) => {
  const status = _.get(row, 'examStatus', '-')
  const { label, color } = formatData(status)
  return (
    <Typography variant="body2" sx={{ ...sx, color }}>
      {label}
    </Typography>
  )
}

export default ActionStatusExam
