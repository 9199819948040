import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  postCourseClassList,
  getCourseDetail,
} from 'src/services/course/detail'
import { initialState } from './model'

const courseDetail = createSlice({
  name: 'courseDetail',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoadMore: (state) => {
      state.isLoadMore = true
    },
    stopLoadMore: (state) => {
      state.isLoadMore = false
    },
    setFilter: (state, { payload }) => {
      const { key, value } = payload
      state.filterBody[key] = value
      state.filterBody.page = 1
    },
    setLoadMore: (state) => {
      const nextPage = state.filterBody.page + 1
      const maxPage = _.ceil(state.totalClass / 12)
      if (nextPage <= maxPage) state.filterBody.page = nextPage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseDetail.fulfilled, (state, { payload }) => {
        state.data = payload.data
      })
      .addCase(getCourseDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postCourseClassList.fulfilled, (state, action) => {
        const { meta, payload } = action
        const classList = _.get(payload, 'data.returnTrainingPlans', [])
        const isFilter = _.get(meta.arg, 'body.page', 1) == 1
        if (isFilter) state.classList = classList
        else state.classList = _.concat(state.classList, classList)
        state.totalClass = _.get(payload.data, 'getTotalTrainingPlans', 0)
      })
      .addCase(postCourseClassList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startLoadMore,
  stopLoadMore,
  setFilter,
  setLoadMore,
} = courseDetail.actions

export default courseDetail.reducer
