import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'

import {
  StyledDialog,
  StyledDialogActions,
  StyledButtonLeft,
  StyledButtonRight,
} from '../../AlertDialog/Styled'
import _ from 'lodash'

import ContentDialog from './Content'
import { setCloseDialog } from '../../../redux/slices/profile'

const AlertDialog = () => {
  const dialog = useSelector((state) => state.profile.alertDialog)
  const { isOpen, isLoading, fullWidth, buttonLeft, buttonRight } = dialog

  const dispatch = useDispatch()
  return (
    <Box>
      <StyledDialog fullWidth={fullWidth} maxWidth="sm" open={isOpen}>
        <Box>
          <ContentDialog />
          <StyledDialogActions>
            {!isLoading && !_.isEmpty(buttonLeft) && (
              <StyledButtonLeft
                data-testid="btn-disagree"
                size="l"
                variant="outlined"
                disabled={_.get(buttonLeft, 'disabled', false)}
                sx={_.get(buttonLeft, 'style', { width: '100%' })}
                onClick={_.get(buttonLeft, 'handleClick', () =>
                  dispatch(setCloseDialog())
                )}
                startIcon={_.get(buttonLeft, 'startIcon', null)}
                endIcon={_.get(buttonLeft, 'endIcon', null)}
              >
                <Typography
                  variant="button"
                  color={_.get(buttonLeft, 'colorText', 'auto')}
                >
                  {_.get(buttonLeft, 'label', 'ยกเลิก')}
                </Typography>
              </StyledButtonLeft>
            )}
            <StyledButtonRight
              data-testid="btn-agree"
              size="l"
              variant="contained"
              loading={isLoading}
              sx={_.get(buttonRight, 'style', { width: '100%' })}
              color={_.get(buttonRight, 'color', 'primary')}
              onClick={_.get(buttonRight, 'handleClick', () =>
                dispatch(setCloseDialog())
              )}
              startIcon={_.get(buttonRight, 'startIcon', null)}
              endIcon={_.get(buttonRight, 'endIcon', null)}
            >
              <Typography
                variant="button"
                sx={{ color: 'auto' }}
                color={_.get(buttonRight, 'colorText', 'auto')}
              >
                {_.get(buttonRight, 'label', 'ตกลง')}
              </Typography>
            </StyledButtonRight>
          </StyledDialogActions>
        </Box>
      </StyledDialog>
    </Box>
  )
}

export default AlertDialog
