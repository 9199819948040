import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledMenu = styled(Box)(({ theme }) => ({
  '& .MuiBox-root': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  '& .MuiFormGroup-root': {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
  },
  '& .MuiDivider-root': {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderColor: theme.palette?.other?.divider,
  },
}))
