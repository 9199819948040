import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledTrainerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: `${theme.spacing(7.5)} ${theme.spacing(10)}`,
  '& #slash': {
    position: 'absolute',
    bottom: 20,
    right: 80,
    zIndex: -2,
    [theme.breakpoints.down('lg')]: {
      width: 18,
      right: 25,
      bottom: 30,
    },
    [theme.breakpoints.down('sm')]: { right: 20, bottom: 40 },
  },
  '& [aria-label="gallery-teacher"] .slider-wrapper': { maxWidth: 1170 },
  [theme.breakpoints.down('lg')]: {
    padding: `42px ${theme.spacing(2.5)}`,
    '& [aria-label="gallery-teacher"] .carousel-slider': {
      height: '290px !important',
    },
    '& [aria-label="gallery-teacher"] .slider-wrapper': { maxWidth: 768 },
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(7)} 0 15px`,
    '& [aria-label="gallery-teacher"].carousel-root': { width: '100%' },
    '& [aria-label="gallery-teacher"] .carousel-slider': {
      height: '560px !important',
    },
    '& [aria-label="gallery-teacher"] .slider-wrapper': { maxWidth: 375 },
    '& [aria-label="gallery-teacher"] .control-dots': {
      padding: `0 ${theme.spacing(1.5)}`,
    },
  },
}))

export const StyledTrainerHeader = styled(Typography)(({ theme }) => ({
  span: { color: theme.palette.primary?.main },
}))

export const StyledDisplayTrainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: 260,
  ':hover': { '& .MuiTypography-root': { color: theme.palette.text?.gray } },
  img: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(2) },
  },
  [theme.breakpoints.down('lg')]: { width: 162 },
  // [theme.breakpoints.down('sm')]: { width: 155 },
  [theme.breakpoints.down('sm')]: { width: 'calc(50% - 24px)' },
}))

export const StyledDivItem = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(10),
  justifyContent: 'center',
  marginTop: theme.spacing(5.5),
  cursor: 'grab',
  [theme.breakpoints.down('lg')]: {
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(4),
    flexWrap: 'wrap',
    marginTop: theme.spacing(4),
  },
}))

export const StyledTypography = styled(Typography)(() => ({
  transition: 'all 0.3s ease',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}))
