import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledRow = styled(Row)(({ theme }) => ({
  alignItems: 'start',
  gap: theme.spacing(5),
  justifyContent: 'center',
  padding: `${theme.spacing(5)} ${theme.spacing(10)}`,
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: 0,
    padding: `${theme.spacing(3)} 0`,
  },
}))

export const StyledRightCol = styled(Column)(({ theme }) => ({
  width: 400,
  alignItems: 'start',
  padding: `${theme.spacing(4)} 0`,
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    padding: 0,
    paddingTop: theme.spacing(3),
  },
}))

export const StyledLeftContainer = styled(Column)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  width: 760,
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    gap: theme.spacing(2),
  },
}))

export const StyledLabelContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text?.secondary,
  gap: theme.spacing(1.5),
  img: { borderRadius: '100%' },
}))

export const StyledSection = styled(Column)(({ theme }) => ({
  alignItems: 'start',
  gap: theme.spacing(1.2),
  padding: `0 ${theme.spacing(5)}`,
  [theme.breakpoints.down('lg')]: {
    padding: `0 ${theme.spacing(3)}`,
  },
}))

export const StyledButtonSeeMore = styled((props) => <Button {...props} />)