export const ECONTENT_TYPE = {
  E_CONTENT: 'E_CONTENT',
  KB: 'KB',
}

export const ECONTENT_TYPE_TH_TEXT = {
  E_CONTENT: 'บทความ',
  KB: 'สาระน่ารู้',
}

export const landingTitle = {
  E_CONTENT: 'บทความแนะนำ',
  KB: 'สาระน่ารู้แนะนำ',
}

export const ECONTENT_TYPE_PATH = {
  E_CONTENT: '/e-content/Article/',
  KB: '/e-content/Fact/',
}

export const CONTENT_TYPE = {
  TEXT: 'TEXT',
  LINK: 'LINK',
  IMAGE: 'IMAGE',
  FILE: 'FILE',
  KB: 'KB',
}
