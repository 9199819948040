import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import { isHomePage } from './events'

export const StyledWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: isHomePage() ? 'calc(25% - 18px)' : '100%',
  width: '100%',
  minWidth: 274.5,
  height: '100%',
  [theme.breakpoints.down(1170)]: {
    maxWidth: isHomePage() ? 'calc(33% - 13px)' : '100%',
  },
  [theme.breakpoints.down(919)]: {
    maxWidth: isHomePage() ? 'calc(50% - 18px)' : '100%',
  },
  [theme.breakpoints.down('sm')]: { maxWidth: '100%', height: 445 },
}))

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  minWidth: 274.5,
  minHeight: 140,
  borderRadius: theme.spacing(1),
  boxShadow: '0px 4px 12px 1px rgba(73, 118, 186, 0.35)',
  backgroundColor: theme.palette?.background?.paper,
  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    height: 'auto',
    width: '90%',
    minWidth: 270,
    left: 0,
    right: 0,
    bottom: 0,
    top: '120px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    padding: theme.spacing(2),
  },
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  [theme.breakpoints.down('sm')]: { height: 411 },
}))

export const StyledContentBox = styled(Box)(({ theme }) => ({
  height: 329,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '8px',
  gap: theme.spacing(1.5),
  padding: theme.spacing(2),
  paddingTop: 0,
  [theme.breakpoints.down('sm')]: { padding: 0, height: '250px' },
}))

export const StyledTitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  gap: theme.spacing(1),
}))

export const StyledFootBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  padding: '0 16px 16px',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: 0,
  right: 0,
  bottom: 0,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: { padding: 0 },
}))

export const StyledTypography = styled(Typography)(({ variant }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: variant === 'overline' ? '1' : '2',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
}))
