import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Download from '@mui/icons-material/Download'
import {
  setCourseCompleted,
  setProgressPercent,
} from 'src/redux/slices/eLearning/learning'
import { moduleCompletedStatus } from 'src/redux/slices/eLearning/learning/events'
import {
  putCompleteCourseGeneral,
  putCompleteCourseOic,
} from 'src/services/eLearning/learning'
import { onDownload } from 'src/modules/MyClass/ELearningGrades/events'
import { Column } from 'src/components/Layout/Styled'
import { COURSE_TYPE } from 'src/constants/eLearning'
import { getLearningState } from '../events'

export const handleProgressChange = (lessons, setAlertDialog) => (dispatch) => {
  const { isCourseCompleted, progressPercent } = getLearningState()
  if (isCourseCompleted) return

  const logs = []
  _.forEach(lessons, (lesson) => {
    _.forEach(lesson.modules, (module) => {
      logs.push(module.result)
    })
  })
  const totalInprogress = logs.filter((item) =>
    moduleCompletedStatus.includes(item)
  ).length
  const percent = (totalInprogress / logs.length) * 100
  const payload = _.isNaN(percent) ? 0 : percent.toFixed()
  const newProgressPercent = Number(payload)

  if (Number(progressPercent) !== newProgressPercent) {
    dispatch(setProgressPercent(newProgressPercent))
  }

  if (newProgressPercent === 100) {
    dispatch(handleCourseComplete(setAlertDialog))
  }
}

export const handleCourseComplete = (setAlertDialog) => async (dispatch) => {
  const { course, eLearningCourseBatchLearner } = getLearningState()
  dispatch(setCourseCompleted())
  const isOic = course.courseType === COURSE_TYPE.OIC
  const body = { eLearningCourseBatchLearner }
  let response
  if (isOic) {
    response = await dispatch(putCompleteCourseOic(body))
  } else {
    response = await dispatch(putCompleteCourseGeneral(body))
  }

  if (_.isEmpty(response.error)) {
    dispatch(handleAlertComplete(response, setAlertDialog))
  } else {
    handleError409(response, setAlertDialog)
  }
}

export const handleError409 = (response, setAlertDialog) => {
  const error = _.get(response, 'meta.response', {})
  const constraints = _.get(error, 'data.constraints', {})
  const errorStatus = _.get(error, 'status', 404)
  const dialogProp = {
    isDialogOpen: true,
    iconType: 'error',
    title: _.get(constraints, 'title', 'ผิดพลาด'),
    message: _.get(constraints, 'value', ['ดำเนินการไม่สำเร็จ']),
    buttonRight:
      errorStatus === 400
        ? { handleClick: () => window.location.reload() }
        : {},
  }
  setAlertDialog((prev) => ({ ...prev, ...dialogProp }))
}

export const handleAlertComplete = (response, setAlertDialog) => (dispatch) => {
  const { uuid } = getLearningState().eLearningCourseBatchLearner
  const data = _.get(response, 'payload.data', {})
  const isHaveCert = _.get(data, 'isHaveCert', false)
  const dialogProp = {
    isDialogOpen: true,
    title: 'ยินดีด้วย! คุณอบรมสำเร็จแล้ว',
    content: (
      <Column alignItems="center" gap={3}>
        <img src="/icon/e-learning/ic_complete.svg" width={190} />
        {isHaveCert && (
          <Typography variant="body2">
            ใบรับรองประกาศนียบัตรจะได้รับเมื่อการอบรมรอบนี้จบแล้ว
          </Typography>
        )}
      </Column>
    ),
    buttonLeft: isHaveCert
      ? {
          label: 'ดาวน์โหลด',
          endIcon: <Download />,
          handleClick: () => dispatch(onDownload(uuid)),
        }
      : {},
    buttonRight: {
      label: 'สรุปผลการเรียน',
      endIcon: <ArrowForward />,
      handleClick: () =>
        (window.location.href = `/my-class/e-learning-registration-detail/${uuid}`),
    },
  }
  setAlertDialog((prev) => ({ ...prev, ...dialogProp }))
}
