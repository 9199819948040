import Box from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'
import styled from '@mui/system/styled'

export const StyledSortMenu = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  '$ .MuiTypography-root': {
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDivider-root': {
    borderColor: theme.palette?.other?.divider,
  },
}))

export const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  marginLeft: theme.spacing(1),
  '& .MuiFormControlLabel-root': { padding: '3px 0 3px 0' },
}))
