import React, { useState } from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import { shallowEqual, useSelector } from 'react-redux'
import Carousel from 'src/components/Carousel'
import { Column, Row } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { handleSelector } from '../events'
import CarouselArrow, { ArrowButton } from './components/CarouselArrow'
import HomeIndicator from './components/HomeIndicator'
import {
  StyledHeaderContent,
  StyledHomeHeader,
  StyledImagePreview,
  StyledTypography,
  StyledVideoPreview,
  StyledSecondaryButton,
  StyledOverlayBg,
} from './Styled'
import { handleButtonSize, handleClickBtn } from './events'

const HomeHeader = () => {
  const bannerSection = useSelector(
    (state) => handleSelector(state, 'bannerSection'),
    shallowEqual
  )
  const bannerList = bannerSection.filter((item) => item.status)
  const [selected, setSelected] = useState(0)

  return (
    <StyledHomeHeader>
      <Carousel
        renderArrowPrev={(onClickHandler) => (
          <RenderedArrow left onClick={onClickHandler} />
        )}
        renderArrowNext={(onClickHandler) => (
          <RenderedArrow right onClick={onClickHandler} />
        )}
        showIndicators={false}
        selectedItem={selected}
        onChange={(index) => setSelected(index)}
      >
        {bannerList.length > 0 &&
          bannerList.map((item, index) => (
            <BannerMedia key={index} data={item} />
          ))}
      </Carousel>
      <CustomIndicator
        list={bannerList}
        selected={selected}
        setSelected={setSelected}
      />
    </StyledHomeHeader>
  )
}

export default HomeHeader

export const RenderedArrow = ({ left, right, onClick }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  if (!isNotSm) return <></>
  return <CarouselArrow left={left} right={right} onClick={onClick} />
}

export const CustomIndicator = (props) => {
  const { selected, setSelected } = props
  const isNotSm = isMediaWidthMoreThan('sm')

  if (isNotSm) return <HomeIndicator {...props} />
  return (
    <Row px={2.5} py={4} justifyContent="space-between">
      <ArrowButton left onClick={() => setSelected(selected - 1)} />
      <HomeIndicator {...props} />
      <ArrowButton right onClick={() => setSelected(selected + 1)} />
    </Row>
  )
}

export const BannerMedia = ({ data }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  const mediaType = _.get(data, 'mediaType', '')
  const isVideo = mediaType == 'VIDEO'
  const image = _.get(data, 'image', '')
  const mobileImage = isVideo
    ? _.get(data, 'videoImg', '')
    : _.get(data, 'mobileImg', '')
  const imageSrc = `${window.__env__.REACT_APP_FILE_URL}${
    isNotSm ? image : mobileImage
  }`
  return (
    <div>
      <StyledOverlayBg />
      {isVideo && isNotSm ? (
        <StyledVideoPreview url={_.get(data, 'videoURL', '')} />
      ) : (
        <StyledImagePreview src={imageSrc} />
      )}
      {isNotSm && <HeaderContent data={data} />}
      {!isNotSm && <HeaderContentSm data={data} />}
    </div>
  )
}

export const HeaderContent = ({ data }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')
  const btnSize = handleButtonSize()
  return (
    <StyledHeaderContent>
      <StyledTypography
        variant={isLg ? 'h6' : 'body2b'}
        color={isNotSm ? 'text.white' : 'text.secondary'}
        dangerouslySetInnerHTML={{ __html: _.get(data, 'subtitle', '') }}
      />
      <StyledTypography
        variant={isLg ? 'h2' : 'h4'}
        color={isNotSm ? 'text.white' : 'primary.main'}
        dangerouslySetInnerHTML={{ __html: _.get(data, 'title', '') }}
      />
      <StyledTypography
        id="content"
        color={isNotSm ? 'text.white' : 'text.secondary'}
        dangerouslySetInnerHTML={{ __html: _.get(data, 'description', '') }}
      />
      {data.primaryStatus && (
        <Button
          size={btnSize}
          sx={{ width: isNotSm ? 200 : 'auto' }}
          data-testid="btn-primary"
          variant="contained"
          color={_.toLower(_.get(data, 'colorButton', 'PRIMARY'))}
          onClick={() => handleClickBtn(_.get(data, 'primaryButtonURL', ''))}
        >
          {_.get(data, 'primaryButtonTitle', '')}
        </Button>
      )}
      {data.secondaryStatus && (
        <StyledSecondaryButton
          size={btnSize}
          data-testid="btn-secondary"
          variant="outlined"
          color={_.toLower(_.get(data, 'colorButton', 'PRIMARY'))}
          onClick={() => handleClickBtn(_.get(data, 'secondaryButtonURL', ''))}
        >
          {_.get(data, 'secondaryButtonTitle', '')}
        </StyledSecondaryButton>
      )}
    </StyledHeaderContent>
  )
}

export const HeaderContentSm = ({ data }) => {
  return (
    <Column mt={4} gap={4}>
      <Column gap={2}>
        <Column>
          <StyledTypography
            variant="body2b"
            color="text.secondary"
            dangerouslySetInnerHTML={{
              __html: _.get(data, 'subtitle', ''),
            }}
          />
          <StyledTypography
            variant="h4"
            color="primary"
            dangerouslySetInnerHTML={{ __html: _.get(data, 'title', '') }}
          />
        </Column>
        <StyledTypography
          variant="body2"
          color="text.secondary"
          dangerouslySetInnerHTML={{
            __html: _.get(data, 'description', ''),
          }}
        />
      </Column>
      <Row gap={2} justifyContent="center">
        {data.primaryStatus && (
          <Button
            data-testid="btn-primary"
            variant="contained"
            color={_.toLower(_.get(data, 'colorButton', 'PRIMARY'))}
            onClick={() => handleClickBtn(_.get(data, 'primaryButtonURL', ''))}
          >
            {_.get(data, 'primaryButtonTitle', '')}
          </Button>
        )}
        {data.secondaryStatus && (
          <StyledSecondaryButton
            data-testid="btn-secondary"
            variant="outlined"
            color={_.toLower(_.get(data, 'colorButton', 'PRIMARY'))}
            onClick={() =>
              handleClickBtn(_.get(data, 'secondaryButtonURL', ''))
            }
          >
            {_.get(data, 'secondaryButtonTitle', '')}
          </StyledSecondaryButton>
        )}
      </Row>
    </Column>
  )
}
