import React from 'react'
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew'
import { StyledBoxShadow, StyledIconButton } from './Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'

const CarouselArrow = ({ mx = 16, left, right, onClick }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const size = isLg ? 100 : 68
  const iconPosition = mx + size / 2
  const styleKey = right ? 'right' : 'left'
  return (
    <>
      <StyledBoxShadow btnSize={size} sx={{ [styleKey]: mx }} />
      <ArrowButton
        left={left}
        right={right}
        onClick={onClick}
        sx={{ [styleKey]: iconPosition }}
      />
    </>
  )
}

export default CarouselArrow

export const ArrowButton = ({ left, right, sx, onClick }) => {
  const transform = right ? '50%' : '-50%'
  return (
    <StyledIconButton sx={sx} transform={transform} onClick={onClick}>
      {right && <ArrowForwardIos />}
      {left && <ArrowBackIosNew />}
    </StyledIconButton>
  )
}
