import React from 'react'
import _ from 'lodash'
import {
  openDialog as openEnrollDialog,
  closeDialog as closeEnrollDialog,
  setChangeBody,
  setChangeCamera,
} from 'src/redux/slices/eLearning/enroll'
import {
  postEnrollRekognition,
  postFailScanCount,
  postVerifyFace,
  putFailScanCount,
} from 'src/services/eLearning/enroll'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { closeDialog, openDialog } from 'src/redux/slices/alertDialog'
import {
  ENROLL_REKOGNITION_STATUS,
  REKOGNITION_EVENT,
} from 'src/constants/eLearning'
import { ERROR_OBJ, ERROR_TYPE } from 'src/components/FaceLiveness/model'
import { getEnrollState } from '../../events'
import { handleDisallowScan, handleVerifySuccess } from '../events'
import CameraDialog from './CameraDialog'

export const handleOpenCameraDialog = () => (dispatch) => {
  dispatch(setChangeCamera({ key: 'isOpen', value: false }))
  dispatch(openEnrollDialog({ content: <CameraDialog /> }))
}

export const handleDetectFace = (livenessData) => async (dispatch) => {
  const { body, eLearningIdCardEnroll, failLogUuid } = getEnrollState()
  const idCardImage = _.get(body, 'idCardImage', '')
  const payload = {
    sessionId: livenessData.sessionId,
    body: { targetImage: idCardImage },
  }
  dispatch(startLoading())
  const response = await dispatch(postVerifyFace(payload))

  if (_.isEmpty(response.error)) {
    const data = _.get(response.payload, 'data', {})
    const faceImageKey = _.get(data, 'faceImageKey', '')
    const status = _.get(data, 'status', '')
    const payload = {
      eLearningCourseBatch: body.batch,
      eLearningIdCardEnroll: eLearningIdCardEnroll,
      event: REKOGNITION_EVENT.ENROLL,
      faceImageKey,
      status,
      percent: _.get(data, 'percent', 0),
      times: 1,
    }

    const resEnroll = await dispatch(postEnrollRekognition(payload))
    dispatch(stopLoading())

    if (
      _.isEmpty(resEnroll.error) &&
      status === ENROLL_REKOGNITION_STATUS.SUCCESS
    ) {
      const payload = { key: 'faceImage', value: faceImageKey }
      dispatch(setChangeBody(payload))

      const updatePayload = { uuid: failLogUuid, checkStatus: 'pass' }
      await dispatch(putFailScanCount(updatePayload))
      dispatch(handleVerifySuccess('ยืนยันตัวตนสำเร็จ'))
    } else dispatch(handleVerifyFail(ERROR_TYPE.VERIFY))
  } else {
    dispatch(stopLoading())
    const errorKey = response.meta.response.data.constraints.key
    const errorType =
      errorKey === ERROR_TYPE.DETECT ? ERROR_TYPE.DETECT : ERROR_TYPE.SYSTEM
    dispatch(handleVerifyFail(errorType))
  }
}

export const handleVerifyFail = (type) => async (dispatch) => {
  const errorObj = _.get(ERROR_OBJ, type, ERROR_OBJ[ERROR_TYPE.SYSTEM])
  if (!errorObj.isCount) {
    dispatch(openScanErrorDialog(type))
    return
  }

  const { data, failLogUuid } = getEnrollState()
  dispatch(startLoading())
  let response = {}
  if (_.isEmpty(failLogUuid)) {
    response = await dispatch(postFailScanCount({ eLearningCourse: data }))
  } else {
    const payload = { uuid: failLogUuid, checkStatus: 'fail' }
    response = await dispatch(putFailScanCount(payload))
  }
  dispatch(stopLoading())

  const expireDateTime = _.get(response.payload.data, 'registerBreakTime', '')
  if (!_.isEmpty(expireDateTime)) {
    dispatch(closeDialog())
    dispatch(handleDisallowScan(response))
  } else {
    dispatch(openScanErrorDialog(type))
  }
}

export const openScanErrorDialog = (type) => (dispatch) => {
  const { title, message } = ERROR_OBJ[type]
  dispatch(
    openDialog({
      isCloseDialog: false,
      iconType: 'error',
      title,
      message: _.concat(message),
      buttonLeft: {
        handleClick: () => {
          dispatch(closeDialog())
          dispatch(closeEnrollDialog())
        },
      },
      buttonRight: {
        label: 'ลองอีกครั้ง',
        handleClick: () => {
          dispatch(closeDialog())
          dispatch(handleOpenCameraDialog())
        },
      },
    })
  )
}
