import React, { useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import CustomTabs from 'src/components/Tabs'
import Condition from './Condition'
import Detail from './Detail'
import { StyledTabBox, StyledTabContent } from './Styled'
import HtmlContent from './HtmlContent'
import { shallowEqual, useSelector } from 'react-redux'
import { handleDisplayTab } from './events'
import { classTabLabel } from 'src/constants/course'

const CourseTab = () => {
  const [value, setValue] = useState(0)
  const classDetail = useSelector(
    (state) => state.classDetail.data,
    shallowEqual
  )
  const classTab = useMemo(() => handleDisplayTab(classDetail), [classDetail])
  return (
    <Box>
      <StyledTabBox>
        <CustomTabs
          listTab={classTab}
          value={value}
          onChange={(tab) => setValue(tab)}
        />
      </StyledTabBox>
      {classTabLabel.map((label, index) => (
        <TapContent key={index} value={value} index={index}>
          {DisplayContent[value]}
        </TapContent>
      ))}
    </Box>
  )
}

export default CourseTab

export const DisplayContent = [
  <Detail key="0" />,
  <HtmlContent key="1" field="courseDetail.coursePurpose" />,
  <HtmlContent key="2" field="courseDetail.courseOutline" />,
  <Condition key="3" />,
  <HtmlContent key="4" field="trainingPlanData.moreDetail" />,
]

export const TapContent = ({ children, value, index }) => {
  return (
    <StyledTabContent
      hidden={value !== index}
      id={`course-tabcontent-${index}`}
      aria-labelledby={`course-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </StyledTabContent>
  )
}
