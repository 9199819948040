import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledIndicatorWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  bottom: 24,
  left: '50%',
  transform: 'translate(-50%)',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderRadius: theme.spacing(3),
  background: 'rgba(0, 0, 0, 0.20)',
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    position: 'relative',
    background: 'transparent',
    bottom: 0,
    padding: 0,
    left: 'unset',
    transform: 'unset',
  },
}))

export const StyledIndicator = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }) => ({
  cursor: 'pointer',
  background: isActive ? theme.palette?.info?.main : '#cccccc',
  width: isActive ? 24 : 8,
  height: 8,
  borderRadius: theme.spacing(5),
  transition: 'ease 0.1s all',
  ':hover': { background: theme.palette?.info?.main },
}))

export const StyledCountBox = styled(Box)(({ theme }) => ({
  background: theme.palette?.info?.main,
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  borderRadius: theme.spacing(5),
  '& > .MuiTypography-root': {
    fontSize: '18px',
    lineHeight: '18px',
    color: theme.palette?.text?.white,
  },
}))
