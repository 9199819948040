import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { handleTrueFalseChange } from '../events'
import {
  StyledHeadContainer,
  StyledQuestionContainer,
  StyledQuestionList,
  StyledRadioContainer,
} from './Styled'

const TrueFalse = ({ uuid, question, resultProps }) => {
  const choices = _.get(question, 'answers', [])
  const dispatch = useDispatch()
  const { answerETesting, correctAnswer } = useSelector(
    (state) => ({
      answerETesting: state.eTesting.answerETesting.filter(
        (item) => item.questionUuid == uuid
      ),
      questionResult: state.eTesting.questionResult,
      correctAnswer: state.eTesting.correctAnswers.find(
        (item) => item.questionUuid == uuid
      ),
    }),
    shallowEqual
  )
  return (
    <>
      <StyledHeadContainer>
        <Typography color="text.secondary" variant="body1b">
          ใช่
        </Typography>
        <Typography color="text.secondary" variant="body1b">
          ไม่ใช่
        </Typography>
      </StyledHeadContainer>
      {choices.map((choice, index) => {
        const answer = _.get(correctAnswer, 'answers', []).find(
          (ans) => ans.answerUuid === choice.uuid
        )
        const booleanAns = _.get(answer, 'booleanAns', '')
        const answerObj = answerETesting.find(
          (ans) => ans.answerUuid === choice.uuid
        )
        const value = _.get(answerObj, 'isValueAns', '')
        const isAnswer =
          value === String(choice.booleanAns) || value === String(booleanAns)
        return (
          <StyledQuestionList
            key={index}
            resultProps={{ ...resultProps, isAnswer }}
          >
            <StyledQuestionContainer>
              <Typography variant="body1" sx={{ minWidth: 15 }}>
                {index + 1} )
              </Typography>
              <Typography sx={{ wordBreak: 'break-word' }} variant="body1">
                {choice.title}
              </Typography>
            </StyledQuestionContainer>
            <RadioGroup
              value={value}
              onClick={(e) =>
                dispatch(handleTrueFalseChange(e, uuid, choice.uuid))
              }
            >
              <StyledRadioContainer>
                <FormControlLabel value={true} control={<Radio />} />
                <FormControlLabel value={false} control={<Radio />} />
              </StyledRadioContainer>
            </RadioGroup>
          </StyledQuestionList>
        )
      })}
    </>
  )
}

export default TrueFalse
