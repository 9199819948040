import { COURSE_ORDER } from 'src/constants/eLearning'

export const initialState = {
  isLoadMore: false,
  isSuccess: false,
  filterData: null,
  filterBody: {
    order: COURSE_ORDER.DESC,
    sortBy: 'POPULAR',
    learningPathStatus: [],
    myLearningPath: [],
    acquiredSkill: [],
    productType: [],
    name: '',
    pathHourFrom: 0,
    pathHourTo: 0,
    startDate: '',
    finishDate: '',
    page: 1,
    limit: 12,
  },
  learningPaths: [],
  totalLearningPath: 0,
  error: null,
}

export const MY_LEARNING_PATH_LABEL = {
  INPROGRESS: 'เส้นทางการเรียนรู้ที่กำลังเรียน',
  LIKE: 'เส้นทางการเรียนรู้ที่ถูกใจ',
}
