import dayjs from 'dayjs'
import _ from 'lodash'

export const handleTimestampText = (date) => {
  const now = dayjs(new Date())
  const convertedDate = `${dayjs(new Date(date)).format('MM/DD/YYYY')} ${dayjs(
    new Date(date)
  )
    .utc()
    .format(window.__env__.REACT_APP_DATE_TIME)}`

  const dataDate = dayjs(new Date(convertedDate))
  const minDiff = now.diff(dataDate, 'minutes', true)
  const hourDiff = now.diff(dataDate, 'hours', true)
  const dayDiff = now.diff(dataDate, 'day', true)
  const monthDiff = now.diff(dataDate, 'month', true)
  const yearDiff = now.diff(dataDate, 'year', true)
  const timeObj = { value: '', prefix: '' }

  if (minDiff / 60 < 1) {
    timeObj.value = minDiff
    timeObj.prefix = 'นาที'
  } else if (hourDiff / 24 < 1) {
    timeObj.value = hourDiff
    timeObj.prefix = 'ชั่วโมง'
  } else if (dayDiff / 30 < 1) {
    timeObj.value = dayDiff
    timeObj.prefix = 'วัน'
  } else if (monthDiff / 12 < 1) {
    timeObj.value = monthDiff
    timeObj.prefix = 'เดือน'
  } else {
    timeObj.value = yearDiff
    timeObj.prefix = 'ปี'
  }

  timeObj.value = _.round(timeObj.value)
  return timeObj
}
