import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Column, Row } from 'src/components/Layout/Styled'
import { StyledInputHidden } from 'src/modules/Document/DocumentInput/Styled'
import { ENROLL_INPUT_TYPE } from 'src/constants/eLearning'
import { SubLabel } from '../..'
import { StyledButton, StyledInputWrapper, StyledTypography } from './Styled'
import { handleUploadFile } from './events'

const UploadFileInput = () => {
  const { idCardImage, typeInput } = useSelector(
    (state) => ({
      idCardImage: state.eLearningEnroll.body.idCardImage || '',
      typeInput: state.eLearningEnroll.body.typeInput,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const imageName = idCardImage.split('/')[3] || ''
  const isDisplay = imageName && typeInput === ENROLL_INPUT_TYPE.UPLOAD
  return (
    <Column gap={1}>
      <StyledInputWrapper>
        <SubLabel label="บัตรประชาชน / ใบขับขี่" />
        <Typography variant="body2" color="text.gray">
          ไฟล์ที่รองรับ .jpg, .jpeg, .png ขนาดของไฟล์ที่แนะนำไม่เกิน 2 MB
        </Typography>
      </StyledInputWrapper>
      <label htmlFor="upload-file">
        <Row sx={{ height: 40, border: '1px solid #ccc' }}>
          <StyledButton component="span">เลือกไฟล์</StyledButton>
          <StyledTypography color={isDisplay ? 'text.primary' : 'text.silver'}>
            {isDisplay ? imageName : 'กรุณาเลือกไฟล์'}
          </StyledTypography>
        </Row>
        <StyledInputHidden
          data-testid="upload-file"
          id="upload-file"
          accept=".jpg, .jpeg, .png"
          type="file"
          onChange={(e) => dispatch(handleUploadFile(e))}
        />
      </label>
    </Column>
  )
}

export default UploadFileInput
