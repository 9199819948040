export const initialState = {
  isLoadMore: false,
  isSuccess: false,
  filterData: null,
  filterBody: {
    sortBy: 'POPULAR',
    trainingPlatform: [],
    roadMap: [],
    courseStatus: [],
    acquiredSkill: [],
    productType: [],
    courseName: '',
    courseHourFrom: 0,
    courseHourTo: 0,
    startDate: '',
    finishDate: '',
    page: 1,
  },
  courses: [],
  totalCourses: 0,
  error: null,
}
