import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Alert from '@mui/material/Alert'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import PaidOutlined from '@mui/icons-material/PaidOutlined'
import { classRegisterStatus, courseInclude } from 'src/constants/course'
import { convertDateRange, isMediaWidthMoreThan } from 'src/utils/lib'
import { Column } from 'src/components/Layout/Styled'
import ButtonAndAmount from '../ButtonAndAmount'
import {
  StyledContentColumn,
  StyledDetailCard,
  StyledDivider,
  StyledFixedFooter,
  StyledIconContent,
  StyledImage,
  StyledIncludeRow,
  StyledLeftBox,
  StyledRightBox,
  StyledStatusRow,
} from './Styled'

const DetailCard = ({ socketId }) => {
  const isWidthLg = isMediaWidthMoreThan('lg')
  const data = useSelector((state) => state.classDetail.data, shallowEqual)
  const courseDetail = _.get(data, 'courseDetail', null)
  const trainingPlanData = _.get(data, 'trainingPlanData', null)
  const isAttendance = _.get(courseDetail, 'isAttendance', false)
  const preTest = _.get(courseDetail, 'preTest', false)
  const postTest = _.get(courseDetail, 'postTest', false)
  const evaluation = _.get(courseDetail, 'evaluation', false)
  const assessment = _.get(courseDetail, 'assessment', false)
  const eCert = _.get(courseDetail, 'eCertificationVersion', false)
  const paidCost = _.get(courseDetail, 'paidCost', false)
  const registerDate = convertDateRange(
    _.get(trainingPlanData, 'registerDateRange', null)
  )
  const canRegister = _.get(trainingPlanData, 'canRegister', 'CLOSED')
  const availableSeats = _.get(trainingPlanData, 'availableSeats', 0)
  const registerStatus = _.get(
    classRegisterStatus(availableSeats),
    canRegister,
    {}
  )
  const publicClass = _.get(trainingPlanData, 'publicClass', false)
  const image = _.get(courseDetail, 'imageKey', '')
  const src = `${window.__env__.REACT_APP_FILE_URL}${image}`

  return (
    <>
      <StyledDetailCard>
        <StyledLeftBox>
          <StyledImage src={src} alt="preview_img" />
          <StyledContentColumn>
            <Typography
              variant={isWidthLg ? 'h3' : 'h5'}
              sx={{ wordBreak: 'break-word' }}
            >
              {_.get(courseDetail, 'nameForLearner', '') ||
                _.get(courseDetail, 'englishName', '-')}
            </Typography>
            <StyledIncludeRow>
              {isAttendance && <IconContent field="attendance" />}
              {preTest && <IconContent field="preTest" />}
              {postTest && <IconContent field="postTest" />}
              {evaluation && <IconContent field="evaluation" />}
              {eCert && <IconContent field="eCert" />}
              {assessment && <IconContent field="assessment" />}
            </StyledIncludeRow>
          </StyledContentColumn>
        </StyledLeftBox>
        <StyledDivider />
        <StyledRightBox>
          <Column sx={{ gap: 0.5, textAlign: 'center' }}>
            <StyledStatusRow>
              {_.get(registerStatus, 'icon', <></>)}
              <Typography variant="h5">
                {_.get(registerStatus, 'text', '-')}
              </Typography>
            </StyledStatusRow>
            {publicClass && (
              <Typography variant="body1">
                เปิดรับสมัคร {registerDate}
              </Typography>
            )}
          </Column>

          <ButtonAndAmount socketId={socketId} />
          {paidCost && (
            <Alert
              sx={{ alignItems: 'center' }}
              variant="outlined"
              severity="info"
              icon={<PaidOutlined />}
            >
              หลักสูตรนี้มีค่าใช้จ่ายโปรดดำเนินการชำระเงินก่อนสมัคร
              สอบถามเพิ่มเติม{' '}
              {
                <Link target="_blank" href="/location">
                  ติดต่อเจ้าหน้าที่
                </Link>
              }
            </Alert>
          )}
        </StyledRightBox>
      </StyledDetailCard>
      <FixedFooterContent socketId={socketId} />
    </>
  )
}

export default DetailCard

export const IconContent = ({ field }) => {
  const include = _.get(courseInclude, field, 'attendance')
  return (
    <StyledIconContent>
      {_.get(include, 'icon', <></>)}
      <Typography>{_.get(include, 'label', <></>)}</Typography>
    </StyledIconContent>
  )
}

export const FixedFooterContent = ({ socketId }) => {
  return (
    <StyledFixedFooter>
      <ButtonAndAmount fixed socketId={socketId} />
    </StyledFixedFooter>
  )
}
