import { createTheme } from '@mui/material/styles'

import htcBd from './fonts/db-helvethaica-x-it-v3.2-webfont.woff2'
import htcBd2 from './fonts/db-helvethaica-x-bd-v3.2-webfont.woff'
import htcIta from './fonts/db-helvethaica-x-it-v3.2-webfont.woff'
import htcIta2 from './fonts/db-helvethaica-x-it-v3.2-webfont.woff2'
import htcMd from './fonts/db-helvethaica-x-med-v3.2-webfont.woff'
import htcMd2 from './fonts/db-helvethaica-x-med-v3.2-webfont.woff2'
import htcRgl from './fonts/db-helvethaica-x-v3.2-webfont.woff'
import htcRgl2 from './fonts/db-helvethaica-x-v3.2-webfont.woff2'

import { themeColor, fontStyle } from './themeData'
export const theme = createTheme({
  spacing: 8,
  palette: themeColor,
  components: {
    MuiTypography: {
      variants: [
        {
          props: { weight: '400' },
          style: { fontWeight: 500 },
        },
        {
          props: { weight: '500' },
          style: { fontWeight: 500 },
        },
        {
          props: { weight: '700' },
          style: { fontWeight: 700 },
        },
        {
          props: { size: '16' },
          style: { fontSize: 16 },
        },
        {
          props: { size: '24' },
          style: { fontSize: 24 },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        select: { padding: '6px', borderColor: themeColor?.text?.silver },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          '&.Mui-disabled': {
            backgroundColor: themeColor.text.wildSand,
            WebkitTextFillColor: themeColor.text.silver,
          },
        },
        input: {
          borderColor: themeColor?.text?.silver,
          height: 24,
          padding: '13px',
          '&.Mui-disabled': {
            backgroundColor: themeColor.text.wildSand,
            WebkitTextFillColor: themeColor.text.silver,
          },
        },
      },
    },
    MuiInputLabel: {
      variants: [
        {
          props: { required: true },
          style: {
            fontSize: fontStyle.body2,
            span: {
              color: themeColor.error.main,
            },
          },
        },
      ],
    },
    MuiInputBase: {
      variants: [
        {
          props: { type: 'basic' },
          style: {
            borderRadius: '4px',
            border: `1px solid ${themeColor.text.silver}`,
            paddingLeft: '8px',
            '&.Mui-disabled': {
              backgroundColor: `${themeColor.text.wildSand}`,
            },
          },
        },
        {
          props: { inputAdd: 'adorned' },
          style: {
            border: `1px solid ${themeColor.text.silver}`,
            paddingLeft: '8px',
          },
        },
      ],
    },
    MuiRadio: {
      styleOverrides: {
        root: { padding: '6px' },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color:
            ownerState.variant == 'contained'
              ? themeColor[ownerState.color].contrast
              : themeColor[ownerState.color].main,
          borderRadius: '0px',
          boxShadow:
            ownerState.color == 'primary' && ownerState.variant == 'contained'
              ? 'inset 0px -3px 0px #00006D'
              : 'none',
          overflow: 'hidden',
          position: 'relative',
          zIndex: 1,
          '&:after': {
            background:
              ownerState.variant == 'text'
                ? themeColor[ownerState.color].outlinedHoverBackground
                : themeColor[ownerState.color].dark,
            content: '""',
            opacity: 0,
            width: 0,
            height: 880,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0px) rotate(45deg)',
            transition: 'all 550ms cubic-bezier(0.19, 1, 0.22, 1)',
            zIndex: -1,
          },
          ':hover': {
            color:
              ownerState.variant == 'text'
                ? themeColor[ownerState.color].main
                : themeColor[ownerState.color].contrast,
            background:
              ownerState.variant == 'text'
                ? 'transparent'
                : themeColor[ownerState.color].main,
            boxShadow: 'none',
            '&:after': {
              opacity: 1,
              width: '100%',
              transition: 'width 0.3s ease 0s, opacity 0.3s ease 0s',
            },
          },
        }),
      },
      variants: [
        {
          props: { size: 's' },
          style: {
            height: 30,
            fontSize: 17,
            padding: '4px 10px',
            '& .MuiSvgIcon-root': { width: 18, height: 18 },
          },
        },
        {
          props: { size: 'm' },
          style: {
            height: 40,
            fontSize: 18,
            '& .MuiSvgIcon-root': { width: 20, height: 20 },
          },
        },
        {
          props: { size: 'l' },
          style: {
            height: 50,
            fontSize: 19,
            padding: '12px 35px',
            '& .MuiSvgIcon-root': { width: 24, height: 24 },
          },
        },
        {
          props: { variant: 'outlined' },
          style: { border: `1px solid `, borderRadius: '0px' },
        },
        {
          props: { variant: 'upload' },
          style: {
            border: `1px dashed ${themeColor.other.outlineBorder}`,
            borderRadius: '4px',
            minHeight: '150px',
          },
        },
      ],
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontSize: 18,
          weight: 400,
          style: 'normal',
        },
        selectIcon: {
          top: 3,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        paddingCheckbox: {
          padding: '7px 0 0 0',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        edgeEnd: {
          color: themeColor.primary.main,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: { padding: '0px' },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        style: {
          borderRadius: '4px',
          border: `1px solid ${themeColor.text.silver}`,
          paddingLeft: '8px',
          '&.Mui-disabled': {
            backgroundColor: `${themeColor.text.wildSand}`,
          },
        },
      },
      variants: [
        {
          props: { type: 'hover' },
          style: {
            '&:hover': {
              backgroundColor: themeColor.action.hover,
            },
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Helvethaica';
          src:local('Helvethaica'), local('Helvethaica-Regular'), url(${htcRgl2}) format('woff2'),
              url(${htcRgl}) format('woff');
          font-weight: 400;
          font-style: normal;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
        @font-face {
          font-family: 'Helvethaica';
          src: local('Helvethaica'), local('Helvethaica-Bold'), url(${htcBd2}) format('woff2'),
              url(${htcBd}) format('woff');
          font-weight: 700;
          font-style: bold;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Helvethaica';
          src: local('Helvethaica'), local('Helvethaica-Medium'), url(${htcMd2}) format('woff2'),
              url(${htcMd}) format('woff');
          font-weight: 500;
          font-style: normal;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Helvethaica';
          src: local('Helvethaica'), local('Helvethaica-Italic'), url(${htcIta2}) format('woff2'),
              url(${htcIta}) format('woff');
          font-weight: 400;
          font-style: italic;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiCheckbox: {
      variants: [
        {
          props: { typeColor: 'oceanBlue' },
          style: {
            color: themeColor.blue.oceanBlue,
            '&.Mui-checked': {
              color: themeColor.blue.oceanBlue,
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltipArrow: {
          background: themeColor.primary.main,
        },
        arrow: {
          color: themeColor.primary.main,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          color: themeColor.error.main,
          ...fontStyle.body2,
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            height: 24,
            borderColor: 'other.outlineBorder',
            fontWeight: 400,
            fontSize: '17px',
            span: {
              padding: '0 10px',
            },
          },
        },
        {
          props: { variant: 'card-open' },
          style: {
            height: 28,
            fontWeight: 500,
            fontSize: '17px',
            span: { padding: '0 10px', color: '#ffffff' },
            backgroundColor: '#3B3FD8',
          },
        },
        {
          props: { variant: 'card-soon' },
          style: {
            height: 28,
            fontWeight: 500,
            fontSize: '17px',
            span: { padding: '0 10px', color: '#ffffff' },
            backgroundColor: '#999999',
          },
        },
        {
          props: { variant: 'cardIcon' },
          style: {
            height: 28,
            span: {
              display: 'flex',
              alignItems: 'center',
              padding: '0 10px',
              color: '#ffffff',
              svg: { fontSize: '15px' },
            },
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          '& .MuiPaper-root': {
            borderRadius: '2px',
          },
        },
      },
      variants: [
        {
          props: { maxWidth: 'xs' },
          style: {
            '& .MuiPaper-root': {
              maxWidth: '335px',
            },
          },
        },
        {
          props: { maxWidth: 'sm' },
          style: {
            '& .MuiPaper-root': {
              maxWidth: '500px',
            },
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'question' },
          style: {
            borderRadius: '8px',
            boxShadow: '0px 4px 40px 1px rgba(73, 118, 186, 0.1)',
          },
        },
      ],
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'small' },
          style: {
            width: 16,
            height: 16,
          },
        },
      ],
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: 'info' },
          style: {
            borderColor: themeColor.info.main,
            '& .MuiAlert-icon': { color: themeColor.info.main },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: { borderColor: themeColor.other.divider },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Helvethaica',
    ...fontStyle,
  },
})
