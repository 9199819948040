import React from 'react'
import UploadFile from '@mui/icons-material/UploadFile'
import MonetizationOnOutlined from '@mui/icons-material/MonetizationOnOutlined'

export const SECTION_KEY = {
  COURSE: 'COURSE',
  E_LEARNING: 'E_LEARNING',
  LEARNING_PATH: 'LEARNING_PATH',
  COLLECTION: 'COLLECTION',
}

export const SECTION_OBJ = {
  [SECTION_KEY.E_LEARNING]: {
    unit: 'บทเรียน',
    keyCount: 'lessonAmount',
    link: '/e-learning/course',
  },
  [SECTION_KEY.COURSE]: {
    link: '/course',
  },
  [SECTION_KEY.LEARNING_PATH]: {
    title: 'เส้นทางการเรียนรู้',
    unit: 'หลักสูตร',
    keyCount: 'courseCount',
    link: '/e-learning/learning-path',
  },
  [SECTION_KEY.COLLECTION]: {
    title: 'Collection',
    unit: 'หลักสูตร',
    keyCount: 'courseCount',
    link: '/e-learning/collection',
  },
}

export const COURSE_ICON_KEY = {
  FACE_DETECTION: 'FACE_DETECTION',
  UPLOAD_FILE: 'UPLOAD_FILE',
  CERTIFICATE: 'CERTIFICATE',
  PAID: 'PAID',
}

export const COURSE_ICON = {
  [COURSE_ICON_KEY.FACE_DETECTION]: {
    title: 'ถ่ายรูปยืนยันตัวตน',
    icon: <img src="/icon/ic_face_detection.svg" />,
  },
  [COURSE_ICON_KEY.UPLOAD_FILE]: {
    title: 'อัปโหลดเอกสารยืนยันตัวตน',
    icon: <UploadFile color="primary" fontSize="20px" />,
  },
  [COURSE_ICON_KEY.CERTIFICATE]: {
    title: 'มีประกาศนียบัตร',
    icon: <img src="/icon/ic_certificate_outlined.svg" />,
  },
  [COURSE_ICON_KEY.PAID]: {
    title: 'มีค่าใช้จ่าย',
    icon: <MonetizationOnOutlined color="primary" fontSize="20px" />,
  },
}
