import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const HeaderSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const Section = styled(Box)(() => ({
  borderLeft: '1px solid #BDBDBD',
}))

export const SectionContent = styled(Box)(({ theme }) => ({
  border: '1px solid #DBE4F1',
  margin: theme.spacing(3),
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
  borderRadius: '2px',
}))

export const TypographyIcon = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

export const BlueCircle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.background?.paper,
  backgroundColor: theme.palette.primary?.main,
  width: 24,
  height: 24,
  borderRadius: '50%',
}))

export const Content = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(12),
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 20,
    marginRight: 20,
  },
}))
