import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Column } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import ContentTab from './ContentTab'
import {
  StyledLabelContent,
  StyledLeftContainer,
  StyledRightCol,
  StyledRow,
  StyledSection,
} from './Styled'

const Content = () => {
  const [isShowAll, setShowAll] = useState(false)
  const trainingPlanData = useSelector(
    (state) => _.get(state.classDetail.data, 'trainingPlanData', null),
    shallowEqual
  )
  const contact = _.get(trainingPlanData, 'contact', '')
  const assignee = _.get(trainingPlanData, 'assignee', [])
  const assigneeDisplay = useMemo(
    () => (isShowAll ? assignee : _.slice(assignee, 0, 5)),
    [assignee, isShowAll]
  )
  return (
    <StyledRow>
      <StyledLeftContainer>
        <ContentTab />
      </StyledLeftContainer>

      <StyledRightCol>
        <Section title="วิทยากรบรรยาย">
          {assigneeDisplay.map((data, index) => (
            <AssigneeContent key={index} data={data} />
          ))}
          {assignee.length > 5 && (
            <MoreLessButton
              sx={{ ml: 6 }}
              isShowAll={isShowAll}
              setShowAll={setShowAll}
            />
          )}
        </Section>
        {contact != '' && <ContactSection contact={contact} />}
      </StyledRightCol>
    </StyledRow>
  )
}

export default Content

export const Section = ({ title, children }) => {
  const isWidthLg = isMediaWidthMoreThan('lg')

  return (
    <StyledSection>
      <Typography variant={isWidthLg ? 'h6' : 'body1b'}>{title}</Typography>
      {children}
    </StyledSection>
  )
}

export const AssigneeContent = ({ data }) => {
  const image = _.get(data, 'image', '')
  return (
    <StyledLabelContent>
      <img
        src={`${window.__env__.REACT_APP_FILE_URL}${image}`}
        width={40}
        height={40}
        style={{ objectFit: 'cover' }}
      />
      {_.get(data, 'firstNameTH', '')} {_.get(data, 'lastNameTH', '')}
    </StyledLabelContent>
  )
}

export const MoreLessButton = ({ isShowAll, setShowAll, sx }) => {
  if (!isShowAll) {
    return (
      <Button
        sx={sx}
        data-testid="btn-more"
        size="s"
        onClick={() => setShowAll(true)}
        endIcon={<ExpandMore size="small" />}
      >
        ดูทั้งหมด
      </Button>
    )
  }

  return (
    <Button
      sx={sx}
      data-testid="btn-less"
      size="s"
      onClick={() => setShowAll(false)}
      endIcon={<ExpandLess size="small" />}
    >
      ดูน้อยลง
    </Button>
  )
}

export const ContactSection = ({ contact }) => {
  const [isShowAll, setShowAll] = useState(false)
  const contactSplit = contact.split('\n')
  const contactDisplay = useMemo(
    () => (isShowAll ? contactSplit : _.slice(contactSplit, 0, 3)),
    [contactSplit, isShowAll]
  )
  return (
    <>
      <Divider sx={{ width: '100%' }} />
      <Section title="ช่องทางติดต่อ">
        <Column>
          {contactDisplay.map((item, index) => (
            <Typography key={index}>{item}</Typography>
          ))}
        </Column>
        {contactSplit.length > 3 && (
          <MoreLessButton isShowAll={isShowAll} setShowAll={setShowAll} />
        )}
      </Section>
    </>
  )
}
