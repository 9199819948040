import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledDateRangeBox = styled(Box)({
  position: 'relative',
  width: '100%',
})

export const StyledDateInput = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: 40,
  border: `1px solid ${theme.palette.text?.silver}`,
  background: theme.palette.background?.paper,
  cursor: 'pointer',
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  ':hover': {
    borderColor: theme.palette.text?.primary,
  },
}))
