import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Box } from '@mui/material'

import Template from 'src/components/Template'
import CustomTabs from 'src/components/Tabs'
import { myClassTabLabel } from 'src/constants/course'
import { setMyScheduleFilterInit, setTab } from 'src/redux/slices/class/list'
import FilterSidebar from './FilterSidebar'
import {
  StyledContainer,
  StyledLoading,
  StyledFilterButton,
  StyleLoadingOverlay,
} from './Styled'
import FilterDrawer from './FilterDrawyer'
import ClassList from './ClassList'
import { fetchMySchedulePage, handleCancelMySchedule } from './event'
import { getQueryParams } from 'src/utils/lib'

const MyClass = () => {
  const dispatch = useDispatch()
  const { tab, myScheduleFilterBody, myScheduleList } = useSelector(
    (state) => ({
      tab: state.classList.tab,
      myScheduleFilterBody: state.classList.myScheduleFilterBody,
      myScheduleList: state.classList.myScheduleList,
    }),
    shallowEqual
  )
  const [isOpen, setOpen] = useState(false)
  const [isInit, setIsInit] = useState(true)

  const handleScroll = () => {
    if (!myScheduleFilterBody.hasMore || myScheduleFilterBody.isFetch) return
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight - 400
    if (bottom && !isInit) {
      dispatch(fetchMySchedulePage({ page: myScheduleFilterBody.page + 1 }))
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [
    myScheduleFilterBody.hasMore,
    myScheduleFilterBody.isFetch,
    myScheduleFilterBody.page,
  ])

  useEffect(() => {
    const tab = getQueryParams('tab') || 'MY_CLASS'
    handleClickTab(tab)
    // resetTable(tab)
  }, [])

  const handleClickTab = async (value) => {
    dispatch(setTab(value))
    setOpen(false)
    setIsInit(true)
    resetTable(value)
  }

  const resetTable = (value) => {
    if (value === 'MY_CLASS') {
      dispatch(setMyScheduleFilterInit())
      dispatch(fetchMySchedulePage({ page: 1 }))
      setIsInit(false)
    } else {
      dispatch(setMyScheduleFilterInit())
      dispatch(fetchMySchedulePage({ page: 1 }))
      setIsInit(false)
    }
  }

  const handleCancelBtn = (uuid) => {
    dispatch(handleCancelMySchedule(uuid))
  }

  return (
    <Template>
      <StyledContainer>
        <Typography variant="h4">คลาสของฉัน</Typography>
        <CustomTabs
          listTab={myClassTabLabel}
          value={tab}
          onChange={(value) => handleClickTab(value)}
        />
        <Grid container spacing={{ xs: 2, lg: 3 }}>
          <Grid item md={12} lg={3}>
            <StyledFilterButton>
              <IconButton
                sx={{ position: 'relative' }}
                data-testid="btn-filter"
                size="small"
                onClick={() => setOpen(true)}
              >
                <FilterListIcon />
              </IconButton>
            </StyledFilterButton>
            <FilterSidebar />
          </Grid>
          <Grid item md={12} lg={9} sx={{ position: 'relative' }}>
            <ClassList
              tab={tab}
              myScheduleList={myScheduleList}
              handleCancelMySchedule={handleCancelBtn}
            />

            {myScheduleFilterBody.isFetch ? (
              <>
                <StyleLoadingOverlay />
                <Box textAlign={'center'} position="relative" zIndex={999}>
                  <StyledLoading />
                </Box>
              </>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <FilterDrawer isOpen={isOpen} setOpen={setOpen} />
      </StyledContainer>
    </Template>
  )
}

export default MyClass
