import React, { useEffect, useState } from 'react'
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness'
import { Loader, ThemeProvider } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import awsexports from '../../amplifyconfiguration.json'
import { fetchCreateLiveness, credentialProvider } from './events'
import { displayTh } from './model'

import '@aws-amplify/ui-react/styles.css'
import './style.css'

Amplify.configure(awsexports)

const FaceLiveness = ({ onCapture, onError }) => {
  const [isLoading, setLoading] = useState(true)
  const [livenessData, setLivenessData] = useState(null)

  useEffect(() => {
    fetchCreateLiveness(setLoading, setLivenessData)
  }, [])

  return (
    <ThemeProvider>
      {isLoading ? (
        <Loader />
      ) : (
        <FaceLivenessDetector
          region={window.__env__.REACT_APP_AWS_REGION}
          sessionId={livenessData.sessionId}
          config={{ credentialProvider }}
          displayText={displayTh}
          onAnalysisComplete={() => onCapture(livenessData)}
          onError={(error) => {
            console.error(error)
            onError(error.state)
          }}
        />
      )}
    </ThemeProvider>
  )
}

export default FaceLiveness
