import React from 'react'
import _ from 'lodash'
import { closeDialog, openDialog } from 'src/redux/slices/alertDialog'
import DialogContent from './DialogContent'
import ArrowForward from '@mui/icons-material/ArrowForward'
import {
  getCheckPrerequisite,
  postEnrollClassQueue,
} from 'src/services/class/detail'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { startEnrollLoading } from 'src/redux/slices/class/detail'

export const handleClickEnroll = (props, socketId) => async (dispatch) => {
  const classUuid = _.get(props, 'trainingPlanUuid', '')
  const courseUuid = _.get(props, 'courseVersionUuid', '')
  const secret = _.get(props, 'secret', '')
  const courseDetail = _.get(props, 'courseDetail', {})
  const paidCost = _.get(courseDetail, 'paidCost', false)
  dispatch(startLoading())

  const response = await dispatch(
    getCheckPrerequisite({
      trainingPlanUuid: classUuid,
      courseVersionUuid: courseUuid,
    })
  )
  dispatch(stopLoading())

  if (!_.isNil(response.error)) {
    const error = _.get(response, 'meta.response.data.constraints', {})
    const errorKey = _.get(error, 'key', '')
    if (errorKey === 'IsPrerequisiteError') dispatch(handlePrerequisiteFail())
    else if (errorKey === 'LevelLearnerNotMatch')
      dispatch(handleLevelOfLearnerNotMatch(error))
    else dispatch(handleRegisterFail(error))
    return
  }

  const canRegister = _.get(response, 'payload.data.canRegister', false)
  if (canRegister) {
    if (paidCost) dispatch(handleEnrollWithPaid(classUuid, secret, socketId))
    else
      dispatch(
        handlePrerequisitePass({ courseDetail, classUuid, secret }, socketId)
      )
  } else {
    dispatch(handlePrerequisiteFail())
  }
}

export const handleRegisterFail = (props) => (dispatch) => {
  const title = _.get(props, 'title', 'ไม่สามารถสมัครเข้าอบรมได้')
  const value = _.get(props, 'value', 'เกิดข้อผิดพลาด')
  dispatch(
    openDialog({
      iconType: 'error',
      title,
      message: value.replace('ไม่สามารถสมัครเข้าอบรมได้', '').split(' '),
      buttonRight: {
        label: 'ตกลง',
        handleClick: () => window.location.reload(),
      },
    })
  )
}

export const handleLevelOfLearnerNotMatch = (error) => (dispatch) => {
  const title = _.get(error, 'title', 'ไม่สามารถสมัครเข้าอบรมได้')
  const value = _.get(error, 'value', '')
  dispatch(
    openDialog({
      iconType: 'error',
      title: title,
      message: [value],
      buttonRight: {
        label: 'ตกลง',
        handleClick: () => {
          dispatch(closeDialog())
        },
      },
    })
  )
}

export const handlePrerequisiteFail = () => (dispatch) => {
  dispatch(
    openDialog({
      iconType: 'error',
      title: 'ไม่สามารถสมัครอบรมได้',
      message: ['คุณสมบัติของท่านไม่ตรงตามเงื่อนไขของหลักสูตร'],
    })
  )
}

export const handlePrerequisitePass = (props, socketId) => (dispatch) => {
  const { courseDetail, classUuid, secret } = props
  const englishName = _.get(courseDetail, 'englishName', '-')
  const nameForLearner = _.get(courseDetail, 'nameForLearner', '-')
  const courseName = nameForLearner || englishName

  dispatch(
    openDialog({
      isCloseDialog: false,
      title: 'ยืนยันสมัครเข้าอบรม',
      message: [courseName],
      content: <DialogContent />,
      buttonRight: {
        label: 'ยืนยัน',
        handleClick: () =>
          dispatch(handleSubmitEnroll(classUuid, secret, socketId)),
      },
      buttonLeft: { label: 'ยกเลิก' },
    })
  )
}

export const handleEnrollWithPaid =
  (classUuid, secret, socketId) => (dispatch) => {
    dispatch(
      openDialog({
        isCloseDialog: false,
        iconType: 'paid',
        title: 'หลักสูตรเรียนนี้มีค่าใช้จ่าย',
        message: ['โปรดติดต่อเจ้าหน้าที่', 'เพื่อดำเนินการชำระเงินก่อนสมัคร'],
        buttonRight: {
          label: 'ฉันชำระเงินแล้ว',
          handleClick: () =>
            dispatch(handleSubmitEnroll(classUuid, secret, socketId)),
        },
        buttonLeft: { label: 'ปิด' },
      })
    )
  }

export const handleSubmitEnroll =
  (classUuid, secret, socketId) => async (dispatch) => {
    if (socketId) {
      dispatch(startEnrollLoading())
      dispatch(closeDialog())
      const payload = {
        trainingPlanUuid: classUuid,
        secretTrainingPlan: secret,
      }
      await dispatch(postEnrollClassQueue({ socketId, payload }))
    }
  }

export const handleOpenSuccessDialog = (classUuid) => (dispatch) => {
  dispatch(
    openDialog({
      isCloseDialog: false,
      iconType: 'time',
      title: 'รอผลการตรวจสอบ',
      message: [
        'ระบบได้ทำการส่งอีเมลยืนยันการลงทะเบียนให้คุณเรียบร้อยแล้ว',
        'โปรดติดตามผลการตรวจสอบเอกสารทางอีเมลอีกครั้ง',
      ],
      buttonRight: {
        label: 'ไปที่คลาสเรียน',
        endIcon: <ArrowForward />,
        handleClick: () =>
          (window.location.href = `/my-class/registration-detail/${classUuid}`),
      },
      buttonLeft: {
        label: 'ดูหลักสูตรอื่นต่อ',
        handleClick: () => (window.location.href = '/course'),
      },
    })
  )
}
