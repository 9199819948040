import _ from 'lodash'
import { setMyScheduleFilter } from 'src/redux/slices/class/list'

export const handleCheckboxChange = (e, filterBody) => (dispatch) => {
  const payload = handleCourseFiltering(e, filterBody)
  dispatch(setMyScheduleFilterState(payload))
}

export const setMyScheduleFilterState = (payload) => (dispatch) => {
  dispatch(
    setMyScheduleFilter([
      payload,
      { key: 'page', value: 1 },
      { key: 'isFetch', value: true },
    ])
  )
}

export const handleCourseFiltering = (e, filterBody) => {
  const name = _.get(e.target, 'name', '')
  const isChecked = _.get(e.target, 'checked', false)
  const value = _.get(e.target, 'value', '')
  let filterList = [...filterBody]
  if (isChecked) filterList.push(value)
  else filterList = filterList.filter((item) => item != value)
  return { key: name, value: filterList }
}

export const handleChangePeriod = (formattedDate) => (dispatch) => {
  dispatch(
    setMyScheduleFilter([
      { key: 'startDate', value: formattedDate.startDate },
      { key: 'finishDate', value: formattedDate.finishDate },
    ])
  )
  dispatch(
    setMyScheduleFilterState([
      { key: 'startDate', value: formattedDate.startDate },
      { key: 'finishDate', value: formattedDate.finishDate },
    ])
  )
}

export const handleResetPeriod = () => (dispatch) => {
  dispatch(
    setMyScheduleFilter([
      { key: 'startDate', value: '' },
      { key: 'finishDate', value: '' },
    ])
  )
  dispatch(
    setMyScheduleFilterState([
      { key: 'startDate', value: '' },
      { key: 'finishDate', value: '' },
    ])
  )
}
