import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState } from './model'

const basicTable = createSlice({
  name: 'basicTable',
  initialState,
  reducers: {
    setInitialTable: (state, { payload }) => {
      state.table.rows = _.get(payload, 'rows', [])
      state.table.allCount = _.get(payload, 'allCount', 0)
      state.table.headCells = _.get(payload, 'headCells', [])
      state.table.tableHeadText = _.get(payload, 'tableHeadText', '')
      state.customStyle = _.get(payload, 'customStyle', {})
      state.table.isHideSearch = _.get(payload, 'isHideSearch', false)
    },
    setChangeRowsPerPage: (state, { payload }) => {
      state.table.rowsPerPage = payload.rowsPerPage
      state.table.limit = payload.limit
      state.table.tablePage = 0
      state.table.page = 1
    },
    setChangePage: (state, { payload }) => {
      state.table.page = payload.page
      state.table.tablePage = payload.tablePage
    },
    setDefaultSort: (state, { payload }) => {
      state.table.defaultSort = payload
    },
    setOrder: (state, { payload }) => {
      state.table.order = payload.order
      state.table.sort = payload.sort
    },
    setSelected: (state, { payload }) => {
      state.table.selected = payload
    },
  },
})

export const {
  setInitialTable,
  setChangeRowsPerPage,
  setChangePage,
  setDefaultSort,
  setOrder,
  setSelected,
} = basicTable.actions

export default basicTable.reducer
