import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getCourseDetail,
  postLikeCourse,
  postBatchFailScanCount,
  getCheckBatchFailScanCount,
} from 'src/services/eLearning/detail'
import { initialState } from './model'

const eLearningDetail = createSlice({
  name: 'eLearningDetail',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setChangeCamera: (state, { payload }) => {
      const { key, value } = payload
      state.camera[key] = value
    },
    openDialog: (state) => {
      state.isDialogOpen = true
    },
    closeDialog: (state) => {
      state.isDialogOpen = false
    },
    setScanAllow: (state, { payload }) => {
      state.isScanAllow = payload
    },
    setTime: (state, { payload }) => {
      state.time.mins = _.get(payload, 'mins', 0)
      state.time.secs = _.get(payload, 'secs', 0)
    },
    setFailMaxTimes: (state, { payload }) => {
      state.failMaxTimes = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseDetail.fulfilled, (state, { payload }) => {
        state.data = payload.data
      })
      .addCase(getCourseDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postLikeCourse.pending, (state) => {
        state.isLikeLoading = true
      })
      .addCase(postLikeCourse.fulfilled, (state, { payload }) => {
        state.data.isLike = payload.data.isLike
        state.isLikeLoading = false
      })
      .addCase(postLikeCourse.rejected, (state, action) => {
        state.error = action.meta?.message
        state.isLikeLoading = false
      })
      .addCase(getCheckBatchFailScanCount.fulfilled, (state, { payload }) => {
        state.failLogUuid = _.get(payload.data, 'uuid', null)
      })
      .addCase(getCheckBatchFailScanCount.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postBatchFailScanCount.fulfilled, (state, { payload }) => {
        state.failLogUuid = _.get(payload.data, 'uuid', null)
      })
      .addCase(postBatchFailScanCount.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  setChangeCamera,
  openDialog,
  closeDialog,
  setScanAllow,
  setTime,
  setFailMaxTimes,
} = eLearningDetail.actions

export default eLearningDetail.reducer
