import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '169px',
  background: `url('/image/background.svg')`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  marginTop: 72,
}))
