import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { SECTION_KEY } from 'src/components/CourseCard/model'
import Template from '../../components/Template'
import CourseCard from '../../components/CourseCard'
import ContentNoClass from '../../components/ContentNoClass'
import { StyledGrid } from '../ELearning/components/Styled'
import Filter from './Filter'
import ListHeader from './ListHeader'
import Header from './Header'
import {
  StyledContainer,
  StyledCardBox,
  StyledRowContainer,
  StyledLoading,
} from './Styled'
import {
  fetchCourseFilter,
  fetchCourseList,
  handleLoadMore,
  handleSeeMore,
} from './events'

const CourseList = () => {
  const dispatch = useDispatch()
  const { isLoadMore, courses, filterBody } = useSelector(
    (state) => state.courseList,
    shallowEqual
  )
  const triggerProps = [
    filterBody.roadMap,
    filterBody.acquiredSkill,
    filterBody.sortBy,
    filterBody.trainingPlatform,
    filterBody.courseStatus,
    filterBody.productType,
    filterBody.startDate,
    filterBody.finishDate,
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(fetchCourseFilter())

    window.addEventListener('scroll', () => dispatch(handleLoadMore()))
    return () => {
      window.removeEventListener('scroll', () => dispatch(handleLoadMore()))
    }
  }, [])

  useEffect(() => {
    dispatch(fetchCourseList(filterBody))
  }, [...triggerProps])

  useEffect(() => {
    dispatch(handleSeeMore(filterBody))
  }, [filterBody.page])

  return (
    <>
      <Header />
      <Template maxWidth={1440}>
        <StyledContainer>
          <ListHeader />
          <StyledRowContainer>
            <Filter />
            <StyledCardBox>
              <Grid container spacing={2}>
                {courses.length > 0 ? (
                  courses.map((course, index) => (
                    <StyledGrid key={index}>
                      <CourseCard
                        course={course}
                        keySection={SECTION_KEY.COURSE}
                      />
                    </StyledGrid>
                  ))
                ) : (
                  <ContentNoClass />
                )}
              </Grid>
              {isLoadMore && <StyledLoading />}
            </StyledCardBox>
          </StyledRowContainer>
        </StyledContainer>
      </Template>
    </>
  )
}

export default CourseList
