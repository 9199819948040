import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { getQueryParams } from 'src/utils/lib'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import Button from '@mui/material/Button'
import { responsiveButton } from 'src/components/Preview/Footer/Styled'
import { handleAnswerAmount } from '../events'
import { handleSubmit } from './event'

const SubmitBtn = ({ isDisplayAll }) => {
  const { id: uuid } = useParams()
  const trainingPlan = getQueryParams('class')
  const buttonSize = responsiveButton()
  const dispatch = useDispatch()
  const eTesting = useSelector((state) => state.eTesting, shallowEqual)
  const { answerETesting, totalETesting, isButtonLoading } = eTesting
  const submitProps = { uuid, trainingPlan, eTesting }
  const isDisable = useMemo(
    () => totalETesting > handleAnswerAmount(eTesting),
    [answerETesting, totalETesting]
  )

  return (
    <>
      {isDisplayAll ? (
        <Button
          type="submit"
          variant="contained"
          size={buttonSize}
          data-testid="btn-submit"
          disabled={isDisable}
          onClick={() => dispatch(handleSubmit(submitProps))}
        >
          ส่งคำตอบ
        </Button>
      ) : (
        <LoadingButton
          variant="contained"
          size={buttonSize}
          loading={isButtonLoading}
          loadingPosition="center"
          data-testid="btn-submit-loading"
          disabled={isDisable}
          onClick={() => dispatch(handleSubmit(submitProps))}
        >
          ส่งคำตอบ
        </LoadingButton>
      )}
    </>
  )
}

export default SubmitBtn
