import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { getQueryParams } from 'src/utils/lib'
import {
  StyledContainer,
  StyledResultContent,
} from 'src/components/Preview/Result/Styled'
import { handleError, handleSubmitEEvaluation } from './events'

const Result = () => {
  const { id: uuid } = useParams()
  const trainingPlan = getQueryParams('class')
  const courseTestAndEva = getQueryParams('eva')
  const { answerEEvaluation, error, isLoading, isDuplicate, isModule } =
    useSelector(
      (state) => ({
        answerEEvaluation: state.eEvaluation.answerEEvaluation,
        error: state.eEvaluation.error,
        isLoading: state.loading.isLoading,
        isDuplicate: state.eEvaluation.isDuplicate,
        isModule: state.eEvaluation.result.isModule,
      }),
      shallowEqual
    )
  const dispatch = useDispatch()
  const props = {
    uuid,
    answerEEvaluation,
    trainingPlan,
    courseTestAndEva,
    isModule,
  }
  const mainPageLink = trainingPlan
    ? `/my-class/registration-detail/${trainingPlan}`
    : '/my-profile/standalone'

  useEffect(() => {
    dispatch(handleSubmitEEvaluation(props))
  }, [])

  useEffect(() => {
    dispatch(handleError(error))
  }, [error])

  if (isLoading || (error && !isDuplicate)) return <></>

  return (
    <StyledContainer>
      {isDuplicate ? <BannerError /> : <BannerFinish />}
      {!isModule && (
        <Link href={mainPageLink} sx={{ fontWeight: 500 }}>
          กลับหน้าหลัก
        </Link>
      )}
    </StyledContainer>
  )
}

export default Result

export const BannerFinish = () => {
  return (
    <StyledResultContent>
      <img src="/icon/main_success.svg" />
      <Typography textAlign="center" variant="h3" color="primary">
        ทำแบบประเมินสำเร็จ
      </Typography>
      <Typography variant="h6" color="text.secondary">
        ขอบคุณที่ทำแบบประเมิน
      </Typography>
    </StyledResultContent>
  )
}

export const BannerError = () => {
  return (
    <StyledResultContent>
      <img src="/icon/main_error.svg" />
      <Typography textAlign="center" variant="h3" color="error">
        เคยทำแบบประเมินนี้แล้ว
      </Typography>
      <Typography variant="h6" color="text.secondary">
        คุณเคยทำแบบประเมินนี้ไปแล้ว
      </Typography>
    </StyledResultContent>
  )
}
