import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { isOnELearning } from '../../events'

export const StyledTestingContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  minHeight: isOnELearning() ? 'calc(100vh - 308px)' : 'calc(100vh - 180px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${theme.spacing(8)} ${theme.spacing(3)} ${theme.spacing(5)}`,
  gap: 20,
  [theme.breakpoints.down('lg')]: {
    minHeight: isOnELearning() ? 'calc(100vh - 276px)' : 'calc(100vh - 148px)',
  },
}))
