import React, { useEffect } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { Column, Row } from 'src/components/Layout/Styled'
import { DisabledInput, StepTitle } from '..'
import IdCardSection from './IdCardSection'
import FaceCaptureSection from './FaceCaptureSection'
import AlertDialog from './AlertDialog'
import { StyledColumn } from './Styled'
import { handleCheckAllowScan } from './events'

const Step2 = ({ isVerification }) => {
  const [user] = useLocalStorage('user')
  const uuid = useSelector(
    (state) => _.get(state.eLearningEnroll.data, 'uuid', ''),
    shallowEqual
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(handleCheckAllowScan(uuid))
  }, [uuid])

  return (
    <Column pb={3}>
      <StepTitle
        label={isVerification ? 'ยืนยันตัวตน' : 'ข้อมูลส่วนตัว'}
        description={`กรุณาตรวจสอบและเพิ่มข้อมูลสำหรับการ${
          isVerification ? 'ยืนยันตัวตน' : 'สมัคร'
        }หลักสูตรอบรม OIC ให้ครบถ้วน หากข้อมูลไม่ถูกต้องกรุณาติดต่อเจ้าหน้าที่ KTAXA`}
      />

      <Divider />

      <StyledColumn>
        <Row>
          <DisabledInput
            label="ชื่อผู้เข้าอบรม"
            value={`${user.firstNameTH} ${user.lastNameTH}`}
          />
          <DisabledInput
            label="เลขประจำตัวประชาชน 13 หลัก"
            value={user.idCardNo}
          />
        </Row>
        <Row>
          <DisabledInput label="หมายเลขโทรศัพท์มือถือ" value={user.mobileNo} />
          <DisabledInput label="อีเมล" value={user.email} />
        </Row>
      </StyledColumn>

      <Divider sx={{ mb: 2 }} />
      <IdCardSection />

      <Divider sx={{ mb: 2 }} />
      <FaceCaptureSection />

      <AlertDialog />
    </Column>
  )
}

export default Step2

export const SubLabel = ({ label }) => {
  return (
    <Typography>
      {label}{' '}
      <Typography variant="span" color="error">
        *
      </Typography>
    </Typography>
  )
}
