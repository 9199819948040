import Button from '@mui/material/Button'
import styled from '@mui/system/styled'

export const StyledButton = styled(Button)(({ theme, isCanSent }) => ({
  backgroundColor: isCanSent
    ? theme.palette.action.disabledBackground
    : theme.palette.secondary?.main,
  color: isCanSent
    ? theme.palette.action.disabled
    : theme.palette.secondary?.contrast,
  padding: '4px 10px',
  minWidth: 54,
  cursor: isCanSent ? 'revert' : 'pointer',
  '&:hover': {
    backgroundColor: isCanSent
      ? theme.palette.action.disabledBackground
      : theme.palette.secondary?.main,
    color: isCanSent
      ? theme.palette.action.disabled
      : theme.palette.secondary?.contrast,
  },
}))
