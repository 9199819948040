import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { StyledCheckbox, StyledTableCell, StyledTableRow } from './Styled'
import { Row } from '../../../../components/Layout/Styled'
import { convertDateShortTh } from '../../events'
import { handleAvailableRow, handleSelect } from './event'

const TableBodyRow = ({ index }) => {
  const dispatch = useDispatch()
  const { round, selectedRounds, examiners } = useSelector(
    (state) => ({
      round: state.eExamination.roundList[index],
      selectedRounds: state.eExamination.selectedRounds,
      examiners: state.eExamination.examiners,
    }),
    shallowEqual
  )
  const $available =
    Boolean(
      _.find(selectedRounds, (selected) => {
        return selected.uuid == round.uuid
      })
    ) ||
    handleAvailableRow(
      examiners.length,
      round.available,
      round.date,
      round.startTime,
      round.disabled
    )
  return (
    <StyledTableRow $available={$available}>
      <StyledTableCell>
        <StyledCheckbox
          $available={$available}
          checked={Boolean(
            _.find(selectedRounds, (selected) => {
              return selected.uuid == round.uuid
            })
          )}
          onChange={(e) => {
            if ($available) {
              dispatch(handleSelect({ check: e.target.checked, value: round }))
            }
          }}
        />
      </StyledTableCell>
      <StyledTableCell
        align="center"
        sx={{
          fontWeight: 700,
          color:
            round.available > 0 && round.status !== 'CANCEL'
              ? '#169E3E'
              : '#C91432',
        }}
      >
        {round.status === 'CANCEL'
          ? 'ยกเลิก'
          : round.available > 0
          ? `ว่าง ${round.available}`
          : 'เต็ม'}
      </StyledTableCell>
      <StyledTableCell $available={$available}>
        <Row sx={{ justifyContent: 'center' }}>
          {convertDateShortTh(round.date)}
        </Row>
        <Row sx={{ justifyContent: 'center' }}>
          {`${round.startTime} - ${round.endTime}`}
        </Row>
      </StyledTableCell>
      <StyledTableCell $available={$available} align="center">
        {round.examinationField}
      </StyledTableCell>
      <StyledTableCell $available={$available} align="center">
        {round.examinationLocation}
      </StyledTableCell>
      <StyledTableCell $available={$available} align="center">
        {round.examinationOther}
      </StyledTableCell>
      <StyledTableCell $available={$available}>
        <Row sx={{ justifyContent: 'center' }}>{`${convertDateShortTh(
          round.startDateRegister
        )} ${round.startTimeRegister} - `}</Row>
        <Row sx={{ justifyContent: 'center' }}>
          {`${convertDateShortTh(round.endDateRegister)} ${
            round.endTimeRegister
          }`}
        </Row>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default TableBodyRow
