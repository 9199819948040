import React from 'react'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { formatData } from './events'

const ActionStatusProspect = ({ row, sx }) => {
  const data = _.get(row, 'status', '')
  return (
    <Typography variant="body2" sx={sx}>
      {formatData(data)}
    </Typography>
  )
}

export default ActionStatusProspect
