import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { Column, Row } from 'src/components/Layout/Styled'
import Template from 'src/components/Template'
import LeftContent from './LeftContent'
import RightContent from './RightContent'
import Header from './Header'
import EnrollButton from './EnrollButton'
import ScrolledFooter from './ScrolledFooter'
import { StyledContainer, StyledWrapper } from './Styled'
import { checkScrollWidth, fetchCourseDetail } from './events'

const Detail = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const data = useSelector((state) => state.eLearningDetail.data, shallowEqual)
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')
  const [isScrolled, setScroll] = useState(false || !isNotSm)

  useEffect(() => {
    dispatch(fetchCourseDetail(id))
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', () =>
      checkScrollWidth(setScroll, isLg, isNotSm)
    )
    return () => {
      window.removeEventListener('scroll', () =>
        checkScrollWidth(setScroll, isLg, isNotSm)
      )
    }
  }, [isLg, isNotSm])

  return (
    <StyledWrapper>
      {data ? (
        <>
          <Header />

          <Template noMinHeight>
            <Content />
          </Template>

          {isScrolled && <ScrolledFooter />}
        </>
      ) : (
        <Template />
      )}
    </StyledWrapper>
  )
}

export default Detail

export const Content = ({ sx }) => {
  const data = useSelector((state) => state.eLearningDetail.data, shallowEqual)
  const isNotSm = isMediaWidthMoreThan('sm')
  const isCoursePage = window.location.href.includes('/course')
  return (
    <StyledContainer sx={sx}>
      {!isNotSm && isCoursePage && (
        <MobileEnrollmentContent data={data} />
      )}

      <LeftContent />
      <RightContent />
    </StyledContainer>
  )
}

export const MobileEnrollmentContent = ({ data }) => {
  const sellCourse = _.get(data, 'setting.sellCourse', {})
  return (
    <Row sx={{ gap: 2, my: 1 }}>
      {sellCourse.isSell && (
        <Column sx={{ width: 'auto' }}>
          <Typography variant="body2" color="text.gray">
            ราคาทั้งหมด
          </Typography>
          <Typography variant="h6" color="primary">
            ฿
            {Number(
              `${_.get(sellCourse, 'cost', 0)}`.replace(',', '')
            ).toLocaleString()}
          </Typography>
        </Column>
      )}

      <EnrollButton color="text.primary" />
    </Row>
  )
}
