import React, { useEffect, useRef } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew'
import { setExpand } from 'src/redux/slices/eLearning/learning'
import { handleHoverOnVideo } from '../LeftPanel/events'
import { MODULE_TYPE } from 'src/constants/eLearning'
import { StyledFloatButton, height, StyledFloatBg } from '../Styled'

const FloatingButtons = () => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { isExpanded, type } = useSelector(
    (state) => ({
      isExpanded: state.eLearningLearning.isExpanded,
      type: _.get(state.eLearningLearning.selectedLesson, 'type', ''),
    }),
    shallowEqual
  )
  const ref = useRef(null)
  const isFile = type === MODULE_TYPE.UPLOAD_FILE

  useEffect(() => {
    handleHoverOnVideo(ref)
  }, [])

  return (
    <Box ref={ref} sx={{ width: '100%', height, position: 'absolute' }}>
      <StyledFloatButton
        isFile={isFile}
        sx={{ left: 28 }}
        size="small"
        startIcon={<ArrowBackIosNew />}
        onClick={() => history.push(`/e-learning/course/${uuid}`)}
      >
        กลับไปหน้าหลัก
      </StyledFloatButton>
      <StyledFloatBg isFile={isFile} />
      {!isExpanded && (
        <StyledFloatButton
          isFile={isFile}
          size="small"
          variant="outlined"
          sx={{ right: 28 }}
          startIcon={<img src="/icon/e-learning/player/expand_menu.svg" />}
          onClick={() => dispatch(setExpand(true))}
        >
          เปิดเมนู
        </StyledFloatButton>
      )}
    </Box>
  )
}

export default FloatingButtons
