import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import CameraOutdoor from '@mui/icons-material/CameraOutdoor'
import InsertInvitationOutlined from '@mui/icons-material/InsertInvitationOutlined'
import TimerOutlined from '@mui/icons-material/TimerOutlined'
import WidgetsOutlined from '@mui/icons-material/WidgetsOutlined'
import Typography from '@mui/material/Typography'
import { Row } from 'src/components/Layout/Styled'
import { convertDateRange, convertFormatTime } from 'src/utils/lib'
import { StlyedContent, StyledLabelIcon } from './Styled'

const DialogContent = () => {
  const data = useSelector((state) => state.classDetail.data, shallowEqual)
  const courseDetail = _.get(data, 'courseDetail', null)
  const trainingPlanData = _.get(data, 'trainingPlanData', null)
  const dateRange = convertDateRange(
    _.get(trainingPlanData, 'dateRangeMonthlyPlan', null)
  )
  const time = convertFormatTime(courseDetail)
  return (
    <StlyedContent>
      <LabelAndData label="วันที่อบรม" icon={<InsertInvitationOutlined />}>
        {dateRange}
      </LabelAndData>
      <LabelAndData label="ระยะเวลาของหลักสูตร" icon={<TimerOutlined />}>
        {time}
      </LabelAndData>
      <LabelAndData label="รูปแบบการสอน" icon={<CameraOutdoor />}>
        {_.get(courseDetail, 'trainingPlatformName', '-')}
      </LabelAndData>
      <LabelAndData label="กลุ่มหลักสูตร" icon={<WidgetsOutlined />}>
        {_.get(courseDetail, 'roadmap', '-')}
      </LabelAndData>
    </StlyedContent>
  )
}

export default DialogContent

export const LabelAndData = ({ label, icon, children }) => {
  return (
    <Row gap={3}>
      <StyledLabelIcon>
        {icon}
        <Typography>{label}</Typography>
      </StyledLabelIcon>
      <Typography>{children}</Typography>
    </Row>
  )
}
