import React, { useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import { E_LEARNING_LEARNER_LOG_RESULT } from 'src/constants/eLearning'
import { MODULE_ICON } from '../../../Detail/LeftContent/ContentTab/model'
import { SubTitleContent } from '../../../Detail/LeftContent/ContentTab/events'
import { mediaType } from '../../model'
import { StyledOneLineText } from '../Styled'
import SubLabelForFile from './components/SubLabelForFile'
import CircularProgressWithLabel from './components/CircularProgressWithLabel'
import { StyledBoxText, StyledModule, StyledModuleLabel } from './Styled'
import { handleSelectModule } from './events'

const ModuleLabel = ({ data, index, mIndex }) => {
  const dispatch = useDispatch()
  const { isExpanded, lessonNo, no } = useSelector(
    (state) => ({
      isExpanded: state.eLearningLearning.isExpanded,
      lessonNo: state.eLearningLearning.selectedLesson.lessonNo,
      no: state.eLearningLearning.selectedLesson.no,
    }),
    shallowEqual
  )
  const lessonName = _.get(data, 'lessonName', '')
  const type = _.get(data, 'type', '')
  const isCompleted = data.result === E_LEARNING_LEARNER_LOG_RESULT.COMPLETE
  const percent = isCompleted ? 100 : _.get(data, 'progress', 0)
  const isSelected = index === lessonNo - 1 && mIndex === no - 1
  const isUploadFile = type === mediaType.UPLOAD_FILE
  const [isShow, setShow] = useState(index === 0 && mIndex === 0)
  const icon = MODULE_ICON[type]

  if (!isExpanded) return <></>

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <StyledModuleLabel
        disabled={data.disabled}
        isSelected={isSelected}
        onClick={() => {
          if (isUploadFile) setShow(!isShow)
          else dispatch(handleSelectModule(data))
        }}
      >
        <StyledModule>
          <CircularProgressWithLabel value={percent} />
          <StyledBoxText sx={{ width: 'calc(100% - 46px)' }}>
            <StyledOneLineText variant="body2b">{lessonName}</StyledOneLineText>
            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
              {icon && <img src={`/icon/e-learning/${icon}`} />}
              <Typography variant="body2" color="text.lightGray">
                {SubTitleContent(data)}
              </Typography>
            </Box>
          </StyledBoxText>
        </StyledModule>

        {isUploadFile && (
          <IconButton onClick={() => setShow(!isShow)}>
            {isShow ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </StyledModuleLabel>

      {isUploadFile &&
        isShow &&
        _.get(data, 'fileList', []).map((file, index) => (
          <SubLabelForFile key={index} index={index} data={data} file={file} />
        ))}
    </Box>
  )
}

export default ModuleLabel
