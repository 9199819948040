import React from 'react'
import Typography from '@mui/material/Typography'
import {
  StyledCountBox,
  StyledIndicator,
  StyledIndicatorWrapper,
} from './Styled'

const HomeIndicator = ({ list, selected, setSelected }) => {
  const current = String(selected + 1)?.padStart(2, '0')
  const total = String(list.length)?.padStart(2, '0')
  return (
    <StyledIndicatorWrapper>
      {list.map((_, index) => (
        <StyledIndicator
          key={index}
          isActive={selected === index}
          onClick={() => setSelected(index)}
        />
      ))}
      <StyledCountBox>
        <Typography>
          {current}/{total}
        </Typography>
      </StyledCountBox>
    </StyledIndicatorWrapper>
  )
}

export default HomeIndicator
