import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import _ from 'lodash'
import { formatData } from './event'

export const Dot = ({ color }) => {
  return (
    <Box
      sx={{ width: '8px', height: '8px', bgcolor: color, borderRadius: '50%' }}
    ></Box>
  )
}

const ActionConsent = ({ row, sx }) => {
  let data = {}
  const tempProspectProfile = _.get(row, 'tempProspectProfile', null)
  const prospectProfile = _.get(row, 'prospectProfile', null)

  if (tempProspectProfile !== null) {
    data = tempProspectProfile
  } else if (prospectProfile !== null) {
    data = prospectProfile
  }

  const consent = _.get(data, 'consent', '')

  return (
    <Typography variant="body2" sx={sx}>
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Dot color={formatData(consent, true)} />
        {formatData(consent, false)}
      </Box>
    </Typography>
  )
}

export default ActionConsent
