import React from 'react'
import { useDroppable } from '@dnd-kit/core'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { alphabets } from 'src/constants/eTesting'
import Draggable from '../Draggable'
import { StyledNumberBadge } from '../Styled'
import { StyledDropBox } from './Styled'

const Droppable = ({ index, data, resultProps, correctNo, onSelect }) => {
  const { isMobile, selectedItem } = useSelector(
    (state) => state.eTesting.mobileProps,
    shallowEqual
  )
  const { onViewState, questionResult, correctAnswer } = resultProps
  const { active, setNodeRef } = useDroppable({ id: index })
  const resultType = _.get(questionResult, 'resultType', 'SHOW_TRUE_FALSE')
  const answers = _.get(correctAnswer, 'answers', [])
  const targetAnswer = _.get(answers, index, {})
  const targetId = _.get(alphabets, index, '')
  const isCorrect =
    targetId === _.get(data, 'target', '') ||
    _.get(targetAnswer, 'answerUuid', '') === _.get(data, 'uuid', '')
  const isActive = active && data && active.id === data.uuid

  return (
    <>
      <StyledDropBox
        aria-label="Droppable-region"
        id={`drag-drop-area-${index}`}
        ref={setNodeRef}
        variant="span"
        value={data}
        onViewState={onViewState}
        isCorrect={isCorrect}
        isMobileSelect={!_.isNil(selectedItem)}
        isDragOut={isActive}
        onClick={() => isMobile && onSelect(index)}
      >
        {data ? (
          <Draggable data={data} onViewState={onViewState} isDropped />
        ) : (
          BlankSpace
        )}
      </StyledDropBox>
      {onViewState && resultType === 'SHOW_TRUE_FALSE' && (
        <StyledNumberBadge
          variant="span"
          color="text.white"
          lineHeight="24px"
          sx={{ margin: '0 4px' }}
          type={isCorrect ? 'true' : 'false'}
        >
          {correctNo}
        </StyledNumberBadge>
      )}
    </>
  )
}

export default Droppable

export const BlankSpace = (
  <>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</>
)
