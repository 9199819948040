import React from 'react'
import { StyledContainerBg } from './Styled'

const Background = ({ children, withHeader, withFooter, sx }) => {
  return (
    <StyledContainerBg withHeader={withHeader} withFooter={withFooter} sx={sx}>
      {children}
    </StyledContainerBg>
  )
}

export default Background
