import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'

export const StyledFooter = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 90,
  backgroundColor: theme?.palette?.other?.standardInputLine,
  position: 'sticky',
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  zIndex: 2,
  '& > .MuiBox-root': {
    width: 896,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [theme.breakpoints.down('lg')]: { '& > .MuiBox-root': { width: 672 } },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    '& > .MuiBox-root': {
      width: 335,
      flexDirection: 'column',
      justifyContent: 'center',
      gap: theme.spacing(1),
    },
  },
}))

export const responsiveButton = () => {
  const isMediaLg = isMediaWidthMoreThan('lg')
  const isMediaMd = isMediaWidthMoreThan('sm')
  if (isMediaLg) return 'l'
  else {
    if (isMediaMd) return 'm'
    else return 's'
  }
}
