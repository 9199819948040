import React, { useEffect, useState } from 'react'
import { DndContext } from '@dnd-kit/core'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import reactStringReplace from 'react-string-replace'
import _ from 'lodash'
import { setMobileProps } from 'src/redux/slices/eTesting'
import { alphabets } from 'src/constants/eTesting'
import Draggable from './Draggable'
import Droppable from './Droppable'
import {
  getPlainTextWithBlank,
  handleDragDropChange,
  handleDragEnd,
  handleMobileSelect,
} from './events'
import { blankReg, blankSimbol } from './model'
import {
  StyledDialogContent,
  StyledDragDropContainer,
  StyledKeywordContainer,
} from './Styled'

const DragAndDrop = ({ uuid, question, resultProps }) => {
  const dispatch = useDispatch()
  const { answerETesting, selectedItem } = useSelector(
    (state) => ({
      answerETesting: state.eTesting.answerETesting.find(
        (item) => item.questionUuid == uuid
      ),
      selectedItem: state.eTesting.mobileProps.selectedItem,
    }),
    shallowEqual
  )
  const dialog = getPlainTextWithBlank(_.get(question, 'subTitle', ''))
  const keywords = _.get(question, 'answers', [])
  const answers = _.get(answerETesting, 'fillAns', []).map((ans) =>
    keywords.find((kw) => kw.uuid === ans?.answerUuid)
  )
  const [dragDropArr, setDragDropArr] = useState([...answers])
  const [shuffledKeywords, setKeyword] = useState([])
  const { onViewState } = resultProps
  const handleProps = { dragDropArr, setDragDropArr, selectedItem }

  useEffect(() => {
    const userDevice = navigator.userAgent
    const deviceType =
      userDevice.match(/Android/i) ||
      userDevice.match(/webOS/i) ||
      userDevice.match(/iPhone/i) ||
      userDevice.match(/iPad/i) ||
      userDevice.match(/iPod/i) ||
      userDevice.match(/BlackBerry/i) ||
      userDevice.match(/Windows Phone/i)
    dispatch(setMobileProps({ isMobile: !!deviceType }))
  }, [])

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (selectedItem && !e.target.id?.includes('drag-drop')) {
        dispatch(setMobileProps({ selectedItem: null }))
      }
    })
  }, [selectedItem])

  useEffect(() => {
    setKeyword(_.shuffle(keywords))
  }, [])

  useEffect(() => {
    !onViewState && dispatch(handleDragDropChange(dragDropArr, uuid))
  }, [dragDropArr])

  useEffect(() => {
    onViewState && setDragDropArr(answers)
  }, [onViewState])

  return (
    <DndContext
      onDragEnd={(props) => {
        const newArr = handleDragEnd(props, dragDropArr)
        setDragDropArr(newArr)
      }}
    >
      <StyledDragDropContainer>
        <StyledKeywordContainer>
          {shuffledKeywords
            .filter(
              (item) =>
                !dragDropArr.some((data) => data?.uuid === item.uuid) ||
                onViewState
            )
            .map((data, index) => (
              <Draggable
                key={index}
                index={index}
                data={data}
                onViewState={onViewState}
                isSelected={
                  (onViewState &&
                    answers.some((ans) => ans.uuid === data.uuid)) ||
                  selectedItem?.uuid === data.uuid
                }
              />
            ))}
        </StyledKeywordContainer>
        <StyledDialogContent>
          <Typography lineHeight={2.2}>
            {DroppableContent({
              dialog,
              dragDropArr,
              keywords: shuffledKeywords,
              resultProps,
              onSelect: (index) =>
                dispatch(handleMobileSelect(index, handleProps)),
            })}
          </Typography>
        </StyledDialogContent>
      </StyledDragDropContainer>
    </DndContext>
  )
}
export default DragAndDrop

export const DroppableContent = (props) => {
  const { dialog, dragDropArr, keywords, resultProps, onSelect } = props
  let index = -1
  const convertedDiaglog = dialog.replace(blankReg, blankSimbol)
  return reactStringReplace(convertedDiaglog, blankSimbol, () => {
    index++
    const correctIndex = keywords.findIndex(
      (ans) => ans.target === _.get(alphabets, index, '')
    )
    return (
      <Droppable
        key={index}
        index={index}
        data={dragDropArr[index]}
        resultProps={resultProps}
        correctNo={correctIndex + 1}
        onSelect={onSelect}
      />
    )
  })
}
