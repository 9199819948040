export const steps = [
  'เลือกรอบอบรมที่ต้องการ',
  'ข้อมูลส่วนตัว',
  'รายละเอียดยืนยันข้อมูลการเข้าอบรม',
]

export const verificationSteps = [
  'ยืนยันตัวตน',
  'รายละเอียดยืนยันข้อมูลการเข้าอบรม',
]
