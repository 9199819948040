import React, { useState } from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'

import TextInput from '../../../components/Input/TextInput'
import { closeDialog } from '../../../redux/slices/alertDialog'
import { validateRemark } from './events'

import { StyledButtonLeft, StyledButtonRight } from '../Styled'

export const Index = ({ labelInput, submitDialog }) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    remark: '',
    isError: false,
  })

  return (
    <Box sx={{ mx: 3, my: 2 }}>
      <Box sx={{ ml: 1, mt: 1 }}>
        <TextInput
          required
          type="basic"
          name="remark"
          id="remark"
          rows={3}
          labelText={labelInput}
          placeholder=""
          multiline={true}
          value={state.remark}
          textError={state.isError ? 'ระบุได้ไม่เกิน 3000 ตัวอักษร' : ''}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              remark: _.get(e, 'target.value', ''),
            }))
          }
        />
      </Box>

      <Box
        sx={{
          mt: 1.5,
          display: 'flex',
          gap: 3,
          mb: '60px',
          mr: 0,
          ml: 1,
        }}
      >
        <StyledButtonLeft
          data-testid="btn-disagree"
          size="m"
          variant="outlined"
          sx={{ width: '100%' }}
          onClick={() => dispatch(closeDialog())}
        >
          <Typography color="primary" variant="button">
            ยกเลิก
          </Typography>
        </StyledButtonLeft>
        <StyledButtonRight
          data-testid="btn-agree"
          size="m"
          variant="contained"
          disabled={!state.remark}
          sx={{ width: '100%' }}
          color="primary"
          onClick={() =>
            dispatch(validateRemark(state, setState, submitDialog))
          }
        >
          <Typography
            variant="button"
            sx={{
              color: state.remark ? 'auto' : 'rgba(0, 0, 0, 0.26)',
            }}
            color="auto"
          >
            ยืนยัน
          </Typography>
        </StyledButtonRight>
      </Box>
    </Box>
  )
}

export default Index
