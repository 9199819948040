import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import styled from '@mui/system/styled'

export const StyledBoxShadow = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'btnSize',
})(({ btnSize }) => ({
  position: 'absolute',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mixBlendMode: 'multiply',
  width: btnSize,
  height: btnSize,
  background: '#cccccc',
  borderRadius: '50%',
  top: '50%',
  transform: 'translate(0, -50%)',
}))

export const StyledIconButton = styled(IconButton)(({ theme, transform }) => ({
  position: 'absolute',
  zIndex: 1,
  top: '50%',
  transform: `translate(${transform}, -50%)`,
  background: theme.palette?.info?.main,
  color: theme.palette?.background?.paper,
  svg: { fontSize: '2.5rem' },
  ':hover': { background: theme.palette?.info?.main },
  [theme.breakpoints.down('lg')]: { svg: { fontSize: '1.5rem' } },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    top: 0,
    transform: 'unset',
    padding: '5px',
    svg: { fontSize: '1rem' }
  },
}))
