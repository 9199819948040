import _ from 'lodash'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getContentHome } from 'src/services/home'

export const fetchContentHome = () => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(getContentHome())
  dispatch(stopLoading())
}

export const handleSelector = (state, keyField) => {
  return _.get(state.home.data, keyField, [])
}
