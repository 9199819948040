import _ from 'lodash'
import { startLoading, stopLoading } from '../../../../redux/slices/loading'
import { historyCells } from '../model'
import { setInitialTable } from '../../../../redux/slices/basicTable'

import { postManageProspectHistory } from '../../../../services/manageProspect'

export const fetchDataHistory = (props) => async (dispatch) => {
  const { uuid, table } = props
  const { page, limit } = table
  const realPage = page <= 0 ? 1 : +page
  const body = {
    page: realPage,
    limit,
    prospectUuid: uuid,
  }

  dispatch(startLoading())

  const result = await dispatch(postManageProspectHistory(body))

  dispatch(
    setInitialTable({
      isCheckBox: false,
      rows: _.get(result, 'payload.result', []),
      allCount: _.get(result, 'payload.totalCount', 0),
      headCells: historyCells,
      isHideSearch: true,
    })
  )

  dispatch(stopLoading())
}
