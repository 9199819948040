import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eLearningETesting,
  eLearningETestingAnswer,
  eLearningETestingCorrectAnswer,
  eLearningETestingLearnerScore,
  eTesting,
  eTestingAnswer,
  eTestingAnswerNow,
  eTestingScore,
} from 'src/utils/apiPath'

export const getETesting = createAsyncThunk(
  'eTesting/getETesting',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid } = payload
      if (payload.moduleUuid) {
        const response = await axios.post(eLearningETesting, payload)
        return response
      } else {
        const { trainingPlan, onTesting } = payload
        let path = `${eTesting}/${uuid}`
        if (trainingPlan) {
          path = `${path}?trainingPlan=${trainingPlan}`
        }

        if (onTesting) {
          if (trainingPlan) {
            path = `${path}&ontesting=${onTesting}`
          } else {
            path = `${path}?ontesting=${onTesting}`
          }
        }
        const response = await axios.get(path)
        return response
      }
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postETestingAnswerNow = createAsyncThunk(
  'eTesting/postETestingAnswerNow',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, questionUuid } = payload
      const body = { questionUuid }
      const response = await axios.post(`${eTestingAnswerNow}/${uuid}`, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postETestingSubmit = createAsyncThunk(
  'eTesting/postETestingSubmit',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, body, isModule } = payload
      const response = await axios.post(
        isModule ? `${eLearningETestingAnswer}/${uuid}` : `${eTesting}/${uuid}`,
        body
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getETestingScore = createAsyncThunk(
  'eTesting/getETestingScore',
  async ({ body, isModule }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        isModule ? eLearningETestingLearnerScore : eTestingScore,
        body
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getETestingAnswer = createAsyncThunk(
  'eTesting/getETestingAnswer',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, trainingPlan, isModule } = payload
      if (isModule) {
        const body = {
          eTestingUuid: uuid,
          eLearningCourseBatchLearner: payload.eLearningCourseBatchLearner,
        }
        const response = await axios.post(eLearningETestingCorrectAnswer, body)
        return response
      } else {
        const body = {
          courseTestUuid: uuid,
          trainingPlanUuid: trainingPlan || '',
        }
        const response = await axios.post(eTestingAnswer, body)
        return response
      }
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
