import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const StyledCard = styled(Card)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '460px',
  height: 'auto',
  transform: 'translate(-50%, -50%)',
  border: 'solid 1px',
  borderColor: theme.palette.blue?.blueLine,
  borderRadius: '2px',
  boxShadow: theme.palette.boxShadow?.oceanBlue, // ไม่มีค่าใน themeData
  [theme.breakpoints.down('sm')]: {
    width: '335px',
  },
}))
