import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Breadcrumb from 'src/components/Breadcrumb'
import Template from 'src/components/Template'
import { StyledContainer } from 'src/components/Layout/Styled'
import Content from './Content'
import ClassList from './ClassList'
import { listBreadcrumbs } from './model'
import { fetchCourseDetail } from './events'

const Detail = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCourseDetail(id))
  }, [])

  return (
    <>
      <Template noMinHeight>
        <StyledContainer>
          <Breadcrumb list={listBreadcrumbs} />

          <Content />
        </StyledContainer>
      </Template>

      <ClassList />
    </>
  )
}

export default Detail
