import _ from 'lodash'
import { convertFormatDateTime } from 'src/utils/lib'

export const handleFormatDate = (selectedDates) => {
  const startDate = convertFormatDateTime({
    value: _.get(selectedDates, 'startDate', ''),
    type: 'dateDb',
  })
  const finishDate = convertFormatDateTime({
    value: _.get(selectedDates, 'endDate', ''),
    type: 'dateDb',
  })
  return { startDate, finishDate }
}
