import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Column = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const Row = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  marginTop: theme.spacing(9),
  padding: `${theme.spacing(5)} 0`,
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
    gap: theme.spacing(3),
  },
}))
