import _ from 'lodash'
import { setAnswerEEvaluation } from 'src/redux/slices/eEvaluation'

export const handleDefaultValue = (eEvaluation, uuid, staffUuid) => {
  const question = eEvaluation.answerEEvaluation.find((item) => {
    if (item.staffUuid) {
      return item.questionUuid == uuid && item.staffUuid == staffUuid
    }
    return item.questionUuid == uuid
  })
  return _.get(question, 'answerUuid', '')
}

export const handleSelectRating = (e, uuid, staffUuid) => (dispatch) => {
  const value = e.target.value
  dispatch(
    setAnswerEEvaluation({
      type: 'RATING',
      uuid,
      value,
      staffUuid,
    })
  )
}
