import React, { useMemo } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { LoadingButton } from '@mui/lab'
import { setPreviousQuestion } from 'src/redux/slices/eTesting'
import { checkDisplayOneAndNow } from 'src/redux/slices/eTesting/events'
import { responsiveButton } from 'src/components/Preview/Footer/Styled'
import BackToMainBtn from '../BackToMainBtn'
import SubmitBtn from '../SubmitBtn'
import { handleIsAnswered, handleNextQuestion } from './events'

const ButtonForOne = () => {
  const buttonSize = responsiveButton()
  const dispatch = useDispatch()
  const eTesting = useSelector((state) => state.eTesting, shallowEqual)
  const uuid = _.get(eTesting, 'uuid', '')
  const answerETesting = _.get(eTesting, 'answerETesting', [])
  const questions = _.get(eTesting, 'questions', [])
  const page = _.get(eTesting, 'page', 1)
  const question = _.get(questions, [page - 1], {})
  const isButtonLoading = _.get(eTesting, 'isButtonLoading', false)
  const onShowAnswer = _.get(eTesting, 'onShowAnswer', false)
  const onReviewAnswer = _.get(eTesting, 'onReviewAnswer', false)
  const onViewState = onShowAnswer || onReviewAnswer
  const isNow = checkDisplayOneAndNow(eTesting)
  const isShowBack = !isNow || onReviewAnswer
  const nextQuestionProps = {
    uuid,
    question,
    isNow,
    onViewState,
  }
  const isAnswered = useMemo(
    () => handleIsAnswered({ answerETesting, question }),
    [answerETesting, page]
  )

  return (
    <Box sx={{ display: 'flex', gap: 3 }}>
      {page > 1 && isShowBack && (
        <Button
          variant="contained"
          size={buttonSize}
          data-testid="btn-back"
          onClick={() => dispatch(setPreviousQuestion())}
          startIcon={<ArrowBack />}
        >
          กลับ
        </Button>
      )}
      {page < questions.length && (
        <LoadingButton
          variant="contained"
          size={buttonSize}
          onClick={() => dispatch(handleNextQuestion(nextQuestionProps))}
          data-testid="btn-next"
          endIcon={<ArrowForward />}
          loading={isButtonLoading}
          loadingPosition="end"
          disabled={!isAnswered && !onReviewAnswer}
        >
          ถัดไป
        </LoadingButton>
      )}
      {page == questions.length && !onReviewAnswer && <SubmitBtn />}
      {onReviewAnswer && <BackToMainBtn />}
    </Box>
  )
}

export default ButtonForOne
