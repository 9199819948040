import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getMyClassHistory,
  getMyClassList,
  getEExaminationMySchedule,
} from 'src/services/class/list'
import {
  handleFilterBody,
  handleFulfilled,
  handlePending,
  handleRejected,
} from './events'
import { initialState } from './model'

const classList = createSlice({
  name: 'classList',
  initialState,
  reducers: {
    startLoadMore: (state) => {
      state.isLoadMore = true
    },
    stopLoadMore: (state) => {
      state.isLoadMore = false
    },
    setTab: (state, { payload }) => {
      state.tab = payload
      state.filter = []
      state.filterBody = { ...initialState.filterBody }
      handleFilterBody(state, payload)
    },
    setFilter: (state, { payload }) => {
      state.filter = payload
      state.filterBody.page = 1
      if (payload.length == 0) handleFilterBody(state, state.tab)
      else state.filterBody.classStatus = payload
    },
    setSearch: (state, { payload }) => {
      state.filterBody.courseName = payload
      state.filterBody.page = 1
    },
    setPeriod: (state, { payload }) => {
      const { startDate, finishDate } = payload
      state.filterBody.startDate = startDate
      state.filterBody.finishDate = finishDate
      state.filterBody.page = 1
    },
    setLoadMore: (state) => {
      const nextPage = state.filterBody.page + 1
      const maxPage = _.ceil(state.totalClasses / 12)
      if (nextPage <= maxPage) state.filterBody.page = nextPage
    },
    setMyScheduleFilterInit: (state) => {
      state.myScheduleFilterBody = initialState.myScheduleFilterBody
      state.myScheduleList = []
    },
    setMyScheduleFilter: (state, { payload }) => {
      payload.map((item) => {
        const { key, value } = item
        state.myScheduleFilterBody[key] = value
      })
    },
    setMyScheduleList: (state, { payload }) => {
      state.myScheduleList = payload
    },
    updateMySchedule: (state, { payload }) => {
      const { uuid, item } = payload
      const { myScheduleList } = state
      const newList = myScheduleList.map((el) => {
        if (el.uuid === uuid) {
          return {
            ...el,
            ...item,
          }
        }
        return el
      })
      state.myScheduleList = newList
    },
    setCancelTokenSource: (state, { payload }) => {
      state.cancelTokenSource = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyClassList.fulfilled, (state, { payload }) => {
        const { trainingPlans, totalTrainingPlan } = payload.data
        const { myScheduleList } = state
        if (state.myScheduleFilterBody.page > 1) {
          const mergeMyScheduleList = [...myScheduleList, ...trainingPlans]
          state.myScheduleList = mergeMyScheduleList
        } else {
          state.myScheduleList = trainingPlans
        }
        state.myScheduleFilterBody.isFetch = false
        state.myScheduleFilterBody.hasMore =
          state.myScheduleList.length < totalTrainingPlan
      })
      .addCase(getMyClassList.pending, handlePending)
      .addCase(getMyClassList.rejected, handleRejected)
      .addCase(getMyClassHistory.fulfilled, handleFulfilled)
      .addCase(getMyClassHistory.pending, handlePending)
      .addCase(getMyClassHistory.rejected, handleRejected)
      .addCase(getEExaminationMySchedule.fulfilled, (state, { payload }) => {
        const { result, totalCount } = payload.data
        const { myScheduleList } = state
        if (state.myScheduleFilterBody.page > 1) {
          const mergeMyScheduleList = [...myScheduleList, ...result]
          state.myScheduleList = mergeMyScheduleList
        } else {
          state.myScheduleList = result
        }
        state.myScheduleFilterBody.isFetch = false
        state.myScheduleFilterBody.hasMore =
          state.myScheduleList.length < totalCount
      })
      .addCase(getEExaminationMySchedule.rejected, handleRejected)
  },
})

export const {
  startLoadMore,
  stopLoadMore,
  setTab,
  setFilter,
  setSearch,
  setPeriod,
  setLoadMore,
  setMyScheduleFilterInit,
  setMyScheduleFilter,
  updateMySchedule,
  setCancelTokenSource,
  setMyScheduleList,
} = classList.actions

export default classList.reducer
