import React, { useEffect } from 'react'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ContentNoClass from 'src/components/ContentNoClass'
import TabContainer from './TabContainer'
import { handleRedirect } from './events'
import {
  StyledCourseContainer,
  StyledHeaderBody,
  StyledHeaderText,
} from './Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { handleSelector } from '../events'
import { setCoursePosition } from 'src/redux/slices/utils/userSlice'

const Course = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const isLg = isMediaWidthMoreThan('lg')
  const courses = useSelector(
    (state) => handleSelector(state, 'coursesInformation[0]'),
    shallowEqual
  )
  const coursesTitle = useSelector(
    (state) => handleSelector(state, 'coursesTitle'),
    shallowEqual
  )
  const tabs = _.get(courses, 'taps', []).filter((item) => item.status)

  useEffect(() => {
    if (location.hash) {
      const section = document.getElementById(location.hash.slice(1))
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' })
        dispatch(setCoursePosition(section.offsetTop))
      }
    } else window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location, courses])

  return (
    <StyledCourseContainer id="recommended-course">
      <StyledHeaderText>
        <Typography
          id="title"
          variant={isLg ? 'h3' : 'h5'}
          color="text.primary"
        >
          {_.get(coursesTitle, 'title', '')}
        </Typography>
        <StyledHeaderBody
          variant={isLg ? 'body1' : 'body2'}
          color="text.secondary"
        >
          {_.get(coursesTitle, 'description', '')}
        </StyledHeaderBody>
      </StyledHeaderText>

      {tabs.length > 0 ? <TabContainer tabs={tabs} /> : <ContentNoClass />}

      {coursesTitle.statusButton && (
        <Button
          data-testid="btn-course"
          onClick={() => handleRedirect(_.get(coursesTitle, 'URL', ''))}
          variant="outlined"
          size={isLg ? 'l' : 'm'}
        >
          {_.get(coursesTitle, 'button', '')}
        </Button>
      )}
    </StyledCourseContainer>
  )
}

export default Course
