import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { StyledProgress, StyledProgressMilestone } from './Styled'

const ProgressMilestone = ({ value }) => {
  const percentage = _.defaultTo(value, 0)
  return (
    <StyledProgressMilestone>
      <Typography color="text.white">{percentage}%</Typography>
      <StyledProgress value={value} />
    </StyledProgressMilestone>
  )
}

export default ProgressMilestone
