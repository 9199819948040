import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isScrolled',
})(({ theme, isScrolled }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
  position: isScrolled ? 'fixed' : 'absolute',
  left: 0,
  padding: `0 ${theme.spacing(10)}`,
  backgroundColor: theme.palette.primary?.contrast,
  ...fixedHeaderResponsive(isScrolled),
  [theme.breakpoints.down('lg')]: {
    padding: `0 ${theme.spacing(4.5)}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.spacing(2.5)}`,
  },
}))

export const fixedHeaderResponsive = (isScrolled) => {
  if (!isScrolled) return null

  return {
    width: '100%',
    maxWidth: 1440,
    position: 'fixed',
    top: 71,
    left: '50%',
    transform: 'translate(-50%)',
    zIndex: 99,
  }
}