import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledFixedHeader = styled(Row)(({ theme, isScrolled }) => ({
  display: isScrolled ? 'flex' : 'none',
  position: 'fixed',
  top: 72,
  zIndex: 1100,
  borderTop: '1px solid',
  borderColor: theme.palette?.text?.wildSand,
  boxShadow: theme.palette?.boxShadow?.menu,
  background: theme.palette?.background?.paper,
  [theme.breakpoints.down('lg')]: { display: 'none' },
}))

export const StyledContainer = styled(Row)(({ theme }) => ({
  maxWidth: 1146,
  justifyContent: 'space-between',
  margin: 'auto',
  padding: `${theme.spacing(1)} ${theme.spacing(10)}`,
}))

export const StyledHeaderText = styled(Typography)(() => ({
  maxWidth: 'calc(100% - 420px)',
  wordBreak: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}))
