import FastRewind from '@mui/icons-material/FastRewind'

export const initialState = {
  search: { placeholder: '', searchText: '' },
  table: {
    isLoading: false,
    isHideHeadCell: false,
    isCheckBox: false,
    rows: [],
    headCells: [],
    allCount: 0,
    page: -1,
    tablePage: 0,
    selected: [],
    numSelected: 0,
    order: 'desc',
    sort: 'updatedAt',
    defaultSortId: 'updatedAt',
    defaultSort: { state: 0, id: '', active: false },
    limit: '20',
    rowsPerPage: { label: '20', value: 20 },
    isShowPagination: true,
    hiddenRowsPerPage: FastRewind,
  },
  filter: {
    isFilterDrawer: false,
    status: [],
    filterProp: null,
    filterTotal: 0,
    quickFilter: [],
    other: null,
  },
  handleSearch: null,
  onDownload: null,
  onDelete: null,
  onQuickFilter: null,
  customStyle: {},
  customToolbar: null,
  onSelect: null,
  onSelectAll: null,
}

export const initialStateBasic = {
  isLoading: false,
  rows: [],
  headCells: [],
  allCount: 0,
  page: -1,
  tablePage: 0,
  order: 'desc',
  sort: 'updatedAt',
  defaultSortId: 'updatedAt',
  defaultSort: { state: 0, id: '', active: false },
  limit: '20',
  rowsPerPage: { label: '20', value: 20 },
  isShowPagination: true,
}
