import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const SliderContainer = styled(Box)(({ theme, url }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '40px',
  width: '100%',
  padding: '120px 100px',
  backgroundImage: `url(${url})`,
  backgroundSize: 'cover',
  backgroundPosition: 'right',
  backgroundRepeat: 'no-repeat',
  height: '780px',
  [theme.breakpoints.down('lg')]: {
    alignItems: 'center',
    height: '440px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0px',

    height: '348px',
    justifyContent: 'center',
  },
}))

export const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  textAlign: 'center',
  width: '100%',
  maxWidth: 660,
  zIndex: 999,
  [theme.breakpoints.down('sm')]: { maxWidth: 327 },
}))

export const CarouselDot = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'countContent',
})(({ isSelected, theme, index, countContent }) => ({
  borderRadius: '50%',
  backgroundColor: isSelected
    ? theme.palette?.secondary?.contrast
    : theme.palette?.action?.disabledBackground,
  cursor: 'pointer',
  color: isSelected
    ? theme.palette?.secondary?.contrast
    : theme.palette?.action?.disabledBackground,
  fontSize: isSelected ? '10px' : '5px',
  padding: isSelected ? '1px 7px' : '0px 3px',
  marginLeft: index === 0 ? '0px' : '14px',
  display: countContent > 1 ? 'auto' : 'none !important',
}))

export const Overlay = styled(Box)(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  background: 'rgba(0, 0, 0, 0.35)',
  width: '100%',
  height: '100%',
  opacity: 1,
}))
