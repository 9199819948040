import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { setStartModule } from 'src/redux/slices/eLearning/learning'
import { responsive, StyledButton, StyledDescriptionContent } from './Styled'

const Content = ({ information, url, buttonText, condition, isModule }) => {
  const isDuplicate = _.get(condition, 'isDuplicate', false)
  const isOpen = _.get(condition, 'isOpen', false)
  const canDo = isOpen && !isDuplicate
  const dispatch = useDispatch()
  return (
    <Box sx={{ mt: 5 }}>
      <Typography
        align={_.lowerCase(_.get(information, 'title1Position', 'LEFT'))}
        variant={responsive('h3', 'h4', 'h5')}
        color="primary"
      >
        {_.get(information, 'title1', '')}
      </Typography>
      <Box sx={{ textAlign: _.get(information, 'title2Position', 'LEFT') }}>
        <Typography variant={responsive('h4', 'h5', 'body1b')} color="primary">
          {_.get(information, 'title2', '')}
        </Typography>
      </Box>
      <StyledDescriptionContent
        align={_.get(information, 'descriptionPosition', 'LEFT')}
      >
        <Typography
          variant={responsive('h6', 'h6', 'body2b')}
          color="text.secondary"
        >
          {canDo
            ? _.get(information, 'description', '')
            : _.get(condition, 'conditionText', '')}
        </Typography>
        {canDo && (
          <StyledButton
            size={responsive('l', 'm', 's')}
            data-testid="btn-start"
            onClick={() =>
              isModule
                ? dispatch(setStartModule())
                : (window.location.href = url)
            }
          >
            {buttonText}
          </StyledButton>
        )}
      </StyledDescriptionContent>
    </Box>
  )
}

export default Content
