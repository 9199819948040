import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'

import { Check, ArrowForward } from '@mui/icons-material/'
import { CardSection } from '..'

import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import { checkDataSuccess, handleChecked, handleSubmit } from '../events'
import { handleSubmitSentMail } from '../../Form/events'

const Index = () => {
  const dispatch = useDispatch()

  const { isLoading } = useSelector((state) => state.loading, shallowEqual)

  const { course, prospects, isChecked, isSuccess } = useSelector(
    (state) => state.manageProspectEnrollment,
    shallowEqual
  )
  const trainingPlanUuid = _.get(course, 'trainingPlan.value', '')
  const countSuccess = checkDataSuccess(prospects)
  const color = countSuccess <= 0 ? 'text.lightGray' : 'text.primary'

  return (
    <CardSection
      section="3"
      labelSection="ยืนยันการสมัคร"
      labelCard="ยืนยันการสมัครเข้าอบรม"
    >
      <Box sx={{ border: '1px solid #DBE4F1', borderRadius: '2px', mt: 3 }}>
        <Box sx={{ m: 2 }}>
          {!isSuccess ? (
            <>
              {trainingPlanUuid && !isLoading && (
                <Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1">
                      โปรดตรวจสอบรายชื่อตัวแทนรอรหัสอีกครั้ง
                    </Typography>
                    <Typography variant="body1">
                      ระบบจะส่งอีเมลให้กับตัวแทนรอรหัสเพื่อนำส่งเอกสาร
                      และยินยอมการใช้ข้อมูลส่วนบุคคลเพื่อการฝึกอบรม
                      ภายในระยะเวลาที่กำหนด
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                    <Typography variant="body1b">รายชื่อที่เพิ่มได้</Typography>
                    <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                      <Check color="success" />
                      <Typography
                        variant="body1"
                        sx={{ display: 'flex', gap: 1 }}
                      >
                        เพิ่มได้
                        <Typography variant="body1" color="success.main">
                          {countSuccess} รายชื่อ
                        </Typography>
                        จากทั้งหมด {prospects.length} รายชื่อ
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!isChecked && countSuccess <= 0}
                          name="check"
                          onClick={() => dispatch(handleChecked())}
                        />
                      }
                      label={
                        <Typography variant="body1" color={color}>
                          ยืนยันการสมัคร
                        </Typography>
                      }
                    />
                  </Box>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}
                  >
                    <Button
                      size="m"
                      variant="contained"
                      disabled={!isChecked}
                      onClick={() => {
                        if (isChecked)
                          dispatch(handleSubmit(prospects, trainingPlanUuid))
                      }}
                    >
                      <Typography variant="button">ยืนยันการสมัคร</Typography>
                    </Button>
                  </Box>
                </Box>
              )}

              {trainingPlanUuid && isLoading && (
                <Box sx={{ textAlign: 'center', py: 3 }}>
                  <img
                    alt="check_register"
                    src="/icon/table/check_register.svg"
                  />
                  <Typography variant="body1" color="text.gray">
                    กำลังตรวจสอบการลงทะเบียนเรียนของแต่ละรายชื่อผู้สมัคร
                  </Typography>
                </Box>
              )}

              {!trainingPlanUuid && !isLoading && (
                <Box sx={{ textAlign: 'center', py: 3 }}>
                  <img alt="check_detail" src="/icon/table/check_detail.svg" />
                  <Typography variant="body1" color="text.gray">
                    กรุณาเลือกหลักสูตร และตรวจสอบรายละเอียดของคลาส
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <Box sx={{ textAlign: 'center', py: 3 }}>
              <img alt="enroll_success" src="/icon/table/enroll_success.svg" />
              <Typography variant="h4" color="primary">
                ส่งคำขอเข้าอบรมเรียบร้อยแล้ว
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body2" color="text.secondary">
                  การสมัครจะสำเร็จเมื่อตัวแทนรอรหัสอัปโหลดเอกสารกลับมาครบถ้วน
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  และยินยอมการใช้ข้อมูลส่วนบุคคลภายในระยะเวลาที่กำหนด
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 4,
                  display: 'flex',
                  gap: 2,
                  justifyContent: 'center',
                }}
              >
                <Button
                  size="l"
                  variant="outlined"
                  onClick={() => {
                    window.location.href = '/manage-prospect'
                  }}
                >
                  <Typography variant="buttonl">กลับหน้าโปรไฟล์ทีม</Typography>
                </Button>
                <Button
                  size="l"
                  variant="contained"
                  onClick={() => {
                    dispatch(
                      handleSubmitSentMail(
                        _.map(prospects, (ele) => ele.uuid),
                        true
                      )
                    )
                  }}
                  endIcon={<ArrowForward />}
                >
                  <Typography variant="buttonl">ไปสมัครสอบต่อ</Typography>
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </CardSection>
  )
}

export default Index
