export const checkColorStatus = (status, theme) => {
  const statusText = status?.toString().toLowerCase()

  const colorList = {
    active: theme?.palette?.success?.main,
    inactive: theme?.palette?.text?.silver,
    deleted: theme?.palette?.text?.white,
    waiting: theme?.palette?.warning?.light,
    waiting_confirm_identity: theme?.palette?.warning?.light,
    waiting_for_active: theme?.palette?.warning?.light,
    approve: theme?.palette?.success?.main,
    reject: theme?.palette?.error?.main,
    draft: theme?.palette?.silver?.primary,
    cancel: theme?.palette?.error?.main,
    in_progress: theme?.palette?.primary?.main,
  }
  return colorList[`${statusText}`]
}
