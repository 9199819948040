import styled from '@mui/system/styled'
import { Carousel } from 'react-responsive-carousel'

export const StyledCarousel = styled(Carousel)(({ theme }) => ({
  '& .control-dots': {
    marginBottom: theme.spacing(4.5),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
}))
