import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { Column } from 'src/components/Layout/Styled'
import { convertFormatTime } from 'src/utils/lib'
import {
  StyledChipGroup,
  StyledSection,
  StyledSectionContainer,
} from './Styled'

const Detail = () => {
  const data = useSelector((state) => state.classDetail.data, shallowEqual)
  const courseDetail = _.get(data, 'courseDetail', null)
  const time = convertFormatTime(courseDetail)
  const acquiredSkills = _.get(courseDetail, 'getAcquiredSkillAndType', null)
  const softSkill = _.get(acquiredSkills, 'Softskill', null)
  const hardSkill = _.get(acquiredSkills, 'Hardskill', null)
  const productType = _.get(courseDetail, 'getProductType', [])
  const trainingRoom = _.get(data, 'trainingPlanData.trainingRoom', [])

  return (
    <Column gap={3}>
      <StyledSectionContainer>
        <Section label="ชื่อหลักสูตร TH">
          {_.get(courseDetail, 'thaiName', '-') || '-'}
        </Section>
        <Section label="ชื่อหลักสูตร EN">
          {_.get(courseDetail, 'englishName', '-')}
        </Section>
        <Section label="ชื่อหลักสูตร (สำหรับ Learner)">
          {_.get(courseDetail, 'nameForLearner', '-') || '-'}
        </Section>
        <Section label="ช่องทางการจำหน่าย">
          {_.get(courseDetail, 'distribution', '-')} (
          {_.get(courseDetail, 'distributionAbbreviation', '-')})
        </Section>
        <Section label="กลุ่มหลักสูตร">
          {_.get(courseDetail, 'roadmap', '-')} (
          {_.get(courseDetail, 'roadmapAbbreviation', '-')})
        </Section>
        <Section group label="ระดับของผู้เรียน">
          <ChipGroup data={_.get(courseDetail, 'levelOfLearner', [])} />
        </Section>
        <Section group label="ระดับของผู้เรียน ช่องทางการจำหน่ายอื่น">
          <ChipGroup data={_.get(courseDetail, 'otherLevelOfLearner', [])} />
        </Section>
        <Section label="รูปแบบการสอน">
          {_.get(courseDetail, 'trainingPlatformName', '-')}
        </Section>
        <Section label="รหัสหลักสูตร">
          {_.get(courseDetail, 'courseCode', '-')}
        </Section>
        <Section label="ระยะเวลาของหลักสูตร">{time}</Section>
        <Section group label="ทักษะที่ได้จากหลักสูตร">
          {softSkill && <ChipGroup label="Soft Skill" data={softSkill} />}
          {hardSkill && <ChipGroup label="Hard Skill" data={hardSkill} />}
        </Section>
        <Section label="ทักษะที่ได้จากหลักสูตร">
          {productType.map(
            (item, index) =>
              `${item}${index < productType.length - 1 ? ',' : ''}`
          )}
        </Section>
      </StyledSectionContainer>
      <StyledSectionContainer>
        <Typography variant="body1b">ตารางเวลาอบรม</Typography>
        {trainingRoom.map((item, index) => (
          <DateTimeRoomContent key={index} item={item} />
        ))}
      </StyledSectionContainer>
    </Column>
  )
}

export default Detail

export const Section = ({ label, children, group }) => {
  return (
    <StyledSection>
      <Box>
        <Typography variant="body1" color="text.secondary">
          {label}
        </Typography>
      </Box>
      {group ? (
        <Box id="group">{children}</Box>
      ) : (
        <Box>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ wordWrap: 'break-word' }}
          >
            {children}
          </Typography>
        </Box>
      )}
    </StyledSection>
  )
}

export const ChipGroup = ({ data, label }) => {
  return (
    <StyledChipGroup>
      {label && <Typography>{label}:</Typography>}
      {data.map((text) => (
        <Chip key={text} variant="outlined" label={text} />
      ))}
    </StyledChipGroup>
  )
}

export const DateTimeRoomContent = ({ item }) => {
  const day = _.get(item, 'dateDay', '-')
  const month = _.get(item, 'dateMonth', '')
  const year = _.get(item, 'dateYear', '')
  const date = `${day} ${month} ${year}`
  return (
    <Section label={date}>
      {_.get(item, 'typeTime', '')} {_.get(item, 'startTime', '')} -{' '}
      {_.get(item, 'endTime', '')} | {_.get(item, 'roomName', '-')}
    </Section>
  )
}
