import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import styled from '@mui/system/styled'

export const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-valueLabel': {
    borderRadius: theme.spacing(0.5),
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
  },
  '& .MuiSlider-valueLabelLabel': { fontSize: '18px' },
}))

export const StyledFilterContent = styled(Box)(({ theme }) => ({
  width: 235,
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    '& .rdrDateRangePickerWrapper': { left: -6 },
    '& .rdrCalendarWrapper': { width: 300 },
  },
}))
