import _ from 'lodash'
import { store } from 'src/App'
import { openErrorDialog } from 'src/redux/slices/alertDialog'
import { setSectionResult } from 'src/redux/slices/eEvaluation'

export const handleSubmitEEvaluation = (isModule) => (dispatch) => {
  if (isModule) {
    const { selectedLesson } = store.getState().eLearningLearning
    const errorTitle = 'ไม่สามารถส่งแบบประเมินได้'
    if (selectedLesson.isSubmitted) {
      dispatch(
        openErrorDialog({
          title: errorTitle,
          message: [
            'เนื่องจากคุณผ่านบทเรียนนี้ไปแล้ว ก่อนมีการเปลี่ยนแปลงหลักสูตร',
          ],
        })
      )
      return
    }
    if (_.isEmpty(selectedLesson.logUuid)) {
      dispatch(
        openErrorDialog({
          title: errorTitle,
          message: [
            'เนื่องจากคุณผ่านหลักสูตรนี้ไปแล้ว ก่อนมีการเปลี่ยนแปลงหลักสูตร',
          ],
        })
      )
      return
    }
  }

  dispatch(setSectionResult())
}
