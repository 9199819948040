import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eContentById,
  eContentFilter,
  eContentRelated,
  getEContentOptions,
  getEContentSubCategoryOptions,
} from 'src/utils/apiPath'

export const postEContentFilter = createAsyncThunk(
  'eContent/postEContentFilter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eContentFilter, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getEContentById = createAsyncThunk(
  'eContent/getEContentById',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eContentById}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postEContentRelated = createAsyncThunk(
  'eContent/postEContentRelated',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eContentRelated, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getOptions = createAsyncThunk(
  'eContent/getOptions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(getEContentOptions)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getSubCategoryOptions = createAsyncThunk(
  'eContent/getSubCategoryOptions',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(getEContentSubCategoryOptions, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
