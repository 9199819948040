import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InputBase from '@mui/material/InputBase'
import InputAdornment from '@mui/material/InputAdornment'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useTheme } from '@mui/material/styles'

const InputText = (props) => {
  const {
    boxSx,
    sx,
    type,
    placeholder,
    labelText,
    labelVariant = 'body2',
    required,
    disabled,
    readOnly,
    id,
    name,
    onChange,
    onBlur,
    onClick,
    textError,
    value,
    defaultValue,
    multiline,
    rows,
    endAdornmentText,
    inputProps,
    isShowTextError = true,
    dialogRight,
    maxRows,
    maxlength = 'unset',
  } = props
  const theme = useTheme()

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant={labelVariant}
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
        {dialogRight && (
          <Box sx={{ ml: 'auto', display: 'flex' }}>
            <InfoOutlinedIcon
              sx={{
                color: `${theme.palette.warning?.main}`,
                cursor: 'pointer',
              }}
            />
            <Typography
              sx={{ ml: 1 }}
              variant="body2"
              color={dialogRight.color}
            >
              {dialogRight.text}
            </Typography>
          </Box>
        )}
      </Box>
      <InputBase
        id={id}
        name={name}
        disabled={disabled}
        sx={{ ...sx, borderRadius: 0 }}
        type={type}
        placeholder={placeholder}
        onClick={onClick}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        key={defaultValue}
        defaultValue={defaultValue}
        multiline={multiline}
        inputProps={{
          ...inputProps,
          'data-testid': id,
          maxLength: maxlength,
        }}
        rows={rows}
        maxRows={maxRows}
        readOnly={readOnly}
        endAdornment={
          <InputAdornment position="end" sx={{ mr: 1 }}>
            {endAdornmentText}
          </InputAdornment>
        }
      ></InputBase>
      {isShowTextError && (
        <Typography sx={{ height: '25px' }} variant="body2" color="error">
          {textError}
        </Typography>
      )}
    </Box>
  )
}
export default InputText
