import React from 'react'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledDivider = styled((props) => (
  <Divider {...props} orientation="vertical" variant="middle" flexItem />
))(({ theme }) => ({ borderColor: theme.palette?.other?.divider }))

export const StyledLabelIcon = styled(Row)(({ theme }) => ({
  width: 'auto',
  gap: theme.spacing(1),
  color: theme.palette.text?.secondary,
}))

export const StyledRowContent = styled(Row)(({ theme }) => ({
  textAlign: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& #training': { display: 'none' },
  },
}))

export const StyledRowTwo = styled(Row)(({ theme }) => ({
  width: '50%',
  gap: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& .MuiDivider-root': { display: 'none' },
  },
}))

export const StyledHalfColumn = styled(Column)(({ theme }) => ({
  width: '50%',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const StyledDetailBox = styled(Row)(({ theme }) => ({
  padding: theme.spacing(1.5),
  border: '1px solid',
  borderColor: theme.palette?.text?.mercury,
  borderRadius: theme.spacing(0.5),
  gap: theme.spacing(4),
  alignItems: 'start',
  [theme.breakpoints.down('lg')]: {
    paddingBottom: theme.spacing(2.5),
    gap: theme.spacing(2.5),
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
    flexDirection: 'column',
  },
}))

export const StyledImage = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  width: 600,
  minWidth: 600,
  height: 400,
  [theme.breakpoints.down('lg')]: {
    width: 230,
    minWidth: 230,
    height: 153,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 207,
  },
}))

export const StyledContentColumn = styled(Column)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(2),
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: { paddingTop: theme.spacing(1) },
  [theme.breakpoints.down('sm')]: { paddingTop: 0 },
}))

export const StyledIncludeRow = styled(Row)(({ theme }) => ({
  flexWrap: 'wrap',
  rowGap: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
}))

export const StyledIconContent = styled(Row)(({ theme }) => ({
  width: '50%',
  gap: theme.spacing(1.5),
  svg: { color: theme.palette.text?.secondary },
}))

export const StyledRespColumn = styled(Column)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: { display: 'block' },
}))
