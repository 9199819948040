import React, { useRef } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ZoomIn from '@mui/icons-material/ZoomIn'
import ZoomOut from '@mui/icons-material/ZoomOut'
import RestartAlt from '@mui/icons-material/RestartAlt'
import { FILE_TYPE } from 'src/constants/eLearning'
import { handleDownloadFile } from '../../../RightPanel/ModuleLabel/events'
import { MediaBoxStyled } from '../Styled'
import {
  StyledCenteredContent,
  StyledFileRenderWrapper,
  StyledToolWrapper,
  StyledZipDisplay,
  StyledZipDownloadBtn,
  imageStyle,
} from './Styled'

const FileRender = ({ selectedLesson }) => {
  const fileList = _.get(selectedLesson, 'fileList', [])
  const selectedIndex = _.get(selectedLesson, 'selectedFile', 0)
  const selectedFile = _.get(fileList, selectedIndex, {})
  const ref = useRef(null)
  const height = 'calc(100vh - 96px)'
  const backendApiUrl = window.__env__.REACT_APP_SOCKET_URL
  const dispatch = useDispatch()

  if (selectedFile.fileType === _.lowerCase(FILE_TYPE.ZIP)) {
    return (
      <MediaBoxStyled key={selectedFile} sx={{ height }}>
        <StyledZipDisplay src="/icon/e-learning/ic_zip.svg" />

        <StyledZipDownloadBtn
          startIcon={<img src="/icon/ic_download.svg" />}
          onClick={() => dispatch(handleDownloadFile(selectedFile))}
        >
          ดาวน์โหลด
        </StyledZipDownloadBtn>
      </MediaBoxStyled>
    )
  }

  if (selectedFile.status === 'INPROGRESS') {
    return (
      <MediaBoxStyled key={selectedFile} sx={{ height }}>
        <Typography variant="h5" sx={{ ...StyledCenteredContent }}>
          อยู่ระหว่างดำเนินการอัปโหลดไฟล์
        </Typography>
      </MediaBoxStyled>
    )
  }

  return (
    <MediaBoxStyled key={selectedFile} sx={{ height }}>
      <StyledToolWrapper>
        <IconButton
          color="primary"
          onClick={() => (ref.current ? ref.current?.zoomIn() : null)}
        >
          <ZoomIn />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => (ref.current ? ref.current?.zoomOut() : null)}
        >
          <ZoomOut />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => (ref.current ? ref.current?.resetTransform() : null)}
        >
          <RestartAlt />
        </IconButton>
      </StyledToolWrapper>
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        wheel={{ wheelDisabled: true }}
        ref={ref}
      >
        {() => (
          <TransformComponent
            wrapperStyle={{ ...imageStyle, height: '100%', overflowY: 'auto' }}
            contentStyle={imageStyle}
          >
            <StyledFileRenderWrapper>
              {_.get(selectedFile, 'fileName', []).map((img, index) => (
                <img key={index} src={`${backendApiUrl}/file${img}`} />
              ))}
            </StyledFileRenderWrapper>
          </TransformComponent>
        )}
      </TransformWrapper>
    </MediaBoxStyled>
  )
}

export default FileRender
