import _ from 'lodash'

export const handleHoverOnVideo = (controlRef) => {
  if (_.isNil(controlRef.current)) return

  let timeout = 0

  const displayOpa = () => {
    if (!controlRef?.current?.style) return
    controlRef.current.style.opacity = 1
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (!controlRef?.current?.style) return
      controlRef.current.style.opacity = 0
    }, 1000)
  }

  controlRef.current.addEventListener('mousemove', displayOpa)
  return () => {
    controlRef.current.removeEventListener('mousemove', displayOpa)
  }
}
