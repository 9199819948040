export const initialState = {
  btnDisabled: true,
  body: {},
  alertDialog: {
    isOpen: false,
    isLoading: false,
    fullWidth: true,
    title: '',
    message: '',
    iconType: null,
    buttonLeft: {},
    buttonRight: {},
  }
}

export const prefixTHtoEN = {
  นาย: 'Mr',
  นางสาว: 'Ms',
  นาง: 'Mrs',
}
