import _ from 'lodash'
import { setFilter } from 'src/redux/slices/course/list'

export const handleMargin = (index, isNotSm) => {
  switch (index) {
    case 1:
      return isNotSm ? 32 : 17
    case 2:
      return isNotSm ? 64 : 35
    case 3:
      return isNotSm ? 96 : 53
    case 4:
      return isNotSm ? 76 : 42
    case 5:
      return isNotSm ? 40 : 22
    default:
      return 0
  }
}

export const handleRouterTrainingPlatform = (data, history) => (dispatch) => {
  const productTypes = _.get(data, 'productTypes', [])
  const payload = {
    key: 'productType',
    value: productTypes.map((item) => item.uuid),
  }
  dispatch(setFilter(payload))
  history.push('/course')
}
