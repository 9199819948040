import _ from 'lodash'
import { startLoading2, stopLoading2 } from '../../../../redux/slices/loading'
import { examinationHistoryCells } from '../model'
import { setInitialTable } from '../../../../redux/slices/basicTable'

import { postManageProspectExaminationHistory } from '../../../../services/manageProspect'

export const EXAMINER_EVENT_STATUS_TH = {
  CREATED: 'สมัครสอบ',
  UPDATED: 'แก้ไขตารางสอบ',
  DELETED: 'ลบตารางสอบ',
  UPLOAD_PERMISSION: 'อัปโหลดชื่อผู้มีสิทธิ์สอบ',
  ANNOUNCE: 'ประกาศรายชื่อผู้สอบ',
  UPLOAD_RESULT: 'อัปโหลดผลการสอบ',
  CREATED_STUDENT: 'เพิ่มรายชื่อผู้สอบ',
  UPDATED_STUDENT: 'แก้ไขรายชื่อผู้สอบ',
  DELETED_STUDENT: 'ลบรายชื่อผู้สอบ',
  CANCEL_STUDENT: 'ไม่มีสิทธิ์สอบ',
  CANCEL_BY_SELF: 'ยกเลิกโดยคุณ',
  CANCEL_BY_ADMIN: 'ยกเลิกโดยแอดมิน',
  CANCEL_EXAMINATION: 'ยกเลิกตารางสอบ',
  CANCEL_BY_MANAGER: 'ยกเลิกโดยผู้จัดการ',
}

export const fetchDataHistory = (props) => async (dispatch) => {
  const { uuid, table } = props
  const { page, limit } = table
  const realPage = page <= 0 ? 1 : +page
  const body = {
    page: realPage,
    limit,
    prospectUuid: uuid,
  }

  dispatch(startLoading2())

  const result = await dispatch(postManageProspectExaminationHistory(body))

  const data = _.map(_.get(result, 'payload.result', []), (payload) => {
    return { ...payload, event: EXAMINER_EVENT_STATUS_TH[payload?.event] }
  })
  dispatch(
    setInitialTable({
      isCheckBox: false,
      rows: data,
      allCount: _.get(result, 'payload.totalCount', 0),
      headCells: examinationHistoryCells,
      isHideSearch: true,
    })
  )

  dispatch(stopLoading2())
}
