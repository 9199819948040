import styled from '@mui/system/styled'
import { Carousel } from 'react-responsive-carousel'

export const StyledCarousel = styled(Carousel)(({ theme }) => ({
  '& .control-dots': {
    marginBottom: theme.spacing(4.5),
    left: '50%',
    transform: 'translate(-50%)',
    width: 'auto',
    '@media (max-width: 1146px)': {
      right: 0,
      left: 0,
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2.5),
    },
  },
}))
