import React from 'react'
import RadioButtonCheckedOutlined from '@mui/icons-material/RadioButtonCheckedOutlined'
import CheckBoxRounded from '@mui/icons-material/CheckBoxRounded'
import ShortTextRounded from '@mui/icons-material/ShortTextRounded'
import StarHalf from '@mui/icons-material/StarHalf'
import { themeColor } from './../utils/themeData'
import { path } from './path'
export const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
  },
  {
    id: 'codeId',
    disablePadding: false,
    label: 'ID',
    width: '80px',
  },
  {
    id: 'name',
    label: 'ชื่อแบบประเมิน',
    disablePadding: false,
  },
  {
    id: 'numberOfVersions',
    label: 'จำนวนเวอร์ชั่น',
    disablePadding: false,
  },
  {
    id: 'updatedBy',
    label: 'แก้ไขล่าสุดโดย',
    disablePadding: false,
  },
  {
    id: 'actionE',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideEdit: true,
    hideDelete: true,
    hideSortIcon: true,
    viewPath: path.eEvaluation,
  },
]

export const versionHeadCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
  },
  {
    id: 'version',
    disablePadding: false,
    label: 'เวอร์ชั่น',
    width: '80px',
  },
  {
    id: 'name',
    label: 'ชื่อแบบประเมิน',
    disablePadding: false,
  },
  {
    id: 'startDate',
    label: 'วันเริ่มต้นใช้งาน',
    disablePadding: false,
  },
  {
    id: 'updatedBy',
    label: 'แก้ไขล่าสุดโดย',
    disablePadding: false,
  },
  {
    id: 'versionStatus',
    label: 'สถานะ',
    disablePadding: false,
  },
  {
    id: 'actionEVersion',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `${path.eEvaluation}/version/view`,
    editPath: `${path.eEvaluation}/version/edit`,
    module: 'E_TESTING_SETTING', // 'E_EVALUATION_SETTING'
  },
]

export const defaultValue = {
  defaultTap: {
    assessment: 'assessment',
    setting: 'setting',
  },
  eEvaluationType: {
    class: 'CLASS',
    standALone: 'STANDALONE',
  },
  position: {
    left: 'LEFT',
    rihht: 'CENTER',
    center: 'RIGHT',
  },
  questionType: {
    single: 'SINGLE',
    multiple: 'MULTIPLE',
    freeText: 'FREE_TEXT',
    rating: 'RATING',
    ranking: 'RAKING',
  },
  mediaType: {
    image: 'VIDEO',
    video: 'IMAGE',
  },
  //setting
  displayQuestion: {
    all: 'ALL',
    one: 'ONE',
  },
  //preview
  defaultSection: {
    evaluate: 'EVALUATE',
    result: 'RESULT',
  },
}

export const listPositionEva = [
  {
    label: 'Left align',
    value: 'LEFT',
  },
  {
    label: 'Right align',
    value: 'RIGHT',
  },
  {
    label: 'Center align',
    value: 'CENTER',
  },
]

export const listChoiceEva = [
  {
    label: 'Single Choice',
    value: 'SINGLE',
  },
  {
    label: 'Multiple Choice',
    value: 'MULTIPLE',
  },
  {
    label: 'Rating',
    value: 'RATING',
  },
  {
    label: 'Free Text',
    value: 'FREE_TEXT',
  },
]

export const optionQuestionEva = [
  {
    labelText: 'Single Choice',
    icon: <RadioButtonCheckedOutlined color="action" />,
    value: 'SINGLE',
  },
  {
    labelText: 'Multiple Choice',
    icon: <CheckBoxRounded color="action" />,
    value: 'MULTIPLE',
  },
  {
    labelText: 'Rating',
    icon: <StarHalf color="action" />,
    value: 'RATING',
  },
  {
    labelText: 'Free Text',
    icon: <ShortTextRounded color="action" />,
    value: 'FREE_TEXT',
  },
]

export const optionSwap = [
  {
    label: 'ก่อน',
    value: 'BEFORE',
  },
  {
    label: 'หลัง',
    value: 'AFTER',
  },
]

export const sectionName = {
  courses: 'แบบประเมินเนื้อหาหลักสูตร',
  lecturers: 'แบบประเมินวิทยากร',
  courseManagements: 'แบบประเมินการจัดการหลักสูตร',
  questions: 'แบบประเมิน/แบบสอบถามอื่นๆ',
}

export const ratingColor = [
  themeColor.grey.grey50,
  themeColor.rating.theBest,
  themeColor.rating.great,
  themeColor.rating.veryGood,
  themeColor.rating.good,
  themeColor.rating.ok,
  themeColor.rating.notBad,
  themeColor.rating.notOk,
  themeColor.rating.bad,
  themeColor.rating.veryBad,
  themeColor.rating.worst,
]

export const ratingChoices = [
  { title: 'ดีมาก', sequence: 1 },
  { title: 'ดี', sequence: 2 },
  { title: 'ปานกลาง', sequence: 3 },
  { title: 'พอใช้', sequence: 4 },
  { title: 'ควรปรับปรุง', sequence: 5 },
]

export const listTypeOfAnswers = [
  {
    label: 'อย่างน้อย',
    value: 'MINIMUM',
  },
  {
    label: 'มากสุด',
    value: 'MAXIMUM',
  },
  {
    label: 'เท่ากับ',
    value: 'EQUAL',
  },
]

export const answerWording = {
  NONE: 'ตอบได้หลายข้อ',
  MINIMUM: 'ตอบอย่างน้อย',
  MAXIMUM: 'ตอบได้มากสุด',
  EQUAL: 'เลือกตอบ',
}

export const assessmentField = {
  course: 'courses',
  lecturer: 'lecturers',
  courseManagement: 'courseManagements',
  question: 'questions',
}

export const mockLecturerName = {
  men: 'สมชาย ใจดี',
  women: 'สมหญิง ใจดี',
}

export const answerSection = {
  courses: 'COURSE_ASSESSMENTS',
  lecturers: 'LECTURER_ASSESSMENTS',
  courseManagements: 'COURSE_MANAGEMENT_ASSESSMENTS',
  questions: 'QUESTIONS',
}
