import React from 'react'
import Link from '@mui/material/Link'
import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledDialogHeader = styled(Row)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(2),
  justifyContent: 'space-between',
  borderBottom: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  '& .MuiTypography-root': { width: 400 },
  [theme.breakpoints.down('sm')]: {
    '& > #dialog-title': { paddingRight: theme.spacing(1) },
    '& .MuiTypography-root': { width: 250 },
  },
}))

export const StyledContent = styled(Column)(({ theme }) => ({
  padding: theme.spacing(1),
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  gap: theme.spacing(1.5),
}))

export const StyledContentHeader = styled(Row)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  background: theme.palette?.blue?.blueLine,
  textAlign: 'center',
  justifyContent: 'center',
}))

export const StyledFooter = styled(Column)(({ theme }) => ({
  width: 500,
  left: 0,
  bottom: 0,
  position: 'absolute',
  background: theme.palette?.background?.paper,
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: { width: '100%' },
}))

export const StyledLangButton = styled((props) => (
  <Link {...props} component="button" variant="buttons" />
))(({ theme, active }) => ({
  color: active ? theme.palette.text?.primary : theme.palette.text?.gray,
}))
