import React, { useState, useEffect } from 'react'
import { StyledContainer } from './Styled'
import { stickNavbar } from './events'

const ScrollFixedTopContainer = ({ children, threshold = 250, sx }) => {
  const [isScrolled, setScroll] = useState(false)

  useEffect(() => {
    const handleScrolled = () => stickNavbar(setScroll, threshold)

    window.addEventListener('scroll', handleScrolled)
    return () => {
      window.removeEventListener('scroll', handleScrolled)
    }
  }, [])

  return (
    <StyledContainer isScrolled={isScrolled} sx={sx}>
      {children}
    </StyledContainer>
  )
}

export default ScrollFixedTopContainer
