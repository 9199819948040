import _ from 'lodash'
import { setStep } from 'src/redux/slices/eLearning/enroll'
import {
  postEnrollCourse,
  putBatchLearnerStatus,
} from 'src/services/eLearning/enroll'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { closeDialog, openDialog } from 'src/redux/slices/alertDialog'
import { getQueryParams } from 'src/utils/lib'
import { getEnrollState } from '../events'

export const handleClickBack = () => (dispatch) => {
  const activeStep = getEnrollState().activeStep
  dispatch(setStep(activeStep - 1))
}

export const handleClickNext = () => async (dispatch) => {
  const activeStep = getEnrollState().activeStep
  dispatch(setStep(activeStep + 1))
}

export const handleDisableBtn = (isVerification) => {
  const state = getEnrollState()
  const activeStep = state.activeStep
  const body = state.body
  const isVerifyInvalid = _.isNil(body.idCardImage) || _.isNil(body.faceImage)

  if (isVerification && activeStep === 0) {
    return isVerifyInvalid
  }

  if (activeStep === 0) return _.isNil(body.batch)
  if (activeStep === 1) return isVerifyInvalid
}

export const handleSubmitEnroll = (isVerification) => async (dispatch) => {
  const state = getEnrollState()

  dispatch(startLoading())
  let response
  if (isVerification) {
    const batchLearnerUuid = getQueryParams('learner')
    response = await dispatch(putBatchLearnerStatus(batchLearnerUuid))
  } else {
    const body = {
      eLearningCourse: state.data,
      eLearningCourseBatch: state.body.batch,
    }
    response = await dispatch(postEnrollCourse(body))
  }
  dispatch(stopLoading())

  if (_.isEmpty(response.error)) {
    dispatch(handleSuccess(isVerification))
  }
}

export const handleSuccess = (isVerification) => (dispatch) => {
  const state = getEnrollState()
  dispatch(
    openDialog({
      iconType: 'success',
      title: isVerification ? 'ยืนยันตัวตนสำเร็จ' : 'ลงทะเบียนเข้าอบรมสำเร็จ',
      buttonRight: {
        handleClick: () => {
          dispatch(closeDialog())
          window.location.href = `/e-learning/course/${state.data.uuid}`
        },
      },
    })
  )
}
