import React from 'react'
import CheckCircle from '@mui/icons-material/CheckCircle'
import QuizOutlined from '@mui/icons-material/QuizOutlined'
import RecordVoiceOverOutlined from '@mui/icons-material/RecordVoiceOverOutlined'
import Rule from '@mui/icons-material/Rule'
import SchoolOutlined from '@mui/icons-material/SchoolOutlined'
import TimerOutlined from '@mui/icons-material/TimerOutlined'
import Dot from 'src/components/Dot'

export const courseInclude = {
  attendance: {
    icon: <TimerOutlined fontSize="small" />,
    label: 'เช็คชื่อ',
  },
  preTest: {
    icon: <QuizOutlined fontSize="small" />,
    label: 'แบบทดสอบก่อนอบรม',
  },
  postTest: {
    icon: <Rule fontSize="small" />,
    label: 'แบบทดสอบหลังอบรม',
  },
  evaluation: {
    icon: <RecordVoiceOverOutlined fontSize="small" />,
    label: 'แบบประเมินการอบรม',
  },
  assessment: {
    icon: <RecordVoiceOverOutlined fontSize="small" />,
    label: 'แบบฝึกหัด',
  },
  eCert: {
    icon: <SchoolOutlined fontSize="small" />,
    label: 'ประกาศนียบัตร',
  },
}

export const classRegisterStatus = (availableSeats) => ({
  WAITING_RESULT: {
    text: 'สมัครแล้ว (รอผล)',
    icon: <CheckCircle id="progress" />,
  },
  REGISTERED: { text: 'สมัครแล้ว', icon: <CheckCircle color="success" /> },
  CAN_REGISTER: { text: 'สมัครได้แล้ววันนี้', icon: <Dot status="active" /> },
  CLOSED: {
    text: availableSeats == 0 ? 'สมัครได้แล้ววันนี้' : 'ปิดรับสมัครแล้ว',
    icon: <Dot status={availableSeats == 0 ? 'active' : 'inactive'} />,
  },
})

export const classTabLabel = [
  { label: 'รายละเอียด', value: 0 },
  { label: 'จุดประสงค์ของหลักสูตร', value: 1 },
  { label: 'โครงร่างของหลักสูตร', value: 2 },
  { label: 'เงื่อนไขการเข้าอบรม', value: 3 },
  { label: 'เพิ่มเติม', value: 4 },
]

export const courseStatusCheckbox = [
  { label: 'เปิดรับสมัครแล้ว', value: 'OPEN' },
  { label: 'ปิดรับสมัครแล้ว', value: 'CLOSED' },
]

export const myClassTabLabelOld = [
  { label: 'ทั้งหมด', value: 'ALL' },
  { label: 'ระหว่างการอบรม', value: 'BETWEEN_TRAINING' },
  { label: 'หลักสูตรที่ลงทะเบียน', value: 'BETWEEN_REGIS' },
  { label: 'หลักสูตรที่จบแล้ว', value: 'CLOSED' },
  { label: 'หลักสูตรที่จบแล้ว (ระบบเดิม)', value: 'CLOSED_OLD_SYSTEM' },
  { label: 'ไม่มีสิทธิ์เรียน / ยกเลิก', value: 'CANCELED' },
]

export const myClassTabLabel = [
  { label: 'หลักสูตรของฉัน', value: 'MY_CLASS' },
  { label: 'การสมัครสอบของฉัน', value: 'MY_SCHEDULE' },
]

export const myClassFilter = {
  BETWEEN_TRAINING: [
    { label: 'รอเข้าอบรม', value: 'WAITING_CLASS_TO_START' },
    { label: 'ระหว่างอบรม', value: 'CLASS_IN_PROGRESS' },
  ],
  BETWEEN_REGIS: [
    { label: 'รออัปโหลดเอกสาร', value: 'WAITING_DOCUMENT' },
    { label: 'รอตรวจสอบ', value: 'WAITING_CHECK' },
  ],
  CLOSED: [
    { label: 'ผ่าน', value: 'CLASS_CLOSED_PASS' },
    { label: 'ไม่ผ่าน', value: 'CLASS_CLOSED_NOT_PASS' },
  ],
  CANCELED: [
    { label: 'ไม่มีสิทธิ์เรียน', value: 'INELIGIBLE' },
    { label: 'ยกเลิกโดยคุณ', value: 'CANCEL' },
    { label: 'ยกเลิกโดยแอดมิน', value: 'CANCELED' },
    { label: 'หมดระยะเวลาอัปโหลด', value: 'TIME_OUT' },
  ],
}

export const myClassStatus = {
  WAITING_DOCUMENT: 'waiting',
  WAITING_CHECK: 'waiting',
  WAITING_ANNOUNCE: 'waiting',
  WAITING_CLASS_TO_START: 'inactive',
  CLASS_IN_PROGRESS: 'in_progress',
  CLASS_CLOSED_PASS: 'active',
  CLASS_CLOSED_NOT_PASS: 'cancel',
  TRAINING_CLOSED_PASS: 'active',
  TRAINING_CLOSED_NOT_PASS: 'cancel',
  INELIGIBLE: 'cancel',
  CANCEL: 'cancel',
  CANCELED: 'cancel',
  CANCEL_CLASS: 'cancel',
  TIME_OUT: 'cancel',
}

export const myClassELearningStatus = {
  INELIGIBLE: 'INELIGIBLE',
  ELIGIBLE_ANNOUNCE: 'ELIGIBLE_ANNOUNCE',
  WAITING_ANNOUNCE: 'WAITING_ANNOUNCE',
  WAITING_CHECK: 'WAITING_CHECK',
  REJECTED_DOCUMENT: 'REJECTED_DOCUMENT',
  CLASS_IN_PROGRESS: 'CLASS_IN_PROGRESS',
  CANCEL: 'CANCEL',
  CANCELED: 'CANCELED',
  CANCEL_CLASS: 'CANCEL_CLASS',
  TRAINING_CLOSED_PASS: 'TRAINING_CLOSED_PASS',
  TRAINING_CLOSED_NOT_PASS: 'TRAINING_CLOSED_NOT_PASS',
  WAITING_CLASS_TO_START: 'WAITING_CLASS_TO_START',
}

export const examinationField = [
  { label: 'สมาคม', value: 'ASSOCIATION' },
  { label: 'บริษัท', value: 'COMPANY' },
  { label: 'อื่นๆ', value: 'OTHER' },
]

export const examinationStatus = {
  WAITING_CHECK: {
    text: 'รอตรวจสอบสิทธิ์',
    isFilter: true,
  },
  ACCEPT_PERMISSION: {
    text: 'มีสิทธิ์สอบ',
    isFilter: true,
  },
  PERMISSION_DENIED: {
    text: 'ไม่มีสิทธิ์สอบ',
    isFilter: true,
  },
  END: {
    text: 'จบการสอบ',
    isFilter: true,
  },
  MISS: {
    text: 'ขาดสอบ',
    isFilter: true,
  },
  PASS: {
    text: 'จบการสอบ(ผ่าน)',
    isFilter: false,
  },
  NOT_PASS: {
    text: 'จบการสอบ(ไม่ผ่าน)',
    isFilter: false,
  },
  WAITING_ANNOUNCEMENT: {
    text: 'รอตรวจสอบสิทธิ์',
    isFilter: false,
  },
  ANNOUNCEMENT: {
    text: 'มีสิทธิ์สอบ',
    isFilter: false,
  },
  ABSENCE: {
    text: 'ขาดสอบ',
    isFilter: false,
  },
  CANCEL: {
    text: 'ยกเลิก',
    subText: 'ยกเลิกโดยคุณ',
    isFilter: true,
  },
  CANCELED: {
    text: 'ยกเลิกโดยแอดมิน',
    isFilter: false,
  },
  CLASS_CANCELED: {
    text: 'ยกเลิกคลาส',
    isFilter: false,
  },
  CANCEL_BY_SELF: {
    text: 'ยกเลิกโดยคุณ',
    isFilter: false,
  },
  CANCEL_BY_ADMIN: {
    text: 'ยกเลิกโดยแอดมิน',
    isFilter: false,
  },
  CANCEL_EXAMINATION: {
    text: 'ยกเลิกตารางสอบ',
    isFilter: false,
  },
  CANCEL_BY_MANAGER: {
    text: 'ยกเลิกโดยผู้จัดการ',
    isFilter: false,
  },
}

export const myClassType = [
  { label: 'E-Learning', value: 'E_LEARNING' },
  { label: 'Classroom', value: 'CLASSROOM' },
]

export const myClassCourse = [
  { label: 'ระหว่างอบรม', value: 'CLASS_IN_PROGRESS' },
  { label: 'หลักสูตรที่ลงทะเบียนแล้ว', value: 'REGISTERED' },
  { label: 'หลักสูตรที่จบแล้ว', value: 'END' },
  { label: 'ไม่มีสิทธิ์เรียน / ยกเลิก', value: 'INELIGIBLE' },
]

export const myClassStatusFilter = [
  { label: 'รอยืนยันตัวตน', value: 'WAITING_CONFIRM_IDENTITY' },
  { label: 'รอเข้าอบรม', value: 'WAITING_CLASS_TO_START' },
  { label: 'รอตรวจสอบ', value: 'WAITING_CHECK' },
  { label: 'รออัปโหลดเอกสาร', value: 'WAITING_DOCUMENT' },
  { label: 'รอประกาศสิทธิ์', value: 'WAITING_ANNOUNCE' },
  { label: 'ระหว่างอบรม', value: 'CLASS_IN_PROGRESS' },
  { label: 'ผ่านการอบรม', value: 'CLASS_CLOSED_PASS' },
  { label: 'ไม่ผ่านการอบรม', value: 'CLASS_CLOSED_NOT_PASS' },
  { label: 'ไม่มีสิทธิ์เรียน / ยกเลิก', value: 'INELIGIBLE' },
]

export const FilterList = {
  MY_SCHEDULE: [
    {
      field: 'schedule',
      label: 'สนามสอบ',
      list: examinationField,
      type: 'checkbox',
    },
    {
      field: 'search',
      label: 'สถานที่',
      type: 'text',
    },
    {
      field: 'status',
      label: 'สถานะ',
      list: examinationStatus,
      type: 'checkbox',
    },
  ],
  MY_CLASS: [
    {
      field: 'classType',
      label: 'กลุ่มหลักสูตร',
      list: myClassType,
      type: 'checkbox',
    },
    // {
    //   field: 'classCourse',
    //   label: 'หลักสูตรของฉัน',
    //   list: myClassCourse,
    //   type: 'checkbox',
    // },
    {
      field: 'classStatus',
      label: 'สถานะ',
      list: myClassStatusFilter,
      type: 'checkbox',
    },
    {
      field: 'startDate',
      label: 'วันที่เริ่มอบรม',
      type: 'dateRange',
    },
    {
      field: 'className',
      label: 'ชื่อหลักสูตร',
      type: 'text',
    },
  ],
}

export const COURSE_TYPE = {
  VIRTUAL_CLASSROOM: 'VIRTUAL CLASSROOM',
  CLASSROOM: 'CLASSROOM',
  E_LEARNING: 'E-LEARNING'}