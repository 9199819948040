import React from 'react'
import { StyledTemplate } from './Styled'

const Template = ({ children, noMinHeight, maxWidth = 1170, sx }) => {
  return (
    <StyledTemplate sx={sx} maxWidth={maxWidth} noMinHeight={noMinHeight}>
      {children}
    </StyledTemplate>
  )
}
export default Template
