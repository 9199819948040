import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'
import { useFormikContext } from 'formik'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { setDisableButton } from '../../../redux/slices/profile'
import { prefixTHtoEN } from '../../../redux/slices/profile/model'
import TextInput from '../../Input/TextInput'
import Select from '../../Input/Select'
import { isMediaWidthMoreThan } from '../../../utils/lib'
import { optionPrefixEn, optionPrefixTh, userType } from './model'
import { StyledFormRow, StyledFormWrapper } from './Styled'
import AlertDialog from '../AlertDialog'

const UpdateProfileForm = () => {
  const [user] = useLocalStorage('user')
  const isAgent = user.userType === userType.AGENT
  const isCS =
    _.get(user, 'distributionChannel.abbreviation', '') === userType.CS
  const isStaff = user.userType === userType.STAFF
  const isProspect = user.userType === userType.PROSPECT
  const { values, errors, handleChange, setFieldValue } = useFormikContext()
  const dispatch = useDispatch()
  const btnDisabled = useSelector(
    (state) => state.profile.btnDisabled,
    shallowEqual
  )
  const isNotSm = isMediaWidthMoreThan('sm')

  useEffect(() => {
    dispatch(setDisableButton({ values, isProspect, isCS }))
  }, [values])

  return (
    <StyledFormWrapper>
      <img src="/icon/axa_help.svg" />
      <Typography variant="h4" color="primary">
        โปรดอัปเดตข้อมูล
      </Typography>
      <Select
        id="prefixTH"
        name="prefixTH"
        labelText="คำนำหน้าชื่อ"
        type="basic"
        required={!isCS}
        disabled={isStaff}
        options={optionPrefixTh}
        showTextError={false}
        value={_.get(values, 'prefixTH', '')}
        handleChange={(e) => {
          setFieldValue('prefixTH', e.target.value)
          setFieldValue('prefix', prefixTHtoEN[e.target.value])
        }}
      />
      <StyledFormRow>
        <TextInput
          type="basic"
          id="firstNameTH"
          labelText="ชื่อ"
          isShowTextError={false}
          required
          disabled
          value={_.get(values, 'firstNameTH', '')}
        />
        <TextInput
          type="basic"
          id="lastNameTH"
          labelText="นามสกุล"
          isShowTextError={false}
          required={!isCS}
          disabled
          value={_.get(values, 'lastNameTH', '')}
        />
      </StyledFormRow>
      {!isAgent && (
        <>
          <Divider sx={{ width: '100%', borderStyle: 'dashed' }} />
          <Select
            id="prefixEN"
            name="prefixEN"
            labelText="Title Name"
            type="basic"
            required
            disabled
            options={optionPrefixEn}
            showTextError={false}
            value={_.get(values, 'prefix', '')}
          />
          <StyledFormRow id="name">
            <TextInput
              type="basic"
              name="firstName"
              id="firstName"
              labelText="First Name"
              isShowTextError={!isStaff}
              required
              disabled={isStaff}
              textError={_.get(errors, 'firstName', '')}
              value={_.get(values, 'firstName', '')}
              onChange={handleChange}
            />
            <TextInput
              type="basic"
              name="lastName"
              id="lastName"
              labelText="Last Name"
              isShowTextError={!isStaff}
              required
              disabled={isStaff}
              textError={_.get(errors, 'lastName', '')}
              value={_.get(values, 'lastName', '')}
              onChange={handleChange}
            />
          </StyledFormRow>
        </>
      )}
      <Typography
        id="remark"
        variant="body2"
        color="primary"
        sx={{ textAlign: 'center' }}
      >
        หากต้องการเปลี่ยนแปลงข้อมูล
        {!isNotSm && <br />}
        กรุณาติดต่อต้นสังกัด
      </Typography>
      <Button
        size="l"
        variant="contained"
        type="submit"
        sx={{ width: '100%', my: 3 }}
        disabled={btnDisabled}
      >
        ยืนยัน
      </Button>
      <AlertDialog />
    </StyledFormWrapper>
  )
}

export default UpdateProfileForm
