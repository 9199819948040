import _ from 'lodash'
import {
  setCloseDialog,
  setLoadingDialog,
  setOpenDialog,
  setOpenErrorDialog,
  setOpenSuccessDialog,
  setStopLoadingDialog,
} from '../../redux/slices/profile'
import { postUpdateProfile } from '../../services/profile'

export const handleSubmit = (props) => (dispatch) => {
  dispatch(
    setOpenDialog({
      title: 'บันทึกข้อมูล',
      message: ['คุณต้องการบันทึกข้อมูลนี้?'],
      buttonLeft: {
        label: 'ยกเลิก',
      },
      buttonRight: {
        handleClick: () => dispatch(handleConfirm(props)),
      },
    })
  )
}

export const handleConfirm = (props) => async (dispatch) => {
  const { values, user, setUser, onClose } = props
  dispatch(setLoadingDialog())
  const body = { ...values, uuid: user.uuid }
  const res = await dispatch(postUpdateProfile(body))
  const payload = _.get(res, 'payload', null)
  dispatch(setStopLoadingDialog())
  if (payload.data) {
    dispatch(
      setOpenSuccessDialog({
        title: 'อัปเดตข้อมูลสำเร็จ',
        message: '',
        buttonLabel: 'เรียนต่อ',
        handleClick: () => {
          const updatedData = _.get(payload.data, 'updatedData', user)
          setUser(updatedData)
          onClose()
          dispatch(setCloseDialog())
        },
      })
    )
  } else {
    dispatch(
      setOpenErrorDialog({
        title: 'อัปเดตข้อมูลไม่สำเร็จ',
        message: ['โปรดลองใหม่อีกครั้ง'],
      })
    )
  }
}
