import _ from 'lodash'
import { answerSection, assessmentField } from 'src/constants/eEvaluation'
import { answerEEvaluationObj } from './model'

export const isHaveType = (list) => !_.isUndefined(list) && list.length > 0

export const handleInitialAssessment = (state, { payload }) => {
  const {
    pointerCourse,
    pointerLecturer,
    pointerCourseManagement,
    pointerQuestion,
  } = payload
  const assessmentList = []

  // section 1
  if (isHaveType(pointerCourse)) {
    pointerCourse.forEach((item) => {
      const list = { ...item, section: 1 }
      assessmentList.push(list)
    })
  }

  // section 2
  if (isHaveType(pointerLecturer)) {
    let section = 2
    if (!isHaveType(pointerCourse)) section--

    pointerLecturer.forEach((item) => {
      const list = { ...item, section }
      assessmentList.push(list)
    })
  }

  // section 3
  if (isHaveType(pointerCourseManagement)) {
    let section = 3
    if (!isHaveType(pointerCourse)) section--
    if (!isHaveType(pointerLecturer)) section--

    pointerCourseManagement.forEach((item) => {
      const list = { ...item, section }
      assessmentList.push(list)
    })
  }

  // section 4
  if (isHaveType(pointerQuestion)) {
    let section = 4
    if (!isHaveType(pointerCourse)) section--
    if (!isHaveType(pointerLecturer)) section--
    if (!isHaveType(pointerCourseManagement)) section--

    pointerQuestion.forEach((item) => {
      const list = { ...item, section }
      assessmentList.push(list)
    })
  }

  _.set(state, 'constantList', assessmentList)
  _.set(state, 'assessmentList', assessmentList)
  _.set(state, 'totalAssessments', assessmentList.length)
}

export const getAssessmentPointer = (assessments, keyField) => {
  const list = _.defaultTo(assessments, [])
  return list.map((item) => ({
    uuid: item.uuid,
    section: item.section,
    key: item.key,
    keyField,
  }))
}

export const getQuestionPointer = (questions) => {
  const list = _.defaultTo(questions, [])
  return list.map((item) => {
    let obj = {
      uuid: item.uuid,
      section: item.section,
      type: item.type,
      key: item.key,
      keyField: assessmentField.question,
    }

    if (item.type == 'MULTIPLE') {
      obj = {
        ...obj,
        setTypeOfAnswers: _.get(item, 'setTypeOfAnswers', false),
        typeOfAnswers: _.get(item, 'typeOfAnswers', 'EQUAL'),
        numberOfAnswers: _.get(item, 'numberOfAnswers', 0),
      }
    }

    return obj
  })
}

export const getBetweenRedirectPage = (redirectPage, item) => {
  const { from, to } = redirectPage
  if (to > from) return item.key > from && item.key < to
  else return item.key < from && item.key > to
}

export const resetNextQuestionAnswer = (state, from) => {
  return state.answerEEvaluation.map((item) => {
    if (item.key > from) {
      return {
        ...item,
        isAnswered: false,
        answerUuid: '',
        freetextAnswer: '',
        otherAnswer: '',
        suggestion: '',
      }
    }
    return item
  })
}

export const handleAnswerType = (state, answerState, payload) => {
  const { type, value } = payload
  const answerType = _.get(payload, 'name', 'SINGLE')
  switch (type) {
    case 'SINGLE':
    case 'RATING': {
      _.set(answerState, 'answerUuid', value)
      _.set(answerState, 'otherAnswer', '')
      _.set(answerState, 'isAnswered', answerType != 'OTHER')
      break
    }
    case 'FREE_TEXT': {
      _.set(answerState, 'freetextAnswer', value)
      _.set(answerState, 'isAnswered', !_.isEqual(value, ''))
      break
    }
    case 'OTHER': {
      _.set(answerState, 'otherAnswer', value)
      _.set(answerState, 'isAnswered', !_.isEqual(value, ''))
      break
    }
    case 'MULTIPLE': {
      const questionUuid = _.get(payload, 'uuid', '')
      const key = _.get(payload, 'key', undefined)
      let newAnswer = state.answerEEvaluation.filter(
        (item) =>
          item.questionUuid != questionUuid ||
          (item.questionUuid == questionUuid && item.answerUuid != '')
      )
      if (value.checked) {
        newAnswer.push({
          ...answerEEvaluationObj,
          answerSection: answerSection.questions,
          answerUuid: value.value,
          isAnswered: answerType != 'OTHER',
          questionUuid,
          key,
        })
      } else {
        newAnswer = newAnswer.filter((item) => item.answerUuid != value.value)
      }

      _.set(state, 'answerEEvaluation', newAnswer)
      break
    }
    default:
      break
  }
}

export const handleAssignSubAnswer = (answerEEvaluation, subQuestions) => {
  if (_.isNil(subQuestions)) return

  if (subQuestions.type == 'RATING') {
    subQuestions.questions.forEach((item) => {
      const answerObj = {
        ...answerEEvaluationObj,
        questionUuid: item.uuid,
        answerSection: 'QUESTIONS',
        mainUuid: subQuestions.uuid,
      }
      answerEEvaluation.push(answerObj)
    })
  } else {
    let answerObj = {
      ...answerEEvaluationObj,
      questionUuid: subQuestions.uuid,
      answerSection: 'QUESTIONS',
    }

    if (subQuestions.type == 'MULTIPLE') {
      answerObj = {
        ...answerObj,
        setTypeOfAnswers: _.get(subQuestions, 'setTypeOfAnswers', false),
        typeOfAnswers: _.get(subQuestions, 'typeOfAnswers', 'EQUAL'),
        numberOfAnswers: _.get(subQuestions, 'numberOfAnswers', 0),
      }
    }
    answerEEvaluation.push(answerObj)
  }

  return answerEEvaluation
}

export const handleDuplicateSubAnswer = (answerEEvaluation, assessmentList) => {
  return answerEEvaluation.filter((item) => {
    if (item.mainUuid) {
      return item.mainUuid != assessmentList.uuid
    }
    return item.questionUuid != assessmentList.uuid
  })
}

export const isDuplicateAnswer = (state, payload) => {
  const questionUuid = _.get(payload, 'questionUuid', '')
  const staffUuid = _.get(payload, 'staffUuid', '')
  return state.answerEEvaluation.some(
    (item) => item.questionUuid == questionUuid && item.staffUuid == staffUuid
  )
}

export const handleAssignSub = (state, { payload }) => {
  const { keyQuestion, value } = payload
  const assessmentList = _.get(state, 'assessmentList', [])
  const answerEEvaluation = _.get(state, 'answerEEvaluation', [])
  const question = state.questions.find((item) => item.key == keyQuestion)
  const index = assessmentList.findIndex(
    (e) => e.uuid == _.get(question, 'uuid', '')
  )
  const choice = _.get(question, 'choices', []).find(
    (item) => item.uuid == value
  )
  const nextIndex = index + 1
  const nextList = assessmentList[nextIndex]
  const haveSubNext = !!nextList && !!_.get(nextList, 'keySub', undefined)
  const subQuestions = _.get(choice, 'subQuestions', null)

  if (subQuestions) {
    const subQuestionKey = {
      type: subQuestions.type,
      key: subQuestions.sequence,
      keySub: subQuestions.subSequence,
      keyField: assessmentField.question,
      uuid: subQuestions.uuid,
    }
    if (haveSubNext) {
      const newAnswerEEvaluation = handleDuplicateSubAnswer(
        answerEEvaluation,
        assessmentList[nextIndex]
      )
      const answerList = handleAssignSubAnswer(
        newAnswerEEvaluation,
        subQuestions
      )
      _.set(state, 'answerEEvaluation', answerList)
      assessmentList[nextIndex] = subQuestionKey
    } else {
      const answerList = handleAssignSubAnswer(answerEEvaluation, subQuestions)
      _.set(state, 'answerEEvaluation', answerList)
      assessmentList.splice(nextIndex, 0, subQuestionKey)
    }
  } else if (haveSubNext) {
    const newAnswerEEvaluation = handleDuplicateSubAnswer(
      answerEEvaluation,
      assessmentList[nextIndex]
    )
    _.set(state, 'answerEEvaluation', newAnswerEEvaluation)
    assessmentList.splice(nextIndex, 1)
  }

  _.set(state, 'totalAssessments', assessmentList.length)
}
