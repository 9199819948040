import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Template from 'src/components/Template'
import { Column } from 'src/components/Layout/Styled'
import CustomTabs from 'src/components/Tabs'
import ContentNoClass from 'src/components/ContentNoClass'
import { myClassTabLabelOld } from 'src/constants/course'
import { setTab } from 'src/redux/slices/class/list'
import FilterHeader from './FilterHeader'
import ClassCard from './ClassCard'
import { StyledContainer, StyledLoading } from './Styled'
import { fetchMyClassList, handleLoadMore, handleSeeMore } from './event'

const MyClass = () => {
  const dispatch = useDispatch()
  const { isLoadMore, classes, tab, filterBody } = useSelector(
    (state) => state.classList,
    shallowEqual
  )
  const triggerProps = [
    filterBody.courseName,
    filterBody.startDate,
    filterBody.finishDate,
    filterBody.classStatus,
  ]

  useEffect(() => {
    window.scrollTo(0, 0)

    window.addEventListener('scroll', () => dispatch(handleLoadMore()))
    return () => {
      window.removeEventListener('scroll', () => dispatch(handleLoadMore()))
    }
  }, [])

  useEffect(() => {
    dispatch(fetchMyClassList(filterBody, tab))
  }, [...triggerProps])

  useEffect(() => {
    dispatch(handleSeeMore(filterBody, tab))
  }, [filterBody.page])

  return (
    <Template>
      <StyledContainer>
        <Typography variant="h4">คลาสของฉัน</Typography>

        <Column>
          <CustomTabs
            listTab={myClassTabLabelOld}
            value={tab}
            onChange={(value) => dispatch(setTab(value))}
          />
          <Divider />
          <Column gap={3} pt={3}>
            <FilterHeader />
            {classes.length > 0 ? (
              classes.map((item, index) => (
                <ClassCard key={index} data={item} />
              ))
            ) : (
              <ContentNoClass />
            )}
            {isLoadMore && <StyledLoading />}
          </Column>
        </Column>
      </StyledContainer>
    </Template>
  )
}

export default MyClass
