import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { Column, Row } from 'src/components/Layout/Styled'
import CameraDialogComp from 'src/components/CameraDialog'
import { handleCapture, handleOpenCamera } from '../../../events'

const CameraDialog = () => {
  const { isOpen, idCardImage } = useSelector(
    (state) => state.eLearningEnroll.camera,
    shallowEqual
  )
  const field = 'idCardImage'
  const isNotSm = isMediaWidthMoreThan('sm')
  const dispatch = useDispatch()
  return (
    <CameraDialogComp
      field={field}
      isOpen={isOpen}
      image={idCardImage}
      onCapture={(camRef) => dispatch(handleCapture(camRef, field))}
      onOpenCamera={() => dispatch(handleOpenCamera(field))}
    >
      <Column gap={0.5} alignItems="center">
        <Typography variant="h5">ถ่ายรูปบัตรประชาชน</Typography>
        <Row gap={isNotSm ? 0.5 : 0} justifyContent="center">
          <Typography variant={isNotSm ? 'body1' : 'body2'} color="text.gray">
            กรุณากดปุ่ม “
          </Typography>
          <img src="/icon/e-learning/ic_camera_sm.svg" width={20} />
          <Typography variant={isNotSm ? 'body1' : 'body2'} color="text.gray">
            ” ด้านล่าง เพื่อถ่ายบัตรประชาชนของคุณ
          </Typography>
        </Row>
        <Typography variant="body2b" color="primary" textAlign="center">
          โดยให้เห็นข้อมูลรายละเอียดที่ชัดเจน{!isNotSm && <br />}
          บัตรไม่หมดอายุและถ่ายไม่ติดใบหน้าผู้อื่น
        </Typography>
      </Column>
    </CameraDialogComp>
  )
}

export default CameraDialog
