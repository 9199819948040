import React, { useState, Fragment } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import { StyledLink } from '../Styled'
import { StyledMenu } from './Styled'
import { handleClick, handleClose } from './events'

const TapMenuDropdown = ({ menu }) => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)
  const subMenu = _.get(menu, 'subMenu', [])
  const isActive = subMenu.some((sub) => sub.href === window.location.pathname)

  return (
    <>
      <StyledLink
        data-testid="btn-menu"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => handleClick(event, setAnchorEl)}
        active={isActive || isOpen ? 1 : 0}
        underline="none"
      >
        <Typography
          variant={isActive ? 'body1b' : 'body1'}
          sx={{ textTransform: 'capitalize' }}
        >
          {menu.label}
        </Typography>
      </StyledLink>
      <StyledMenu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => handleClose(setAnchorEl)}
      >
        {subMenu.map((sub, index) => (
          <MenuItem
            key={index}
            data-testid={`sub-${index}`}
            onClick={() => history.push(sub.href)}
          >
            {sub.icon ?? sub.icon}
            {sub.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}

export default TapMenuDropdown
