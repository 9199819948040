import React from 'react'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import reactStringReplace from 'react-string-replace'
import _ from 'lodash'
import { Box, Radio, RadioGroup } from '@mui/material'
import {
  StyledDialogContent,
  StyledDragDropContainer,
} from '../DragAndDrop/Styled'
import { StyledChoice } from '../Styled'
import { handleMatchFillChange } from '../events'
import { getPlainTextWithBlank } from './events'
import { blankReg } from './model'

const FillChoice = ({ uuid, question, resultProps }) => {
  const dispatch = useDispatch()
  const { answerETesting, correctAnswer } = useSelector(
    (state) => ({
      answerETesting: state.eTesting.answerETesting.find(
        (item) => item.questionUuid == uuid
      ),
      correctAnswer: state.eTesting.correctAnswers.find(
        (item) => item.questionUuid == uuid
      ),
    }),
    shallowEqual
  )
  const dialog = getPlainTextWithBlank(_.get(question, 'subTitle', ''))
  const choices = _.get(question, 'answers', [])
  const fillAns = _.get(answerETesting, 'fillAns', [])

  return (
    <StyledDragDropContainer>
      <StyledDialogContent>
        <Typography>
          {reactStringReplace(dialog, blankReg, (match) => (
            <Typography
              key={match}
              variant="span"
              color="text.lightGray"
            >{`_____${match}_____`}</Typography>
          ))}
        </Typography>
      </StyledDialogContent>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {choices.map((choice, index) => {
          const selectedAns = fillAns.find(
            (fill) => fill.target === choice.target
          )
          return (
            <Box
              key={index}
              sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}
            >
              <Typography variant="body1b" color="text.secondary">
                ช่องว่าง {_.get(choice, 'target', '')}
              </Typography>
              <RadioGroup
                sx={{ ml: 3 }}
                value={_.get(selectedAns, 'answerUuid', '')}
                onChange={(e) => {
                  const props = { e, choice, uuid }
                  dispatch(handleMatchFillChange(props, fillAns))
                }}
              >
                {_.get(choice, 'subAnswers', []).map((ans, key) => (
                  <StyledChoice
                    key={key}
                    control={<Radio />}
                    checked={ans?.uuid === _.get(selectedAns, 'answerUuid', '')}
                    label={_.get(ans, 'title', '')}
                    value={_.get(ans, 'uuid', '')}
                    resultProps={{ ...resultProps, choice: ans, correctAnswer }}
                  />
                ))}
              </RadioGroup>
            </Box>
          )
        })}
      </Box>
    </StyledDragDropContainer>
  )
}
export default FillChoice
