import React from 'react'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  '& .MuiBox-root#header': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0',
    margin: `0 ${theme.spacing(2)}`,
  },
  '& .MuiDivider-root#divider': {
    marginBottom: theme.spacing(2),
    borderColor: theme.palette?.other?.divider,
  },
  '& .MuiBox-root#body': {
    width: 320,
    padding: `0 ${theme.spacing(2)}`,
  },
  '& ::-webkit-scrollbar': { display: 'none' },
}))
