import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import Background from 'src/components/Preview/Background'
import Content from 'src/components/Preview/Content'
import { path } from 'src/constants/path'
import { getQueryParams } from 'src/utils/lib'
import {
  StyledNoPreviewImage,
  StyledPreviewContainer,
  StyledPreviewContent,
  StyledPreviewImage,
} from 'src/components/Preview/Styled'
import { fetchETesting } from './events'

const ETesting = ({ isModule }) => {
  const { codeId, version, id: uuid } = useParams()
  const trainingPlan = getQueryParams('class')
  const payload = { uuid: uuid, trainingPlan }
  const dispatch = useDispatch()
  const { eTesting, isLoading } = useSelector(
    (state) => ({
      isLoading: state.loading.isLoading,
      eTesting: state.eTesting,
    }),
    shallowEqual
  )
  const isDuplicate = _.get(eTesting, 'isDuplicateAnswer', false)
  const isOpen = _.get(eTesting, 'isETestingOpen', false)
  const coverImage = _.get(eTesting, 'coverImage', undefined)
  const coverText = _.get(eTesting, 'coverText', undefined)
  const pathStandalone = `${path.eTesting}/on-test/${codeId}/${version}/${uuid}`
  const pathOnTesting = `${path.eTesting}/on-test/${uuid}?class=${trainingPlan}`

  useEffect(() => {
    dispatch(fetchETesting({ payload, isModule }))
  }, [])

  return (
    <Background withFooter>
      {eTesting && !isLoading && (
        <StyledPreviewContainer>
          <StyledPreviewContent>
            <Box sx={{ position: 'relative' }}>
              {coverText && (
                <Typography variant="coverText" color="primary">
                  {coverText}
                </Typography>
              )}
              {coverImage && (
                <StyledPreviewImage
                  src={`${window.__env__.REACT_APP_FILE_URL}${coverImage}`}
                />
              )}
              {coverText && !coverImage && <StyledNoPreviewImage />}
            </Box>
            <Content
              information={eTesting}
              url={trainingPlan ? pathOnTesting : pathStandalone}
              buttonText="เริ่มทำแบบทดสอบ"
              isModule={isModule}
              condition={{
                isDuplicate,
                isOpen,
                conditionText: 'ไม่สามารถทำแบบทดสอบได้ กรุณาติดต่อแอดมิน',
              }}
            />
          </StyledPreviewContent>
        </StyledPreviewContainer>
      )}
    </Background>
  )
}

export default ETesting
