import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  postCourseBatchQuestion,
  putCourseLogLearnerPerLesson,
} from 'src/services/eLearning/learning'
import { getCheckBatchFailScanCount } from 'src/services/eLearning/detail'
import { initialState } from './model'
import {
  handleInitialCourse,
  handleInitialProgress,
  handleRelationObj,
  handleSetLessonState,
  moduleCompletedStatus,
} from './events'

const eLearningLearning = createSlice({
  name: 'eLearningLearning',
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLessonLoading: (state) => {
      state.isLessonLoading = true
    },
    stopLessonLoading: (state) => {
      state.isLessonLoading = false
    },
    startVideoLoading: (state) => {
      state.isVideoLoading = true
    },
    stopVideoLoading: (state) => {
      state.isVideoLoading = false
    },
    setExpand: (state, { payload }) => {
      state.isExpanded = payload
    },
    setInitialPreview: (state, { payload }) => {
      state.progressPercent = handleInitialProgress(payload)
      const eLearningCourseLesson = handleInitialCourse(payload)
      state.questions = payload.questions
      delete payload.questions
      delete payload.eLearningCourseLesson
      state.course = payload
      state.verifiedTimes = payload.verifiedTimes
      const lessons = [...eLearningCourseLesson]
      state.lessons = lessons

      eLearningCourseLesson.forEach((lesson) => {
        const module = lesson.modules.find((module) => module.isLatestLearn)
        if (!_.isEmpty(module)) state.selectedLesson = module
      })

      const batchLearner = _.get(payload, 'eLearningCourseBatchLearner', {})
      state.eLearningCourseBatchLearner = handleRelationObj(batchLearner)
      state.eLearningCourseBatch = handleRelationObj(
        _.get(batchLearner, 'eLearningCourseBatch', {})
      )
      state.eLearningIdCardEnroll = _.get(payload, 'eLearningIdCardEnroll', {})

      const isSkipAllowed = _.get(payload.setting, 'isSkipAllowed', false)
      state.isSkipAllowed = isSkipAllowed
      state.isAutoNextLesson = !isSkipAllowed

      const learnerResult = _.get(batchLearner, 'learnerResult', '')
      state.isCourseCompleted = moduleCompletedStatus.includes(learnerResult)

      // time
      if (payload.timeRemain) {
        state.time.timeRemain = payload.timeRemain
        state.verifyDialog.verifyRound = payload.verifyRound
      }
    },
    setSelectedLesson: (state, { payload }) => {
      state.isExpanded = false
      const module = { ...payload, disabled: false }
      handleSetLessonState(state, module)
    },
    setSelectedFile: (state, { payload }) => {
      state.isExpanded = false
      state.selectedLesson.selectedFile = payload
      _.set(state.selectedLesson.fileList, `[${payload}].process`, 100)
      _.set(state.selectedLesson.fileList, `[${payload}].isFinished`, true)
    },
    setSetting: (state, { payload }) => {
      const { key, value } = payload
      state.setting[key] = value
    },
    setVideoState: (state, { payload }) => {
      const module = {
        ...state.selectedLesson,
        videoState: {
          ..._.get(state.selectedLesson, 'videoState', {}),
          ...payload,
        },
      }
      handleSetLessonState(state, module)
    },
    setLessonData: (state, { payload }) => {
      const { key, value } = payload
      const module = { ...state.selectedLesson, [key]: value }
      handleSetLessonState(state, module)
    },
    setModuleProgress: (state, { payload }) => {
      const oldProgress = _.get(state.selectedLesson, 'progress', 0)
      const module = {
        ...state.selectedLesson,
        progress: oldProgress === 100 ? 100 : payload,
      }
      handleSetLessonState(state, module)
    },
    resetModuleProgress: (state) => {
      const module = {
        ...state.selectedLesson,
        progress: 0,
      }
      handleSetLessonState(state, module)
    },
    setStartModule: (state) => {
      const module = {
        ...state.selectedLesson,
        isInProgress: true,
        progress: 0,
      }
      handleSetLessonState(state, module)
    },
    setSubmitModule: (state, { payload }) => {
      const module = { ...state.selectedLesson, isSubmitted: payload }
      handleSetLessonState(state, module)
    },
    reStartETestingModule: (state) => {
      const testRound = _.get(state.selectedLesson, 'testRound', 1)
      const module = {
        ...state.selectedLesson,
        isInProgress: false,
        isSubmitted: false,
        progress: 0,
        testRound: testRound + 1,
      }
      handleSetLessonState(state, module)
    },
    setEnableModule: (state) => {
      const lessonNo = _.get(state.selectedLesson, 'lessonNo', 1)
      const moduleNo = _.get(state.selectedLesson, 'no', 1)
      let lessonIndex = lessonNo - 1
      let moduleIndex = moduleNo - 1

      const nowModuleLesson = _.get(
        state.lessons,
        `[${lessonIndex}].modules`,
        []
      )
      const isLastLesson = state.lessons.length === lessonNo
      const isLastModule = nowModuleLesson.length === moduleNo

      if (isLastLesson && isLastModule) return

      if (isLastModule) {
        lessonIndex++
        moduleIndex = 0
      } else moduleIndex++

      const disableKey = `[${lessonIndex}].modules[${moduleIndex}].disabled`
      _.set(state.lessons, disableKey, false)
    },
    setCourseCompleted: (state) => {
      state.isCourseCompleted = true
      state.progressPercent = 100
    },
    setFullScreen: (state, { payload }) => {
      state.isFullScreen = _.isNil(payload) ? !state.isFullScreen : payload
    },
    setValidate: (state) => {
      state.isValidate = true
    },
    setChangeCamera: (state, { payload }) => {
      const { key, value } = payload
      state.camera[key] = value
    },
    openDialog: (state) => {
      state.verifyDialog.isDialogOpen = true
    },
    closeDialog: (state) => {
      state.verifyDialog.isDialogOpen = false
    },
    openClickDialog: (state) => {
      state.clickDialog.isDialogOpen = true
    },
    closeClickDialog: (state) => {
      state.clickDialog.isDialogOpen = false
    },
    setRandomTime: (state, { payload }) => {
      state.verifyDialog.randomTime = payload
    },
    setRound: (state, { payload }) => {
      state.verifyDialog.round = payload
    },
    setClickRound: (state, { payload }) => {
      state.clickDialog.round = payload
    },
    setUpdateVerifyTimes: (state) => {
      state.verifiedTimes = state.verifiedTimes + 1
    },
    setTime: (state, { payload }) => {
      state.time.mins = _.get(payload, 'mins', 0)
      state.time.secs = _.get(payload, 'secs', 0)
    },
    resetTime: (state, { payload }) => {
      const timeField = payload?.isClick ? 'click' : 'face'
      const timeRemain = _.get(state.time, `timeRemain[${timeField}]`, 0)
      const minutes = Math.floor(timeRemain / 60)
      state.time.mins = minutes
      state.time.secs = timeRemain - minutes * 60
    },
    setRandomDateTime: (state, { payload }) => {
      state.verifyDialog.randomDateTime = payload
    },
    setClickRandomDateTime: (state, { payload }) => {
      state.clickDialog.randomDateTime = payload
    },
    setProgressPercent: (state, { payload }) => {
      state.progressPercent = payload
    },
    setFailTime: (state, { payload }) => {
      state.failTime.mins = _.get(payload, 'mins', 0)
      state.failTime.secs = _.get(payload, 'secs', 0)
    },
    setFailMaxTimes: (state, { payload }) => {
      state.failMaxTimes = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(putCourseLogLearnerPerLesson.fulfilled, (state, { payload }) => {
        const module = {
          ...state.selectedLesson,
          result: _.get(payload.data, 'data.result', ''),
          startTime: _.get(payload.data, 'data.startTime', undefined),
          endTime: _.get(payload.data, 'data.endTime', undefined),
          scormData: _.get(payload.data, 'data.scormData', undefined),
          playedPercent: _.get(payload.data, 'data.playedPercent', undefined),
        }
        handleSetLessonState(state, module)
      })
      .addCase(postCourseBatchQuestion.fulfilled, (state, { payload }) => {
        state.questions.push(payload.data)
      })
      .addCase(getCheckBatchFailScanCount.fulfilled, (state, { payload }) => {
        state.failLogUuid = _.get(payload.data, 'uuid', null)
      })
      .addCase(getCheckBatchFailScanCount.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startLessonLoading,
  stopLessonLoading,
  startVideoLoading,
  stopVideoLoading,
  setExpand,
  setInitialPreview,
  setSelectedLesson,
  setSelectedFile,
  setSetting,
  setVideoState,
  setLessonData,
  setModuleProgress,
  resetModuleProgress,
  setStartModule,
  setSubmitModule,
  reStartETestingModule,
  setEnableModule,
  setCourseCompleted,
  setFullScreen,
  setValidate,
  setChangeCamera,
  openDialog,
  closeDialog,
  openClickDialog,
  closeClickDialog,
  setRandomTime,
  setRound,
  setClickRound,
  setUpdateVerifyTimes,
  setTime,
  resetTime,
  setRandomDateTime,
  setClickRandomDateTime,
  setProgressPercent,
  setFailTime,
  setFailMaxTimes,
} = eLearningLearning.actions

export default eLearningLearning.reducer
