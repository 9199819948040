import Button from '@mui/material/Button'
import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'

export const StyledButton = styled((props) => (
  <Button {...props} variant="contained" />
))(({ theme }) => ({
  height: 50,
  padding: '0 22px',
  [theme.breakpoints.down('sm')]: { minWidth: 109 },
}))

export const StyledPreview = styled('img')(() => ({ objectFit: 'cover' }))

export const StyledColumn = styled(Column)(({ theme }) => ({
  padding: `${theme.spacing(3)} 0`,
  gap: theme.spacing(2),
  '& > .MuiBox-root': { gap: theme.spacing(3) },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(2)} 0`,
    gap: theme.spacing(3),
    '& > .MuiBox-root': { flexDirection: 'column' },
  },
}))
