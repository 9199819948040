import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const MediaBoxStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height,
  background: theme.palette?.background?.paper,
}))

export const height = 'calc(100vh - 68px)'
