import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import _ from 'lodash'

import DateRangeMenu from 'src/components/Filter/DateRangeMenu'
import ExpandedMenu from '../ExpandedMenu'
import { StyledBox } from './Styled'
import { FilterList } from 'src/constants/course'
import SearchBox from './SearchBox'
import { handleChangePeriod, handleResetPeriod } from '../ExpandedMenu/events'

const FilterSidebar = ({ isDrawer }) => {
  const dispatch = useDispatch()
  const { tab, startDate, finishDate, search } = useSelector(
    (state) => ({
      tab: state.classList.tab,
      startDate: state.classList.myScheduleFilterBody.startDate,
      finishDate: state.classList.myScheduleFilterBody.finishDate,
      search: state.classList.myScheduleFilterBody.search,
      courseName: state.classList.courseName,
    }),
    shallowEqual
  )

  return (
    <StyledBox isDrawer={isDrawer}>
      {_.get(FilterList, tab, []).map((value) => {
        let list = value.list ?? []
        if (value.field === 'status') {
          let statusList = []
          Object.keys(list).forEach(function (key) {
            if (list[key].isFilter)
              statusList.push({
                label: list[key].text,
                value: key,
              })
          })
          list = statusList
        }
        return (
          <>
            {value.type === 'checkbox' ? (
              <ExpandedMenu
                key={value.field}
                field={value.field}
                label={value.label}
                list={list}
              />
            ) : (
              ''
            )}

            {value.type === 'text' ? (
              <ExpandedMenu
                key={value.field}
                field={value.field}
                label={value.label}
              >
                <SearchBox search={search} />
              </ExpandedMenu>
            ) : (
              ''
            )}

            {value.type === 'dateRange' ? (
              <ExpandedMenu
                key={value.field}
                field={value.field}
                label={value.label}
              >
                <DateRangeMenu
                  startDate={startDate}
                  finishDate={finishDate}
                  onChange={(formattedDate) =>
                    dispatch(handleChangePeriod(formattedDate))
                  }
                  onReset={() => dispatch(handleResetPeriod())}
                />
              </ExpandedMenu>
            ) : (
              ''
            )}
          </>
        )
      })}
    </StyledBox>
  )
}

export default FilterSidebar
