import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { visuallyHidden } from '@mui/utils'
import { useSelector, useDispatch } from 'react-redux'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import {
  StyledFullWidth,
  StyledTable,
  StyledTableBodyCell,
  StyledTableCell,
  StyledTableSortLabel,
} from '../Styled'
import {
  checkConditionView,
  handleChangePage,
  handleChangeRowsPerPage,
  handleLabelDisplay,
  handleRequestSort,
} from './events'
import { ROW_PAGE_PER_LIST } from '../../../constants/table'

export const EnhancedTableHead = () => {
  const dispatch = useDispatch()
  const { table } = useSelector((state) => state.basicTable)
  const { headCells, defaultSort, order, sort } = table

  return (
    <TableHead>
      <TableRow key="head">
        {headCells &&
          headCells.map((headCell) => {
            const valueId = headCell.id
            let checkActive = false

            if (defaultSort && valueId === defaultSort.id) {
              checkActive = defaultSort.active
            }
            return (
              <StyledTableCell
                key={valueId}
                align={'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={false}
                headcellwidth={headCell.width}
              >
                <StyledTableSortLabel
                  data-testid={`sort-cell-${valueId}`}
                  active={checkActive}
                  direction={sort === valueId ? order : 'asc'}
                  onClick={() =>
                    dispatch(handleRequestSort(valueId, table, headCell))
                  }
                  hideSortIcon={headCell.hideSortIcon}
                  headcell={headCell}
                >
                  {headCell.label}
                  {sort === valueId && (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  )}
                </StyledTableSortLabel>
              </StyledTableCell>
            )
          })}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTable = ({ type }) => {
  const dispatch = useDispatch()
  const { table } = useSelector((state) => state.basicTable)

  const {
    rows,
    headCells,
    isShowPagination,
    allCount,
    rowsPerPage,
    tablePage,
  } = table

  return (
    <StyledFullWidth>
      <TableContainer>
        <StyledTable sx={{ border: 'none' }}>
          <EnhancedTableHead type={type} />
          <TableBody>
            {rows &&
              rows.map((row, rowIndex) => {
                return (
                  <TableRow
                    key={rowIndex}
                    role="checkbox"
                    tabIndex={-1}
                    color="primary"
                    type="hover"
                  >
                    {headCells &&
                      headCells.map((_, cellIndex) =>
                        checkConditionView(headCells, row, rowIndex, cellIndex)
                      )}
                  </TableRow>
                )
              })}
            {rows.length === 0 && (
              <TableRow>
                <StyledTableBodyCell colSpan={headCells.length}>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledTableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {isShowPagination && (
        <TablePagination
          data-testid="page-test"
          rowsPerPageOptions={ROW_PAGE_PER_LIST}
          component="div"
          count={allCount}
          rowsPerPage={rowsPerPage.value}
          labelRowsPerPage={
            <Typography variant="caption" color="text.secondary">
              แถวต่อหน้า{'\xA0'} :
            </Typography>
          }
          labelDisplayedRows={() => handleLabelDisplay(table)}
          page={tablePage < 0 ? 1 : +tablePage}
          onPageChange={(_, pageChanged) =>
            dispatch(handleChangePage(pageChanged))
          }
          onRowsPerPageChange={(event) =>
            dispatch(handleChangeRowsPerPage(event))
          }
        />
      )}
    </StyledFullWidth>
  )
}

export default EnhancedTable
