import React from 'react'
import DateRangeInput from 'src/components/DateRangeInput'
import { StyledFilterContent } from './Styled'
import { handleFormatDate } from './events'

const DateRangeMenu = ({ startDate, finishDate, onChange, onReset }) => {
  const isStartDate = startDate != ''
  const isFinishDate = finishDate != ''
  const defaultStart = isStartDate ? new Date(startDate) : new Date()
  const defaultFinish = isFinishDate ? new Date(finishDate) : new Date()
  return (
    <StyledFilterContent>
      <DateRangeInput
        placeholder="dd/mm/yyyy - dd/mm/yyyy"
        dateState={{
          startDate: defaultStart,
          endDate: defaultFinish,
          display: isStartDate,
          key: 'selection',
        }}
        onChange={(selectedDates) => {
          const formattedDate = handleFormatDate(selectedDates)
          onChange(formattedDate)
        }}
        onReset={onReset}
      />
    </StyledFilterContent>
  )
}

export default DateRangeMenu
