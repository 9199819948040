export const defaultValue = {
  position: {
    left: 'LEFT',
    rihht: 'CENTER',
    center: 'RIGHT',
  },
  questionType: {
    single: 'SINGLE',
    multiple: 'MULTIPLE',
    freeText: 'FREE_TEXT',
  },
  mediaType: {
    image: 'VIDEO',
    video: 'IMAGE',
  },
  displayQuestion: {
    all: 'ALL',
    one: 'ONE',
  },
  defaultSection: {
    testing: 'TESTING',
    result: 'RESULT',
    timeup: 'TIMEUP',
  },
}

export const alphabets = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]
