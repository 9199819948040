import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { formatNowDateTime } from 'src/modules/EEvaluation/Evaluate/Header/Round/events'
import { convertFormatDateTime, isMediaWidthMoreThan } from 'src/utils/lib'
import { responsiveText, StyledRoundContainer } from './Styled'

export const Round = () => {
  const isMediaLg = isMediaWidthMoreThan('md')
  const textVariant = responsiveText(isMediaLg)
  const { testRoundAt, error, result } = useSelector(
    (state) => ({
      testRoundAt: state.eTesting.testRoundAt,
      result: state.eTesting.result,
      error: state.eTesting.error,
    }),
    shallowEqual
  )
  const round = _.get(result, 'round', '')
  const createdAt = _.get(result, 'createdAt', '')
  const dateTime = createdAt
    ? convertFormatDateTime({ value: createdAt, type: 'dateTime' })
    : formatNowDateTime()

  if (error) return <></>

  return (
    <StyledRoundContainer>
      <Typography variant={textVariant} color="text.white">
        สอบครั้งที่ {round || testRoundAt}
      </Typography>
      <Typography variant={textVariant} color="text.white">
        {dateTime} น.
      </Typography>
    </StyledRoundContainer>
  )
}

export default Round
