import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { closeDialog } from 'src/redux/slices/alertDialog'
import { Row } from 'src/components/Layout/Styled'
import {
  StyledContent,
  StyledContentHeader,
  StyledDialogHeader,
  StyledFooter,
  StyledLangButton,
} from 'src/modules/Document/Consent/Styled'
import { convertFormatDateTime, isMediaWidthMoreThan } from 'src/utils/lib'
import { consentObj } from './model'

const ConsentDialog = ({ keyConfig }) => {
  const [contentHeight, setContentHeight] = useState(480)
  const [isEng, setEng] = useState(true)
  const data = useSelector((state) => state.eConfiguration.body, shallowEqual)
  const consent = consentObj[keyConfig]
  const dispatch = useDispatch()
  const isNotSm = isMediaWidthMoreThan('sm')
  const ref = useRef()
  const isLanscape = window.matchMedia('(orientation: landscape)').matches
  const publishedOn = convertFormatDateTime({ value: new Date(), type: 'date' })

  useEffect(() => {
    setContentHeight(
      ref.current && isLanscape ? ref.current.offsetHeight - 48 : 480
    )
  }, [ref, isLanscape])

  return (
    <>
      <StyledDialogHeader>
        <DialogTitle id="dialog-title">
          <Typography variant={isNotSm ? 'h5' : 'body1b'}>
            {_.get(consent, 'consentMainHeader', '-')}
          </Typography>
        </DialogTitle>
        <IconButton
          data-testid="btn-close"
          onClick={() => dispatch(closeDialog())}
        >
          <Close />
        </IconButton>
      </StyledDialogHeader>
      <Box sx={{ p: 3, overflow: 'hidden' }} ref={ref}>
        <StyledContent height={contentHeight}>
          <StyledContentHeader>
            <Typography variant="body1b">
              {_.get(consent, 'consentSubHeader', '-')}
            </Typography>
          </StyledContentHeader>
          <Box sx={{ px: 1, mb: 2.5, overflow: 'auto', '& *': { whiteSpace: 'normal' } }}>
            <div
              dangerouslySetInnerHTML={{
                __html: isEng
                  ? _.get(data, `${keyConfig}EN`, '-')
                  : _.get(data, `${keyConfig}TH`, '-'),
              }}
            />
          </Box>
        </StyledContent>
        <StyledFooter>
          <Row gap={1} justifyContent="space-between">
            <Row sx={{ width: 'auto' }} gap={0.5}>
              <StyledLangButton
                data-testid="btn-en"
                active={isEng}
                onClick={() => setEng(true)}
              >
                EN
              </StyledLangButton>
              <Typography variant="caption">/</Typography>
              <StyledLangButton
                data-testid="btn-th"
                active={!isEng}
                onClick={() => setEng(false)}
              >
                TH
              </StyledLangButton>
            </Row>

            <Typography variant="caption">
              [Published on {publishedOn}]
            </Typography>
          </Row>
        </StyledFooter>
      </Box>
    </>
  )
}

export default ConsentDialog
