import React from 'react'
import _ from 'lodash'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import { StyledRadioGroup, StyledSortMenu } from './Styled'

const SortMenu = ({ sortBy, list, onChange }) => {
  return (
    <StyledSortMenu>
      <Typography variant="h6">เรียงตาม</Typography>
      <FormControl sx={{ mt: 1 }}>
        <StyledRadioGroup
          data-testid="sort"
          name="sortBy"
          value={sortBy}
          onChange={onChange}
        >
          {list.map((item, index) => {
            const value = _.get(item, 'value', '')
            return (
              <FormControlLabel
                key={index}
                value={value}
                control={<Radio />}
                label={_.get(item, 'label', '')}
              />
            )
          })}
        </StyledRadioGroup>
      </FormControl>
      <Divider />
    </StyledSortMenu>
  )
}

export default SortMenu
