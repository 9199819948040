import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'

import { CardSection } from '../'
import { handleDelete } from '../events'
import { listStatusText } from '../model'

const Index = () => {
  const dispatch = useDispatch()
  const [prospectDetail, setProspectDetail] = useLocalStorage('prospectDetail')

  const { prospects, isSuccess, course } = useSelector(
    (state) => ({
      prospects: state.manageProspectEnrollment.prospects,
      isSuccess: state.manageProspectEnrollment.isSuccess,
      course: state.manageProspectEnrollment.course,
    }),
    shallowEqual
  )

  const trainingPlan = _.get(course, 'trainingPlan.value', '')

  return (
    <CardSection
      section="2"
      labelSection="ตรวจสอบรายชื่อที่สมัคร"
      labelCard="รายชื่อที่สมัครเข้าอบรม"
      isAddName={true}
      disabled={isSuccess || _.isEmpty(trainingPlan)}
    >
      <Box sx={{ border: '1px solid #DBE4F1', borderRadius: '2px', mt: 3 }}>
        <Box sx={{ m: 2, overflow: 'auto' }}>
          <Table aria-label="table-manage-prospect" sx={{ minWidth: 520 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">No.</TableCell>
                <TableCell align="left">ชื่อ - นามสกุล</TableCell>
                <TableCell align="left">Unit Code</TableCell>
                <TableCell align="left">สถานะการเพิ่มผู้เรียน</TableCell>
                <TableCell align="left">คำสั่ง</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prospects.length > 0 &&
                prospects.map((row, index) => (
                  <TableRow status={row.isTrue} key={index}>
                    <TableCell align="left">
                      {index + 1 <= 9 ? `0${index + 1}` : index + 1}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.unitNumber}</TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color:
                          listStatusText[row.status.toUpperCase()] || '#333333',
                      }}
                    >
                      {row.statusText || '-'}
                    </TableCell>
                    <TableCell align="left">
                      <DeleteTwoTone
                        disabled={isSuccess}
                        sx={{ cursor: isSuccess ? 'no-drop' : 'pointer' }}
                        color={isSuccess ? 'disabled' : 'action'}
                        onClick={() =>
                          !isSuccess &&
                          dispatch(
                            handleDelete(
                              index,
                              row.uuid,
                              prospectDetail,
                              setProspectDetail
                            )
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {!prospects.length && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Box sx={{ textAlign: 'center' }}>
                      <InboxTwoTone color="disabled" />
                      <Typography variant="body2" color="text.lightGray">
                        ไม่พบข้อมูล
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </CardSection>
  )
}

export default Index
