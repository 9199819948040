import React, { useEffect, useRef, useState } from 'react'
import Typography from '@mui/material/Typography'
import Event from '@mui/icons-material/Event'
import Close from '@mui/icons-material/Close'
import DateRangePicker from './DateRangePicker'
import { Row } from '../Layout/Styled'
import {
  formatDateDisplay,
  getDisabledDates,
  formatBuddhistEra,
} from './events'
import { StyledDateInput, StyledDateRangeBox } from './Styled'

const DateRangeInput = (props) => {
  const {
    dateState,
    disableDates,
    placeholder = 'Start date - End date',
    maxDate,
    onChange,
    onReset,
    label,
  } = props
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)
  const [state, setState] = useState([dateState])
  const [disableDateArr, setDisableDate] = useState([])

  useEffect(() => {
    setState([dateState])
  }, [dateState])

  useEffect(() => {
    const allDisabledDates = getDisabledDates(disableDates)
    setDisableDate(allDisabledDates)
  }, [disableDates])

  useEffect(() => {
    document.addEventListener('click', handleClickAway, true)
  }, [])

  useEffect(() => {
    formatBuddhistEra(ref)
  }, [ref, isOpen])

  const handleClickAway = (e) => {
    if (ref.current && !ref.current.contains(e.target)) setOpen(false)
  }

  return (
    <StyledDateRangeBox>
      <StyledDateInput id="DateRangeBox" data-testid="date-input">
        <Row gap={0.5} onClick={() => setOpen((e) => !e)}>
          {label && <Typography>{label}</Typography>}
          {state[0].display ? (
            <Typography color="text.primary">
              {formatDateDisplay(state[0].startDate)} -{' '}
              {formatDateDisplay(state[0].endDate)}
            </Typography>
          ) : (
            <Typography color="text.lightGray">{placeholder}</Typography>
          )}
        </Row>
        {state[0].display ? (
          <Close color="primary" onClick={() => onReset && onReset()} />
        ) : (
          <Event color="primary" onClick={() => setOpen((e) => !e)} />
        )}
      </StyledDateInput>
      <div ref={ref}>
        {isOpen && (
          <DateRangePicker
            maxDate={maxDate}
            selectedDates={state}
            disableDates={disableDateArr}
            onChange={(item) => {
              const newDateState = { ...item.selection, display: true }
              setState([newDateState])
              onChange(newDateState, setOpen)
            }}
          />
        )}
      </div>
    </StyledDateRangeBox>
  )
}

export default DateRangeInput
