import React from 'react'
import { DateRangePicker } from 'react-date-range'
import { th } from 'react-date-range/dist/locale'
import { useTheme } from '@mui/material'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './date-range-style.css'

const DateRange = ({ selectedDates, disableDates, maxDate, onChange }) => {
  const theme = useTheme()
  return (
    <DateRangePicker
      locale={th}
      onChange={onChange}
      showSelectionPreview={true}
      months={1}
      ranges={selectedDates}
      direction="horizontal"
      className="dateRange"
      maxDate={maxDate}
      // minDate={new Date('08-13-2022')}
      rangeColors={[theme.palette.primary?.main]}
      disabledDates={disableDates}
    />
  )
}

export default DateRange
