import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eLearningBatch,
  eLearningBatchLearnerStatus,
  eLearningCourseBatchDetail,
  eLearningEnroll,
  eLearningEnrollIdCard,
  eLearningEnrollRekognition,
  eLearningFailScanCount,
  eLearningQueueSession,
  eLearningVerifyIdCard,
} from 'src/utils/apiPath'

export const getCourseBatch = createAsyncThunk(
  'eLearningEnroll/getCourseBatch',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningBatch}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postVerifyIdCard = createAsyncThunk(
  'eLearningEnroll/postVerifyIdCard',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningVerifyIdCard, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postEnrollIdCard = createAsyncThunk(
  'eLearningEnroll/postEnrollIdCard',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningEnrollIdCard, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postVerifyFace = createAsyncThunk(
  'eLearningEnroll/postVerifyFace',
  async (payload, { rejectWithValue }) => {
    try {
      const { sessionId, body } = payload
      const response = await axios.post(
        `${eLearningQueueSession}/${sessionId}`,
        body
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getCheckFailScanCount = createAsyncThunk(
  'eLearningEnroll/getCheckFailScanCount',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningFailScanCount}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postFailScanCount = createAsyncThunk(
  'eLearningEnroll/postFailScanCount',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningFailScanCount, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putFailScanCount = createAsyncThunk(
  'eLearningEnroll/putFailScanCount',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningFailScanCount, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postEnrollRekognition = createAsyncThunk(
  'eLearningEnroll/postEnrollRekognition',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningEnrollRekognition, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postEnrollCourse = createAsyncThunk(
  'eLearningEnroll/postEnrollCourse',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningEnroll, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getBatchDetail = createAsyncThunk(
  'eLearningEnroll/getBatchDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourseBatchDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putBatchLearnerStatus = createAsyncThunk(
  'eLearningEnroll/putBatchLearnerStatus',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${eLearningBatchLearnerStatus}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
