import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import CameraOutdoor from '@mui/icons-material/CameraOutdoor'
import PagesOutlined from '@mui/icons-material/PagesOutlined'
import PaidOutlined from '@mui/icons-material/PaidOutlined'
import TimerOutlined from '@mui/icons-material/TimerOutlined'
import { courseInclude } from 'src/constants/course'
import { convertFormatTime, isMediaWidthMoreThan } from 'src/utils/lib'
import { Column, Row } from 'src/components/Layout/Styled'
import {
  StyledContentColumn,
  StyledDetailBox,
  StyledDivider,
  StyledHalfColumn,
  StyledIconContent,
  StyledImage,
  StyledIncludeRow,
  StyledLabelIcon,
  StyledRespColumn,
  StyledRowContent,
  StyledRowTwo,
} from './Styled'

const Content = () => {
  const data = useSelector((state) => state.courseDetail.data, shallowEqual)
  const isWidthLg = isMediaWidthMoreThan('lg')

  return (
    <Column gap={3}>
      <Typography
        variant={isWidthLg ? 'h3' : 'h5'}
        color="text.primary"
        sx={{ wordWrap: 'break-word' }}
      >
        {_.get(data, 'nameForLearner', '') || _.get(data, 'englishName', '')}
      </Typography>

      <CourseRowContent data={data} />

      <DetailBox data={data} />
    </Column>
  )
}

export default Content

export const CourseRowContent = ({ data }) => {
  const time = convertFormatTime(data)
  return (
    <StyledRowContent>
      <StyledHalfColumn>
        <StyledLabelIcon>
          <CameraOutdoor fontSize="small" />
          <Typography variant="body2">รูปแบบการสอน</Typography>
        </StyledLabelIcon>
        <Typography variant="h6">
          {_.get(data, 'trainingPlatformName', '')}
        </Typography>
      </StyledHalfColumn>
      <StyledDivider id="training" />
      <StyledRowTwo>
        <StyledHalfColumn>
          <StyledLabelIcon>
            <PagesOutlined fontSize="small" />
            <Typography variant="body2">ช่องทางการจำหน่าย</Typography>
          </StyledLabelIcon>
          <Typography variant="h6">
            {_.get(data, 'distribution', '')}
          </Typography>
        </StyledHalfColumn>
        <StyledDivider />
        <StyledHalfColumn>
          <StyledLabelIcon>
            <TimerOutlined fontSize="small" />
            <Typography variant="body2">ระยะเวลาของหลักสูตร</Typography>
          </StyledLabelIcon>
          <Typography variant="h6">{time}</Typography>
        </StyledHalfColumn>
      </StyledRowTwo>
    </StyledRowContent>
  )
}

export const IconContent = ({ field }) => {
  const include = _.get(courseInclude, field, 'attendance')
  return (
    <StyledIconContent>
      {_.get(include, 'icon', <></>)}
      <Typography>{_.get(include, 'label', <></>)}</Typography>
    </StyledIconContent>
  )
}

export const DetailBox = ({ data }) => {
  const skills = _.get(data, 'getAcquiredSkill', [])
  const isAttendance = _.get(data, 'isAttendance', false)
  const preTest = _.get(data, 'preTest', false)
  const postTest = _.get(data, 'postTest', false)
  const evaluation = _.get(data, 'evaluation', false)
  const assessment = _.get(data, 'assessment', false)
  const eCert = _.get(data, 'eCertificationVersion', false)
  const isShowSection =
    isAttendance || preTest || postTest || assessment || eCert
  const paidCost = _.get(data, 'paidCost', false)
  const image = _.get(data, 'imageKey', '')
  const src = `${window.__env__.REACT_APP_FILE_URL}${image}`
  return (
    <StyledDetailBox>
      <StyledImage src={src} alt="preview_img" />
      <StyledContentColumn>
        <Column gap={1}>
          <Typography variant="body1b">กลุ่มหลักสูตร</Typography>
          <Typography variant="body1">{_.get(data, 'roadmap', '')}</Typography>
        </Column>
        {isShowSection && (
          <Column gap={1}>
            <Typography variant="body1b">หลักสูตรนี้ประกอบไปด้วย</Typography>
            <StyledIncludeRow>
              {isAttendance && <IconContent field="attendance" />}
              {preTest && <IconContent field="preTest" />}
              {postTest && <IconContent field="postTest" />}
              {evaluation && <IconContent field="evaluation" />}
              {eCert && <IconContent field="eCert" />}
              {assessment && <IconContent field="assessment" />}
            </StyledIncludeRow>
          </Column>
        )}
        <Column gap={1}>
          <Typography variant="body1b">ทักษะที่ได้จากหลักสูตร</Typography>
          <Row flexWrap="wrap" gap={1}>
            {skills.map((item, index) => (
              <Chip key={index} variant="outlined" label={item} />
            ))}
          </Row>
        </Column>
        {paidCost && (
          <Alert
            sx={{ alignItems: 'center' }}
            variant="outlined"
            severity="info"
            icon={<PaidOutlined />}
          >
            <StyledRespColumn>
              <span>หลักสูตรนี้มีค่าใช้จ่ายโปรดดำเนินการชำระเงินก่อนสมัคร{" "}</span>
              <span>
                สอบถามเพิ่มเติม{' '}
                {
                  <Link target="_blank" href="/location">
                    ติดต่อเจ้าหน้าที่
                  </Link>
                }
              </span>
            </StyledRespColumn>
          </Alert>
        )}
      </StyledContentColumn>
    </StyledDetailBox>
  )
}
