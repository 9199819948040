import { createSlice } from '@reduxjs/toolkit'
import { getCourseClassDetail } from 'src/services/class/detail'
import { initialState } from './model'

const classDetail = createSlice({
  name: 'classDetail',
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = null
    },
    startEnrollLoading: (state) => {
      state.isEnrollLoading = true
    },
    stopEnrollLoading: (state) => {
      state.isEnrollLoading = false
    },
    setQrExpired: (state, { payload }) => {
      state.qrExpired = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseClassDetail.fulfilled, (state, { payload }) => {
        state.data = payload.data
      })
      .addCase(getCourseClassDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  resetData,
  startEnrollLoading,
  stopEnrollLoading,
  setQrExpired,
} = classDetail.actions

export default classDetail.reducer
