import React, { useMemo } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import { Row } from 'src/components/Layout/Styled'
import { COURSE_TYPE, COURSE_TYPE_TH } from 'src/constants/eLearning'
import { COURSE_ICON, COURSE_ICON_KEY } from '../model'
import { StyledTypography } from '../Styled'
import { StyledLikeIcon, StyledTooltip } from './Styled'

const CourseTitle = ({ course }) => {
  const isLike = _.get(course, 'isLike', false)
  const isOIC = course.courseType === COURSE_TYPE.OIC
  const classType = useMemo(() => {
    if (window.location.pathname === '/e-learning/course') {
      return COURSE_TYPE_TH[course.courseType]
    }
    if (course.courseType) {
      const courseType = isOIC ? 'ระดับสูง' : 'ทั่วไป'
      return `E-Learning - ${courseType}`
    } else if (course.trainingPlatformName) {
      return course.trainingPlatformName
    } else return '-'
  }, [course])

  return (
    <Box sx={{ textAlign: 'start', position: 'relative' }}>
      <Row sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <StyledTypography variant="overline" color="secondary">
          {classType}
        </StyledTypography>
        {isLike && <StyledLikeIcon src="/icon/ic_heart_fill.svg" />}
      </Row>
      <StyledTypography variant="h6" sx={{ lineHeight: '24px' }}>
        {course.nameForLearner || course.englishName || course.name}
      </StyledTypography>
      <Row gap={0.5}>
        {isOIC && <TooltipIcon iconKey={COURSE_ICON_KEY.FACE_DETECTION} />}
        {course.requireDocument && (
          <TooltipIcon iconKey={COURSE_ICON_KEY.UPLOAD_FILE} />
        )}
        {course.hasCertificate && (
          <TooltipIcon iconKey={COURSE_ICON_KEY.CERTIFICATE} />
        )}
        {course.paidCost && <TooltipIcon iconKey={COURSE_ICON_KEY.PAID} />}
      </Row>
    </Box>
  )
}

export default CourseTitle

export const TooltipIcon = ({ iconKey }) => {
  const { title, icon } = COURSE_ICON[iconKey]
  return <StyledTooltip title={title}>{icon}</StyledTooltip>
}
