import React from 'react'
import { markScales } from './model'
import { StyledFilterContent, StyledSlider } from './Styled'

const PeriodSliderMenu = (props) => {
  const { hourFrom, hourTo, onChange, onChangeCommitted } = props
  return (
    <StyledFilterContent>
      <StyledSlider
        data-testid="course-hour"
        size="small"
        valueLabelDisplay="on"
        value={[hourFrom, hourTo]}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
        valueLabelFormat={(value) => `${value} ชม.`}
        marks={markScales}
      />
    </StyledFilterContent>
  )
}

export default PeriodSliderMenu
