import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import InsertInvitationOutlined from '@mui/icons-material/InsertInvitationOutlined'
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined'
import StickyNote2Outlined from '@mui/icons-material/StickyNote2Outlined'
import TimerOutlined from '@mui/icons-material/TimerOutlined'
import {
  isMediaWidthMoreThan,
  convertDateRange,
  convertFormatTime,
} from 'src/utils/lib'
import { Column, Row } from 'src/components/Layout/Styled'
import Dot from 'src/components/Dot'
import { myClassStatus } from 'src/constants/course'
import {
  StyledCard,
  StyledCardContent,
  StyledCardFooter,
  StyledImgBox,
  StyledLabelIcon,
  StyledRowFooter,
} from './Styled'
import OutlinedButton from './OutlinedButton'
import ContainedButton from './ContainedButton'

const ClassCard = ({ data }) => {
  const ref = useRef()
  const [imageHeight, setImageHeight] = useState(201)
  const isLg = isMediaWidthMoreThan('lg')
  const isSm = !isMediaWidthMoreThan('sm')
  const image = _.get(data, 'imageKey', '')
  const classImg =
    image != ''
      ? `${window.__env__.REACT_APP_FILE_URL}${image}`
      : '/image/no_class_img.svg'
  const trainingRoom = _.uniq(_.get(data, 'trainingRoom', []))
  const dateRange = convertDateRange(_.get(data, 'dateRangeMonthlyPlan', null))
  const time = convertFormatTime(data)
  const tab = useSelector((state) => state.classList.tab, shallowEqual)
  const isOldSystem = tab == 'CLOSED_OLD_SYSTEM'
  const fontSize = isLg || isSm ? 'h5' : 'h6'

  useEffect(() => {
    setImageHeight(
      ref.current && ref.current.offsetHeight > 201
        ? ref.current.offsetHeight
        : 201
    )
  }, [ref.current, data, fontSize])

  return (
    <StyledCard>
      <StyledImgBox height={imageHeight}>
        <img src={classImg} />
      </StyledImgBox>
      <StyledCardContent isOldSystem={isOldSystem} ref={ref}>
        {isOldSystem ? (
          <>
            <Column sx={{ width: 'auto' }}>
              <HeaderClassCard data={data}>
                <Typography variant={fontSize}>
                  {_.get(data, 'englishName', '')}
                </Typography>
              </HeaderClassCard>
            </Column>

            <StyledLabelIcon sx={{ alignItems: 'start' }}>
              <StickyNote2Outlined
                sx={{ mt: 0.5 }}
                color="text.secondary"
                fontSize="small"
              />
              <Typography variant="body1">
                {_.get(data, 'remark', '-')}
              </Typography>
            </StyledLabelIcon>
          </>
        ) : (
          <Column sx={{ width: 'auto' }}>
            <HeaderClassCard data={data}>
              <Typography variant="overline" color="info.main">
                {_.get(data, 'trainingPlatformName', '')}
              </Typography>
            </HeaderClassCard>
            <Typography variant={fontSize} sx={{ wordWrap: 'break-word' }}>
              {_.get(data, 'nameForLearner', '') ||
                _.get(data, 'englishName', '')}
            </Typography>
          </Column>
        )}

        <StyledCardFooter>
          <StyledRowFooter>
            <StyledLabelIcon>
              <InsertInvitationOutlined
                color="text.secondary"
                fontSize="small"
              />
              <Typography maxWidth={185} variant="body1">
                {dateRange}
              </Typography>
            </StyledLabelIcon>
            <StyledLabelIcon>
              <TimerOutlined color="text.secondary" fontSize="small" />
              <Typography maxWidth={110} variant="body1">
                {time}
              </Typography>
            </StyledLabelIcon>
            <StyledLabelIcon>
              <LocationOnOutlined color="text.secondary" fontSize="small" />
              <Typography maxWidth={270} variant="body1">
                {isOldSystem
                  ? _.get(data, 'location', null) || '-'
                  : trainingRoom.map(
                      (item, index) =>
                        `${item}${index < trainingRoom.length - 1 ? ', ' : ''}`
                    )}
              </Typography>
            </StyledLabelIcon>
          </StyledRowFooter>
          {!isOldSystem && (
            <Row id="button" sx={{ width: 'auto', gap: 2 }}>
              <OutlinedButton data={data} />
              <ContainedButton data={data} />
            </Row>
          )}
        </StyledCardFooter>
      </StyledCardContent>
    </StyledCard>
  )
}

export default ClassCard

export const HeaderClassCard = ({ data, children }) => {
  const classStatus = _.get(data, 'classStatus', '')
  return (
    <Row sx={{ justifyContent: 'space-between', alignItems: 'start' }}>
      {children}

      <Row sx={{ width: 'auto', gap: 1.5, minWidth: 112, mt: 0.5 }}>
        <Dot status={_.get(myClassStatus, classStatus, 'inactive')} />
        <Typography variant="body1b">
          {_.get(data, 'classStatusTH', '')}
        </Typography>
      </Row>
    </Row>
  )
}
