import React from 'react'
import ReactPlayer from 'react-player'
import _ from 'lodash'
import Box from '@mui/material/Box'
import CardMedia from '@mui/material/CardMedia'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { StyledMediaBox, StyledPlayArrow } from './Styled'
import './style.css'

const MediaBox = ({ course, isHover }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const videoUrl = _.get(course, 'linkExampleVideo', '')
  const coverImage =
    _.get(course, 'coverImage', '') || _.get(course, 'imageKey', '')
  const imageSrc = `${window.__env__.REACT_APP_FILE_URL}/get${coverImage}`
  const isShowVideo = isHover && isLg && !_.isEmpty(videoUrl)
  const playerRef = React.useRef()
  const onPause = React.useCallback(() => {
    playerRef.current?.seekTo(0, 'seconds')
  }, [playerRef.current])

  return (
    <StyledMediaBox id="media">
      {!_.isEmpty(videoUrl) && !isShowVideo && isLg && <StyledPlayArrow />}
      <ReactPlayer
        ref={playerRef}
        muted
        onPause={onPause}
        playing={isShowVideo}
        loop={true}
        url={videoUrl}
        config={{ youtube: { playerVars: { disablekb: 1 } } }}
        style={{ display: isShowVideo ? 'block' : 'none' }}
      />
      <Box sx={{ display: isShowVideo ? 'none' : 'block' }}>
        {_.isEmpty(coverImage) ? (
          <ReactPlayer
            muted
            light
            url={videoUrl}
            ref={playerRef}
            onPause={onPause}
            playing={isShowVideo}
            loop={true}
            config={{ youtube: { playerVars: { disablekb: 1 } } }}
          />
        ) : (
          <CardMedia component="img" image={imageSrc} alt="preview" />
        )}
      </Box>
    </StyledMediaBox>
  )
}

export default MediaBox
