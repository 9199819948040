import React from 'react'
import Typography from '@mui/material/Typography'

import ContentLeft from './ContentLeft'
import ContentRight from './ContentRight'
import { BoxContent, BoxGroupContent, BoxLabel } from './Styled'

export const BoxTextLabel = ({
  labelLeft,
  labelRight,
  isFirst,
  isTop,
  isView,
}) => {
  return (
    <BoxLabel isTop={isTop} isFirst={isFirst} isView={isView}>
      <Typography variant="body1" color="text.secondary">
        {labelLeft}
      </Typography>
      <Typography variant="body1" color="text.primary">
        {labelRight}
      </Typography>
    </BoxLabel>
  )
}

const Index = ({ caseType }) => {
  return (
    <BoxContent>
      <BoxGroupContent>
        <ContentLeft />
        <ContentRight caseType={caseType} />
      </BoxGroupContent>
    </BoxContent>
  )
}

export default Index
