import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import styled from '@mui/system/styled'

export const StyledBox = styled(Box)(({ minWidth }) => ({
  minWidth: minWidth ?? 120,
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary?.main,
  color: theme.palette.secondary?.contrast,
  padding: '4px 10px',

  '&:hover': {
    backgroundColor: theme.palette.secondary?.main,
    color: theme.palette.secondary?.contrast,
  },
}))
