export const initialState = {
  tab: 'ALL',
  filter: [],
  filterBody: {
    courseName: '',
    startDate: '',
    finishDate: '',
    classStatus: [],
    page: 1,
  },
  classes: [],
  totalClasses: 0,
  error: null,
  isLoadMore: false,
  myScheduleFilterBody: {
    search: '',
    status: [],
    schedule: [],
    page: 1,
    isFetch: false,
    hasMore: false,
    classType: [],
    classCourse: [],
    classStatus: [],
    startDate: '',
    finishDate: '',
    className: '',
    courseName: '',
  },
  myScheduleList: [],
  cancelTokenSource: {},
}
