import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  responsiveText,
  StyledHeaderContainer,
} from 'src/components/Preview/Header/Styled'
import Timer from './Timer'
import Round from './Round'
import { StyledHeaderTitle } from './Styled'

const Header = ({ isModule }) => {
  const { eTesting, limitTime, onReviewAnswer, section } = useSelector(
    (state) => ({
      eTesting: state.eTesting,
      limitTime: state.eTesting.limitTime,
      onReviewAnswer: state.eTesting.onReviewAnswer,
      section: state.eTesting.section,
    }),
    shallowEqual
  )
  const isSetTimer = _.get(limitTime, 'isOpen', false)
  const timeSecondRemaining = _.get(eTesting, 'timeSecondRemaining', 0)
  const isSectionResult = section === 'RESULT'
  const isShowTimer =
    !onReviewAnswer && !isSectionResult && isSetTimer && timeSecondRemaining > 0
  return (
    <StyledHeaderContainer isModule={isModule}>
      <Box>
        <StyledHeaderTitle
          section={section}
          isSetTimer={!onReviewAnswer && isSetTimer}
        >
          <Typography variant={responsiveText('h4', 'h5', 'body2b')}>
            {_.get(eTesting, 'title1', '')}
          </Typography>
          <Typography variant={responsiveText('h6', 'body1b', 'subtitle2')}>
            {_.get(eTesting, 'title2', '')}
          </Typography>
        </StyledHeaderTitle>
        {isShowTimer && <Timer limitTime={limitTime} />}
        {isSectionResult && <Round />}
      </Box>
    </StyledHeaderContainer>
  )
}

export default Header
