import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'
import { isLearningPath } from './events'

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme.palette?.background?.blue,
}))

export const StyledContent = styled(Row)(({ theme }) => ({
  width: '100%',
  maxWidth: 1440,
  margin: 'auto',
  padding: isLearningPath() ? theme.spacing(3) : `${theme.spacing(5)} 0`,
  alignItems: 'start',
  gap: theme.spacing(5),
  flexDirection: isLearningPath() ? 'column' : 'row',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    gap: theme.spacing(3),
    flexDirection: 'column',
  },
}))
