import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { StyledFooter } from 'src/components/Preview/Footer/Styled'
import ButtonForOne from './ButtonForOne'
import ProgressMilestone from './ProgressMilestone'
import { handleAnswerAmount } from './events'
import SubmitBtn from './SubmitBtn'

const Footer = ({ isModule }) => {
  const evaluation = useSelector((state) => state.eEvaluation, shallowEqual)
  const {
    setting: { displayQuestion },
    page,
    totalAssessments,
  } = evaluation
  const answerAmount = useMemo(
    () => handleAnswerAmount(evaluation),
    [evaluation, page]
  )

  return (
    <StyledFooter>
      <Box>
        <ProgressMilestone value={answerAmount} />
        {displayQuestion == 'ALL' ? (
          <SubmitBtn isModule={isModule} />
        ) : (
          <ButtonForOne totalQuestion={totalAssessments} isModule={isModule} />
        )}
      </Box>
    </StyledFooter>
  )
}

export default Footer
