export const initialState = {
  isLoading: false,
  type: 'create',
  userType: '',
  selectActionType: 'enroll',
  information: {
    image: '',
    prefixTH: '',
    firstNameTH: '',
    lastNameTH: '',
    mobileNo: '',
    email: '',
    unitNumber: '',
    tempCode: '',
    division: '',

    institution: '',
    between: '',
    education: '',
    branch: '',
    educational: [
      {
        institution: '',
        between: '',
        education: '',
        branch: '',
        GPA: '',
      },
    ],
  },
  history: [],
  selectedList: [],
}
