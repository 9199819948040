import React from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { handleCancelClass, handleCancelCourse } from './events'
import { StyledButtonCert } from './Styled'

const OutlinedButton = ({ data }) => {
  const history = useHistory()
  const isLg = isMediaWidthMoreThan('lg')
  const trainingType = _.get(data, 'trainingType', '')
  const status = _.get(data, 'classStatus', '')
  const classUuid = _.get(data, 'trainingPlanUuid', '')
  const classLearnerUuid = _.get(data, 'classLearnerUuid', '')
  const eLearningCourseBatchLearnerUuid = _.get(
    data,
    'courseBatchLearnerUuid',
    ''
  )
  const isCanCancelClass = _.get(data, 'isCanCancelClass', false)
  const isHaveCert = _.get(data, 'isHaveCert', false)
  const isCanDownloadCert = _.get(data, 'isCanDownloadCert', false)
  let gradeUrl = `/my-class/registration-detail/grades/${classUuid}`
  if (trainingType === 'E-LEARNING') {
    gradeUrl = `/my-class/e-learning-registration-detail/grades/${eLearningCourseBatchLearnerUuid}`
  }
  const dispatch = useDispatch()

  switch (status) {
    case 'CLASS_CLOSED_PASS':
    case 'TRAINING_CLOSED_PASS':
      if (!isHaveCert) return <></>
      return (
        <StyledButtonCert
          size={isLg ? 'l' : 'm'}
          variant="outlined"
          color="secondary"
          data-testid="btn-cert"
          disabled={!isCanDownloadCert}
          isCanDownloadCert={isCanDownloadCert}
          onClick={() => history.push(gradeUrl)}
        >
          <img
            width={20}
            height={20}
            src={
              isCanDownloadCert
                ? '/icon/ic_certificate.svg'
                : '/icon/ic_certificate_disable.svg'
            }
          />
          {isCanDownloadCert ? 'ประกาศนียบัตร' : 'หมดอายุแล้ว'}
        </StyledButtonCert>
      )
    default:
      if (!isCanCancelClass) return <></>
      return (
        <Button
          sx={{ px: '11px', minWidth: 120, height: '100%' }}
          size={isLg ? 'l' : 'm'}
          variant="outlined"
          color="secondary"
          data-testid="btn-cancel"
          fullWidth
          onClick={() => {
            if (trainingType === 'E-LEARNING') {
              dispatch(handleCancelCourse(eLearningCourseBatchLearnerUuid))
            } else {
              dispatch(handleCancelClass(classLearnerUuid, classUuid))
            }
          }} // no dialog
        >
          ยกเลิกการสมัคร
        </Button>
      )
  }
}
export default OutlinedButton
