import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'

const CourseAssignee = ({ course }) => {
  const assignees = _.get(course, 'assignees', [])

  if (_.isEmpty(assignees)) return <></>
  return (
    <Box>
      {assignees.slice(0, 2).map((data, index) => {
        const name = _.get(data, 'name', '')
          ?.replace('นางสาว', '')
          ?.replace('นาง', '')
          ?.replace('นาย', '')
          ?.trim()
        return (
          <Typography
            key={index}
            sx={{ textAlign: 'start' }}
            variant="body2"
            color="text.secondary"
          >
            {name}
          </Typography>
        )
      })}
    </Box>
  )
}

export default CourseAssignee
