import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getQueryParams } from 'src/utils/lib'
import Background from 'src/components/Preview/Background'
import { fetchETesting } from '../events'
import Header from './Header'
import Footer from './Footer'
import Questions from './Questions'
import Result from './Result'
import TimeUp from './TimeUp'

const Testing = ({ isReview, isModule }) => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const trainingPlan = getQueryParams('class')
  const payload = { uuid, trainingPlan, onTesting: !isReview }
  const section = useSelector((state) => state.eTesting.section, shallowEqual)

  useEffect(() => {
    dispatch(fetchETesting({ payload, isReview, isModule }))
  }, [])

  return (
    <>
      <Header isModule={isModule} />
      <Background withHeader withFooter={isModule}>
        {section == 'TESTING' && <TestingForm />}
        {section == 'RESULT' && <Result isModule={isModule} />}
        {section == 'TIMEUP' && <TimeUp />}
      </Background>
    </>
  )
}

export default Testing

const TestingForm = () => {
  return (
    <>
      <Questions />
      <Footer />
    </>
  )
}
