import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { manageContentLogin } from 'src/utils/apiPath'

export const getContentLogin = createAsyncThunk(
  'login/getContentLogin',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(manageContentLogin)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
