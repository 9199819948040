import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledPreviewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(3),
}))

export const StyledPreviewContent = styled(Box)(() => ({
  margin: 'auto',
  width: '100%',
  maxWidth: 1216,
}))

export const StyledPreviewImage = styled('img')(() => ({
  width: '100%',
  height: 456,
  borderRadius: 12,
  objectFit: 'cover',
}))

export const StyledNoPreviewImage = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 456,
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme?.palette?.blue?.oceanBlue,
}))
