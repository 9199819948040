import _ from 'lodash'
import {
  setSelectedLesson,
  setVideoState,
  startLessonLoading,
  stopLessonLoading,
  stopVideoLoading,
} from 'src/redux/slices/eLearning/learning'
import { getLearningState, handleUpdateLogPerLesson } from '../../../events'

export const handleWindowBlur = () => (dispatch) => {
  const handleClickOutside = () => {
    const { selectedLesson } = getLearningState()
    const videoState = _.get(selectedLesson, 'videoState', {})
    if (!videoState.playing) return

    dispatch(setVideoState({ playing: false }))
  }

  window.addEventListener('blur', handleClickOutside)
  return () => {
    window.removeEventListener('blur', handleClickOutside)
  }
}

export const handleVideoReady = (videoPlayerRef) => (dispatch) => {
  const { selectedLesson } = getLearningState()
  const duration = _.get(selectedLesson, 'duration', 0)
  const videoState = _.get(selectedLesson, 'videoState', {})
  const playedSeconds = _.get(videoState, 'playedSeconds', 0)
  const played = playedSeconds / duration
  const timeToStart = _.isNil(playedSeconds) ? 0 : playedSeconds
  videoPlayerRef.current.seekTo(timeToStart, 'seconds')
  dispatch(stopVideoLoading())
  dispatch(setVideoState({ playing: false, played }))
}

export const progressHandler = (state) => (dispatch) => {
  if (state.loaded) dispatch(setVideoState({ ...state }))

  const { selectedLesson } = getLearningState()
  const videoState = _.get(selectedLesson, 'videoState', {})
  const playedSeconds = _.get(videoState, 'playedSeconds', 0)

  const roundSec = Number(playedSeconds.toFixed())
  // save seconds of video every ten secs
  if (roundSec !== 0 && roundSec % 10 === 0) {
    dispatch(handleUpdateLogPerLesson())
  }
}

export const handleEnded = () => async (dispatch) => {
  const { isAutoNextLesson, selectedLesson, lessons } = getLearningState()
  dispatch(setVideoState({ playing: false, isFinished: true }))
  await dispatch(handleUpdateLogPerLesson(true))

  if (isAutoNextLesson) {
    const lIndex = _.get(selectedLesson, 'lessonNo', 1) - 1
    const mIndex = _.get(selectedLesson, 'no', 1) - 1
    const nextModule = _.get(lessons[lIndex], `modules[${mIndex + 1}]`, null)
    dispatch(startLessonLoading())
    setTimeout(() => {
      if (!_.isEmpty(nextModule)) {
        dispatch(setSelectedLesson(nextModule))
      } else {
        const nextLessonModule = _.get(lessons[lIndex + 1], 'modules[0]', null)
        if (!_.isEmpty(nextLessonModule)) {
          dispatch(setSelectedLesson(nextLessonModule))
        }
      }
      dispatch(stopLessonLoading())
    }, 500)
  }
}
