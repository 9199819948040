import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { answerWording } from 'src/constants/eEvaluation'
import {
  StyledImagePreview,
  StyledQuestionCard,
  StyledVideoPreview,
} from 'src/components/Preview/QuestionCard/Styled'
import Choice from './Choice'
import { handleSelector } from './events'

const QuestionCard = (props) => {
  const { keyQuestion, keySub } = props
  const question = useSelector(
    (state) => handleSelector(state, props),
    shallowEqual
  )
  const { url, mediaType } = question
  return (
    <StyledQuestionCard variant="question">
      <Question question={question} />
      {url &&
        (mediaType === 'VIDEO' ? (
          <StyledVideoPreview controls url={url} />
        ) : (
          <StyledImagePreview
            src={`${window.__env__.REACT_APP_FILE_URL}${url}`}
          />
        ))}
      <Choice keyQuestion={keyQuestion} keySub={keySub} />
    </StyledQuestionCard>
  )
}

export default QuestionCard

export const Question = ({ question }) => {
  const {
    sequence,
    title,
    type,
    setTypeOfAnswers,
    typeOfAnswers,
    numberOfAnswers,
    subSequence,
  } = question
  return (
    <Box sx={{ width: '100%', display: 'flex', gap: 1 }}>
      <Typography variant="h6">
        {sequence}.{subSequence ?? ''}
      </Typography>
      <Typography sx={{ wordBreak: 'break-word' }} variant="h6">
        {title}{' '}
        {type == 'MULTIPLE' && (
          <span style={{ fontWeight: 400, whiteSpace: 'pre' }}>
            {setTypeOfAnswers
              ? `(${answerWording[typeOfAnswers]} ${numberOfAnswers} ข้อ)`
              : `(${answerWording.NONE})`}
          </span>
        )}
      </Typography>
    </Box>
  )
}
