import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { moduleCompletedStatus } from 'src/redux/slices/eLearning/learning/events'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel'
import { StyledProgressCard } from './Styled'

const ClassProgress = () => {
  const courseLogLearnerPerLessons = useSelector(
    (state) => _.get(state.registration.data, 'courseLogLearnerPerLessons', []),
    shallowEqual
  )
  const totalModule = courseLogLearnerPerLessons.length
  const passModule = useMemo(() => {
    const passList = courseLogLearnerPerLessons.filter((log) =>
      moduleCompletedStatus.includes(log.data.result)
    )
    return passList.length
  }, [courseLogLearnerPerLessons])
  const progress = useMemo(() => (passModule / totalModule) * 100, [passModule])
  const isNotSm = isMediaWidthMoreThan('sm')

  return (
    <StyledProgressCard>
      <CircularProgressWithLabel
        value={progress || 0}
        variant={isNotSm ? 'h4' : 'h6'}
        fontSize="38px"
        size={isNotSm ? 124 : 168}
      />

      <Typography variant={isNotSm ? 'h4' : 'h5'}>
        <Typography variant="span" color="success.main">
          ผ่าน
        </Typography>{' '}
        {passModule} จาก {totalModule} โมดูล
      </Typography>
    </StyledProgressCard>
  )
}

export default ClassProgress
