import useLocalStorage from '@rehooks/local-storage'
import { useEffect } from 'react'
import { useAuth } from "react-oidc-context"
import _ from 'lodash'

const LoggingIn = () => {
  const [type] = useLocalStorage('type')
  const auth = useAuth()
  const user = localStorage.getItem('user')
  const [previousPath] = useLocalStorage('previousPath')

  useEffect(() => {
    if (!user) {
      let previous = !_.isEmpty(previousPath) ? previousPath : '/'

      if(previousPath?.indexOf("/?state") === 0){
        previous = '/'
      }

      if(type === 'Staff' && window.__env__.ENV !== 'DEV'){
        previous = ''
      }

      const redirectTo = `${window.__env__.REACT_APP_APP_URL}${previous}`
      auth.signinRedirect({redirect_uri: redirectTo})
    } else {
      window.location = '/'
    }
  }, [])

  return null
}

export default LoggingIn
