import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(14),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(11),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(23),
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}))

export const BoxCard = styled(Card)(({ theme, custom }) => ({
  width: '100%',
  border: `1px solid ${theme?.palette?.blue?.blueBorder}`,
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    height: custom ? 220 : 'auto',
  },
}))

export const BoxGroupContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  // marginBottom: theme.spacing(10),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

export const BoxLabel = styled(Box)(({ theme, isTop, isFirst, isView }) => {
  const margin = isView ? 2 : 0
  return {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    marginTop: theme.spacing(isTop ? 3 : margin),
    [theme.breakpoints.down('md')]: {
      wordBreak: 'break-all',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      wordBreak: 'unset',
      marginTop: theme.spacing(isFirst || isTop ? 3 : margin),
    },
  }
})

export const BoxContentLabel = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const BoxGroupLeft = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(0),
  },
}))

export const BoxContentRight = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(4),
  paddingBottom: theme.spacing(3),
}))

export const BoxInput = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
}))
