import React from 'react'
import Typography from '@mui/material/Typography'
import _ from 'lodash'

const ActionPhoneNumber = ({ row, sx }) => {
  const number = _.get(row, 'mobileNo', '')
  const formatNumber = number
    ?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    ?.replace(/ /g, '-')
  return <Typography sx={sx}>{formatNumber}</Typography>
}

export default ActionPhoneNumber
