import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { StyledTableCell, StyledTableRow } from './Styled'
import { StyledCheckbox } from '../ResultTable/Styled'
import { Column, Row } from 'src/components/Layout/Styled'
import { convertDateShortTh } from '../../events'
import { handleAvailableRow, handleSelect } from '../ResultTable/event'
import { Typography } from '@mui/material'
import ChairAltOutlinedIcon from '@mui/icons-material/ChairAltOutlined'
import EventIcon from '@mui/icons-material/Event'
import ScheduleIcon from '@mui/icons-material/Schedule'
import BusinessIcon from '@mui/icons-material/Business'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined'

const TableBodyRowMobile = ({ index }) => {
  const dispatch = useDispatch()
  const { round, selectedRounds, examiners } = useSelector(
    (state) => ({
      round: state.eExamination.roundList[index],
      selectedRounds: state.eExamination.selectedRounds,
      examiners: state.eExamination.examiners,
    }),
    shallowEqual
  )
  const $available =
    Boolean(
      _.find(selectedRounds, (selected) => {
        return selected.uuid == round.uuid
      })
    ) ||
    handleAvailableRow(
      examiners.length,
      round.available,
      round.date,
      round.startTime,
      round.disabled
    )
  return (
    <StyledTableRow $available={$available}>
      <StyledTableCell>
        <StyledCheckbox
          $available={$available}
          checked={Boolean(
            _.find(selectedRounds, (selected) => {
              return selected.uuid == round.uuid
            })
          )}
          onChange={(e) => {
            if ($available) {
              dispatch(handleSelect({ check: e.target.checked, value: round }))
            }
          }}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        <Column sx={{ rowGap: 1 }}>
          <Column sx={{ rowGap: 0.5 }}>
            <Row>
              <Typography variant="body2b" color="primary.main">
                จำนวนที่ว่าง
              </Typography>
            </Row>
            <Row>
              <ChairAltOutlinedIcon
                sx={{
                  marginRight: '5px',
                  marginBottom: '-5px',
                  color: '#0000008A',
                  fontSize: 18,
                }}
              />
              <Typography
                variant="body2b"
                color={
                  round.available > 0 && round.status !== 'CANCEL'
                    ? 'success.dark'
                    : 'error.main'
                }
              >
                {round.status === 'CANCEL'
                  ? 'ยกเลิก'
                  : round.available > 0
                  ? `ว่าง ${round.available}`
                  : 'เต็ม'}
              </Typography>
            </Row>
          </Column>
          <Detail
            label="วันที่สอบ"
            icon={
              <EventIcon
                sx={{
                  marginRight: '5px',
                  marginBottom: '-5px',
                  color: '#0000008A',
                  fontSize: 18,
                }}
              />
            }
            detail={convertDateShortTh(round.date)}
          />
          <Detail
            label="เวลาสอบ"
            icon={
              <ScheduleIcon
                sx={{
                  marginRight: '5px',
                  marginBottom: '-5px',
                  color: '#0000008A',
                  fontSize: 18,
                }}
              />
            }
            detail={`${round.startTime} - ${round.endTime}`}
          />
          <Detail
            label="สนามสอบ"
            icon={
              <BusinessIcon
                sx={{
                  marginRight: '5px',
                  marginBottom: '-5px',
                  color: '#0000008A',
                  fontSize: 18,
                }}
              />
            }
            detail={round.examinationField}
          />
          <Detail
            label="สถานที่สอบ"
            icon={
              <LocationOnOutlinedIcon
                sx={{
                  marginRight: '5px',
                  marginBottom: '-5px',
                  color: '#0000008A',
                  fontSize: 18,
                }}
              />
            }
            detail={round.examinationLocation}
          />
          <Detail
            label="อื่นๆ"
            icon={
              <LocationCityOutlinedIcon
                sx={{
                  marginRight: '5px',
                  marginBottom: '-5px',
                  color: '#0000008A',
                  fontSize: 18,
                }}
              />
            }
            detail={round.examinationOther}
          />
          <Detail
            label="วันที่เปิด-ปิดรับสมัคร"
            icon={
              <EventIcon
                sx={{
                  marginRight: '5px',
                  marginBottom: '-5px',
                  color: '#0000008A',
                  fontSize: 18,
                }}
              />
            }
            detail={`${convertDateShortTh(round.startDateRegister)} ${
              round.startTimeRegister
            } - ${convertDateShortTh(round.endDateRegister)} ${
              round.endTimeRegister
            }`}
          />
        </Column>
      </StyledTableCell>
    </StyledTableRow>
  )
}
export default TableBodyRowMobile

const Detail = ({ label, icon, detail }) => {
  return (
    <Column sx={{ rowGap: 0.5 }}>
      <Row>
        <Typography variant="body2b" color="primary.main">
          {label}
        </Typography>
      </Row>
      <Row>
        {icon}
        <Typography variant="body2" color={'text.primary'}>
          {detail}
        </Typography>
      </Row>
    </Column>
  )
}
