import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import styled from '@mui/system/styled'
import { isMediaWidthMoreThan } from './../../../utils/lib'

export const StyledTitle = styled(Typography)(({ align }) => ({
  textAlign: align,
}))

export const StyledDescriptionContent = styled(Box)(({ align }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: alignPosition[align],
}))

export const alignPosition = {
  LEFT: 'start',
  CENTER: 'center',
  RIGHT: 'end',
}

export const StyledButton = styled((props) => (
  <Button variant="contained" {...props} />
))(({ theme }) => ({
  marginTop: theme.spacing(5),
}))

export const responsive = (lg, md, sm) => {
  const isMediaLg = isMediaWidthMoreThan('lg')
  const isMediaMd = isMediaWidthMoreThan('sm')
  if (isMediaLg) return lg
  else {
    if (isMediaMd) return md
    else return sm
  }
}
