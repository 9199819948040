import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getQueryParams } from 'src/utils/lib'
import Background from 'src/components/Preview/Background'
import { fetchEEvaluation } from '../events'
import Header from './Header'
import Footer from './Footer'
import Questions from './Questions'
import Result from './Result'
import { fetchEvaluate } from './events'

export const EvaluateForm = ({ isModule }) => {
  return (
    <>
      <Questions />
      <Footer isModule={isModule} />
    </>
  )
}

const Evaluate = ({ isModule }) => {
  const { id: uuid } = useParams()
  const trainingPlan = getQueryParams('class')
  const courseTestAndEva = getQueryParams('eva')
  const payload = { uuid, trainingPlan, courseTestAndEva }
  const dispatch = useDispatch()
  const { section, result } = useSelector(
    (state) => ({
      section: state.eEvaluation.section,
      result: state.eEvaluation.result,
    }),
    shallowEqual
  )
  const eEvaluation = useMemo(() => result, [result])

  useEffect(() => {
    dispatch(fetchEEvaluation(payload, isModule))
  }, [])

  useEffect(() => {
    dispatch(fetchEvaluate(eEvaluation))
  }, [eEvaluation])

  return (
    <>
      {!isModule && <Header />}
      <Background withHeader withFooter={section == 'RESULT' || isModule}>
        {section == 'EVALUATE' && <EvaluateForm isModule={isModule} />}
        {section == 'RESULT' && <Result />}
      </Background>
    </>
  )
}

export default Evaluate
