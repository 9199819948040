import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { AssessmentCard, QuestionAssessmentCard } from './DisplayCard'
import { StyledContainer } from './Styled'

export const Questions = () => {
  const { displayQuestion, page, assessmentList } = useSelector(
    (state) => ({
      displayQuestion: state.eEvaluation.setting.displayQuestion,
      page: state.eEvaluation.page,
      assessmentList: state.eEvaluation.assessmentList,
    }),
    shallowEqual
  )

  if (assessmentList.length == 0) return <></>

  switch (displayQuestion) {
    case 'ALL':
      return (
        <StyledContainer>
          {assessmentList.map((assessment, index) => {
            if (assessment.keyField == 'questions') {
              return (
                <QuestionAssessmentCard
                  key={index}
                  assessmentKey={assessment}
                />
              )
            } else {
              return <AssessmentCard key={index} assessmentKey={assessment} />
            }
          })}
        </StyledContainer>
      )
    case 'ONE': {
      const assessment = assessmentList[page - 1]
      if (assessment.keyField == 'questions') {
        return (
          <StyledContainer>
            <QuestionAssessmentCard assessmentKey={assessment} isOne />
          </StyledContainer>
        )
      } else {
        return (
          <StyledContainer>
            <AssessmentCard assessmentKey={assessment} isOne />
          </StyledContainer>
        )
      }
    }
    default:
      return <></>
  }
}

export default Questions
