import React from 'react'
import Button from '@mui/material/Button'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'
import { LoadingButton } from '@mui/lab'

export const StyledButton = styled((props) => (
  <Button {...props} size="l" variant="contained" color="secondary" />
))(({ theme, fixed, isScrolled }) => ({
  width: isScrolled || fixed ? '100%' : 320,
  [theme.breakpoints.down('lg')]: { display: fixed ? 'flex' : 'none' },
}))

export const StyledLoadingButton = styled((props) => (
  <LoadingButton {...props} size="l" variant="contained" color="secondary" />
))(({ theme, fixed, isScrolled }) => ({
  width: isScrolled || fixed ? '100%' : 320,
  [theme.breakpoints.down('lg')]: { display: fixed ? 'flex' : 'none' },
}))

export const StyledLabelContent = styled(Row)(({ theme, fixed }) => ({
  width: 100,
  minWidth: 100,
  color: fixed ? theme.palette.text?.white : theme.palette.text?.secondary,
  gap: theme.spacing(0.5),
  justifyContent: 'center',
  img: { borderRadius: '100%' },
  [theme.breakpoints.down('lg')]: { display: fixed ? 'flex' : 'none' },
}))
