import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledDot = styled(Box)(({ theme, status, color }) => ({
  width: 12,
  height: 12,
  border: status.toString().toLowerCase() === 'deleted' ? '1px solid' : 'unset',
  borderRadius: '100%',
  borderColor:
    status.toString().toLowerCase() === 'deleted'
      ? theme.palette.text?.gray
      : 'unset',
  backgroundColor: color,
}))
