import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { eEvaluation, eLearning, eLearningEEvaluation } from 'src/utils/apiPath'

export const getEEvaluationDetail = createAsyncThunk(
  'eEvaluation/getEEvaluationDetail',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, trainingPlan, courseTestAndEva, eLearningCourse } = payload
      let path = `${eEvaluation}/${uuid}`
      if (trainingPlan) {
        path = `${path}?trainingPlan=${trainingPlan}&courseTestAndEva=${courseTestAndEva}`
      }
      if (eLearningCourse) path = `${eLearning}/${eLearningCourse}${path}`
      const response = await axios.get(path)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postEEvaluationSubmit = createAsyncThunk(
  'eEvaluation/postEEvaluationSubmit',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, body, isModule } = payload
      const response = await axios.post(
        isModule ? `${eLearningEEvaluation}/${uuid}` : `${eEvaluation}/${uuid}`,
        body
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
