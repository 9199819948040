import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { getQueryParams } from 'src/utils/lib'
import { StyledTimerContainer } from './Styled'
import { countdown } from './events'

const Timer = () => {
  const { id: uuid } = useParams()
  const trainingPlan = getQueryParams('class')
  const dispatch = useDispatch()
  const eTesting = useSelector((state) => state.eTesting, shallowEqual)
  const submitProps = { uuid, trainingPlan, eTesting }
  const timeSecondRemaining = _.get(eTesting, 'timeSecondRemaining', 0)
  const hours = Math.floor(timeSecondRemaining / 3600)
  const minutes = Math.floor(timeSecondRemaining / 60)
  const seconds = timeSecondRemaining - minutes * 60
  const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds])
  const time = { hrs, mins, secs }
  let timer = null
  const props = { time, timer, setTime, submitProps }

  useEffect(() => {
    timer = setInterval(() => dispatch(countdown(props)), 1000)
    return () => clearInterval(timer)
  })

  const hrStr = hrs.toString().padStart(2, '0')
  const minStr = mins.toString().padStart(2, '0')
  const secStr = secs.toString().padStart(2, '0')

  return (
    <StyledTimerContainer>
      <Typography variant="body1" color="text.white">
        เหลือเวลา
      </Typography>
      <Typography variant="h6" color="text.white">
        {`${hrStr}:${minStr}:${secStr}`}
      </Typography>
    </StyledTimerContainer>
  )
}

export default Timer
