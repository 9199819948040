import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import Background from 'src/components/Preview/Background'
import Content from 'src/components/Preview/Content'
import { path } from 'src/constants/path'
import { getQueryParams } from 'src/utils/lib'
import {
  StyledNoPreviewImage,
  StyledPreviewContainer,
  StyledPreviewContent,
  StyledPreviewImage,
} from 'src/components/Preview/Styled'
import { fetchEEvaluation, handleError } from './events'

const EEvaluation = ({ isModule }) => {
  const { codeId, version, id: uuid } = useParams()
  const trainingPlan = getQueryParams('class')
  const courseTestAndEva = getQueryParams('eva')
  const payload = { uuid, trainingPlan, courseTestAndEva }
  const { result, isLoading, error } = useSelector(
    (state) => ({
      isLoading: state.loading.isLoading,
      result: state.eEvaluation.result,
      error: state.eEvaluation.error,
    }),
    shallowEqual
  )
  const eEvaluation = useMemo(() => result, [result])
  const isDuplicate = _.get(eEvaluation, 'isDuplicateAnswer', false)
  const isOpen = _.get(eEvaluation, 'isEEvaOpen', false)
  const information = _.get(eEvaluation, 'information', null)
  const coverImage = _.get(information, 'coverImage', undefined)
  const coverText = _.get(information, 'coverText', undefined)
  const pathStandalone = `${path.eEvaluation}/evaluate/${codeId}/${version}/${uuid}`
  const pathClass = `${path.eEvaluation}/evaluate/${uuid}?class=${trainingPlan}&eva=${courseTestAndEva}`
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchEEvaluation(payload, isModule))
  }, [])

  useEffect(() => {
    dispatch(handleError(error))
  }, [error])

  return (
    <Background withFooter>
      {eEvaluation && !isLoading && (
        <StyledPreviewContainer>
          <StyledPreviewContent>
            <Box sx={{ position: 'relative' }}>
              {coverText && (
                <Typography variant="coverText" color="primary">
                  {coverText}
                </Typography>
              )}
              {coverImage && (
                <StyledPreviewImage
                  src={`${window.__env__.REACT_APP_FILE_URL}${coverImage}`}
                />
              )}
              {coverText && !coverImage && <StyledNoPreviewImage />}
            </Box>
            <Content
              information={information}
              url={trainingPlan ? pathClass : pathStandalone}
              buttonText="เริ่มทำแบบสอบถาม"
              isModule={isModule}
              condition={{
                isDuplicate,
                isOpen,
                conditionText: 'ไม่สามารถทำแบบประเมินได้ กรุณาติดต่อแอดมิน',
              }}
            />
          </StyledPreviewContent>
        </StyledPreviewContainer>
      )}
    </Background>
  )
}

export default EEvaluation
