import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledWrapper = styled(Row)(({ theme }) => ({
  position: 'relative',
  width: 955,
  alignItems: 'start',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueBorder,
  '& > hr': { borderColor: theme.palette?.blue?.blueBorder },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
  },
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  maxWidth: 405,
  position: 'absolute',
  bottom: 24,
  [theme.breakpoints.down('lg')]: {
    position: 'relative',
    bottom: 0,
    maxWidth: '100%',
  },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(5),
  borderColor: theme.palette?.blue?.blueBorder,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(3),
  },
}))
