import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledFooterWrapper = styled(Row)(({ theme }) => ({
  position: 'sticky',
  zIndex: 1,
  bottom: 0,
  height: 82,
  background: theme.palette?.primary?.main,
}))

export const StyledWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: { margin: `0 ${theme.spacing(5)}` },
  [theme.breakpoints.down('sm')]: { margin: `0 ${theme.spacing(2)}` },
}))

export const StyledStepButton = styled(
  (props) => <Button {...props} variant="outlined" size="l" />,
  { shouldForwardProp: (prop) => prop !== 'activeStep' }
)(({ theme, right, activeStep }) => ({
  position: 'absolute',
  right,
  color: theme.palette.text?.white,
  borderColor: theme.palette.text?.white,
  padding: '0 22px',
  ':hover': {
    color: theme.palette.primary?.main,
    backgroundColor: theme.palette.text?.white,
  },
  '&:after': { background: theme.palette.text?.white },
  '&.Mui-disabled': { background: theme.palette.text?.wildSand },
  [theme.breakpoints.down('sm')]: {
    width: activeStep === 0 ? '100%' : 'calc(50% - 5px)',
  },
}))

export const StyledSubmitButton = styled((props) => (
  <Button {...props} variant="contained" color="secondary" size="l" />
))(({ theme }) => ({
  position: 'absolute',
  right: 0,
  padding: '0 22px',
  [theme.breakpoints.down('sm')]: { width: 'calc(50% - 10px)' },
}))
