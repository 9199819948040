import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Column, Row } from 'src/components/Layout/Styled'
import {
  StyledDivider,
  StyledSectionContainer,
  StyledTypography,
} from './Styled'
import { handleDisplayPrerequisite } from './events'

const Condition = () => {
  const prerequisite = useSelector(
    (state) =>
      _.get(state.classDetail.data, 'courseDetail.coursePrerequisiteData', []),
    shallowEqual
  )
  return (
    <StyledSectionContainer>
      {prerequisite.map((item, index) => (
        <Box key={index}>
          <Typography variant="body1b" color="text.secondary">
            {`${index !== 0 ? 'หรือ' : ''} เงื่อนไข ${index + 1}`}
          </Typography>

          <ContentFieldConditions key={index} data={item} />
        </Box>
      ))}
    </StyledSectionContainer>
  )
}

export default Condition

export const Contents = ({ condition, label }) => {
  return (
    <Row sx={{ pl: 2 }}>
      <Column gap={0.5}>
        {condition && (
          <StyledDivider textAlign="left">
            <Typography color="text.lightGray">{condition}</Typography>
          </StyledDivider>
        )}
        <Row gap={0.5}>
          <StyledTypography dangerouslySetInnerHTML={{ __html: label }} />
        </Row>
      </Column>
    </Row>
  )
}

export const ContentFieldConditions = ({ data }) => {
  const prerequisiteList = handleDisplayPrerequisite(data)
  return (
    <Column gap={0.5}>
      {prerequisiteList.map((andCondition) =>
        andCondition.map((item, key) => (
          <Contents key={key} condition={item.condition} label={item.label} />
        ))
      )}
    </Column>
  )
}
