import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconHistory from '@mui/icons-material/HistoryTwoTone'
import { shallowEqual, useSelector } from 'react-redux'

import Table from '../../../../components/Table2'
import Search from '../../../../components/Table2/Search'

import History from '../ExaminationHistory'

import { BoxCard, BoxDataEmpty, BoxHeader } from './Styled'
import { useDispatch } from 'react-redux'
import { setChangeRowsPerPage } from 'src/redux/slices/basicTable'

const Index = () => {
  const [isOpen, setOpen] = useState(false)
  const dispatch = useDispatch()

  const stateTable = useSelector((state) => state.table2, shallowEqual)
  const { rows } = stateTable.table

  const handleCloseDrawer = () => {
    setOpen(false)
    const rowsPerPage = {
      label: '' + 20,
      value: 20,
    }
    const limit = 20
    dispatch(setChangeRowsPerPage({ rowsPerPage, limit }))
  }

  return (
    <BoxCard>
      <BoxHeader>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant="h6" color="primary.main">
            ประวัติการสมัครสอบ
          </Typography>
          <IconHistory
            color="action.active"
            sx={{ width: 18, height: 18, cursor: 'pointer' }}
            onClick={() => setOpen(true)}
          />
        </Box>
        <Search />
      </BoxHeader>
      <Box>
        {!rows.length && (
          <BoxDataEmpty>
            <Box>
              <img src="/icon/emptyTable.svg" />
            </Box>
            <Typography variant="body1" color="text.lightGray">
              ไม่มีประวัติการสอบ
            </Typography>
          </BoxDataEmpty>
        )}

        {!!rows.length > 0 && <Table />}
      </Box>

      <History isOpen={isOpen} onCloseDrawer={handleCloseDrawer} />
    </BoxCard>
  )
}

export default Index
