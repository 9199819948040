import React, { Fragment, useMemo } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextInput from 'src/components/Input/TextInput'
import {
  StyledImagePreview,
  StyledChoiceContainer,
} from 'src/components/Preview/QuestionCard/Styled'
import {
  handleMultipleChange,
  handleFreeTextChange,
  handleSingleChange,
  isOtherInputShow,
  handleDisableCheckbox,
  handleSelector,
  handleOtherChange,
  handleDefaultValue,
} from './events'

const Choice = (props) => {
  const { keyQuestion } = props
  const dispatch = useDispatch()
  const question = useSelector(
    (state) => handleSelector(state, props),
    shallowEqual
  )
  const uuid = _.get(question, 'uuid', '')
  const choices = _.get(question, 'choices', [])
  const type = _.get(question, 'type', 'FREE_TEXT')
  const key = _.get(question, 'key', 0)
  const answers = useSelector(
    (state) => handleDefaultValue(state.eEvaluation, uuid),
    shallowEqual
  )
  const eventProps = { question, answers }
  const debouncedFreeTextChange = useMemo(
    () => _.debounce((e) => dispatch(handleFreeTextChange(e, uuid)), 1000),
    [uuid]
  )

  const debouncedOtherChange = useMemo(
    () =>
      _.debounce(
        (e, choice) =>
          dispatch(handleOtherChange(e, uuid, choice, keyQuestion)),
        1000
      ),
    [uuid]
  )

  const defaultValue = useMemo(() => _.get(answers[0], 'freetextAnswer', ''), [])

  return (
    <StyledChoiceContainer>
      {type == 'FREE_TEXT' ? (
        <TextInput
          type="basic"
          multiline
          rows={2}
          maxlength={3000}
          isShowTextError={false}
          defaultValue={defaultValue}
          id={`free-text-${keyQuestion}`}
          onChange={debouncedFreeTextChange}
        />
      ) : (
        <RadioGroup
          onChange={(e) => dispatch(handleSingleChange(e, props, eventProps))}
        >
          {choices.map((choice, index) => (
            <Fragment key={index}>
              {type == 'SINGLE' && (
                <FormControlLabel
                  sx={{ mb: 1, wordBreak: 'break-word' }}
                  control={<Radio />}
                  name={choice.answerType}
                  value={choice.uuid}
                  label={choice.title}
                  checked={choice.uuid == _.get(answers[0], 'answerUuid', '')}
                  data-testid={`choice-${keyQuestion}-${index}`}
                />
              )}

              {type == 'MULTIPLE' && (
                <FormControlLabel
                  sx={{ mb: 1, wordBreak: 'break-word' }}
                  control={
                    <Checkbox
                      value={choice.uuid}
                      name={choice.answerType}
                      disabled={handleDisableCheckbox(
                        question,
                        choice.uuid,
                        answers
                      )}
                      onChange={(e) =>
                        dispatch(handleMultipleChange(e, uuid, key))
                      }
                      inputProps={{
                        'data-testid': `choice-${keyQuestion}-${index}`,
                      }}
                    />
                  }
                  label={choice.title}
                  checked={answers.some(
                    (item) => item.answerUuid == choice.uuid
                  )}
                />
              )}

              {isOtherInputShow(choice, answers) && (
                <TextInput
                  type="basic"
                  multiline
                  rows={2}
                  maxlength={3000}
                  name={choice.uuid}
                  placeholder="โปรดระบุ"
                  isShowTextError={false}
                  id={`other-${keyQuestion}`}
                  boxSx={{ ml: 4, mt: '-10px' }}
                  defaultValue={
                    answers.find((item) => item.answerUuid == choice.uuid)
                      .otherAnswer
                  }
                  onChange={(e) => debouncedOtherChange(e, choice)}
                />
              )}

              {choice.mediaUrl && (
                <StyledImagePreview
                  choice
                  src={`${window.__env__.REACT_APP_FILE_URL}${choice.mediaUrl}`}
                />
              )}
            </Fragment>
          ))}
        </RadioGroup>
      )}
    </StyledChoiceContainer>
  )
}

export default Choice
