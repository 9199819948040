import React, { Fragment, useMemo } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import {
  StyledImagePreview,
  StyledChoiceContainer,
  StyledInfoAlert,
} from 'src/components/Preview/QuestionCard/Styled'
import TextInput from 'src/components/Input/TextInput'
import {
  handleMultipleChange,
  handleDefaultValue,
  handleSingleChange,
  handleFreeTextChange,
  handleDisableCheckbox,
} from './events'
import { StyledChoice } from './Styled'
import Sequence from './Sequence'
import Matching from './Matching'
import FillChoice from './FillChoice'
import DragAndDrop from './DragAndDrop'
import TrueFalse from './TrueFalse'

const Choice = ({ keyQuestion }) => {
  const dispatch = useDispatch()
  const {
    question,
    onShowAnswer,
    onReviewAnswer,
    correctAnswers,
    questionResult,
    page,
  } = useSelector(
    (state) => ({
      question: state.eTesting.questions.find(
        (item) => item.key == keyQuestion
      ),
      correctAnswers: state.eTesting.correctAnswers,
      onShowAnswer: state.eTesting.onShowAnswer,
      onReviewAnswer: state.eTesting.onReviewAnswer,
      questionResult: state.eTesting.questionResult,
      page: state.eTesting.page,
    }),
    shallowEqual
  )
  const uuid = _.get(question, 'uuid', '')
  const type = _.get(question, 'questionType', 'FREE_TEXT')
  const choices = _.get(question, 'answers', [])
  const answers = useSelector(
    (state) => handleDefaultValue(state.eTesting, uuid),
    shallowEqual
  )
  const correctAnswer = correctAnswers.find((item) => item.questionUuid == uuid)
  const onViewState = onShowAnswer || onReviewAnswer
  const resultProps = { correctAnswer, onViewState, questionResult }
  const debouncedFreeTextChange = useMemo(
    () => _.debounce((e) => dispatch(handleFreeTextChange(e, uuid)), 1000),
    [uuid]
  )
  const defaultValue = useMemo(
    () => _.get(answers, '[0].freetextAnswer', ''),
    [page]
  )

  if (type === 'SEQUENCE') {
    return (
      <StyledChoiceContainer>
        <Sequence uuid={uuid} onViewState={onViewState} />
      </StyledChoiceContainer>
    )
  }

  if (type === 'MATCHING') {
    return (
      <StyledChoiceContainer>
        <Matching uuid={uuid} question={question} onViewState={onViewState} />
      </StyledChoiceContainer>
    )
  }

  if (type === 'TRUE_FALSE') {
    return (
      <StyledChoiceContainer>
        <TrueFalse uuid={uuid} question={question} resultProps={resultProps} />
      </StyledChoiceContainer>
    )
  }

  if (type === 'FILL_IN_BLANK_CHOICE') {
    return (
      <StyledChoiceContainer>
        <FillChoice uuid={uuid} question={question} resultProps={resultProps} />
      </StyledChoiceContainer>
    )
  }

  if (type === 'FILL_IN_BLANK_DRAG') {
    return (
      <StyledChoiceContainer>
        <DragAndDrop uuid={uuid} question={question} resultProps={resultProps} />
      </StyledChoiceContainer>
    )
  }

  return (
    <StyledChoiceContainer>
      {type == 'FREE_TEXT' ? (
        <>
          <TextInput
            type="basic"
            multiline
            rows={2}
            placeholder="ตอบ"
            isShowTextError={false}
            readOnly={onViewState}
            defaultValue={defaultValue}
            id={`free-text-${keyQuestion}`}
            onChange={debouncedFreeTextChange}
          />
          {onViewState && (
            <FreeTextInfoAlert data-testid={`alert-text-${keyQuestion}`} />
          )}
        </>
      ) : (
        <RadioGroup onChange={(e) => dispatch(handleSingleChange(e, uuid))}>
          {choices.map((choice, index) => {
            const checked = onReviewAnswer
              ? choice.isLearnerAnswer
              : answers.some((item) => item.answerUuid == choice.uuid)
            return (
              <Fragment key={index}>
                {type == 'SINGLE' && (
                  <StyledChoice
                    control={<Radio />}
                    value={choice.uuid}
                    label={choice.title}
                    checked={checked}
                    resultProps={{ ...resultProps, choice }}
                    data-testid={`choice-${keyQuestion}-${index}`}
                  />
                )}

                {type == 'MULTIPLE' && (
                  <StyledChoice
                    control={
                      <Checkbox
                        disabled={handleDisableCheckbox(
                          question,
                          answers,
                          choice.uuid
                        )}
                        value={choice.uuid}
                        onChange={(e) =>
                          dispatch(handleMultipleChange(e, uuid))
                        }
                        inputProps={{
                          'data-testid': `choice-${keyQuestion}-${index}`,
                        }}
                      />
                    }
                    label={choice.title}
                    checked={checked}
                    resultProps={{ ...resultProps, choice }}
                  />
                )}

                {choice.mediaUrl && (
                  <StyledImagePreview
                    choice
                    src={`${window.__env__.REACT_APP_FILE_URL}${choice.mediaUrl}`}
                  />
                )}
              </Fragment>
            )
          })}
        </RadioGroup>
      )}
    </StyledChoiceContainer>
  )
}

export default Choice

export const FreeTextInfoAlert = () => {
  return (
    <StyledInfoAlert>
      <InfoOutlined color="warning" />
      <Typography variant="body1b">รอการตรวจจากวิทยากร</Typography>
    </StyledInfoAlert>
  )
}
