import React, { useState } from 'react'
import _ from 'lodash'
import TruncateMarkup from 'react-truncate-markup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Column, Row } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import LikeButton from '../../../components/LikeButton'
import { handleLikeCourse } from '../events'
import Tabs from './Tabs'

const LeftContent = () => {
  const [isShow, setShow] = useState(false)
  const { data, isLikeLoading } = useSelector(
    (state) => ({
      data: state.eLearningDetail.data,
      isLikeLoading: state.eLearningDetail.isLikeLoading,
    }),
    shallowEqual
  )
  const description = _.get(data, 'description', '-') || '-'
  const width = { lg: 662, md: '100%' }
  const isLike = _.get(data, 'isLike', false)
  const isNotSm = isMediaWidthMoreThan('sm')
  const isLg = isMediaWidthMoreThan('lg')
  const fontVariant = isLg || !isNotSm ? 'body1' : 'avatarletter'
  const dispatch = useDispatch()
  return (
    <Column sx={{ minWidth: width, width, gap: 3 }}>
      <Column gap={1}>
        <Row sx={{ width: '100%', justifyContent: 'space-between' }}>
          <Typography variant={isNotSm ? 'h5' : 'body1b'} color="primary">
            รายละเอียดหลักสูตร
          </Typography>

          {isNotSm && (
            <LikeButton
              isLike={isLike}
              isLoading={isLikeLoading}
              onClick={() => dispatch(handleLikeCourse())}
            />
          )}
        </Row>
        {isShow ? (
          <Typography variant={fontVariant} color="text.secondary">
            {description}
          </Typography>
        ) : (
          <TruncateMarkup
            lines={5}
            ellipsis={() => readMoreEllipsis(setShow, isLg)}
          >
            <Typography variant={fontVariant} color="text.secondary">
              {description}
            </Typography>
          </TruncateMarkup>
        )}
      </Column>

      <Tabs />
    </Column>
  )
}

export default LeftContent

export const readMoreEllipsis = (setShow, isLg) => (
  <span>
    ...{' '}
    <Typography
      variant={isLg ? 'body1b' : 'h6'}
      color="primary"
      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
      onClick={() => setShow(true)}
    >
      ดูเพิ่ม
    </Typography>
  </span>
)
