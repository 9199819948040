import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import IconButton from '@mui/material/IconButton'

import { StyledBox } from './Styled'
import { setOnEdit, setOnView } from '../../../redux/slices/table'

const Action = ({ row, rowIndex, minWidth, condition }) => {
  const dispatch = useDispatch()
  const { onDelete } = useSelector((state) => state.table)

  let viewUrl = `${condition.viewPath}/${row.uuid}`
  const editUrl = `${condition.editPath}/${row.uuid}`
  if (!_.isEmpty(_.get(row, 'urlView', ''))) viewUrl = _.get(row, 'urlView', '')

  return (
    <StyledBox minWidth={minWidth}>
      {!condition.hideView && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnView(viewUrl))}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}

      {!condition.hideEdit && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnEdit(editUrl))}
        >
          <EditTwoTone color="action" />
        </IconButton>
      )}
      {!condition.hideDelete && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-delete-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onDelete(row, rowIndex)}
        >
          <DeleteTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default Action
