import React, { useMemo, useState } from 'react'
import CustomTabs from 'src/components/Tabs'
import ContentNoClass from 'src/components/ContentNoClass'
import CourseCard from 'src/components/CourseCard'
import { SECTION_KEY } from 'src/components/CourseCard/model'
import { StyledContainerCard, StyledTabBox, StyledTabContent } from './Styled'
import { handleCourseData } from './events'

const TabContainer = ({ tabs }) => {
  const [value, setValue] = useState(1)
  const courseTab = tabs.map((item, index) => ({
    label: item.tabTitle,
    value: index + 1,
  }))
  const courseList = useMemo(() => handleCourseData(tabs, value), [tabs, value])

  return (
    <StyledTabBox>
      <StyledTabContent>
        <CustomTabs
          listTab={courseTab}
          value={value}
          onChange={(tab) => setValue(tab)}
        />
      </StyledTabContent>
      <StyledContainerCard>
        {courseList.map((course, index) => {
          const keySection = course.courseType
            ? SECTION_KEY.E_LEARNING
            : SECTION_KEY.COURSE
          return (
            <CourseCard key={index} course={course} keySection={keySection} />
          )
        })}
        {courseList.length == 0 && <ContentNoClass />}
      </StyledContainerCard>
    </StyledTabBox>
  )
}

export default TabContainer
