import _ from 'lodash'
import {
  setLoadMore,
  startLoading,
  startLoadMore,
  stopLoading,
  stopLoadMore,
} from 'src/redux/slices/course/detail'
import { postCourseClassList } from 'src/services/course/detail'
import { isScrolledIntoElement } from 'src/utils/lib'
import { isLearningPath } from '../events'

export const handleCourseClassList = (uuid, filterBody) => async (dispatch) => {
  const payload = { uuid, body: filterBody }
  dispatch(startLoading())
  await dispatch(postCourseClassList(payload))
  dispatch(stopLoading())
}

export const handleLoadMore = () => (dispatch) => {
  const footer = document.getElementById('page-footer')
  const isFooterAppeared = isScrolledIntoElement(footer)
  if (isFooterAppeared) dispatch(setLoadMore())
}

export const handleSeeMore = (uuid, filterBody) => async (dispatch) => {
  if (filterBody.page === 1) return

  const payload = { uuid, body: filterBody }
  dispatch(startLoadMore())
  await dispatch(postCourseClassList(payload))
  dispatch(stopLoadMore())
}

export const handleSeatText = (data) => {
  const canRegister = _.get(data, 'canRegister', false)
  const availableSeats = _.get(data, 'availableSeats', 0)

  if (availableSeats === 0) return { text: 'เต็ม', color: 'error' }

  if (canRegister) {
    return {
      text: isLearningPath() ? availableSeats : `ที่นั่งว่าง ${availableSeats}`,
    }
  } else return { text: '-' }
}
