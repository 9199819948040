import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'

const HtmlContent = ({ field }) => {
  const htmlText = useSelector(
    (state) => _.get(state.classDetail.data, field, ''),
    shallowEqual
  )
  return (
    <Box sx={{ '& div > :first-of-type ': { mt: 0 } }}>
      <div dangerouslySetInnerHTML={{ __html: htmlText }} />
    </Box>
  )
}

export default HtmlContent
