import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'
import CarouselLogin from 'src/components/Carousel/CarouselLogin'
import { CarouselDot, SliderContainer, TextContainer, Overlay } from './Styled'

export const RenderTextHtml = ({ style, variant, labelText, color }) => {
  return (
    <Box sx={style}>
      <Typography
        variant={variant}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& p': {
            margin: '0px',
          },
          '& ul , ol': {
            margin: '0px',
            lineHeight: '12px',
          },
          '& *': {
            color: color ? `${color} !important` : 'auto',
          },
        }}
        dangerouslySetInnerHTML={{
          __html: labelText,
        }}
      />
    </Box>
  )
}

const LoginSlider = () => {
  const loginContent = useSelector((state) => state.login.data, shallowEqual)
  const customStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  }
  if (loginContent.length == 0) return <></>
  return (
    <CarouselLogin
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        return (
          <>
            <CarouselDot
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              isSelected={isSelected}
              index={index}
              aria-label={`${label} ${index + 1}`}
              countContent={loginContent.length}
            ></CarouselDot>
          </>
        )
      }}
    >
      {loginContent.map((login, index) => (
        <SliderContainer
          key={index}
          url={`${window.__env__.REACT_APP_FILE_URL}${login.image}`}
        >
          <Overlay />

          <TextContainer>
            <RenderTextHtml
              variant="h4"
              labelText={_.get(login, 'title', '')}
              color={_.get(login, 'colorTitle', '#FFFFFF')}
              style={{
                '& .MuiTypography-root': {
                  ...customStyle,
                  '-webkit-line-clamp': '1 !important',
                },
              }}
            />
            <RenderTextHtml
              variant="body1"
              labelText={_.get(login, 'description', '')}
              color={_.get(login, 'colorDescription', '#FFFFFF')}
              style={{
                '& .MuiTypography-root': {
                  ...customStyle,
                  '-webkit-line-clamp': '3 !important',
                },
              }}
            />
          </TextContainer>
        </SliderContainer>
      ))}
    </CarouselLogin>
  )
}

export default LoginSlider
