import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { isMediaWidthMoreThan } from './../../../utils/lib'

export const StyledHeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isModule',
})(({ theme, isModule, align = 'center' }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 122,
  marginTop: isModule ? 0 : 72,
  background: theme?.palette?.other?.learnerHeader,
  '& > .MuiBox-root': {
    width: 896,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: align,
  },
  [theme.breakpoints.down('lg')]: {
    height: 90,
    '& > .MuiBox-root': { width: 672 },
  },
  [theme.breakpoints.down('sm')]: {
    '& > .MuiBox-root': { width: 335 },
  },
}))

export const StyledHeaderTitle = styled(Box)(({ theme, section }) => ({
  width: '100%',
  maxWidth: section == 'RESULT' ? 763 : '100%',
  display: 'flex',
  flexDirection: 'column',
  '& > .MuiTypography-root': {
    color: theme.palette?.text?.white,
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: section == 'RESULT' ? 542 : '100%',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: section == 'RESULT' ? 235 : '100%',
  },
}))

export const StyledRoundBox = styled(Box)(({ theme }) => ({
  minWidth: 130,
  textAlign: 'end',
  [theme.breakpoints.down('sm')]: { minWidth: 100 },
}))

export const responsiveText = (lg, md, sm) => {
  const isMediaLg = isMediaWidthMoreThan('lg')
  const isMediaMd = isMediaWidthMoreThan('sm')
  if (isMediaLg) return lg
  else {
    if (isMediaMd) return md
    else return sm
  }
}
