import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import useLocalStorage from '@rehooks/local-storage'
import { useDispatch } from 'react-redux'
import { isMediaWidthMoreThan } from '../../utils/lib'
import { setLogin } from '../../redux/slices/utils/userSlice'
import {
  StyledContainer,
  StyledCard,
  StyledCardLeftContainer,
  StyledCardRightContainer,
  TextContainer,
  StyledStaffContainer,
  RightContainer,
  LeftContainer,
  BoxDisplay,
  BoxImage,
} from './Styled'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LoginSlider from './LoginSlider'
import { fetchLoginContent } from './events'

const Login = () => {
  const [, setType] = useLocalStorage('type')
  const dispatch = useDispatch()
  const isNotSm = isMediaWidthMoreThan('sm')
  const headSize = isNotSm ? 'h3' : 'h4'
  const titleSize = isNotSm ? 'h4' : 'h5'
  const descSize = isNotSm ? 'body1' : 'body2'

  const saveCurrentRealm = (type) => {
    dispatch(setLogin())
    setType(type)
    location.href = '/logging-in'
  }

  useEffect(() => {
    dispatch(fetchLoginContent())
  }, [])

  return (
    <StyledContainer>
      <LeftContainer>
        <LoginSlider />
      </LeftContainer>
      <RightContainer>
        <Box>
          <Typography variant={headSize} color="primary.main">
            เข้าสู่ระบบ
          </Typography>
          <Typography variant={headSize} color="primary.main">
            KTAXA Academy (AMS)
          </Typography>
        </Box>

        <StyledCard gap={2}>
          <StyledCardLeftContainer>
            <img
              alt="agent-icon"
              src="/icon/agent-icon.svg"
              style={{ height: '50px', width: 'auto' }}
            />
            <TextContainer>
              <Typography variant={titleSize} color="primary.main">
                สำหรับตัวแทน KTAXA
              </Typography>
              <Box>
                <Typography variant={descSize} color="primary.main">
                  ทุกช่องทางการจัดจำหน่าย
                </Typography>
                <Typography variant={descSize} color="primary.main">
                  (ใช้ username / password ของ AZ)
                </Typography>
              </Box>
            </TextContainer>
          </StyledCardLeftContainer>
          <StyledCardRightContainer content="flex-end">
            <Button
              size="s"
              variant="contained"
              color="primary"
              onClick={() => saveCurrentRealm('Agent')}
              endIcon={<ArrowForwardIcon />}
              sx={{
                padding: '8px 16px',
                width: 113,
                height: '40px',
              }}
            >
              <Typography variant="button">เข้าสู่ระบบ</Typography>
            </Button>
          </StyledCardRightContainer>
        </StyledCard>

        <StyledCard sx={{ marginTop: '20px', gap: 2 }}>
          <StyledCardLeftContainer>
            <BoxImage>
              <img
                alt="prospect-icon"
                src="/icon/prospect-icon.svg"
                style={{ height: '50px', width: 'auto' }}
              />
              <BoxDisplay isMobile>
                <Link
                  target="_blank"
                  href={`${window.__env__.REACT_APP_KEY_URL}/auth/realms/ams/login-actions/reset-credentials?client_id=ams`}
                  variant="body1"
                  color="primary.main"
                >
                  ลืมรหัสผ่าน?
                </Link>
              </BoxDisplay>
            </BoxImage>
            <TextContainer>
              <Typography variant={titleSize} color="primary.main">
                สำหรับตัวแทน<u>รอรหัส</u>
              </Typography>
            </TextContainer>
          </StyledCardLeftContainer>
          <StyledCardRightContainer content="space-between">
            <BoxDisplay>
              <Link
                target="_blank"
                href={`${window.__env__.REACT_APP_KEY_URL}/auth/realms/ams/login-actions/reset-credentials?client_id=ams`}
                variant="body1"
                color="primary.main"
              >
                ลืมรหัสผ่าน?
              </Link>
            </BoxDisplay>

            <Button
              size="s"
              variant="outlined"
              color="primary"
              onClick={() => saveCurrentRealm('Prospect')}
              endIcon={<ArrowForwardIcon />}
              sx={{ padding: '8px 16px', width: '111px', height: '40px' }}
            >
              <Typography variant="button">
                เข้าสู่ระบบ
              </Typography>
            </Button>
          </StyledCardRightContainer>
        </StyledCard>
        <StyledStaffContainer>
          <Link
            variant="body1"
            color="primary.main"
            onClick={() => saveCurrentRealm('Staff')}
          >
            STAFF คลิกที่นี่
          </Link>
        </StyledStaffContainer>
      </RightContainer>
    </StyledContainer>
  )
}

Login.templateLayout = 'login'

export default Login
