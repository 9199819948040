import { defaultValue } from '../../../constants/eEvaluation'

export const answerEEvaluationObj = {
  questionUuid: '',
  answerUuid: '',
  suggestion: '',
  otherAnswer: '',
  freetextAnswer: '',
  staffUuid: '',
  answerSection: '', //"COURSE_ASSESSMENTS | COURSE_ASSESSMENTS | COURSE_MANAGEMENT_ASSESSMENTS | QUESTIONS"
  isAnswered: false,
}

export const initialState = {
  result: null,
  uuid: null,
  isDuplicate: false,
  section: defaultValue.defaultSection.evaluate,
  page: 1,
  information: {
    coverImage: '',
    coverText: '',
    description: '',
    descriptionPosition: defaultValue.position.left,
    name: '',
    title1: '',
    title1Position: defaultValue.position.left,
    title2: '',
    title2Position: defaultValue.position.left,
  },
  setting: {
    displayQuestion: defaultValue.displayQuestion.all,
  },
  questions: [],
  courses: [],
  lecturers: [],
  courseManagements: [],
  assessmentList: [],
  totalAssessments: 0,
  answerEEvaluation: [],
  constantList: [],
  error: null,
}
