import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  responsiveText,
  StyledHeaderContainer,
  StyledHeaderTitle,
} from 'src/components/Preview/Header/Styled'
import Round from './Round'

const Header = () => {
  const { information, section } = useSelector(
    (state) => ({
      information: state.eEvaluation.information,
      section: state.eEvaluation.section,
    }),
    shallowEqual
  )
  return (
    <StyledHeaderContainer align="end">
      <Box>
        <StyledHeaderTitle section={section}>
          <Typography
            variant={responsiveText('h4', 'h5', 'body2b')}
            color="text.white"
          >
            {_.get(information, 'title1', '')}
          </Typography>
          <Typography
            variant={responsiveText('h6', 'body1b', 'subtitle2')}
            color="text.white"
          >
            {_.get(information, 'title2', '')}
          </Typography>
        </StyledHeaderTitle>
        {section == 'RESULT' && <Round />}
      </Box>
    </StyledHeaderContainer>
  )
}

export default Header
