import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const BoxCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme?.palette?.blue?.blueBorder}`,
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(7),
  [theme.breakpoints.down('md')]: {
    marginLeft: 20,
    marginRight: 20,
  },
}))

export const BoxDataEmpty = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

export const BoxHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))
