import React, { useState } from 'react'
import { BoxSearch, SearchInput, StyledSearchIcon } from './Styled'
import { useDispatch } from 'react-redux'
import { setMyScheduleFilterState } from '../ExpandedMenu/events'

const SearchBox = (props) => {
  const { search } = props
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState(search)
  const handleSearch = () => {
    dispatch(setMyScheduleFilterState({key: 'search', value: searchValue}))
  }

  return (
    <BoxSearch>
      <SearchInput
        placeholder="ค้นหา"
        onChange={(e) => {setSearchValue(e.target.value)}}
        value={searchValue}
        onBlur={handleSearch}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.target.blur()
          }
        }}
      />
      <StyledSearchIcon color="primary"/>
    </BoxSearch>
  )
}

export default React.memo(SearchBox)
