import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { myClassHistory, myClassList, eExaminationMyScheduleFilter } from 'src/utils/apiPath'

export const getMyClassList = createAsyncThunk(
  'classList/getMyClassList',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(myClassList, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getMyClassHistory = createAsyncThunk(
  'classList/getMyClassHistory',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(myClassHistory, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getEExaminationMySchedule = createAsyncThunk(
  'classList/eExaminationMyScheduleFilter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eExaminationMyScheduleFilter, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)