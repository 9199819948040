import React from 'react'
import Box from '@mui/material/Box'
import PlaceIcon from '@mui/icons-material/Place'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { openDialog } from 'src/redux/slices/alertDialog'
import { getContentLocation } from 'src/services/location'

export const fetchData = () => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(getContentLocation())
  dispatch(stopLoading())
}

export const onMaps = (title, img, map) => (dispatch) => {
  return dispatch(
    openDialog({
      isClose: true,
      title: title,
      customWidth: '860px',
      content: (
        <Box
          sx={{
            maxWidth: '760px',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            margin: '0px auto',
            '@media (max-width: 576px)': {
              maxWidth: '300px',
            },
          }}
        >
          <img src={img} style={{ width: '100%' }} />
        </Box>
      ),
      buttonLeft: {
        label: 'บันทึกรูปภาพ',
        handleClick: () => onDownloadImage(title, img),
      },
      buttonRight: {
        label: 'ขอเส้นทาง',
        endIcon: <PlaceIcon />,
        handleClick: () => onGoogleMaps(map),
      },
    })
  )
}

export const onPhoneCall = (number, setOpen) => {
  if (/Android|webOS|iPhone|iPad/i.test(navigator.userAgent)) {
    window?.open(`tel:${number}`)
  } else {
    setOpen(true)
    navigator.clipboard.writeText(number)
  }
}

export const onGoogleMaps = (googleMap) => {
  window?.open(googleMap)
}

export const onDownloadImage = (name, src) => {
  const img = new Image()
  img.crossOrigin = 'anonymous'
  img.src = src
  img.onload = () => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = img.width
    canvas.height = img.height
    ctx.drawImage(img, 0, 0)
    const a = document.createElement('a')
    a.download = name
    a.href = canvas.toDataURL('image/png')
    a.click()
  }
}
