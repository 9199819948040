import _ from 'lodash'

export const handleExceptPath = (location) => {
  const pathname = _.get(location, 'pathname', '')

  return (
    pathname.includes('/consent') ||
    pathname.includes('/logging-in') ||
    pathname.includes('/location') ||
    pathname.includes('/waiting-for-login') ||
    pathname.includes('/login') ||
    pathname.includes('/switch-from-backend')
  )
}
