import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { io } from 'socket.io-client'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import InsertInvitationOutlined from '@mui/icons-material/InsertInvitationOutlined'
import TimerOutlined from '@mui/icons-material/TimerOutlined'
import CameraOutdoor from '@mui/icons-material/CameraOutdoor'
import Breadcrumb from 'src/components/Breadcrumb'
import Template from 'src/components/Template'
import { StyledContainer } from 'src/components/Layout/Styled'
import {
  convertDateRange,
  convertFormatTime,
  getQueryParams,
} from 'src/utils/lib'
import Content from './Content'
import DetailCard from './DetailCard'
import FixedHeader from './FixedHeader'
import { listBreadcrumbs } from './model'
import {
  StyledRowContent,
  StyledHalfColumn,
  StyledLabelIcon,
  StyledDivider,
  StyledRowTwo,
  StyledBlueBg,
} from './Styled'
import {
  fetchClassDetail,
  handleSocketResponse,
  checkExpiryQRCode,
} from './events'
const { __env__: env } = window

const socketUrl = env.REACT_APP_SOCKET_URL

const socket = io.connect(`${socketUrl}`, {
  autoConnect: false,
  transports: ['websocket', 'polling'],
  auth: {
    Authorization: localStorage.getItem('token'),
  },
})

const ClassDetail = () => {
  const params = useParams()
  const classUuid = _.get(params, 'trainingPlanUuid', '')
  const history = useHistory()
  const dispatch = useDispatch()
  const [socketId, setSocketId] = useState('')
  const enroll = getQueryParams('enroll')
  const secret = getQueryParams('secret')
  socket.connect()
  const qrExpired = useSelector(
    (state) => state.classDetail.qrExpired,
    shallowEqual
  )

  useEffect(() => {
    socket.disconnect()
    socket.connect()
  }, [classUuid])

  useEffect(() => {
    function onConnect() {
      if (socket.connected) {
        setSocketId(socket.id)
        socket.on(socket.id, (res) =>
          dispatch(handleSocketResponse(res, classUuid, secret))
        )
      }
    }

    function onDisconnect() {
      socket.disconnect()
    }

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)

    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(socketId)) {
      if (enroll === 'auto') {
        dispatch(checkExpiryQRCode(params, socketId, enroll, history, secret))
      } else {
        dispatch(
          fetchClassDetail({
            params: params,
            socketId: socketId,
            enroll: enroll,
            qrExpired: qrExpired,
            history: history,
            secret: secret,
          })
        )
      }
    }
  }, [enroll, socketId, classUuid])

  return (
    <>
      <FixedHeader socketId={socketId} />

      <Template noMinHeight>
        <StyledContainer>
          <Breadcrumb list={listBreadcrumbs(_.get(params, 'uuid', ''))} />
          <DetailCard socketId={socketId} />
        </StyledContainer>
      </Template>

      <StyledBlueBg>
        <CourseRowContent />
      </StyledBlueBg>

      <Content />
    </>
  )
}

export default ClassDetail

export const CourseRowContent = () => {
  const data = useSelector((state) => state.classDetail.data, shallowEqual)
  const courseDetail = _.get(data, 'courseDetail', null)
  const trainingPlanData = _.get(data, 'trainingPlanData', null)
  const dateRange = convertDateRange(
    _.get(trainingPlanData, 'dateRangeMonthlyPlan', null)
  )
  const time = convertFormatTime(courseDetail)
  return (
    <StyledRowContent>
      <StyledRowTwo>
        <StyledHalfColumn>
          <StyledLabelIcon>
            <InsertInvitationOutlined fontSize="sm" />
            <Typography variant="body2">วันที่อบรม</Typography>
          </StyledLabelIcon>
          <Typography variant="h6">{dateRange}</Typography>
        </StyledHalfColumn>
        <StyledDivider />
        <StyledHalfColumn>
          <StyledLabelIcon>
            <TimerOutlined fontSize="sm" />
            <Typography variant="body2">ระยะเวลาของหลักสูตร</Typography>
          </StyledLabelIcon>
          <Typography variant="h6">{time}</Typography>
        </StyledHalfColumn>
      </StyledRowTwo>
      <StyledDivider id="training" />
      <StyledHalfColumn>
        <StyledLabelIcon>
          <CameraOutdoor fontSize="sm" />
          <Typography variant="body2">รูปแบบการสอน</Typography>
        </StyledLabelIcon>
        <Typography variant="h6">
          {_.get(courseDetail, 'trainingPlatformName', '-')}
        </Typography>
      </StyledHalfColumn>
    </StyledRowContent>
  )
}
