export const progressTitle = [
  'เลือกรอบสอบที่ต้องการ',
  'กรอกข้อมูลส่วนตัว',
  'ยืนยันข้อมูลการสมัครสอบ',
]

export const type = [
  { label: 'บริษัท', index: 'COMPANY' },
  { label: 'สมาคม', index: 'ASSOCIATION' },
  { label: 'อื่นๆ', index: 'OTHER' },
]

export const TYPE_ENUM = {
  COMPANY: 'บริษัท',
  ASSOCIAITON: 'สมาคม',
  OTHER: 'อื่นๆ',
}

export const genderTitle = {
  ช: 'ชาย',
  ญ: 'หญิง',
}

export const prefixOptions = [
  { value: 'นาย', label: 'นาย' },
  { value: 'นาง', label: 'นาง' },
  { value: 'นางสาว', label: 'นางสาว' },
]

export const USER_TYPE = {
  AGENT: 'AGENT',
  PROSPECT: 'PROSPECT',
  TEMP: 'TEMP',
  STAFF: 'STAFF',
}
