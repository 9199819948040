export const STUDENT_STATUS_TEXT = {
  WAITING_CHECK: 'WAITING_CHECK',
  WAITING_ANNOUNCEMENT: 'WAITING_ANNOUNCEMENT',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  CANCEL: 'CANCEL',
  CANCEL_BY_SELF: 'CANCEL_BY_SELF',
  CANCEL_BY_ADMIN: 'CANCEL_BY_ADMIN',
  CANCEL_EXAMINATION: 'CANCEL_EXAMINATION',
  CANCEL_BY_MANAGER: 'CANCEL_BY_MANAGER',
}

export const formatData = (status) => {
  let result = {
    label: '-',
    color: 'text.primary',
  }
  switch (status) {
    case STUDENT_STATUS_TEXT.WAITING_CHECK:
      result = {
        label: 'รอตรวจสอบ',
        color: 'text.lightGray',
      }
      break
    case STUDENT_STATUS_TEXT.WAITING_ANNOUNCEMENT:
      result = {
        label: 'รอประกาศสิทธิ์',
        color: 'blue.deepDapphire',
      }
      break
    case STUDENT_STATUS_TEXT.ANNOUNCEMENT:
      result = {
        label: 'ประกาศสิทธิ์แล้ว',
        color: 'success.main',
      }
      break
    case STUDENT_STATUS_TEXT.PERMISSION_DENIED:
      result = {
        label: 'ไม่มีสิทธิ์สอบ',
        color: 'text.lightGray',
      }
      break
    case STUDENT_STATUS_TEXT.CANCEL:
      result = {
        label: 'ยกเลิกการสมัคร',
        color: 'error.main',
      }
      break
    case STUDENT_STATUS_TEXT.CANCEL_BY_SELF:
      result = {
        label: 'ยกเลิกโดยผู้สมัคร',
        color: 'error.main',
      }
      break
    case STUDENT_STATUS_TEXT.CANCEL_BY_ADMIN:
      result = {
        label: `ยกเลิกโดยแอดมิน`,
        color: 'error.main',
      }
      break
    case STUDENT_STATUS_TEXT.CANCEL_EXAMINATION:
      result = {
        label: 'ยกเลิกตารางสอบ',
        color: 'error.main',
      }
      break

    case STUDENT_STATUS_TEXT.CANCEL_BY_MANAGER:
      result = {
        label: 'ยกเลิกโดยผู้จัดการ',
        color: 'error.main',
      }
      break
  }

  return result
}
