import CardContent from '@mui/material/CardContent'
import styled from '@mui/system/styled'

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: `${theme.spacing(5)} !important`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  '& .MuiButton-root': { width: '100%' },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(4)} !important`,
  },
}))
