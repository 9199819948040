import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import {
  closeDialog,
  openDialog,
  openErrorDialog,
} from 'src/redux/slices/alertDialog'
import {
  setChangeBody,
  setChangeCamera,
  openDialog as openEnrollDialog,
  closeDialog as closeEnrollDialog,
  setTime,
  setFailMaxTimes,
} from 'src/redux/slices/eLearning/enroll'
import {
  getCheckFailScanCount,
  postEnrollIdCard,
  postVerifyIdCard,
} from 'src/services/eLearning/enroll'
import { dataURLtoFile } from 'src/utils/lib'
import { ENROLL_INPUT_TYPE } from 'src/constants/eLearning'
import { getEnrollState } from '../events'
import DisallowDialog from './DisallowDialog'

export const handleCheckAllowScan = (uuid) => async (dispatch) => {
  if (_.isEmpty(uuid)) return
  const response = await dispatch(getCheckFailScanCount(uuid))
  const expireDateTime = _.get(response.payload.data, 'registerBreakTime', '')
  if (!_.isEmpty(expireDateTime)) {
    dispatch(handleDisallowScan(response))
  }
}

export const handleDisallowScan = (response) => (dispatch) => {
  const expireDateTime = _.get(response.payload.data, 'registerBreakTime', '')
  const time = handleCalculateTimeLeft(expireDateTime)
  dispatch(setTime(time))
  const failTimes = _.get(response.payload.data, 'countRegister', '')
  dispatch(setFailMaxTimes(failTimes))
  dispatch(
    openEnrollDialog({
      content: <DisallowDialog />,
      canClickOutside: false,
    })
  )
}

export const handleSubmitIdCard = (file, typeInput) => async (dispatch) => {
  const formData = new FormData()
  formData.append('file', file)

  dispatch(startLoading())
  const response = await dispatch(postVerifyIdCard(formData))

  if (_.isEmpty(response.error)) {
    const idCardImageKey = _.get(response.payload.data, 'key', '')
    const state = getEnrollState()
    const body = {
      eLearningCourseBatch: state.body.batch,
      idCardImageKey,
      typeInput,
    }

    const resEnroll = await dispatch(postEnrollIdCard(body))
    dispatch(stopLoading())

    if (_.isEmpty(resEnroll.error)) {
      const payload = { key: 'idCardImage', value: idCardImageKey }
      const typeInputValue = { key: 'typeInput', value: typeInput }
      dispatch(setChangeBody(payload))
      dispatch(setChangeBody(typeInputValue))
      dispatch(setChangeBody({ key: 'faceImage', value: null }))
      dispatch(handleVerifySuccess('ถ่ายภาพบัตรประชาชนสำเร็จ'))
    } else {
      dispatch(closeEnrollDialog())
      dispatch(openErrorDialog())
    }
  } else {
    dispatch(stopLoading())
    dispatch(closeEnrollDialog())
    const errorObj = _.get(response, 'meta.response.data', {})
    const constraints = _.get(errorObj, 'constraints', {})
    let message = 'อัปโหลดรูปภาพล้มเหลว กรุณาลองใหม่อีกครั้ง'
    if (constraints.value) message = constraints.value
    if (errorObj.message?.includes('format')) message = 'นามสกุลไฟล์ไม่ถูกต้อง'
    dispatch(openErrorDialog({ ...constraints, message }))
  }
}

export const handleOpenCamera = (field) => (dispatch) => {
  dispatch(setChangeCamera({ key: 'isOpen', value: true }))
  dispatch(setChangeCamera({ key: field, value: null }))
}

export const handleCapture = (camRef, field) => async (dispatch) => {
  const value = camRef.current.getScreenshot()
  const payload = { key: field, value }
  dispatch(setChangeCamera(payload))

  const file = dataURLtoFile(value, `${field}.jpeg`)
  await dispatch(handleSubmitIdCard(file, ENROLL_INPUT_TYPE.CAPTURE))
}

export const handleVerifySuccess = (title) => (dispatch) => {
  dispatch(
    openDialog({
      iconType: 'success',
      title,
      buttonRight: {
        handleClick: () => {
          dispatch(closeDialog())
          dispatch(closeEnrollDialog())
        },
      },
    })
  )
}

export const handleCalculateTimeLeft = (date) => {
  const dateTime =
    dayjs(new Date(date)).utc().format('MM/DD/YYYY') +
    ' ' +
    dayjs(new Date(date)).utc().format('HH:mm:ss')
  const expireDate = dayjs(dateTime)
  const current = dayjs(new Date())
  const timeRemain = expireDate.diff(current, 'second')
  const minutes = Math.floor(timeRemain / 60)
  const mins = minutes
  const secs = timeRemain - minutes * 60
  return { mins, secs }
}
