import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { MediaBoxStyled } from '../Styled'
import {
  getSettingsFromParams,
  handleUpdateScormLog,
  checkScormLastStatus,
} from './events'

const Scorm = ({ selectedLesson }) => {
  const fileUploaded = selectedLesson.eLearningModuleFileUpload
  const fileName = _.get(fileUploaded, '[0].fileName', [])
  const version = _.get(fileUploaded, '[0].version', '2004')
  console.warn('version >>>>>', version)
  const src = `${window.__env__.REACT_APP_APP_URL}${_.get(fileName, '0', '')}`
  const dispatch = useDispatch()
  let scormHistoryData = {}
  let oldCompletionStatus = 'incomplete'
  let scormInterval
  let completionStatus
  let completionStatusInterval

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const settings = getSettingsFromParams(urlParams)
    const script = document.createElement('script')
    script.src = '../../../script/scorm-again.js'
    script.async = true
    document.body.appendChild(script)

    script.onload = async () => {
      const scormData = _.get(selectedLesson, 'scormData', '{}')
      const dataFromLms =
        typeof scormData === 'object' ? scormData : JSON.parse(scormData)
      const iframe = document.getElementById('scormFrame')
      iframe.src = src
      // -------- start script for scorm version 1.2 --------//
      if (version === '1.2') {
        window.API = new window.Scorm12API(settings)
        await window.API.loadFromJSON(dataFromLms, '')
        window.API.on('LMSSetValue.cmi.*', function () {
          scormHistoryData = window.API.renderCommitCMI(true)
          completionStatus = window.API.lmsGetValue('cmi.core.lesson_status')
        })
      }
      // -------- end script for scorm version 1.2 --------//

      // -------- start script for scorm version 2004 --------//
      if (version === '2004') {
        window.API_1484_11 = new window.Scorm2004API(settings)
        await window.API_1484_11.loadFromJSON(dataFromLms, '')
        window.API_1484_11.on('SetValue.cmi.*', async function () {
          scormHistoryData = window.API_1484_11.renderCommitCMI(true)
          completionStatus = window.API_1484_11.lmsGetValue(
            'cmi.completion_status'
          )
        })
      }
      //-------- end script for scorm versoin 2004 ---------//

      oldCompletionStatus = checkScormLastStatus(version)
      scormInterval = setInterval(() => {
        // setInterval ทุก 30วิ ให้เก็บประวัติการเรียนแบบ Real Time
        if (oldCompletionStatus === 'incomplete') {
          //จะเก็บประวัติเมื่อ completion_status เท่ากับ incomplete เท่านั้น
          oldCompletionStatus = checkScormLastStatus(version)
          console.warn('scormHistoryData >>>>>', scormHistoryData)
          dispatch(handleUpdateScormLog(scormHistoryData, version))
        }
      }, 30000)
      completionStatusInterval = setInterval(() => {
        const suspend = _.get(scormHistoryData, 'cmi.suspend_data', '')
        // setInterval ทุก 2 วินาที เพื่อ Get Status ล่าสุดของการเรียน Scorm
        if (
          (completionStatus === 'completed' || completionStatus === 'passed') &&
          !_.isEmpty(suspend)
        ) {
          console.warn('>>>>>>>>', completionStatus)
          // ถ้า status การเรียน scorm นั้น เป็น completed สั่ง update ประวัติ และ clearInterVal ออกทั้งหมด
          dispatch(handleUpdateScormLog(scormHistoryData, version))
          clearInterval(scormInterval)
          clearInterval(completionStatusInterval)
        }
      }, 1000)
    }

    return () => {
      clearInterval(scormInterval) // ถ้าผู้ใช้กดออกจาก Component Scorm สั่ง clearInterval
      clearInterval(completionStatusInterval)
      dispatch(handleUpdateScormLog(scormHistoryData, version)) // ถ้าผู้ใช้กดออกจาก Component Scorm สั่งเก็บประวัติล่าสุด
      document.body.removeChild(script)
    }
  }, [src])

  return (
    <MediaBoxStyled>
      <iframe
        width="100%"
        height="100%"
        style={{ border: 0 }}
        id="scormFrame"
      />
    </MediaBoxStyled>
  )
}

export default Scorm
