import React from 'react'
import { useTheme } from '@mui/material'
import { checkColorStatus } from './events'
import { StyledDot } from './Styled'

const Dot = ({ status }) => {
  const theme = useTheme()
  return <StyledDot status={status} color={checkColorStatus(status, theme)} />
}

export default Dot
