import React, { useEffect } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { Container } from '@mui/material'
import Template from 'src/components/Template'
import AllArticle from './AllArticle'
import ListHeader from '../ListHeader'
import { isMediaWidth, isMediaWidthMoreThan } from 'src/utils/lib'
import { fetchDataList } from './events'
import { resetDataList, setFilterValue } from 'src/redux/slices/eContent'
import { ECONTENT_TYPE } from '../model'

const EKnowledgeList = () => {
  const dispatch = useDispatch()
  const isMobile = isMediaWidth('xs')
  const isLaptop = isMediaWidthMoreThan('md')
  const { page, type, order } = useSelector(
    (state) => ({
      page: state.eContent.filter.page,
      type: state.eContent.filter.type,
      order: state.eContent.filter.order,
    }),
    shallowEqual
  )
  useEffect(() => {
    dispatch(resetDataList())
    return () =>
      dispatch(
        setFilterValue({ key: 'type', value: [ECONTENT_TYPE.E_CONTENT] })
      )
  }, [])
  useEffect(() => {
    if (type.length == 1) {
      dispatch(fetchDataList({ page, type, order }))
    }
  }, [page, type, order])

  return (
    <>
      <Container maxWidth="lg">
        <ListHeader isMobile={isMobile} />
        <Template maxWidth="100%">
          <AllArticle isMobile={isMobile} isLaptop={isLaptop} />
        </Template>
      </Container>
    </>
  )
}

export default EKnowledgeList
