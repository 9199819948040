import FormControlLabel from '@mui/material/FormControlLabel'
import styled from '@mui/system/styled'
import _ from 'lodash'

export const StyledChoice = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'resultProps',
})((props) => {
  const { onViewState } = props.resultProps
  return {
    background: displayResult(props),
    marginBottom: props.theme.spacing(1),
    pointerEvents: onViewState ? 'none' : 'auto',
    cursor: onViewState ? 'default' : 'pointer',
    wordBreak: 'break-word',
    alignItems: 'flex-start',
    input: {
      cursor: onViewState ? 'default' : 'pointer',
    },
    '& > .MuiCheckbox-root': { padding: '6px' },
    '& > .MuiFormControlLabel-label': { marginTop: '6px' },
  }
})

export const displayResult = (props) => {
  const { theme, checked, resultProps } = props
  const { correctAnswer, onViewState, questionResult, choice } = resultProps
  const { isOpen, resultType } = questionResult

  if (!isOpen) return 'none'
  if (!onViewState) return 'none'

  const answer = _.get(correctAnswer, 'answers', [])
  let isAnswer = choice.isCorrect || answer.some((ans) => ans === choice.uuid)
  if (choice.questionType === 'FILL_IN_BLANK_CHOICE') {
    isAnswer = answer.some(
      (ans) => ans.target === choice.target && ans.answerUuid === choice.uuid
    )
  }
  const isChecked = choice.isLearnerAnswer || checked
  if (resultType === 'SHOW_TRUE_FALSE') {
    if (isAnswer) return theme.palette.success?.background
    if (isChecked) return theme.palette.error?.background
  } else {
    if (isChecked) {
      return isAnswer
        ? theme.palette.success?.background
        : theme.palette.error?.background
    }
  }
}
