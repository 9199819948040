import React from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'

import { Column, Row } from 'src/components/Layout/Styled'
import { isLearningPath } from '../events'

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

export const StyledTableColumn = styled(Column)(({ theme }) => ({
  width: isLearningPath() ? '100%' : 'calc(100% - 420px)',
  paddingTop: isLearningPath() ? 0 : theme.spacing(5),
  gap: theme.spacing(1),
  '& div > svg': { color: theme.palette.text?.secondary },
  [theme.breakpoints.down('lg')]: { width: '100%', paddingTop: 0 },
}))

export const StyledEnrollButton = styled((props) => (
  <Button
    {...props}
    size="s"
    color="secondary"
    variant="contained"
    data-testid="btn-enroll"
  />
))(({ theme }) => ({
  width: 125,
  [theme.breakpoints.down('lg')]: { marginRight: theme.spacing(3) },
}))

export const StyledTableRow = styled(Row)(({ theme, isHaveSubject }) => ({
  paddingLeft: isLearningPath() ? 0 : theme.spacing(3),
  paddingRight: isLearningPath() ? 0 : 135,
  justifyContent: 'space-between',
  '& > .MuiTypography-root': { width: isHaveSubject ? '25%' : '33%' },
  '& > div': { width: isHaveSubject ? '25%' : '33%' },
  [theme.breakpoints.down('lg')]: { padding: `0 ${theme.spacing(3)}` },
  [theme.breakpoints.down('md')]: {
    width: 'calc(100% - 149px)',
    flexDirection: 'column',
    alignItems: 'start',
    '&#header': { display: 'none' },
    '& > .MuiTypography-root': { width: '100%' },
    '& > div': { width: '100%' },
  },
}))

export const StyledTableData = styled(Row)(({ theme }) => ({
  gap: theme.spacing(0.5),
  alignItems: 'start',
  svg: { marginTop: theme.spacing(0.5) },
}))

export const StyledLoading = styled(CircularProgress)(
  ({ theme, isLoadMore }) => ({
    margin: 'auto',
    marginTop: theme.spacing(2),
    width: 24,
    height: 24,
    [theme.breakpoints.down('sm')]: { left: isLoadMore ? 0 : 140 },
  })
)

export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('lg')]: { width: '100%' },
}))

export const StyledTypography = styled(Typography)(({ theme, roomWidth }) => ({
  maxWidth: roomWidth - 16,
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.only('md')]: { maxWidth: '100%' },
}))

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette?.text?.primary,
    background: theme.palette?.background?.paper,
    boxShadow: theme.palette?.boxShadow?.menu,
  },
}))

