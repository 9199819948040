import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { Column } from 'src/components/Layout/Styled'
import Carousel from 'src/components/Carousel'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { handleSelector } from '../events'
import {
  StyledDisplayTrainer,
  StyledDivItem,
  StyledTrainerContainer,
  StyledTrainerHeader,
  StyledTypography,
} from './Styled'
import './style.css'

const Trainer = () => {
  const isLg = isMediaWidthMoreThan('lg')
  const trainer = useSelector(
    (state) => handleSelector(state, 'trainerInformation'),
    shallowEqual
  )
  const trainerList = _.get(trainer, 'trainers', [])
  const listTab = _.chunk(trainerList, 4)
  return (
    <StyledTrainerContainer>
      <img src="/image/home_slash.svg" id="slash" />
      <StyledTrainerHeader variant={isLg ? 'h2' : 'h5'}>
        {_.get(trainer, 'title1', '')}{' '}
        <span>{_.get(trainer, 'title2', '')}</span>{' '}
        {_.get(trainer, 'title3', '')}
      </StyledTrainerHeader>
      <Carousel ariaLabel="gallery-teacher">
        {listTab.map((trainers, key) => (
          <StyledDivItem key={key}>
            {trainers.map((data, index) => (
              <DisplayTrainer key={index} trainer={data} />
            ))}
          </StyledDivItem>
        ))}
      </Carousel>
    </StyledTrainerContainer>
  )
}

export default Trainer

export const DisplayTrainer = ({ trainer }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const station = _.get(trainer, 'area[0].station.station', '-')
  const image = _.get(trainer, 'image', '')
  return (
    <StyledDisplayTrainer>
      <img src={`${window.__env__.REACT_APP_FILE_URL}${image}`} />
      <Column>
        <StyledTypography variant={isLg ? 'h5' : 'body1b'}>
          {_.get(trainer, 'firstNameTH', '')}{' '}
          {_.get(trainer, 'lastNameTH', '')}
        </StyledTypography>
        <StyledTypography variant={isLg ? 'body1' : 'body2'}>
          {station}
        </StyledTypography>
      </Column>
    </StyledDisplayTrainer>
  )
}
