import React from 'react'
import Menu from '@mui/material/Menu'
import styled from '@mui/system/styled'

export const StyledMenu = styled((props) => (
  <Menu
    data-testid="btn-sub-menu"
    MenuListProps={{ 'aria-labelledby': 'basic-button' }}
    elevation={0}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    {...props}
  />
))(({ theme }) => ({
  zIndex: '1299 !important',
  '& .MuiPaper-root': {
    borderRadius: 0,
    backgroundColor: theme.palette?.blue?.paper,
    boxShadow: theme?.palette?.boxShadow?.menu,
    color: theme.palette.primary?.dark,
    minWidth: 260,
    '& .MuiMenu-list': { padding: '8px 0' },
    '& .MuiMenuItem-root': {
      gap: theme.spacing(0.5),
      padding: '8px 24px',
      fontSize: theme.typography?.body1?.fontSize,
      fontWeight: theme.typography?.body1?.fontWeight,
      color: theme.palette?.blue,
    },
  },
}))
