import React from 'react'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import styled from '@mui/system/styled'
import { LoadingButton } from '@mui/lab'

export const StyledDialog = styled(
  (props) => (
    <Dialog
      data-testid="alert-dialog"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      {...props}
    />
  ),
  { shouldForwardProp: (prop) => prop !== 'customWidth' }
)(({ theme, customWidth }) => ({
  '& #dialog-header': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(1)}`,
    gap: theme.spacing(1),
  },
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: customWidth ? customWidth : 'auto',
    },
  },
}))

export const StyledDialogMessage = styled((props) => (
  <Typography variant="body1" {...props} />
))(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

export const StyledDialogActions = styled(Box)(({ theme }) => ({
  padding: '24px 60px 60px 60px',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

export const StyledContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingLeft: 60,
  paddingRight: 60,
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

export const handleButtonWidth = ({ theme }) => ({
  padding: '12px 30px',
  [theme.breakpoints.down('sm')]: { width: '100% !important' },
})

export const StyledLeft = ({ theme }) => ({
  padding: '12px 30px',
  [theme.breakpoints.down('sm')]: { width: '100% !important' },
  '&:hover': {
    '& > .MuiTypography-root': { color: theme.palette.text?.white },
  },
})
export const StyledButtonLeft = styled(Button)(StyledLeft)

export const StyledButtonRight = styled(LoadingButton)(handleButtonWidth)
