import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import ReactPlayer from 'react-player'

export const StyledHomeHeader = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 600,
  '& .carousel-slider': { height: 600 },
  [theme.breakpoints.down('lg')]: {
    '& .carousel-slider': { height: 352 },
    height: 352,
  },
  [theme.breakpoints.down('sm')]: {
    '& .carousel-slider': { height: '100%' },
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}))

export const StyledHeaderContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: 548,
  top: 120,
  right: '45.5%',
  textAlign: 'left',
  '& .MuiButton-root': { padding: '12px 22px' },
  '& .MuiButton-root:first-of-type': {
    color: theme.palette.secondary?.contrast,
    marginRight: theme.spacing(3),
  },
  '#content': {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
  [theme.breakpoints.down('lg')]: {
    width: 416,
    top: 57,
    left: 144,
    '#content': {
      fontSize: '18px',
      lineHeight: '20px',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2),
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    position: 'relative',
    top: 0,
    left: 0,
    marginLeft: 0,
    padding: `${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(8)}`,
    textAlign: 'center',
  },
}))

export const StyledImagePreview = styled('img')(({ theme }) => ({
  height: 600,
  objectFit: 'cover',
  [theme.breakpoints.down('lg')]: { height: 352 },
  [theme.breakpoints.down('sm')]: { height: 172, objectPosition: 'center' },
}))

export const StyledVideoPreview = styled((props) => (
  <ReactPlayer
    playing
    loop
    muted
    config={{
      youtube: {
        playerVars: {
          controls: 0,
          fs: 0,
          disablekb: 1,
          cc_load_policy: 1,
          iv_load_policy: 3,
          modestbranding: 1,
        },
      },
      vimeo: {
        playerOptions: {
          controls: false,
          keyboard: false,
          byline: false,
          background: true,
          responsive: true,
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  pointerEvents: 'none',
  width: '100% !important',
  height: '100% !important',
  '& > div:first-of-type': {
    position: 'relative',
    overflow: 'hidden',
    paddingTop: '56.25%',
  },
  '& > div > div': { position: 'initial !important', padding: '0 !important' },
  iframe: {
    position: 'absolute',
    height: '100%',
    width: '100% !important',
    margin: '0 !important',
    top: '-50px !important',
    left: 0,
    bottom: 0,
    right: 0,
    border: 'none',
  },
  video: {
    height: 600,
    width: '100% !important',
    margin: '0 !important',
  },
  [theme.breakpoints.down('lg')]: {
    height: '352px !important',
    iframe: { top: -35 },
  },
  [theme.breakpoints.down('sm')]: { display: 'none' },
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: '100%',
  p: {
    margin: 0,
    [theme.breakpoints.down('sm')]: { span: { color: 'unset !important' } },
  },
}))

export const StyledOverlayBg = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.text?.disabled,
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  [theme.breakpoints.down('sm')]: { display: 'none' },
}))

export const StyledSecondaryButton = styled((props) => (
  <Button {...props} variant="outlined" />
))(({ theme, color }) => ({
  width: 200,
  color: theme.palette?.text?.white,
  borderColor: theme.palette?.text?.white,
  ':hover': {
    color: theme.palette?.grey?.greyA400,
    backgroundColor: theme.palette?.text?.white,
    borderColor: 'transparent',
  },
  '&:after': { background: theme.palette?.text?.white },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    color: theme.palette[color]?.main,
    borderColor: theme.palette[color]?.main,
    ':hover': {
      color: theme.palette?.text?.white,
      backgroundColor: theme.palette[color]?.main,
      borderColor: 'transparent',
    },
    '&:after': { background: theme.palette[color]?.main },
  },
}))
