import React, { useEffect } from 'react'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Table from '../../../../components/Table/BasicTable'

import { fetchDataHistory } from './events'
import { StyledDrawer, StyledHeadLabel, StyledContent } from './Styled'

const Index = ({ isOpen, onCloseDrawer }) => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const { table } = useSelector((state) => state.basicTable, shallowEqual)
  const { limit, order, page } = table

  useEffect(() => {
    if (isOpen) dispatch(fetchDataHistory({ table, uuid }))
  }, [limit, order, page, isOpen])

  return (
    <Box>
      <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
        <StyledContent>
          <StyledHeadLabel>
            <Box id="header">
              <Typography variant="h5">ประวัติการทำรายการ</Typography>
              <IconButton
                data-testid="btn-close-drawer"
                color="primary"
                component="span"
                onClick={onCloseDrawer}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
          </StyledHeadLabel>
          <Box sx={{ m: 3, gap: 4 }}>
            <Table />
          </Box>
        </StyledContent>
      </StyledDrawer>
    </Box>
  )
}

export default Index
