import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 100,
  height: 72,
  [theme.breakpoints.down('lg')]: { height: 24 },
}))

export const StyledInputWrapper = styled(Row)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: 'end',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}))
