import _ from 'lodash'
import {
  openDialog,
  openErrorDialog,
  openSuccessDialog,
} from 'src/redux/slices/alertDialog'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { postCancelClass, postCancelCourse } from 'src/services/class/detail'

export const handleCancelClass =
  (classLearnerUuid, classUuid) => async (dispatch) => {
    dispatch(
      openDialog({
        title: 'ต้องการยกเลิกคลาสเรียน',
        message: ['คุณต้องการยกเลิกคลาสเรียนนี้ใช่หรือไม่'],
        buttonRight: {
          handleClick: () =>
            dispatch(handleConfirmCancel(classLearnerUuid, classUuid)),
        },
        buttonLeft: { label: 'ยกเลิก' },
      })
    )
  }

export const handleConfirmCancel =
  (classLearnerUuid, classUuid) => async (dispatch) => {
    dispatch(startLoading())
    const response = await dispatch(
      postCancelClass({ classLearnerUuid, classUuid })
    )
    dispatch(stopLoading())
    if (_.isNil(response.error)) {
      dispatch(
        openSuccessDialog({ handleClick: () => window.location.reload() })
      )
    } else {
      const title = _.get(
        response,
        'meta.response.data.constraints.title',
        'ไม่สำเร็จ'
      )
      const message = _.get(
        response,
        'meta.response.data.constraints.value',
        'ดำเนินการไม่สำเร็จ'
      )
      dispatch(openErrorDialog({ title, message }))
    }
  }

export const handleCancelCourse =
  (eLearningCourseBatchLearnerUuid) => async (dispatch) => {
    dispatch(
      openDialog({
        title: 'ต้องการยกเลิกหลักสูตร',
        message: ['คุณต้องการยกเลิกหลักสูตรนี้ใช่หรือไม่'],
        buttonRight: {
          handleClick: () =>
            dispatch(
              handleConfirmCancelCourse(eLearningCourseBatchLearnerUuid)
            ),
        },
        buttonLeft: { label: 'ยกเลิก' },
      })
    )
  }

export const handleConfirmCancelCourse =
  (eLearningCourseBatchLearnerUuid) => async (dispatch) => {
    console.log(eLearningCourseBatchLearnerUuid)
    dispatch(startLoading())
    const response = await dispatch(
      postCancelCourse({ eLearningCourseBatchLearnerUuid })
    )
    dispatch(stopLoading())
    if (_.isNil(response.error)) {
      dispatch(
        openSuccessDialog({ handleClick: () => window.location.reload() })
      )
    } else {
      const title = _.get(
        response,
        'meta.response.data.constraints.title',
        'ไม่สำเร็จ'
      )
      const message = _.get(
        response,
        'meta.response.data.constraints.value',
        'ดำเนินการไม่สำเร็จ'
      )
      dispatch(openErrorDialog({ title, message }))
    }
  }
