import _ from 'lodash'
import { openDialog } from '../../../redux/slices/alertDialog'
import { handleSubmitSentMail } from '../../../modules/ManageProspect/Form/events'

export const handleClickMenu = (href, onClick) => {
  if (_.isUndefined(href)) onClick()
  else window.location.href = href
}

export const handleDialogSentMail = () => (dispatch) => {
  const listProspectUuid = JSON.parse(
    JSON.stringify(localStorage.getItem('prospect'))
  )
  dispatch(
    openDialog({
      isCloseDialog: false,
      iconType: 'warning',
      title: 'คุณต้องการยกเลิกการสมัครใช่หรือไม่?',
      message: ['รายการการสมัครที่คุณทำไว้ยังไม่ได้บันทึก'],
      buttonLeft: {
        label: 'ไม่',
      },
      buttonRight: {
        label: 'ใช่',
        handleClick: () => dispatch(handleSubmitSentMail(listProspectUuid)),
      },
    })
  )
}
