import { store } from 'src/App'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getCourseBatch, getBatchDetail } from 'src/services/eLearning/enroll'
import { getEConfiguration } from 'src/services/eConfiguration'

export const fetchCourseDetail = (uuid, isVerification) => async (dispatch) => {
  dispatch(getEConfiguration())

  dispatch(startLoading())
  if (isVerification) {
    await dispatch(getBatchDetail(uuid))
  } else {
    await dispatch(getCourseBatch(uuid))
  }
  dispatch(stopLoading())
}

export const getEnrollState = () => {
  return store.getState().eLearningEnroll
}
