import React from 'react'
import { openDialog } from 'src/redux/slices/alertDialog'
import ConsentDialog from '../ConsentDialog'

export const handleOpenDialog = (key) => (dispatch) => {
  dispatch(
    openDialog({
      type: 'custom',
      content: <ConsentDialog keyConfig={key} />,
    })
  )
}
