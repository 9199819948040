import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledTruncateBox = styled(Box)(({ maxWidth }) => ({
  maxWidth: maxWidth,
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  wordBreak: 'break-all',
  verticalAlign: 'middle',
}))
