import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  cancelClass,
  checkPrerequisite,
  courseClassDetail,
  courseClassRegister,
  enrollQueue,
  checkExpiryQRCode,
  eLearningCancelCourse,
} from 'src/utils/apiPath'

export const getCourseClassDetail = createAsyncThunk(
  'classDetail/getCourseClassDetail',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, trainingPlanUuid, secretTrainingPlan } = payload
      const response = await axios.get(
        `${courseClassDetail}/${uuid}/${trainingPlanUuid}?secretTrainingPlan=${secretTrainingPlan}`
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getCheckPrerequisite = createAsyncThunk(
  'classDetail/getCheckPrerequisite',
  async (payload, { rejectWithValue }) => {
    try {
      const { trainingPlanUuid, courseVersionUuid } = payload
      const response = await axios.get(
        `${checkPrerequisite}/${courseVersionUuid}/${trainingPlanUuid}`
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postCourseClassRegis = createAsyncThunk(
  'classDetail/postCourseClassRegis',
  async (trainingPlanUuid, { rejectWithValue }) => {
    try {
      const body = { trainingPlanUuid }
      const response = await axios.post(courseClassRegister, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postEnrollClassQueue = createAsyncThunk(
  'classDetail/postEnrollClassQueue',
  async ({ socketId, payload }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(enrollQueue, {
        id: socketId,
        token,
        payload,
      })
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postCancelClass = createAsyncThunk(
  'classDetail/postCancelClass',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(cancelClass, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postCancelCourse = createAsyncThunk(
  'classDetail/postCancelCourse',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningCancelCourse, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const checkExpiryQRCodeClass = createAsyncThunk(
  'classDetail/checkExpiryQRCode',
  async (payload, { rejectWithValue }) => {
    try {
      const { trainingPlanUuid } = payload
      const response = await axios.get(
        `${checkExpiryQRCode}/${trainingPlanUuid}`
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
