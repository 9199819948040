import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  updateUserProfile,
  updateUserProfileIdCardNo,
} from '../../utils/apiPath'

export const postUpdateProfile = createAsyncThunk(
  'profile/postUpdateProfile',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(updateUserProfile, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postUpdateIdCardNo = createAsyncThunk(
  'profile/postUpdateIdCardNo',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(updateUserProfileIdCardNo, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
