import React from 'react'
import Box from '@mui/material/Box'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'
import LinearProgress from '@mui/material/LinearProgress'
import PlayArrow from '@mui/icons-material/PlayArrow'
import styled from '@mui/system/styled'

export const StyledFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}))

export const StyledContentButton = styled(StyledFlex, {
  shouldForwardProp: (prop) => prop !== 'isInProgress',
})(({ theme, isInProgress }) => ({
  justifyContent: 'space-between',
  button: {
    height: 40,
    width: isInProgress ? 'calc(50% - 8px)' : '100%',
    padding: `${theme.spacing(1)} 0`,
  },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette?.blue?.blueBorder,
}))

export const StyledMediaBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 181,
  overflow: 'hidden',
  borderRadius: theme.spacing(0.5),
  margin: 'auto',
  '& > div': {
    width: '100% !important',
    height: '100% !important',
    pointerEvents: 'none',
  },
  img: { height: '100%', objectFit: 'cover', borderRadius: theme.spacing(0.5) },
}))

export const StyledLikeIcon = styled('img')(() => ({
  width: '17px !important',
  height: 17,
}))

export const StyledLinearProgress = styled(LinearProgress)(() => ({
  height: 9,
  background: 'transparent',
  border: '1px solid #DADADA',
}))

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow placement="top" classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '14px',
    lineHeight: '14px',
    backgroundColor: theme.palette?.blue?.azure,
    '& .MuiTooltip-arrow': { color: theme.palette?.blue?.azure },
  },
  img: { width: 20, height: 20 },
}))

export const StyledPlayArrow = styled(PlayArrow)(({ theme }) => ({
  position: 'absolute',
  margin: 'auto',
  fontSize: '140px',
  color: theme.palette?.text?.white,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  textAlign: 'center',
}))
