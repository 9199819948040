import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const TopicSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}))

export const Container = styled(Box)(({ theme }) => ({
  margin: 'auto',
  padding: `${theme.spacing(10)} 0`,
  [theme.breakpoints.down('lg')]: {
    maxWidth: 768,
    padding: `${theme.spacing(8)} ${theme.spacing(2)} 0`,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const CardSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(7.5),
  margin: '80px auto',
  flexWrap: 'wrap',
  maxWidth: 1146,
  [theme.breakpoints.down('lg')]: {
    margin: '48px auto',
    justifyContent: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '40px auto',
  },
}))

export const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '25px',
  width: 543,
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '300px',
  },
}))

export const DetailContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    textAlign: 'center',
  },
}))

export const ButtonContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '24px',
  marginTop: '24px',
}))

export const ImageDialog = styled(Box)(({ theme }) => ({
  maxWidth: '760px',
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
  margin: '0px auto',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '300px',
  },
}))
