import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDrawer',
})(({ theme, isDrawer }) => ({
  width: '100%',
  maxWidth: 286,
  height: '100%',
  backgroundColor: theme.palette.background?.paper,
  [theme.breakpoints.down('lg')]: {
    display: isDrawer ? 'block' : 'none',
  },
}))
