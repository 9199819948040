import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import styled from '@mui/system/styled'

export const textColor = 'text.wildSand'

export const StyledContainer = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  width: '100%',
  minHeight: '100vh',
  overflow: 'hidden',
}))

export const StyledExpandButton = styled((props) => (
  <Button {...props} size="small" variant="outlined" />
))(() => ({
  fontSize: '17px',
  '&:hover img': { filter: 'brightness(100) invert(1) grayscale(1)' },
}))

export const StyledFloatButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isFile',
})(({ isFile }) => ({
  position: 'absolute',
  top: 30,
  zIndex: isFile ? 2 : 3,
  fontSize: '17px',
  ':hover': { img: { filter: 'brightness(100) invert(1) grayscale(1)' } },
}))

export const StyledFloatBg = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFile',
})(({ theme, isFile }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  background: theme.palette?.background?.paper,
  width: '100%',
  height: 96,
  zIndex: isFile ? 1 : 2,
}))

export const height = '100vh'

export const StyledCircularBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.4)',
  zIndex: 998,
}))

export const StyledCircularLoader = styled((props) => (
  <CircularProgress {...props} size="50px" />
))(() => ({
  display: 'inline-block',
  position: 'absolute',
  top: 'calc(50% - 25px)',
  left: 'calc(50% - 25px)',
  zIndex: 999,
}))

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '18px',
  },
}))
