import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'
import { isLearningPath } from '../events'

export const StyledFilterColumn = styled(Column)(({ theme }) => ({
  width: isLearningPath() ? '100%' : 380,
  paddingLeft: isLearningPath() ? 0 : 135,
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    padding: `0 ${theme.spacing(3)}`,
  },
  [theme.breakpoints.down('sm')]: { padding: `0 ${theme.spacing(2.5)}` },
}))

export const StyledFilterHeader = styled(Row)(({ theme }) => ({
  color: theme.palette.primary?.main,
  gap: theme.spacing(1.5),
}))
