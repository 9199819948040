import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { StyledFooter } from 'src/components/Preview/Footer/Styled'
import ButtonForOne from './ButtonForOne'
import ButtonForAll from './ButtonForAll'
import { handleAnswerAmount } from './events'

const Footer = () => {
  const {
    onReviewAnswer,
    displayQuestion,
    questions,
    answerETesting,
    totalETesting,
    page,
  } = useSelector(
    (state) => ({
      onReviewAnswer: state.eTesting.onReviewAnswer,
      displayQuestion: state.eTesting.displayQuestion,
      questions: state.eTesting.questions,
      answerETesting: state.eTesting.answerETesting,
      totalETesting: state.eTesting.totalETesting,
      page: state.eTesting.page,
    }),
    shallowEqual
  )
  const isDisplayAll = displayQuestion === 'ALL'
  const answerAmount = useMemo(
    () =>
      onReviewAnswer ? page : handleAnswerAmount({ answerETesting, questions }),
    [answerETesting, page]
  )
  return (
    <StyledFooter>
      <Box>
        <Typography variant="buttonl" color="text.white">
          แบบทดสอบ{' '}
          {isDisplayAll && onReviewAnswer ? totalETesting : answerAmount} /{' '}
          {totalETesting} ข้อ
        </Typography>
        {isDisplayAll ? <ButtonForAll /> : <ButtonForOne />}
      </Box>
    </StyledFooter>
  )
}

export default Footer
