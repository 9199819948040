import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

const Breadcrumb = (props) => {
  const { list, iconColor, icon } = props

  return (
    <Breadcrumbs
      separator={
        <Typography variant="body1" color={iconColor}>
          {icon ? icon : '/'}
        </Typography>
      }
      aria-label="breadcrumb"
    >
      {list.map((item, index) => {
        return (
          <Box key={index}>
            {item.isLink ? (
              <Link
                sx={{ cursor: 'pointer' }}
                variant="body1"
                underline={'hover'}
                color={item.color}
                href={item.link}
                onClick={item.onClick}
              >
                {item.label}
              </Link>
            ) : (
              <Typography variant="body1" color={item.color}>
                {item.label}
              </Typography>
            )}
          </Box>
        )
      })}
    </Breadcrumbs>
  )
}

export default Breadcrumb
