import _ from 'lodash'
import { ratingChoices } from 'src/constants/eEvaluation'

export const handleSelector = (state, keyProps) => {
  const { keyField, keyAssessment, keySub, keyQuestion, staffUuid } = keyProps
  const assessment = _.get(state.eEvaluation, keyField, []).find(
    (item) => item.key == keyAssessment
  )

  if (!keySub) {
    const questionObj = assessment.questions.find(
      (item) => item.key == keyQuestion
    )
    return {
      uuid: _.get(questionObj, 'uuid', ''),
      choices: _.get(assessment, 'choices', ratingChoices),
      title: _.get(questionObj, 'title', ''),
      answer: _.get(questionObj, 'answer', ''),
      staffUuid,
    }
  }

  const choice = assessment.choices.find(
    (item) => item.nextSubQuestionSequence == keySub
  )
  const subQuestion = _.get(choice, 'subQuestions', {})
  const question = subQuestion.questions.find((item) => item.key == keyQuestion)
  return {
    uuid: _.get(question, 'uuid', ''),
    choices: _.get(subQuestion, 'choices', ratingChoices),
    title: _.get(question, 'title', ''),
    answer: _.get(question, 'answer', ''),
  }
}
