import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase'
import styled from '@mui/system/styled'
import SearchIcon from '@mui/icons-material/Search'

export const BoxSearch = styled(Box)(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.text?.silver}`,
  backgroundColor: theme?.palette?.text?.white,
  width: '100%',
  minWidth: 227,
  '&:hover': {
    backgroundColor: theme?.palette?.text?.wildSand,
  },
  marginLeft: 'auto',
  [theme.breakpoints.up('lg')]: {
    width: 'auto',
    maxHeight: 40,
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 180,
  },
}))

export const SearchInput = styled(InputBase)(({ theme }) => ({
  maxHeight: 40,
  width: '90%',
  marginLeft: theme.spacing(1),
  [theme.breakpoints.up('lg')]: {
    width: '85%',
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    width: '95%',
  },
}))

export const SearchButton = styled(SearchIcon)(({ theme }) => ({
  width: 20,
  height: 20,
  cursor: 'pointer',
  position: 'absolute',
  color: theme.palette.primary?.main,
  marginTop: theme.spacing(1),
  top: 0,
  right: theme.spacing(1),
}))
