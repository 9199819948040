import React from 'react'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'
import { handleSelector } from './events'
import { StyledTitleData } from './Styled'
import RatingRadio from './RatingRadio'

const QuestionRow = ({ keyProps, keyQuestion, isTitle }) => {
  const payload = { ...keyProps, keyQuestion }
  const questionProps = useSelector(
    (state) => handleSelector(state, payload),
    shallowEqual
  )
  const { title } = questionProps

  if (isTitle) {
    return (
      <StyledTitleData>
        <Typography>{title}</Typography>
      </StyledTitleData>
    )
  } else {
    return <RatingRadio questionProps={questionProps} />
  }
}

export default QuestionRow
