import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import IconButton from '@mui/material/IconButton'

import { StyledBox } from './Styled'
import { setOnEdit, setOnView } from '../../../../../redux/slices/table'

const ActionCommand = ({ row, rowIndex, minWidth }) => {
  const dispatch = useDispatch()
  const { onDelete } = useSelector((state) => state.table)

  const viewUrl = `/manage-prospect/view/${row?.uuid}`
  const editUrl = `/manage-prospect/${row?.uuid}`
  return (
    <StyledBox minWidth={minWidth}>
      {row?.status === 'ACTIVE' && (
        <>
          <IconButton
            data-testid={`btn-view-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => dispatch(setOnView(viewUrl))}
          >
            <RemoveRedEyeTwoTone color="action" />
          </IconButton>

          <IconButton
            data-testid={`btn-edit-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => dispatch(setOnEdit(editUrl))}
          >
            <EditTwoTone color="action" />
          </IconButton>
          <IconButton
            data-testid={`btn-delete-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => onDelete(row, rowIndex)}
          >
            <DeleteTwoTone color="action" />
          </IconButton>
        </>
      )}
      {row?.status === 'INACTIVE' && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnView(viewUrl))}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default ActionCommand
