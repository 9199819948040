import React from 'react'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledDetailCard = styled(Row)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette?.text?.mercury,
  borderRadius: theme.spacing(0.5),
  alignItems: 'start',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
    flexDirection: 'column',
  },
}))

export const StyledLeftBox = styled(Row)(({ theme }) => ({
  width: 'calc(100% - 400px)',
  alignItems: 'start',
  gap: theme.spacing(4),
  padding: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    gap: theme.spacing(2.5),
    padding: theme.spacing(1.5),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const StyledDivider = styled((props) => (
  <Divider {...props} orientation="vertical" flexItem />
))(({ theme }) => ({
  borderColor: theme.palette?.text?.mercury,
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}))

export const StyledRightBox = styled(Column)(({ theme }) => ({
  width: 400,
  minHeight: 272,
  padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
  gap: theme.spacing(3),
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    minHeight: 'auto',
    borderLeft: 0,
    borderTop: '1px solid',
    borderColor: theme.palette?.text?.mercury,
    gap: theme.spacing(2.5),
    padding: theme.spacing(2.5),
  },
}))

export const StyledImage = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  width: 330,
  minWidth: 330,
  height: 220,
  [theme.breakpoints.down('lg')]: {
    width: 240,
    minWidth: 240,
    height: 160,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 207,
  },
}))

export const StyledContentColumn = styled(Column)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
  },
}))

export const StyledIncludeRow = styled(Row)(({ theme }) => ({
  flexWrap: 'wrap',
  rowGap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
}))

export const StyledFixedFooter = styled(Column)(({ theme }) => ({
  display: 'none',
  alignItems: 'center',
  gap: theme.spacing(2),
  zIndex: 99,
  width: '100%',
  position: 'fixed',
  left: 0,
  bottom: 0,
  padding: `${theme.spacing(2)} ${theme.spacing(2.5)} ${theme.spacing(2.5)}`,
  background: theme.palette?.blue?.deepDapphire,
  [theme.breakpoints.down('lg')]: { display: 'flex' },
}))

export const StyledIconContent = styled(Row)(({ theme }) => ({
  width: '50%',
  gap: theme.spacing(1.5),
  svg: { color: theme.palette.text?.secondary },
}))

export const StyledStatusRow = styled(Row)(({ theme }) => ({
  gap: theme.spacing(1),
  justifyContent: 'center',
  '& > #progress': { color: theme.palette?.other?.ratingActive },
}))
