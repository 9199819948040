import _ from 'lodash'
import { store } from 'src/App'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { openErrorDialog } from 'src/redux/slices/alertDialog'
import { postEEvaluationSubmit } from 'src/services/eEvaluation'

export const handleSubmitEEvaluation = (props) => async (dispatch) => {
  dispatch(startLoading())
  const { uuid, answerEEvaluation, isModule } = props
  const answerBody = answerEEvaluation
    .filter((item) => item.isAnswered)
    .map((item) => ({
      questionUuid: item.questionUuid,
      answerUuid: item.answerUuid,
      suggestion: item.suggestion,
      otherAnswer: item.otherAnswer,
      freetextAnswer: item.freetextAnswer,
      staffUuid: item.staffUuid,
      answerSection: item.answerSection,
    }))

  const body = {
    trainingPlanUuid: props.trainingPlan || '',
    courseTestAndEvaluateEntityUuid: props.courseTestAndEva || '',
    answerEEvaluation: answerBody,
  }
  const payload = { uuid, body }

  if (isModule) {
    const { course, eLearningCourseBatchLearner, selectedLesson } =
      store.getState().eLearningLearning
    const isSubmitted = _.get(selectedLesson, 'isSubmitted', false)
    if (isSubmitted) {
      dispatch(stopLoading())
      return
    }

    payload.uuid = _.get(selectedLesson, 'eEvaluationUuid', '')
    payload.body = {
      eLearningCourseBatchLearner,
      eLearningCourse: { id: course.id, uuid: course.uuid },
      answerEEvaluation: answerBody,
    }
    payload.isModule = true
  }

  await dispatch(postEEvaluationSubmit(payload))
  dispatch(stopLoading())
}

export const handleError = (error) => (dispatch) => {
  if (_.isNil(error)) return

  dispatch(openErrorDialog({ message: error.value || error.message }))
}
