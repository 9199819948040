import React from 'react'
import Typography from '@mui/material/Typography'
import { Container, StyledHeaderText } from './Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'

const Header = () => {
  const isSm = isMediaWidthMoreThan('sm')
  return (
    <Container>
      <StyledHeaderText>
        <Typography id="title" variant={isSm ? 'h3' : 'h4'} color="text.white">
          หลักสูตรทั้งหมด
        </Typography>
      </StyledHeaderText>
    </Container>
  )
}

export default Header
