import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 32,
  [theme.breakpoints.down('lg')]: {
    marginTop: 40,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '24px',
  },
}))

export const ButtonSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 24,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
  },
}))

export const Container = styled(Box)(({ theme }) => ({
  margin: 'auto',
  padding: `${theme.spacing(16)} 0 ${theme.spacing(10)}`,
  [theme.breakpoints.down('lg')]: {
    width: 768,
    padding: `${theme.spacing(8)} ${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const TableSection = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.blue?.blueBorder}`,
  borderRadius: '2px',
}))

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(7.5)}`,
  [theme.breakpoints.down('sm')]: { padding: `0 ${theme.spacing(3)}` },
}))
