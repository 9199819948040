export const initialState = {
  isLoading: false,
  isLoadMore: false,
  error: null,
  data: null,
  classList: [],
  filterBody: {
    courseStatus: ['OPEN'],
    startDate: '',
    finishDate: '',
    page: 1,
  },
  totalClass: 0
}
