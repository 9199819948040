export const optionPrefixTh = [
  { label: 'นาย', value: 'นาย' },
  { label: 'นางสาว', value: 'นางสาว' },
  { label: 'นาง', value: 'นาง' },
]

export const optionPrefixEn = [
  { label: 'Mr', value: 'Mr' },
  { label: 'Ms', value: 'Ms' },
  { label: 'Mrs', value: 'Mrs' },
]

export const userType = {
  AGENT: 'AGENT',
  STAFF: 'STAFF',
  PROSPECT: 'PROSPECT',
  CS: 'CS',
}
