import React, { useMemo } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { LoadingButton } from '@mui/lab'
import {
  setNextQuestion,
  setPreviousQuestion,
} from 'src/redux/slices/eEvaluation'
import { responsiveButton } from 'src/components/Preview/Footer/Styled'
import SubmitBtn from '../SubmitBtn'
import { handleIsAnswered } from '../events'

const ButtonForOne = ({ totalQuestion, isModule }) => {
  const buttonSize = responsiveButton()
  const dispatch = useDispatch()
  const assessmentProps = useSelector(
    (state) => ({
      page: state.eEvaluation.page,
      assessmentList: state.eEvaluation.assessmentList,
      answerEEvaluation: state.eEvaluation.answerEEvaluation,
      displayQuestion: _.get(
        state.eEvaluation.setting,
        'displayQuestion',
        'ALL'
      ),
    }),
    shallowEqual
  )
  const { page, answerEEvaluation } = assessmentProps
  const isAnswered = useMemo(
    () => handleIsAnswered(assessmentProps),
    [answerEEvaluation, page]
  )
  return (
    <Box sx={{ display: 'flex', gap: 3 }}>
      {page > 1 && (
        <Button
          variant="contained"
          size={buttonSize}
          data-testid="btn-back"
          onClick={() => dispatch(setPreviousQuestion())}
          startIcon={<ArrowBack />}
        >
          กลับ
        </Button>
      )}
      {page < totalQuestion && (
        <LoadingButton
          variant="contained"
          size={buttonSize}
          onClick={() => dispatch(setNextQuestion())}
          data-testid="btn-next"
          endIcon={<ArrowForward />}
          loadingPosition="end"
          disabled={!isAnswered}
        >
          ถัดไป
        </LoadingButton>
      )}
      {page == totalQuestion && <SubmitBtn isModule={isModule} />}
    </Box>
  )
}

export default ButtonForOne
