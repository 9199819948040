import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { answerSection } from 'src/constants/eEvaluation'
import {
  getEEvaluationDetail,
  postEEvaluationSubmit,
} from 'src/services/eEvaluation'
import {
  getBetweenRedirectPage,
  handleAnswerType,
  handleAssignSub,
  handleInitialAssessment,
  isDuplicateAnswer,
  resetNextQuestionAnswer,
} from './events'
import { answerEEvaluationObj, initialState } from './model'

const eEvaluation = createSlice({
  name: 'eEvaluation',
  initialState: initialState,
  reducers: {
    setInitialPreview: (state, { payload }) => {
      Object.assign(state, payload)
    },
    setInitialAnswer: (state, { payload }) => {
      if (isDuplicateAnswer(state, payload)) return
      state.answerEEvaluation.push(payload)
    },
    setInititialAssessment: handleInitialAssessment,
    setAssignSubQuestion: handleAssignSub,
    setAnswerEEvaluation: (state, { payload }) => {
      const { uuid, type, name, staffUuid } = payload
      const answerState = state.answerEEvaluation.find((item) => {
        const questionUuid = item.questionUuid
        const answerUuid = item.answerUuid
        if (type == 'RATING' && staffUuid) {
          return questionUuid == uuid && item.staffUuid == staffUuid
        }
        if (type == 'OTHER') return questionUuid == uuid && answerUuid == name
        else return questionUuid == uuid
      })
      handleAnswerType(state, answerState, payload)
    },
    resetAnswer: (state) => {
      state.answerEEvaluation = []
    },
    setSuggestionAnswer: (state, { payload }) => {
      const { uuid, value, keyField, staffUuid, key } = payload
      const answerEEvaluation = _.get(state, 'answerEEvaluation', [])
      const suggestionIndex = answerEEvaluation.findIndex((item) => {
        if (item.staffUuid) {
          return item.questionUuid == uuid && item.staffUuid == staffUuid
        } else return item.questionUuid == uuid
      })
      const isHaveSuggestion = suggestionIndex != -1
      if (value != '') {
        const answerObj = {
          ...answerEEvaluationObj,
          questionUuid: uuid,
          suggestion: value,
          answerSection: answerSection[keyField],
          isAnswered: true,
          staffUuid,
          key,
        }
        if (isHaveSuggestion) {
          answerEEvaluation[suggestionIndex] = answerObj
        } else {
          answerEEvaluation.push(answerObj)
        }
      } else {
        if (isHaveSuggestion) answerEEvaluation.splice(suggestionIndex, 1)
      }
    },
    // Display question one per page
    setRedirectPage: (state, { payload }) => {
      const { from, to } = payload
      const fromIndex = state.assessmentList.findIndex(
        (item) => item.key == from
      )
      if (fromIndex === -1) return

      const assessmentOld = state.assessmentList.slice(0, fromIndex)
      const assessmentNew = state.constantList.slice(from - 1)
      const assessmentFiltered = assessmentNew.filter(
        (item) => !getBetweenRedirectPage(payload, item)
      )
      const newAssessmentList = _.concat(assessmentOld, assessmentFiltered)
      const targetPage = newAssessmentList.findIndex((item) => item.key === to)
      if (targetPage === -1) return

      _.set(state, 'assessmentList', newAssessmentList)
      _.set(state, 'totalAssessments', newAssessmentList.length)
      _.set(state, 'page', targetPage + 1)
      _.set(state, 'answerEEvaluation', resetNextQuestionAnswer(state, from))
    },
    resetRedirectPage: (state, { payload }) => {
      const fromIndex = state.assessmentList.findIndex(
        (item) => item.key == payload
      )
      const assessmentOld = state.assessmentList.slice(0, fromIndex)
      const assessmentNew = state.constantList.slice(payload - 1)
      const newAssessmentList = _.concat(assessmentOld, assessmentNew)
      _.set(state, 'assessmentList', newAssessmentList)
      _.set(state, 'totalAssessments', newAssessmentList.length)
      _.set(state, 'answerEEvaluation', resetNextQuestionAnswer(state, payload))
    },
    // Display question one per page
    setNextQuestion: (state) => {
      state.page = state.page + 1
    },
    setPreviousQuestion: (state) => {
      state.page = state.page - 1
    },
    setSectionEvaluate: (state) => {
      state.section = 'EVALUATE'
      state.page = 1
    },
    setSectionResult: (state) => {
      state.section = 'RESULT'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEEvaluationDetail.fulfilled, (state, { payload }) => {
        state.result = payload.data
      })
      .addCase(getEEvaluationDetail.rejected, (state, { meta }) => {
        state.error = meta?.response?.data?.constraints
      })
      .addCase(postEEvaluationSubmit.fulfilled, (state) => {
        state.error = null
      })
      .addCase(postEEvaluationSubmit.rejected, (state, { meta }) => {
        state.error = meta.response.data.constraints || meta?.response?.data
      })
  },
})

export const {
  setInitialPreview,
  setInitialAnswer,
  setInititialAssessment,
  setAssignSubQuestion,
  setAnswerEEvaluation,
  setSuggestionAnswer,
  setRedirectPage,
  resetRedirectPage,
  setNextQuestion,
  setPreviousQuestion,
  setSectionEvaluate,
  setSectionResult,
  resetAnswer,
} = eEvaluation.actions

export default eEvaluation.reducer
