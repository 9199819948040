import React, { useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { draftAssessmentQuestion } from 'src/redux/slices/eEvaluation/selectors'
import TextInput from 'src/components/Input/TextInput'
import {
  StyledContainer,
  StyledRatingColumn,
  StyledRatingHead,
  StyledTitleColumn,
} from './Styled'
import { StyledTitleData } from './QuestionRow/Styled'
import QuestionRow from './QuestionRow'
import {
  handleDefaultValue,
  handleSelector,
  handleSuggestionChange,
} from './events'

const RatingContent = ({ keyProps }) => {
  const ref = useRef(null)
  const [ratingWidth, setRatingWidth] = useState(80)
  const { staffUuid } = keyProps
  const { uuid, choices, isSuggestion } = useSelector(
    (state) => handleSelector(state, keyProps),
    shallowEqual
  )
  const listQuestion = useSelector(
    draftAssessmentQuestion(keyProps),
    shallowEqual
  )
  const defaultSuggestion = useSelector(
    (state) => handleDefaultValue(state.eEvaluation, uuid, staffUuid),
    shallowEqual
  )

  const dispatch = useDispatch()

  const debouncedSuggestChange = useMemo(
    () =>
      _.debounce(
        (e) => dispatch(handleSuggestionChange(e, uuid, keyProps)),
        1000
      ),
    [uuid, staffUuid]
  )

  useEffect(() => {
    setRatingWidth(ref.current ? ref.current.offsetWidth : 80)
  }, [ref.current])

  return (
    <>
      <StyledContainer>
        <StyledTitleColumn>
          <StyledTitleData header />
          {listQuestion.map((item, index) => (
            <QuestionRow
              key={index}
              keyProps={keyProps}
              keyQuestion={item}
              isTitle
            />
          ))}
        </StyledTitleColumn>
        <StyledRatingColumn>
          <Box id="header">
            {choices.map((item) => (
              <StyledRatingHead
                ref={ref}
                ratingWidth={ratingWidth}
                key={item.sequence}
              >
                <Typography variant="body1b">{item.title}</Typography>
              </StyledRatingHead>
            ))}
          </Box>
          {listQuestion.map((item, index) => (
            <QuestionRow key={index} keyProps={keyProps} keyQuestion={item} />
          ))}
        </StyledRatingColumn>
      </StyledContainer>
      {isSuggestion && (
        <TextInput
          labelText="ข้อเสนอแนะ / ความคิดเห็นเพิ่มเติม"
          labelVariant="body2b"
          type="basic"
          multiline
          rows={2}
          maxlength={3000}
          isShowTextError={false}
          id="suggestion"
          onChange={debouncedSuggestChange}
          defaultValue={defaultSuggestion}
        />
      )}
    </>
  )
}

export default RatingContent
