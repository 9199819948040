import React from 'react'
import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useSelector, shallowEqual } from 'react-redux'

import {
  BoxContent,
  BoxImage,
  Image,
  BoxCard,
  BoxListContent,
  BoxColumn,
} from './Styled'
import Point from './Point'
import { Column } from 'src/components/Layout/Styled'
import Badge from './Badge'

export const BoxLabel = ({ labelTop, labelBottom }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Typography variant="body1" color="text.secondary" sx={{ minWidth: 200 }}>
        {labelTop}
      </Typography>
      <Typography
        variant="body1"
        sx={{ minWidth: 200, wordWrap: 'break-word' }}
      >
        {labelBottom}
      </Typography>
    </Box>
  )
}

const Index = () => {
  const [user] = useLocalStorage('user')

  const details = useSelector((state) => state.myProfile.details, shallowEqual)

  const defaultImage = '/icon/img_empty.png'
  const image = _.get(details, 'userImg', null)
  const urlImage = `${window.__env__.REACT_APP_FILE_URL}${image}`

  const prefixTH = _.isNull(details.prefixTH) ? '' : details.prefixTH
  const firstNameTH = _.get(details, 'firstNameTH', '')
  const lastNameTH = _.get(details, 'lastNameTH', '') || ''
  const name = `${prefixTH}${firstNameTH} ${lastNameTH}`

  const station = _.get(details, 'areas[0].station', {})
  const userType = _.get(user, 'userType', '')

  return (
    <BoxCard sx={{ flexDirection: 'column' }}>
      <BoxContent>
        <BoxImage>
          <Image
            src={_.isNull(image) || image === '' ? defaultImage : urlImage}
          />
        </BoxImage>
        <BoxColumn>
          <BoxListContent isProfile>
            <BoxLabel labelTop="ชื่อ - นามสกุล" labelBottom={name} />
            <BoxLabel
              labelTop="วัน เดือน ปี เกิด"
              labelBottom={_.get(details, 'dateOfBirth', '-')}
            />
          </BoxListContent>
          <BoxListContent isProfile>
            <BoxLabel
              labelTop="เบอร์โทรศัพท์"
              labelBottom={_.get(details, 'mobileNo', '-')}
            />
            <BoxLabel
              labelTop="อีเมล"
              labelBottom={_.get(details, 'email', '-')}
            />
          </BoxListContent>
          {userType !== 'AGENT' && userType !== 'PROSPECT' && (
            <BoxListContent isProfile>
              <BoxLabel
                labelTop="Job Title"
                labelBottom={_.get(details, 'jobTitle.jobTitle', '-')}
              />
              <BoxLabel
                labelTop="Station"
                labelBottom={_.get(station, 'station', '-')}
              />
            </BoxListContent>
          )}
        </BoxColumn>
      </BoxContent>
      {userType == 'AGENT' && (
        <Column sx={{ gap: 3, mb: 3 }}>
          <Point />
          <Badge />
        </Column>
      )}
    </BoxCard>
  )
}

export default Index
