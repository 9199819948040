import React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import styled from '@mui/system/styled'

export const StyledListItem = styled((props) => (
  <ListItem {...props} disablePadding />
))(({ theme, active }) => ({
  borderBottom: `solid 1px ${theme.palette?.other?.divider}`,
  borderLeft: `solid 3px ${
    active ? theme.palette.secondary?.main : theme.palette?.background?.paper
  }`,
  '& .MuiListItemButton-root': {
    padding: '16px',
    '& .MuiTypography-root': { color: theme.palette.primary?.main },
    '& .MuiTypography-root:hover': { color: theme.palette.primary?.dark },
  },
}))

export const StyledLeftAppMenu = styled(Box)(({ theme, open }) => ({
  display: 'none',
  backgroundColor: theme.palette?.background?.paper,
  position: 'fixed',
  height: 'calc(100% - 72px)',
  width: '100%',
  top: 72,
  right: 0,
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  transition: 'transform .5s ease',
  boxShadow: theme.palette?.boxShadow?.menuInset,
  transform: open ? 'translate(0,0)' : 'translate(120%, 0)',
  '& .MuiList-root': {
    padding: 0,
  },
  [theme.breakpoints.down('lg')]: {
    display: 'unset',
  },
}))

export const StyledHoverList = styled(List)(() => ({
  position: 'relative',
  '& li:nth-of-type(1):hover ~ #line': hoverStyled(1),
  '& li:nth-of-type(2):hover ~ #line': hoverStyled(2),
  '& li:nth-of-type(3):hover ~ #line': hoverStyled(3),
  '& li:nth-of-type(4):hover ~ #line': hoverStyled(4),
  '& li:nth-of-type(5):hover ~ #line': hoverStyled(5),
  '& li:nth-of-type(6):hover ~ #line': hoverStyled(6),
  '& li:nth-of-type(7):hover ~ #line': hoverStyled(7),
  '& li:nth-of-type(8):hover ~ #line': hoverStyled(8),
  '& li:nth-of-type(9):hover ~ #line': hoverStyled(9),
  '& li:nth-of-type(10):hover ~ #line': hoverStyled(10),
  '& li:nth-of-type(11):hover ~ #line': hoverStyled(11),
  '& li:nth-of-type(12):hover ~ #line': hoverStyled(12),
  '& li:nth-of-type(13):hover ~ #line': hoverStyled(13),
}))

export const listHeight = 57

export const hoverStyled = (number) => ({
  display: 'block',
  top: listHeight * (number - 1),
})
