import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { formatData } from './event'

const ActionAgent = ({ row, sx }) => {
  const data = _.get(row, 'prospectProfile.refAgentCode', null)

  return (
    <Typography variant="body2" sx={sx}>
      {formatData(data)}
    </Typography>
  )
}

export default ActionAgent
