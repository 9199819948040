import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import FilterExpandedMenu from 'src/components/Filter/ExpandedMenu'
import { handleCheckboxChange } from './events'

const ExpandedMenu = ({ label, field, children, list }) => {
  const { myScheduleFilterBody } = useSelector(
    (state) => ({
      myScheduleFilterBody: _.get(
        state.classList.myScheduleFilterBody,
        field,
        []
      ),
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const itemList = list ?? []
  return (
    <FilterExpandedMenu
      field={field}
      label={label}
      list={itemList}
      filterBody={myScheduleFilterBody}
      defaultExpand={true}
      onChange={(e) => dispatch(handleCheckboxChange(e, myScheduleFilterBody))}
    >
      {children}
    </FilterExpandedMenu>
  )
}

export default ExpandedMenu
