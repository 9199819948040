import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import AppMenu from './AppMenu'
import RightAppMenu from './RightAppMenu'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import {
  StyledAppBar,
  StyledAppMenuButton,
  StyledHoverLine,
  StyledHoverTab,
  StyledLink,
  StyledToolbar,
} from './Styled'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { handleDialogSentMail } from './RightAppMenu/events'
import TapMenuDropdown from './TapMenuDropdown'
import { menuList, noLoginMenuList } from './model'

const Appbar = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)
  const user = localStorage.getItem('user')
  const isLogin = !!user
  const { alertProspect, coursePosition } = useSelector(
    (state) => ({
      alertProspect: state.manageProspectEnrollment.alertProspect,
      coursePosition: state.user.coursePosition,
    }),
    shallowEqual
  )
  const checked = checkSentMailPosoect(alertProspect)
  const [isOnPosition, setOnPosition] = useState(false)
  const displayMenuList = isLogin ? menuList : noLoginMenuList

  const handleScroll = () => {
    if (window.location.pathname === '/') {
      const position = window.pageYOffset
      setOnPosition(position >= coursePosition)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [coursePosition])

  useEffect(() => {
    if (isOpen) {
      document.querySelector('body').style.position = 'fixed'
    } else {
      document.querySelector('body').style.position = 'unset'
    }
  }, [isOpen])

  return (
    <Box>
      <StyledAppBar>
        <StyledToolbar>
          <img
            data-testid="btn-home"
            src="/logo/logo_app.png"
            id="logo"
            alt="AMS"
            height={48}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              checked ? dispatch(handleDialogSentMail()) : history.push('/')
            }}
          />

          <Box>
            <StyledHoverTab isLogin={isLogin}>
              {displayMenuList.map((menu, index) => {
                if (menu.isMobile) {
                  return <Fragment key={index}></Fragment>
                }
                if (menu.type === 'menu') {
                  return <TapMenuDropdown key={index} menu={menu} />
                }
                return (
                  <TapMenu
                    key={index}
                    href={menu.href}
                    onClick={menu.onClick}
                    isOnPosition={menu.isPositionCheck && isOnPosition}
                  >
                    {menu.label}
                  </TapMenu>
                )
              })}

              <StyledHoverLine id="line" />
            </StyledHoverTab>
            {isLogin && <AppMenu />}
          </Box>
          <StyledAppMenuButton>
            <Divider />
            <IconButton
              data-testid="btn-toggle"
              size="large"
              onClick={() => setOpen(!isOpen)}
            >
              <AppMenuIcon isOpen={isOpen} />
            </IconButton>
          </StyledAppMenuButton>
        </StyledToolbar>
        <RightAppMenu open={isOpen} setOpen={setOpen} isLogin={isLogin} />
      </StyledAppBar>
    </Box>
  )
}

export default Appbar

export const AppMenuIcon = ({ isOpen }) => {
  return isOpen ? <CloseIcon /> : <MenuIcon />
}

export const checkSentMailPosoect = (alertProspect) => {
  const url = window.location.pathname
  return alertProspect && url === '/manage-prospect/enrollment'
}

export const TapMenu = ({ href, children, onClick, isOnPosition }) => {
  const dispatch = useDispatch()
  const alertProspect = useSelector(
    (state) => state.manageProspectEnrollment.alertProspect,
    shallowEqual
  )
  const checked = checkSentMailPosoect(alertProspect)
  const isActive =
    (isOnPosition && href !== '/') ||
    (window.location.pathname === href && !isOnPosition)

  return (
    <StyledLink
      href={!checked ? href : null}
      onClick={
        onClick ? onClick : () => checked && dispatch(handleDialogSentMail())
      }
      underline="none"
      active={isActive ? 1 : 0}
    >
      <Typography variant={isActive ? 'body1b' : 'body1'}>
        {children}
      </Typography>
    </StyledLink>
  )
}
