import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { courseFilter, courseList} from 'src/utils/apiPath'

export const getCourseFilter = createAsyncThunk(
  'courseList/getCourseFilter',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(courseFilter)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postCourseList = createAsyncThunk(
  'courseList/postCourseList',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(courseList, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
