const { createSlice } = require('@reduxjs/toolkit')

export const initialState = {
  isLoading: false,
  isLogin: false,
  coursePosition: 2576,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLogin: (state) => {
      state.isLogin = true
    },
    setCoursePosition: (state, { payload }) => {
      state.coursePosition = payload
    },
  },
})

export const { setLogin, setCoursePosition } = userSlice.actions

export default userSlice.reducer
