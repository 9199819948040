import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import FilterList from '@mui/icons-material/FilterList'
import Typography from '@mui/material/Typography'
import ToggleButton from '@mui/material/ToggleButton'
import { myClassFilter } from 'src/constants/course'
import DateRangeInput from 'src/components/DateRangeInput'
import { Row } from 'src/components/Layout/Styled'
import { setFilter, setSearch } from 'src/redux/slices/class/list'
import Search from '../../../components/Search'
import {
  StyledFilterContainer,
  StyledSearchDateRow,
  StyledToggleButtons,
} from './Styled'
import { handleChangePeriod, handleResetPeriod } from './events'

const FilterHeader = () => {
  const dispatch = useDispatch()
  const { tab, filter, filterBody } = useSelector(
    (state) => state.classList,
    shallowEqual
  )
  const filterTab = _.get(myClassFilter, tab, null)
  return (
    <StyledFilterContainer>
      {filterTab && (
        <Row gap={2.5}>
          <Row sx={{ width: 'auto', gap: 1 }}>
            <FilterList fontSize="small" color="text.secondary" />
            <Typography variant="body2">ตัวกรอง:</Typography>
          </Row>
          <StyledToggleButtons
            color="primary"
            value={filter}
            onChange={(e, newVal) => dispatch(setFilter(newVal))}
          >
            {filterTab.map((item, index) => (
              <ToggleButton
                data-testid={`btn-${index}`}
                key={index}
                value={item.value}
              >
                {item.label}
              </ToggleButton>
            ))}
          </StyledToggleButtons>
        </Row>
      )}

      <SearchAndDatePicker filterBody={filterBody} />
    </StyledFilterContainer>
  )
}

export default FilterHeader

export const SearchAndDatePicker = ({ filterBody }) => {
  const dispatch = useDispatch()
  const courseName = _.get(filterBody, 'courseName', '')
  const startDate = _.get(filterBody, 'startDate', '')
  const finishDate = _.get(filterBody, 'finishDate', '')
  const [text, setText] = useState(courseName)
  const isStartDate = startDate != ''
  const isFinishDate = finishDate != ''
  const defaultStart = isStartDate ? new Date(startDate) : new Date()
  const defaultFinish = isFinishDate ? new Date(finishDate) : new Date()

  useEffect(() => {
    setText(courseName)
  }, [courseName])

  return (
    <StyledSearchDateRow>
      <Search
        placeholder="ค้นหาชื่อหลักสูตร"
        text={text}
        setText={(e) => setText(e)}
        handleSearch={() => dispatch(setSearch(text))}
      />
      <DateRangeInput
        label="วันที่เริ่มอบรม:"
        placeholder="วว/ดด/ปปปป - วว/ดด/ปปปป"
        dateState={{
          startDate: defaultStart,
          endDate: defaultFinish,
          display: isStartDate,
          key: 'selection',
        }}
        onChange={(selectedDates) =>
          dispatch(handleChangePeriod(selectedDates))
        }
        onReset={() => dispatch(handleResetPeriod())}
      />
    </StyledSearchDateRow>
  )
}
