import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { StyledDialog } from 'src/components/AlertDialog/Styled'
import { MILLI_PER_SEC, SEC_PER_MIN } from 'src/constants'
import CameraContent from './CameraContent'
import {
  handleCheckAllowScan,
  handleOpenDialog,
  handleRandomTime,
  handleToggleTime,
} from './events'

const AlertDialog = () => {
  const { isDialogOpen, resetMins, round } = useSelector(
    (state) => state.eLearningLearning.verifyDialog
  )
  const dispatch = useDispatch()

  // handle random time every 30 minutes
  useEffect(() => {
    dispatch(handleRandomTime())
    const intervalCall = setInterval(() => {
      console.log('reset random time')
      dispatch(handleRandomTime())
    }, resetMins * SEC_PER_MIN * MILLI_PER_SEC)
    return () => clearInterval(intervalCall)
  }, [])

  // handle open dialog when toggle
  useEffect(() => {
    const toggleMins = handleToggleTime()
    console.log('toggleMins', toggleMins)
    const intervalCall = setInterval(() => {
      dispatch(handleOpenDialog())
    }, toggleMins * SEC_PER_MIN * MILLI_PER_SEC)
    return () => clearInterval(intervalCall)
  }, [round])

  useEffect(() => {
    dispatch(handleCheckAllowScan(isDialogOpen))
  }, [isDialogOpen])

  return (
    <Box>
      <StyledDialog
        maxWidth="sm"
        fullWidth={true}
        customWidth={532}
        sx={{ zIndex: '1298 !important' }}
        open={isDialogOpen}
      >
        <CameraContent />
      </StyledDialog>
    </Box>
  )
}

export default AlertDialog
