import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const imageStyle = { margin: '0 auto', width: '100%' }

export const StyledFileRenderWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: `0 ${theme.spacing(10)} ${theme.spacing(10)}`,
  img: {
    objectFit: 'contain',
    border: '5px solid',
    borderColor: theme.palette?.text?.gray,
  },
  [theme.breakpoints.down('lg')]: {
    padding: `0 ${theme.spacing(5)} ${theme.spacing(5)}`,
  },
  [theme.breakpoints.down('lg')]: {
    padding: `0 ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
}))

export const StyledToolWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 175,
  top: -70,
  zIndex: 99,
  display: 'flex',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: { left: 152, gap: theme.spacing(0.5) },
}))

export const StyledCenteredContent = {
  position: 'absolute',
  top: 200,
  left: '50%',
  transform: 'translate(-50%)',
}

export const StyledZipDisplay = styled('img')(() => ({
  width: 100,
  ...StyledCenteredContent,
}))

export const StyledZipDownloadBtn = styled(Button)(() => ({
  ...StyledCenteredContent,
  top: 335,
}))
