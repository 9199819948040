import _ from 'lodash'
import React from 'react'
import { onEnterSearch } from './events'
import { BoxSearch, SearchButton, SearchInput } from './Styled'

const SearchCustom = (props) => {
  const { handleSearch, text, setText, boxSx } = props
  const setSearchText = _.get(props, 'setSearchText', () => {})
  return (
    <BoxSearch sx={{ ...boxSx }}>
      <SearchInput
        data-testid="search-input"
        placeholder={props.placeholder}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(e) => onEnterSearch(e, setSearchText, handleSearch)}
        value={text}
      />
      <SearchButton
        data-testid="search-icon"
        color="action.active"
        onClick={() => {
          setSearchText(text)
          handleSearch(text)
        }}
      />
    </BoxSearch>
  )
}

export default SearchCustom
