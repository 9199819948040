import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import IconButton from '@mui/material/IconButton'

import { StyledBox } from '../../Styled'
import { setOnView } from '../../../../../redux/slices/table'

const Index = ({ row, rowIndex }) => {
  const dispatch = useDispatch()
  const { onDelete } = useSelector((state) => state.table)

  const viewUrl = `/course/${row.uuid}`
  const name = _.get(row, 'courseName', '')

  return (
    <StyledBox>
      <IconButton
        data-testid={`btn-view-${rowIndex}`}
        color="primary"
        component="span"
        onClick={() => dispatch(setOnView(viewUrl))}
      >
        <RemoveRedEyeTwoTone color="action" />
      </IconButton>
      {row.deleteIcon && (
        <IconButton
          data-testid={`btn-delete-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onDelete(row.classLearnerUuid, name)}
        >
          <DeleteTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default Index
