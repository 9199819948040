import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './style.css'
import { StyledCarousel } from './Styled'

const CustomCarousel = ({
  children,
  ariaLabel,
  selectedItem,
  infiniteLoop = true,
  autoPlay = true,
  swipeable = true,
  emulateTouch = true,
  showArrows = false,
  showStatus = false,
  showThumbs = false,
  showIndicators = true,
  stopOnHover = false,
  interval = 5000,
  swipeScroll = 50,
  renderArrowPrev,
  renderArrowNext,
  renderIndicator,
  onChange,
}) => {
  return (
    <StyledCarousel
      ariaLabel={ariaLabel}
      selectedItem={selectedItem}
      infiniteLoop={infiniteLoop}
      autoPlay={autoPlay}
      swipeable={swipeable}
      emulateTouch={emulateTouch}
      showArrows={showArrows}
      showStatus={showStatus}
      showThumbs={showThumbs}
      showIndicators={showIndicators}
      stopOnHover={stopOnHover}
      interval={interval}
      swipeScrollTolerance={swipeScroll}
      preventMovementUntilSwipeScrollTolerance
      renderArrowPrev={renderArrowPrev}
      renderArrowNext={renderArrowNext}
      renderIndicator={renderIndicator}
      onChange={onChange}
    >
      {children}
    </StyledCarousel>
  )
}

export default CustomCarousel
