import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledInputWrapper = styled(Row)(({ theme }) => ({
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  height: 40,
  padding: '0 20px',
  background: theme.palette?.text?.mercury,
  color: theme.palette?.text?.primary,
  border: '1px solid',
  borderColor: theme.palette?.text?.silver,
  borderLeft: 'none',
  ':hover': {
    background: theme.palette?.text?.mercury,
  },
  [theme.breakpoints.down('sm')]: { minWidth: 92 },
}))

export const StyledTypography = styled(Typography)(({theme}) => ({
  padding: `0 ${theme.spacing(2.5)}`,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-all',
}))