import Button from '@mui/material/Button'
import styled from '@mui/system/styled'

export const StyledButtonCert = styled(Button)(({ theme, isCanDownloadCert }) => ({
  padding: '0 22px',
  minWidth: isCanDownloadCert ? 165 : 149,
  width: '100%',
  height: '100%',
  gap: theme.spacing(1),
  ':hover': { img: { filter: 'brightness(0) invert(1)' } },
}))
