import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StlyedContent = styled(Column)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(7.5)} 0`,
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))

export const StyledLabelIcon = styled(Row)(({ theme }) => ({
  width: 160,
  minWidth: 160,
  gap: theme.spacing(1),
  color: theme.palette.text?.secondary,
  [theme.breakpoints.down('sm')]: {
    width: 148,
    minWidth: 148,
  },
}))
