import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import BackToMainBtn from '../BackToMainBtn'
import SubmitBtn from '../SubmitBtn'

const ButtonForAll = () => {
  const onReviewAnswer = useSelector(
    (state) => state.eTesting.onReviewAnswer,
    shallowEqual
  )

  if (onReviewAnswer) return <BackToMainBtn />

  return <SubmitBtn isDisplayAll />
}

export default ButtonForAll
