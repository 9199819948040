import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { toggleDrawer } from './events'
import { StyledDrawer } from './Styled'
import FilterSidebar from '../FilterSidebar'

const FilterDrawer = ({ isOpen, setOpen }) => {
  return (
    <StyledDrawer open={isOpen} onClose={toggleDrawer(false, setOpen)}>
      <Box id="header">
        <Typography variant="h6">ตัวกรอง</Typography>
        <IconButton
          data-testid="btn-close"
          color="primary"
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider id="divider" />
      <Box id="body">
        <FilterSidebar isDrawer />
      </Box>
    </StyledDrawer>
  )
}

export default FilterDrawer
