import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import Search from '@mui/icons-material/Search'
import { InputBase } from '@mui/material'

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDrawer',
})(({ theme, isDrawer }) => ({
  width: '100%',
  maxWidth: 286,
  height: '100%',
  backgroundColor: theme.palette.background?.paper,
  [theme.breakpoints.down('lg')]: {
    display: isDrawer ? 'block' : 'none',
  },
}))

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  width: 20,
  height: 20,
  cursor: 'pointer',
  // marginTop: theme.spacing(1),
}))

export const BoxSearch = styled(Box)(({ theme }) => ({
  borderRadius: theme?.shape?.borderRadius,
  border: `1px solid ${theme.palette.text?.silver}`,
  backgroundColor: theme?.palette?.text?.white,
  width: '100%',
  minWidth: '250px',
  '&:hover': {
    backgroundColor: theme?.palette?.text?.wildSand,
  },
  marginLeft: 0,
  marginTop: 9,
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  [theme.breakpoints.up('lg')]: {
    width: 'auto',
    maxHeight: '37.5px',
  },
}))

export const SearchInput = styled(InputBase)(({ theme }) => ({
  maxHeight: '37.5px',
  width: 'calc(100% - 36px)',
  marginLeft: theme.spacing(1),
}))
