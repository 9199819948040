import _ from 'lodash'
import { COURSE_TYPE } from 'src/constants/eLearning'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { postCourseLogLearner } from 'src/services/eLearning/learning'
import {
  setChangeCamera,
  openDialog as openDetailDialog,
} from 'src/redux/slices/eLearning/detail'
import { fetchCourseDetail } from 'src/modules/ELearning/Course/Detail/events'

export const isHomePage = () => {
  return window.location.pathname === '/'
}

export const getLearningUrl = (course) =>
  `/e-learning/course/learning/${_.get(course, 'uuid', '')}`

export const handleGetInClass = (course) => async (dispatch) => {
  const courseType = _.get(course, 'courseType', '')

  if (courseType === COURSE_TYPE.OIC) {
    const response = await dispatch(fetchCourseDetail(course.uuid))

    if (_.isEmpty(response.error)) {
      dispatch(setChangeCamera({ key: 'isOpen', value: false }))
      dispatch(openDetailDialog())
    }
  } else {
    const payload = { eLearningCourse: course, eLearningCourseBatch: null }
    dispatch(startLoading())
    const response = await dispatch(postCourseLogLearner(payload))
    dispatch(stopLoading())

    if (_.isEmpty(response.error)) {
      window.location.href = getLearningUrl(course)
    }
  }
}
