import _ from 'lodash'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { openErrorDialog } from 'src/redux/slices/alertDialog'
import { getEEvaluationDetail } from 'src/services/eEvaluation'

export const fetchEEvaluation = (payload, isModule) => async (dispatch) => {
  if (isModule) return

  dispatch(startLoading())
  await dispatch(getEEvaluationDetail(payload))
  dispatch(stopLoading())
}

export const handleError = (error) => (dispatch) => {
  if (_.isNil(error)) return

  dispatch(openErrorDialog({ message: error.value || error.message }))
}
