import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledSequenceContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const StyledAnswerList = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'resultProps',
})(({ theme, resultProps }) => ({
  minHeight: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: resultProps.onViewState
    ? theme.spacing(1.5)
    : `0 ${theme.spacing(1)} 0 0`,
  border: resultProps.onViewState ? 'none' : '1px solid',
  borderColor: theme.palette?.text?.mercury,
  borderRadius: 2,
  background: displayResult(theme, resultProps),
}))

export const StyledAnswerContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'onViewState',
})(({ onViewState }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: onViewState ? 10 : 0,
}))

export const StyledDragBox = styled(Box)(({ theme, img }) => ({
  display: 'flex',
  minHeight: img ? 100 : 48,
  width: 76,
  minWidth: 76,
  paddingRight: theme.spacing(2.5),
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    minHeight: img ? 178 : 48,
  },
}))

export const StyledImgBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'onViewState',
})(({ theme, onViewState }) => ({
  width: onViewState ? 100 : 150,
  height: 100,
  borderLeft: '1px solid',
  borderRight: '1px solid',
  borderColor: onViewState
    ? 'transparent'
    : theme?.palette?.other?.outlineBorder,
  '& > img': {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
}))

export const StyledNumberBadge = styled(Box)(({ theme, isAnswer }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 25,
  color: theme.palette?.text?.white,
  borderRadius: '100%',
  backgroundColor: isAnswer
    ? theme.palette?.success?.main
    : theme.palette?.error?.main,
}))

export const StyledChoiceContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: 'calc(100% - 76px)',
    padding: `${theme.spacing(2)} 0`,
    alignItems: 'start',
    gap: theme.spacing(2),
  },
}))

export const displayResult = (theme, resultProps) => {
  const { onViewState, questionResult, isAnswer } = resultProps
  const { isOpen } = questionResult

  if (!onViewState || !isOpen)
    return 'linear-gradient(180deg, #FAFAFA 0%, #EEE 100%)'

  return isAnswer
    ? theme?.palette?.success?.background
    : theme?.palette?.error?.background
}
