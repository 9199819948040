import { ECONTENT_TYPE } from 'src/modules/EContent/model'

export const initialState = {
  landing: { E_CONTENT: [], KB: [] },
  dataList: [],
  totalCount: 0,
  contentData: { eContentTag: [], contents: [] },
  filter: {
    type: [ECONTENT_TYPE.E_CONTENT],
    keyword: '',
    category: '',
    subCategory: '',
    tag: [],
    startPublishDateFrom: '',
    startPublishDateTo: '',
    page: 1,
    order: 'DESC',
    tagSelected: [],
  },
  filterMemo: {
    type: [ECONTENT_TYPE.E_CONTENT],
    keyword: '',
    category: '',
    subCategory: '',
    tag: [],
    startPublishDateFrom: '',
    startPublishDateTo: '',
    page: 1,
    order: 'DESC',
    tagSelected: [],
  },
  filterChecked: {
    category: false,
    subCategory: false,
    tagSelected: false,
    startPublishDate: false,
  },
  filterCheckedMemo: {
    category: false,
    subCategory: false,
    tagSelected: false,
    startPublishDate: false,
  },
  isFetchSearch: false,
  options: {
    category: [],
    subCategory: [],
    tag: [],
  },
  related: {
    list: [],
    excludeId: [],
  },
  isFetch: false,
  printLoading: false,
  downloadLoading: false,
}
