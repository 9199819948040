export const formatData = (field) => {
  let text = ''
  let isAgent = false
  if (typeof field === 'string') {
    isAgent = true
  }
  switch (isAgent) {
    case true:
      text = 'เป็นตัวแทนแล้ว'
      break
    case false:
      text = '-'
      break
    default:
      text = '-'
      break
  }
  return text
}
