import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Column, Row } from 'src/components/Layout/Styled'
import { SectionLabelIcon } from '../..'
import { StyledPreview } from '../Styled'
import UploadFileInput from './UploadFileInput'
import IdCardInput from './IdCardInput'
import { StyledBox, StyledInputWrapper } from './Styled'

const IdCardSection = () => {
  const idCardImage = useSelector(
    (state) => state.eLearningEnroll.body.idCardImage,
    shallowEqual
  )
  const imgSrc = `${window.__env__.REACT_APP_REKOGNITION_URL}${idCardImage}`
  return (
    <Column pb={3} gap={2}>
      <SectionLabelIcon
        label="อัปโหลดรูปบัตรประจำตัวประชาชน / ใบขับขี่"
        icon="ic_address_card"
      />
      {idCardImage && (
        <>
          <StyledPreview src={imgSrc} width={101} height={64} />
          <Row gap={1}>
            <img src="/icon/e-learning/ic_success_circle.svg" />
            <Typography variant="body2" color="success.main">
              อัปโหลดบัตรประชาชนแล้ว
            </Typography>
          </Row>
        </>
      )}
      <StyledInputWrapper>
        <UploadFileInput />
        <StyledBox>
          <Typography>หรือ</Typography>
        </StyledBox>
        <IdCardInput />
      </StyledInputWrapper>
    </Column>
  )
}

export default IdCardSection
