import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledContainerBg = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'withHeader' && prop !== 'withFooter',
})((props) => {
  const { theme, withHeader, withFooter } = props
  const minHeightWithHeader = withFooter
    ? 'calc(100vh - 507px)'
    : 'calc(100vh - 194px)'
  const minHeightWithHeaderMd = withFooter
    ? 'calc(100vh - 440px)'
    : 'calc(100vh - 162px)'
  const minHeightWithHeaderSm = withFooter
    ? 'calc(100vh - 524px)'
    : 'calc(100vh - 162px)'
  return {
    display: 'flex',
    flexDirection: 'column',
    position: withFooter ? 'relative' : 'absolute',
    zIndex: !withFooter && 10,
    width: '100%',
    minHeight: withHeader ? minHeightWithHeader : 'calc(100vh - 385px)',
    marginTop: withHeader ? 0 : 72,
    backgroundColor: theme.palette.background?.paper,
    [theme.breakpoints.down('lg')]: {
      minHeight: withHeader ? minHeightWithHeaderMd : 'calc(100vh - 350px)',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: withHeader ? minHeightWithHeaderSm : 'calc(100vh - 434px)',
    },
  }
})
