import React, { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import Template from 'src/components/Template'
import { Column, Row } from 'src/components/Layout/Styled'
import InputText from 'src/components/Input/TextInput'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Consent from './Consent'
import Footer from './Footer'
import { steps, verificationSteps } from './model'
import {
  StyledConnector,
  StyledStep,
  StyledStepIcon,
  StyledStepper,
  StyledWrapper,
} from './Styled'
import { fetchCourseDetail } from './events'

const Enroll = ({ isVerification }) => {
  const { id: uuid } = useParams()
  const activeStep = useSelector(
    (state) => state.eLearningEnroll.activeStep,
    shallowEqual
  )
  const dispatch = useDispatch()
  const displaySteps = isVerification ? verificationSteps : steps
  const displayContent = isVerification ? VerificationStepContent : StepContent
  const isShowConsent = (isVerification && activeStep === 1) || activeStep === 2

  useEffect(() => {
    dispatch(fetchCourseDetail(uuid, isVerification))
  }, [])

  return (
    <>
      <Template sx={{ marginTop: 9 }} noMinHeight={isShowConsent}>
        <StyledWrapper>
          <Box id="stepper">
            <StyledStepper
              alternativeLabel
              activeStep={activeStep}
              connector={<StyledConnector />}
            >
              {displaySteps.map((label, index) => (
                <StyledStep id={`step-${index}`} key={label}>
                  <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                </StyledStep>
              ))}
            </StyledStepper>
          </Box>
          {displayContent[activeStep]}
        </StyledWrapper>
      </Template>
      {isShowConsent && <Consent />}
      <Footer isVerification={isVerification} />
    </>
  )
}

export default Enroll

export const StepContent = [
  <Step1 key="step-1" />,
  <Step2 key="step-2" />,
  <Step3 key="step-3" />,
]

export const VerificationStepContent = [
  <Step2 key="step-1" isVerification />,
  <Step3 key="step-2" />,
]

export const StepIcon = (props) => {
  const { active, completed, className } = props
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <StyledStepIcon ownerState={{ completed, active }} className={className}>
      <Typography variant={isNotSm ? 'h5' : 'body1b'}>{props.icon}</Typography>
    </StyledStepIcon>
  )
}

export const StepTitle = ({ label, description, pb = 5 }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <Column gap={1} pb={isNotSm ? pb : 3}>
      <Typography variant={isNotSm ? 'h4' : 'h6'}>{label}</Typography>
      <Typography variant={isNotSm ? 'body1' : 'body2'}>
        <Typography variant="span" color="error">
          *
        </Typography>{' '}
        {description}
      </Typography>
    </Column>
  )
}

export const SectionLabelIcon = ({ label, icon }) => {
  return (
    <Row id="section-label" gap={1}>
      <img src={`/icon/e-learning/${icon}.svg`} />
      <Typography variant="h6">{label}</Typography>
    </Row>
  )
}

export const DisabledInput = ({ label, value, placeholder }) => {
  return (
    <InputText
      required
      disabled
      type="basic"
      labelText={label}
      placeholder={placeholder}
      isShowTextError={false}
      value={value}
    />
  )
}
