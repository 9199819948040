import React, { useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { StyledMenu } from './Styled'

const ExpandedMenu = (props) => {
  const { field, label, list, filterBody, defaultExpand, children, onChange } =
    props
  const [isExpanded, setExpanded] = useState(defaultExpand)

  return (
    <StyledMenu>
      <Box data-testid={label} onClick={() => setExpanded(!isExpanded)}>
        <Typography variant="h6">{label}</Typography>
        <IconButton data-testid="btn-toggle">
          {isExpanded ? (
            <KeyboardArrowUp color="primary" />
          ) : (
            <KeyboardArrowDown color="primary" />
          )}
        </IconButton>
      </Box>
      {isExpanded ? (
        <ExpandedContent
          list={list}
          field={field}
          filterBody={filterBody}
          onChange={onChange}
        >
          {children}
        </ExpandedContent>
      ) : (
        <Box />
      )}
      <Divider />
    </StyledMenu>
  )
}

export default ExpandedMenu

export const ExpandedContent = (props) => {
  const { children, list, field, filterBody, onChange } = props
  if (children) return children
  return (
    <FormGroup>
      {list.map((item, index) => {
        const value = _.get(item, 'value', '')
        return (
          <FormControlLabel
            sx={{
              alignItems: 'start',
              '& .MuiTypography-root': { pt: 1 },
            }}
            key={index}
            control={<Checkbox value={value} name={field} onClick={onChange} />}
            label={_.get(item, 'label', '')}
            checked={filterBody.some((filter) => filter == value)}
          />
        )
      })}
    </FormGroup>
  )
}
