import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState } from './model'

const manageProspect = createSlice({
  name: 'manageProspect',
  initialState,
  reducers: {
    setChangeType: (state, { payload }) => {
      state.type = payload.caseType
      state.userType = payload.userType
    },
    setChangeInfo: (state, { payload }) => {
      _.set(state, `information.${payload.key}`, payload.value)
    },
    setChangeEducational: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, `information.${key}`, value)
    },
    setAddEducational: (state) => {
      const educational = state.information.educational
      _.set(state, 'information.educational', [
        ...educational,
        ...initialState.information.educational,
      ])
    },
    setDeleteEducational: (state, { payload }) => {
      const educational = state.information.educational
      if (educational.length == 1) {
        _.set(
          state,
          'information.educational',
          initialState.information.educational
        )
      } else {
        educational.splice(payload, 1)
        _.set(state, 'information.educational', educational)
      }
    },
    fetchDataForm: (state, { payload }) => {
      state.information = payload.information
      state.history = payload.history
    },
    setClearData: (state) => {
      state.isLoading = false
      state.type = 'create'
      state.userType = ''
      state.information = initialState.information
      state.history = []
    },
    setSelectActionType: (state, { payload }) => {
      state.selectActionType = payload
    },
    setSelectedList: (state, { payload }) => {
      state.selectedList = payload
    },
  },
})

export const {
  setClearData,
  setChangeType,
  setChangeInfo,
  setChangeEducational,
  setDeleteEducational,
  setAddEducational,
  fetchDataForm,
  setSelectActionType,
  setSelectedList,
} = manageProspect.actions

export default manageProspect.reducer
