import {
  setLoadMore,
  startLoadMore,
  stopLoadMore,
} from 'src/redux/slices/eExamination/announceExaminer/list'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { isScrolledIntoElement } from 'src/utils/lib'
import { getAnnounceFilter } from 'src/services/eExamination'

export const handleFetchAnnounceExaminerList = (body) => async (dispatch) => {
  await dispatch(getAnnounceFilter(body))
}

export const fetchAnnounceExaminerList = (body) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(handleFetchAnnounceExaminerList(body))
  dispatch(stopLoading())
}

export const handleLoadMore = () => (dispatch) => {
  const footer = document.getElementById('page-footer')
  const isFooterAppeared = isScrolledIntoElement(footer)
  if (isFooterAppeared) dispatch(setLoadMore())
}

export const handleSeeMore = (filterBody) => async (dispatch) => {
  if (filterBody.page == 1) return

  dispatch(startLoadMore())
  await dispatch(handleFetchAnnounceExaminerList(filterBody))
  dispatch(stopLoadMore())
}
