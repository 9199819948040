import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(10)} ${theme.spacing(6.5)}`,
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(5)}`,
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
}))

export const StyledRowContainer = styled(Row)(({ theme }) => ({
  marginTop: '70px',
  alignItems: 'start',
  gap: theme.spacing(5),
  [theme.breakpoints.down('lg')]: { marginTop: '122px' },
}))

export const StyledCardBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: 954,
  minWidth: 954,
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: theme.spacing(4),
  marginLeft: 'auto',
  marginRight: 'auto',
  gap: theme.spacing(3),
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: { width: '100%', minWidth: '100%' },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    minWidth: '100%',
    justifyContent: 'center',
    '& > .MuiGrid-root': { justifyContent: 'center' },
  },
}))

export const StyledLoading = styled(CircularProgress)(({ theme }) => ({
  position: 'relative',
  top: 10,
  left: '50%',
  translate: '-50%',
  width: 24,
  height: 24,
  [theme.breakpoints.down('sm')]: { left: 0, translate: 0 },
}))
