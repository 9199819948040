import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { visuallyHidden } from '@mui/utils'
import { useDispatch, useSelector } from 'react-redux'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import { setToggleFilterDrawer } from '../../redux/slices/table'
import {
  StyledBox,
  StyledDownloadButton,
  StyledFilterButton,
  StyledFullWidth,
  StyledTable,
  StyledTableBodyCell,
  StyledTableHeader,
  StyledTableHeadText,
  StyledTableSortLabel,
  StyledToolbar,
  StyledButtonSection,
  StyledTableCell,
} from './Styled'
import Search from './Search'
import * as events from './events'
import { ROW_PAGE_PER_LIST } from '../../constants/table'

export const EnhancedTableToolbar = () => {
  const dispatch = useDispatch()
  const {
    onDownload,
    handleSearch,
    table,
    customToolbar,
    filter: { filterTotal },
  } = useSelector((state) => state.table2)
  const { selected, sort, order, isHideSearch } = table
  return (
    <StyledToolbar>
      <StyledBox>
        {handleSearch && !isHideSearch && <Search />}
        {onDownload && (
          <StyledButtonSection>
            {customToolbar}
            {onDownload !== 'hidden' && (
              <StyledDownloadButton
                data-testid="btn-download"
                disabled={!selected.length > 0}
                onClick={() => onDownload(selected, sort, order)}
              >
                ดาวน์โหลด
              </StyledDownloadButton>
            )}
            <StyledFilterButton
              data-testid="btn-filter"
              onClick={() => dispatch(setToggleFilterDrawer(true))}
            >
              ตัวกรอง {filterTotal != 0 && `(${filterTotal})`}
            </StyledFilterButton>
          </StyledButtonSection>
        )}
      </StyledBox>
    </StyledToolbar>
  )
}

export const EnhancedTableHead = () => {
  const dispatch = useDispatch()
  const { table } = useSelector((state) => state.table2)
  const {
    rows,
    tableHeadText,
    isCheckBox,
    selected,
    numSelected,
    headCells,
    defaultSort,
    order,
    sort,
  } = table
  const rowCount = rows.length
  const selectedCount = selected.length

  return (
    <TableHead>
      {tableHeadText && (
        <TableRow key="headText">
          <StyledTableHeadText colSpan={headCells.length}>
            <Typography variant="body2b">{tableHeadText}</Typography>
          </StyledTableHeadText>
        </TableRow>
      )}
      <TableRow key="head">
        {isCheckBox && (
          <TableCell padding="checkbox" sx={{ p: 0 }}>
            <Checkbox
              data-testid="select-all-element"
              color="primary"
              indeterminate={numSelected > 0 && selectedCount < rowCount}
              checked={rowCount > 0 && selectedCount === rowCount}
              onChange={(event) =>
                dispatch(events.handleSelectAllClick(event, rows))
              }
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        )}
        {headCells &&
          headCells.map((headCell) => {
            const valueId = headCell.id
            let checkActive = false

            if (defaultSort && valueId === defaultSort.id) {
              checkActive = defaultSort.active
            }
            return (
              <StyledTableHeader
                key={valueId}
                align={'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={sort === valueId ? order : false}
                headcellwidth={headCell.width}
                headcellminWidth={headCell.minWidth}
              >
                <StyledTableSortLabel
                  data-testid={`sort-cell-${valueId}`}
                  active={checkActive}
                  direction={sort === valueId ? order : 'asc'}
                  onClick={() =>
                    dispatch(events.handleRequestSort(valueId, table, headCell))
                  }
                  hideSortIcon={headCell.hideSortIcon}
                  headcell={headCell}
                >
                  {headCell.label}
                  {sort === valueId && (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  )}
                </StyledTableSortLabel>
              </StyledTableHeader>
            )
          })}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTable = () => {
  const dispatch = useDispatch()
  const { table, customStyle, onDownload, handleSearch } = useSelector(
    (state) => state.table2
  )

  const {
    rows,
    selected,
    isCheckBox,
    headCells,
    isShowPagination,
    allCount,
    rowsPerPage,
    tablePage,
    sxTable,
    hiddenRowsPerPage,
  } = table

  return (
    <StyledFullWidth sx={customStyle}>
      {(onDownload || handleSearch) && <EnhancedTableToolbar />}
      <TableContainer>
        <StyledTable sx={sxTable}>
          <EnhancedTableHead />
          <TableBody>
            {rows &&
              rows.map((row, rowIndex) => {
                const isItemSelected = events.isSelected(selected, row.uuid)
                const labelId = `enhanced-table-checkbox-${rowIndex}`
                return (
                  <TableRow
                    key={rowIndex}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    color="primary"
                    type="hover"
                  >
                    {isCheckBox && (
                      <StyledTableCell padding="checkbox" sx={{ p: 0 }}>
                        <Checkbox
                          data-testid={`select-${rowIndex}-element`}
                          onClick={() =>
                            dispatch(events.handleClick(selected, row.uuid))
                          }
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </StyledTableCell>
                    )}
                    {headCells &&
                      headCells.map((_, cellIndex) =>
                        events.checkConditionView(
                          headCells,
                          row,
                          rowIndex,
                          cellIndex
                        )
                      )}
                  </TableRow>
                )
              })}
            {rows.length === 0 && (
              <TableRow>
                <StyledTableBodyCell
                  colSpan={isCheckBox ? headCells.length + 1 : headCells.length}
                >
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledTableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {isShowPagination && (
        <TablePagination
          data-testid="page-test"
          rowsPerPageOptions={!hiddenRowsPerPage ? ROW_PAGE_PER_LIST : []}
          component="div"
          count={allCount}
          rowsPerPage={rowsPerPage.value}
          labelRowsPerPage={
            <Typography variant="caption" color="text.secondary">
              {!hiddenRowsPerPage ? `แถวต่อหน้า :` : ''}
            </Typography>
          }
          labelDisplayedRows={() => events.handleLabelDisplay(table)}
          page={tablePage < 0 ? 1 : +tablePage}
          onPageChange={(_, pageChanged) =>
            dispatch(events.handleChangePage(pageChanged))
          }
          onRowsPerPageChange={(event) =>
            dispatch(events.handleChangeRowsPerPage(event))
          }
        />
      )}
    </StyledFullWidth>
  )
}

export default EnhancedTable
