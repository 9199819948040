import React from 'react'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Column, Row } from 'src/components/Layout/Styled'
import { SubLabel } from '../..'
import { StyledButton } from '../../Styled'
import { handleOpenCameraDialog } from './events'

const IdCardInput = () => {
  const dispatch = useDispatch()
  return (
    <Row sx={{ justifyContent: 'space-between', alignItems: 'end', gap: 3 }}>
      <Column sx={{ gap: 0.5, width: 'auto' }}>
        <SubLabel label="ถ่ายรูปบัตรประชาชน" />
        <Typography>กรุณาเปิดกล้องและถ่ายรูปรายละเอียดบัตรให้ชัดเจน</Typography>
      </Column>
      <StyledButton onClick={() => dispatch(handleOpenCameraDialog())}>
        ถ่ายรูป
      </StyledButton>
    </Row>
  )
}

export default IdCardInput
