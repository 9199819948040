import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'

export const StyledCard = styled(Box)(({ theme, paddingValue }) => {
  return {
    backgroundColor: theme.palette?.background?.blue,
    marginBottom: theme.spacing(3),
    padding: paddingValue ? paddingValue : theme.spacing(3),
  }
})

export const StyledBoxWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: 1,
  gridTemplateColumns: '80% 20%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const StyledDetailBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(2),
}))

export const StyledTextInfo = styled(Typography)(({ theme, isTitle }) => ({
  color: isTitle ? theme.palette.text?.secondary : theme.palette.text?.dark,
  fontWeight: isTitle ? '' : 'bold',
  fontSize: '20px',
}))

export const StyledFlexBox = styled(Box)(
  ({ theme, customWidth, marginBottom }) => {
    return {
      width: customWidth ? customWidth : '30%',
      marginRight: theme.spacing(2),
      marginBottom: marginBottom ? marginBottom : theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    }
  }
)

export const StyledStatusBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  marginLeft: 'auto',
}))

export const StyledCircleStatus = styled('span')((props) => {
  const { theme, examStatus } = props
  let bgColor = theme.palette.warning.light
  if (['ACCEPT_PERMISSION', 'END', 'PASS', 'ANNOUNCEMENT'].includes(examStatus))
    bgColor = theme.palette.success.main
  else if (
    [
      'PERMISSION_DENIED',
      'CANCEL',
      'CANCELED',
      'CANCEL_BY_SELF',
      'CANCEL_BY_ADMIN',
      'CANCEL_EXAMINATION',
      'CANCEL_BY_MANAGER',
      'MISS',
      'NOT_PASS',
      'ABSENCE',
    ].includes(examStatus)
  )
    bgColor = theme.palette.error.dark
  else if (['CLASS_CANCELED'].includes(examStatus))
    bgColor = theme.palette.error.main
  return {
    display: 'inline-block',
    width: '12px',
    height: '12px',
    backgroundColor: bgColor,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  }
})

export const StyledCancelButton = styled(Button)(({ theme }) => ({
  width: 'fit-content',
  color: '#F07662',
  padding: '10px',
  marginLeft: 'auto',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}))

export const ImageBox = styled(Box)(({ theme, marginRight }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '24px',
  height: '24px',
  marginRight: marginRight ? marginRight : theme.spacing(1),
}))

export const StyledMyClassWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: 0,
  gridTemplateColumns: '300px 1fr',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const MyClassClassroom = styled(Box)(({ theme }) => ({
  color: theme.palette.info.main,
  textTransform: 'uppercase',
  fontSize: '16px',
}))

export const StyledButtonGroup = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(2),
}))

export const StyledMyClassImage = styled('img')(({ theme }) => ({
  width: '100%',
  objectFit: 'cover',
  height: 201,
  [theme.breakpoints.down('sm')]: {
    height: '50vh',
  },
}))