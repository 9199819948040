import React from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import OutlinedButton from '../ClassCard/OutlinedButton'
import ContainedButton from '../ClassCard/ContainedButton/'
import _ from 'lodash'
import {
  StyledCard,
  StyledDetailBox,
  StyledTextInfo,
  StyledFlexBox,
  StyledCircleStatus,
  StyledStatusBox,
  StyledCancelButton,
  ImageBox,
  StyledBoxWrapper,
  StyledMyClassWrapper,
  MyClassClassroom,
  StyledButtonGroup,
  StyledMyClassImage,
} from './Styled'
import { Row } from 'src/components/Layout/Styled'
import Dot from 'src/components/Dot'
import {
  COURSE_TYPE,
  examinationStatus,
  myClassStatus,
} from '../../../constants/course'
import { convertDateRange, convertFormatTime } from 'src/utils/lib'
import ContentNoClass from 'src/components/ContentNoClass'

export const formatToDate = (date) => {
  let result = '-'
  if (date !== null && date !== undefined && date !== '') {
    const [year, month, day] = date.split('-')
    result = `${day}/${month}/${year}`
  }
  return result
}

const ClassList = ({ tab, myScheduleList, handleCancelMySchedule }) => {
  return (
    <>
      {tab === 'MY_SCHEDULE'
        ? myScheduleList.map((item) => {
            const examDate = item?.date ? formatToDate(item.date) : '-'
            const examStatus =
              item?.status && examinationStatus?.[item.status]
                ? examinationStatus[item.status].subText ??
                  examinationStatus[item.status].text
                : '-'

            return (
              <StyledCard key={item.uuid}>
                <StyledBoxWrapper>
                  <Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {item?.date ? (
                        <StyledFlexBox>
                          <ImageBox>
                            <img src="/icon/myClass/CalendarToday.svg" />
                          </ImageBox>
                          <Typography>วันที่สอบ: {examDate}</Typography>
                        </StyledFlexBox>
                      ) : (
                        ''
                      )}
                      {item?.startTime || item?.endTime ? (
                        <StyledFlexBox>
                          <ImageBox>
                            <img src="/icon/myClass/Timer.svg" />
                          </ImageBox>
                          <Typography>
                            เวลา: {item?.startTime} - {item?.endTime} น.
                          </Typography>
                        </StyledFlexBox>
                      ) : (
                        ''
                      )}
                      {item?.schedule ? (
                        <StyledFlexBox>
                          <ImageBox>
                            <img src="/icon/myClass/BusinessOutlined.svg" />
                          </ImageBox>
                          <Typography>สนามสอบ: {item?.schedule}</Typography>
                        </StyledFlexBox>
                      ) : (
                        ''
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {item?.region ? (
                        <StyledFlexBox>
                          <ImageBox>
                            <img src="/icon/myClass/MapOutlined.svg" />
                          </ImageBox>
                          <Typography>ภูมิภาค: {item?.region}</Typography>
                        </StyledFlexBox>
                      ) : (
                        ''
                      )}
                      {item?.province ? (
                        <StyledFlexBox customWidth={'auto'}>
                          <ImageBox>
                            <img src="/icon/myClass/LocationOnOutlined.svg" />
                          </ImageBox>
                          <Typography>สถานที่: {item?.province}</Typography>
                        </StyledFlexBox>
                      ) : (
                        ''
                      )}
                    </Box>
                    <StyledDetailBox>
                      <StyledTextInfo isTitle>สถานที่สอบ:</StyledTextInfo>
                      <StyledTextInfo sx={{ wordBreak: 'break-word' }}>
                        {item?.location && item?.location != ''
                          ? item?.location
                          : '-'}
                      </StyledTextInfo>
                    </StyledDetailBox>
                    <StyledDetailBox>
                      <StyledTextInfo isTitle>รายละเอียด:</StyledTextInfo>
                      <StyledTextInfo sx={{ wordBreak: 'break-word' }}>
                        {item?.description && item?.description != ''
                          ? item?.description
                          : '-'}
                      </StyledTextInfo>
                    </StyledDetailBox>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <StyledStatusBox>
                      {examStatus ? (
                        <>
                          <StyledCircleStatus examStatus={item?.status} />
                          <StyledTextInfo>{examStatus}</StyledTextInfo>
                        </>
                      ) : (
                        ''
                      )}
                    </StyledStatusBox>
                    {item?.isCancel ? (
                      <StyledCancelButton
                        variant="outlined"
                        color="error"
                        onClick={() => handleCancelMySchedule(item.uuid)}
                      >
                        ยกเลิกการสมัคร
                      </StyledCancelButton>
                    ) : (
                      ''
                    )}
                  </Box>
                </StyledBoxWrapper>
              </StyledCard>
            )
          })
        : ''}

      {tab === 'MY_CLASS'
        ? myScheduleList.map((item) => {
            const dateRange = convertDateRange(_.get(item, 'dateRange', null))
            const time = convertFormatTime(item)
            const trainingRoom = _.uniq(_.get(item, 'trainingRoom', []))
            const image = _.get(item, 'imageKey', '')
            const oldClass = _.get(item, 'oldClass', false)
            const remark = _.get(item, 'remark', '')
            const isELearning = item.trainingType === COURSE_TYPE.E_LEARNING
            const classImg = _.isEmpty(image)
              ? '/image/no_class_img.svg'
              : `${window.__env__.REACT_APP_FILE_URL}${
                  isELearning ? '/get' : ''
                }${image}`
            return (
              <StyledCard key={item.uuid} paddingValue={'0'}>
                <StyledMyClassWrapper>
                  <Box>
                    <StyledMyClassImage src={classImg} />
                  </Box>
                  <Box
                    sx={{ display: 'flex', flexWrap: 'wrap', padding: '15px' }}
                  >
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MyClassClassroom>{item.trainingType}</MyClassClassroom>
                      <Row
                        sx={{ width: 'auto', gap: 1.5, minWidth: 112, mt: 0.5 }}
                      >
                        <Dot
                          status={_.get(
                            myClassStatus,
                            item.classStatus,
                            'inactive'
                          )}
                        />
                        <Typography variant="body1b">
                          {item.classStatusTH}
                        </Typography>
                      </Row>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Typography variant="h5">{item.trainingName}</Typography>
                    </Box>
                    {oldClass === true && remark != null && (
                      <Box sx={{ width: '100%', display: 'flex' }}>
                        <StyledFlexBox
                          customWidth={'auto'}
                          marginBottom={'0px'}
                        >
                          <ImageBox marginRight={'0px'}>
                            <img
                              src="/icon/e-learning/ic_book.svg"
                              width={'17px'}
                              height={'17px'}
                            />
                          </ImageBox>
                          <StyledTextInfo isTitle>{remark}</StyledTextInfo>
                        </StyledFlexBox>
                      </Box>
                    )}
                    <Box sx={{ width: '100%', display: 'flex' }}>
                      {item?.batch ? (
                        <StyledFlexBox
                          customWidth={'auto'}
                          marginBottom={'0px'}
                        >
                          <ImageBox marginRight={'0px'}>
                            <img
                              src="/icon/myClass/Calendar.svg"
                              width={'14px'}
                              height={'14px'}
                            />
                          </ImageBox>
                          <StyledTextInfo isTitle>{item.batch}</StyledTextInfo>
                        </StyledFlexBox>
                      ) : (
                        <></>
                      )}
                      <StyledFlexBox customWidth={'auto'} marginBottom={'0px'}>
                        <ImageBox marginRight={'0px'}>
                          <img
                            src="/icon/myClass/Calendar.svg"
                            width={'14px'}
                            height={'14px'}
                          />
                        </ImageBox>
                        <StyledTextInfo isTitle>{dateRange}</StyledTextInfo>
                      </StyledFlexBox>
                      {!isELearning && (
                        <StyledFlexBox
                          customWidth={'auto'}
                          marginBottom={'0px'}
                        >
                          <ImageBox marginRight={'0px'}>
                            <img
                              src="/icon/myClass/Timer2.svg"
                              width={'16px'}
                              height={'16px'}
                            />
                          </ImageBox>
                          <StyledTextInfo isTitle>{time}</StyledTextInfo>
                        </StyledFlexBox>
                      )}
                    </Box>
                    <Box>
                      {!isELearning && (
                        <StyledFlexBox
                          customWidth={'100%'}
                          marginBottom={'0px'}
                        >
                          <ImageBox marginRight={'0px'}>
                            <img
                              src="/icon/myClass/Location2.svg"
                              width={'16px'}
                              height={'16px'}
                            />
                          </ImageBox>
                          <StyledTextInfo isTitle>
                            {trainingRoom.length > 0
                              ? trainingRoom.map(
                                  (item, index) =>
                                    `${item}${
                                      index < trainingRoom.length - 1
                                        ? ', '
                                        : ''
                                    }`
                                )
                              : '-'}
                          </StyledTextInfo>
                        </StyledFlexBox>
                      )}
                      {isELearning && oldClass === false ? (
                        <StyledFlexBox
                          customWidth={'auto'}
                          marginBottom={'0px'}
                        >
                          <ImageBox marginRight={'0px'}>
                            <img
                              src="/icon/myClass/Timer2.svg"
                              width={'16px'}
                              height={'16px'}
                            />
                          </ImageBox>
                          <StyledTextInfo isTitle>{time}</StyledTextInfo>
                        </StyledFlexBox>
                      ) : (
                        <>&nbsp;</>
                      )}
                    </Box>
                    <StyledButtonGroup>
                      {item.isCancel && (
                        <Button
                          sx={{ px: '22px', width: '100%' }}
                          size="l"
                          variant="outlined"
                          color="primary"
                          data-testid="btn-check"
                          // onClick={}
                        >
                          ยกเลิกการสมัคร
                        </Button>
                      )}
                      {oldClass === false && (
                        <>
                          <Box width={'50%'}>
                            <OutlinedButton data={item} />
                          </Box>
                          <Box width={'50%'}>
                            <ContainedButton data={item} />
                          </Box>
                        </>
                      )}
                    </StyledButtonGroup>
                  </Box>
                </StyledMyClassWrapper>
              </StyledCard>
            )
          })
        : ''}

      {tab === 'MY_SCHEDULE' && myScheduleList?.length <= 0 && (
        <ContentNoClass text="ไม่พบการสมัครสอบ" />
      )}

      {tab === 'MY_CLASS' && myScheduleList?.length <= 0 && <ContentNoClass />}
    </>
  )
}
export default ClassList
