import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getETesting, getETestingAnswer } from 'src/services/eTesting'

export const fetchETesting = (props) => async (dispatch) => {
  const { payload, isReview, isModule } = props
  if (isModule) return

  dispatch(startLoading())
  if (isReview) {
    await dispatch(getETestingAnswer(payload))
  } else {
    await dispatch(getETesting(payload))
  }
  dispatch(stopLoading())
}

export const isOnELearning = () =>
  window.location.href.includes('/e-learning/course/learning')
