import { closeDialog, openDialog } from 'src/redux/slices/alertDialog'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { handleRelationObj } from 'src/redux/slices/eLearning/learning/events'
import { postCourseBatchQuestion } from 'src/services/eLearning/learning'
import { getLearningState } from '../../events'

export const handleSubmit = (values, resetForm) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันการส่งคำถาม',
      message: ['คุณต้องการส่งคำถามเกี่ยวกับหลักสูตรนี้?'],
      buttonRight: {
        handleClick: () => dispatch(handleSubmitConfirm(values, resetForm)),
      },
      buttonLeft: { label: 'ยกเลิก' },
    })
  )
}

export const handleSubmitConfirm = (values, resetForm) => async (dispatch) => {
  const { course, eLearningCourseBatch, eLearningCourseBatchLearner } =
    getLearningState()
  const eLearningCourse = handleRelationObj(course)
  const body = {
    ...values,
    eLearningCourse,
    eLearningCourseBatch,
    questionBy: eLearningCourseBatchLearner,
  }
  dispatch(startLoading())
  await dispatch(postCourseBatchQuestion(body))
  dispatch(stopLoading())
  dispatch(closeDialog())
  resetForm()
}
