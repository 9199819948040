import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { userLogoutEvent } from 'src/utils/apiPath'

export const getLogoutEvent = createAsyncThunk(
  'logout/getLogoutEvent',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(userLogoutEvent)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
