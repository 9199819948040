import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eLearningBatchQuestion,
  eLearningConnection,
  eLearningCourseGeneralComplete,
  eLearningCourseLessonLearning,
  eLearningCourseLogLearner,
  eLearningCourseLogLearnerPerLesson,
  eLearningCourseLogLearnerPerLessonReset,
  eLearningCourseOicComplete,
  eLearningLogClick,
} from 'src/utils/apiPath'

export const checkELearningConnection = createAsyncThunk(
  'eLearningLearning/checkELearningConnection',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningConnection, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const updateELearningConnection = createAsyncThunk(
  'eLearningLearning/updateELearningConnection',
  async (body, { rejectWithValue }) => {
    try {
      const response = await await axios.put(eLearningConnection, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getCourseLessonLearning = createAsyncThunk(
  'eLearningLearning/getCourseLessonLearning',
  async ({ uuid, batchUuid }, { rejectWithValue }) => {
    try {
      let path = `${eLearningCourseLessonLearning}/${uuid}`
      if (batchUuid) path = `${path}/${batchUuid}`
      const response = await axios.get(path)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postCourseLogLearner = createAsyncThunk(
  'eLearningLearning/postCourseLogLearner',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningCourseLogLearner, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putCourseLogLearner = createAsyncThunk(
  'eLearningLearning/putCourseLogLearner',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourseLogLearner, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postCourseLogLearnerPerLesson = createAsyncThunk(
  'eLearningLearning/postCourseLogLearnerPerLesson',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        eLearningCourseLogLearnerPerLesson,
        body
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putCourseLogLearnerPerLesson = createAsyncThunk(
  'eLearningLearning/putCourseLogLearnerPerLesson',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourseLogLearnerPerLesson, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getCourseLogLearnerPerLesson = createAsyncThunk(
  'eLearningLearning/getCourseLogLearnerPerLesson',
  async (batchLearnerUuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${eLearningCourseLogLearnerPerLesson}/${batchLearnerUuid}`
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putResetCourseLogLearnerPerLesson = createAsyncThunk(
  'eLearningLearning/putResetCourseLogLearnerPerLesson',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        eLearningCourseLogLearnerPerLessonReset,
        body
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postCourseBatchQuestion = createAsyncThunk(
  'eLearningLearning/postCourseBatchQuestion',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningBatchQuestion, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postCourseLogClick = createAsyncThunk(
  'eLearningLearning/postCourseLogClick',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningLogClick, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putCompleteCourseGeneral = createAsyncThunk(
  'eLearningLearning/putCompleteCourseGeneral',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourseGeneralComplete, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putCompleteCourseOic = createAsyncThunk(
  'eLearningLearning/putCompleteCourseOic',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourseOicComplete, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
