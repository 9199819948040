import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { courseClassList, courseDetail } from 'src/utils/apiPath'

export const getCourseDetail = createAsyncThunk(
  'courseDetail/getCourseDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${courseDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postCourseClassList = createAsyncThunk(
  'courseDetail/postCourseClassList',
  async (payload, { rejectWithValue }) => {
    try {
      const { body, uuid } = payload
      const response = await axios.post(`${courseClassList}/${uuid}`, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
