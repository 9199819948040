import _ from 'lodash'
import { setButtonLoading, setNextQuestion } from 'src/redux/slices/eTesting'
import { postETestingAnswerNow } from 'src/services/eTesting'

export const handleIsAnswered = ({ answerETesting, question }) => {
  if (_.isNil(question) || _.isNil(answerETesting)) return

  const answers = answerETesting.filter(
    (answer) => answer.questionUuid == question.uuid
  )

  if (answers.length == 0) return false

  const haveNoAnswer = answers.some((item) => {
    if (_.isUndefined(item.isVideoEnded)) return !item.isAnswered
    else return !item.isAnswered || !item.isVideoEnded
  })

  const notComplete = answers.length < question.maxAnswerNumber
  return !haveNoAnswer && !notComplete
}

export const handleNextQuestion = (props) => async (dispatch) => {
  const { uuid, question, isNow, onViewState } = props
  if (isNow && !onViewState) {
    const questionUuid = _.get(question, 'uuid', '')
    dispatch(setButtonLoading())
    await dispatch(postETestingAnswerNow({ uuid, questionUuid }))
    setTimeout(() => {
      dispatch(setNextQuestion())
    }, 3000)
  } else dispatch(setNextQuestion())
}
