import _ from 'lodash'
export const handleFilterTotal = (filter) => {
  const keyChecked = []
  const keyValue = []
  let filterTotal = 0

  for (const key in filter) {
    if (key.includes('displayDate')) continue
    if (key.includes('displayExpiryDate')) continue
    if (key.includes('displayEffectiveDate')) continue

    if (typeof filter[key] == 'boolean' && filter[key] === true) {
      keyChecked.push(key.replace('Checked', ''))
    }
    if (!key.includes('Checked') || typeof filter[key] != 'boolean') {
      keyValue.push(key)
    }
  }

  keyChecked.forEach((key) => {
    const valueKey = keyValue.find((val) =>
      _.toLower(val).includes(_.toLower(key))
    )
    const value = filter[valueKey]
    const valueType = typeof value

    if (valueType === 'object') {
      filterTotal = handleKeyValue(value, filterTotal, 0)
    }
    if ((valueType === 'boolean' && value) || _.defaultTo(value, []).length > 0)
      filterTotal++
  })

  return filterTotal
}

export const handleKeyValue = (obj, filterTotal, newTotal) => {
  let total = filterTotal

  if (obj.toString().includes('Time')) total++ // date object

  for (const key in obj) {
    if (typeof obj[key] == 'object' && newTotal == 0) {
      newTotal = handleKeyValue(obj[key], filterTotal, newTotal)
      total = newTotal > 0 ? total + 1 : total
    } else if (typeof obj[key] == 'boolean' && obj[key] && newTotal == 0) {
      total++
      break
    }
  }
  return total
}
