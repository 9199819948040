import {
  setLoadMore,
  startLoadMore,
  stopLoadMore,
  setMyScheduleFilter,
  updateMySchedule,
} from 'src/redux/slices/class/list'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getMyClassHistory, getMyClassList } from 'src/services/class/list'
import { cancelMySchedule } from 'src/services/eExamination'
import { isScrolledIntoElement } from 'src/utils/lib'
import { openDialog, closeDialog, openErrorDialog, } from '../../redux/slices/alertDialog'
import _ from 'lodash'

export const handleFetchMyClass = (body, tab) => async (dispatch) => {
  if (tab == 'CLOSED_OLD_SYSTEM') {
    const payload = { ...body }
    delete payload.classStatus
    await dispatch(getMyClassHistory(payload))
  } else await dispatch(getMyClassList(body))
}

export const fetchMyClassList = (body, tab) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(handleFetchMyClass(body, tab))
  dispatch(stopLoading())
}

export const handleLoadMore = () => (dispatch) => {
  const footer = document.getElementById('page-footer')
  const isFooterAppeared = isScrolledIntoElement(footer)
  if (isFooterAppeared) dispatch(setLoadMore())
}

export const handleSeeMore = (filterBody, tab) => async (dispatch) => {
  if (filterBody.page == 1) return

  dispatch(startLoadMore())
  await dispatch(handleFetchMyClass(filterBody, tab))
  dispatch(stopLoadMore())
}

export const fetchMySchedulePage = (props) => (dispatch) => {
  const { page } = props
  
  dispatch(setMyScheduleFilter([
    {key: 'page', value: page},
    {key: 'isFetch', value: true}
  ]))
}

export const handleCancelMySchedule = (uuid) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'คุณต้องการยกเลิกการสมัครสอบใช่หรือไม่?',
      iconType: 'warning',
      message: ['รายการการสมัครที่คุณทำไว้ยังไม่ได้บันทึก'],
      buttonLeft: {
        label: 'ไม่',
        handleClick: () => dispatch(closeDialog()),
      },
      buttonRight: {
        label: 'ใช่',
        handleClick: () => dispatch(handleSchedule(uuid)),
      },
    })
  )
}

export const handleSchedule = (uuid) => async (dispatch) => {
  const response = await dispatch(cancelMySchedule(uuid))
  const requestStatus = _.get(response, 'meta.requestStatus', 'rejected')
  if (requestStatus == 'fulfilled') {
    const item = {
      status: "CANCEL_BY_SELF",
      isCancel: false,
    }
    dispatch(updateMySchedule({ uuid, item }))
    dispatch(
      openDialog({
        title: 'บันทึกข้อมูลสำเร็จ',
        iconType: 'success',
        fullWidth: true,
        buttonRight: {
          label: 'ตกลง',
          handleClick: () => {
            dispatch(closeDialog())
          },
        },
      })
    )
  }else{
    dispatch(openErrorDialog({ 
      title: 'ไม่สามารถบันทึกข้อมูลได้',
      message: 'พบปัญหาบางอย่าง กรุณาลองอีกครั้ง?',
    }))
  }
}