import _ from 'lodash'
import { setSuggestionAnswer } from 'src/redux/slices/eEvaluation'
import { ratingChoices } from 'src/constants/eEvaluation'

export const handleSelector = (state, keyProps) => {
  const { keyField, keyAssessment, keySub } = keyProps
  const assessment = _.get(state.eEvaluation, keyField, []).find(
    (item) => item.key == keyAssessment
  )

  if (!keySub) {
    return {
      uuid: _.get(assessment, 'uuid', ''),
      choices: _.get(assessment, 'choices', ratingChoices),
      isSuggestion: _.get(assessment, 'isSuggestion', false),
    }
  }

  const choice = assessment.choices.find(
    (item) => item.nextSubQuestionSequence == keySub
  )
  const subQuestion = _.get(choice, 'subQuestions', {})
  return {
    uuid: _.get(subQuestion, 'uuid', ''),
    choices: _.get(subQuestion, 'choices', ratingChoices),
    isSuggestion: _.get(subQuestion, 'isSuggestion', false),
  }
}

export const handleDefaultValue = (eEvaluation, uuid, staffUuid) => {
  const question = _.get(eEvaluation, 'answerEEvaluation', []).find(
    (item) => {
      if (item.staffUuid) {
        return item.questionUuid == uuid && item.staffUuid == staffUuid
      } else return item.questionUuid == uuid
    }
  )
  return _.get(question, 'suggestion', '')
}

export const handleSuggestionChange = (e, uuid, keyProps) => (dispatch) => {
  const { keyField, staffUuid, keyAssessment } = keyProps
  const value = _.get(e, 'target.value', '')
  dispatch(
    setSuggestionAnswer({
      value,
      uuid,
      keyField,
      staffUuid,
      key: keyAssessment,
    })
  )
}
