import React from 'react'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import { closeDialog } from 'src/redux/slices/alertDialog'
import { StyledDialogContent } from './Styled'

const DialogContent = () => {
  const dispatch = useDispatch()
  return (
    <StyledDialogContent>
      <ErrorOutline sx={{ fontSize: '50px', mb: '14px' }} color="error" />
      <Typography variant="h6">เหลือเวลาอีก 5 นาที</Typography>
      <Typography variant="body1" sx={{ mb: 3.5 }}>
        เหลือเวลาอีก 5 นาที ในการทำข้อสอบ
      </Typography>
      <Button
        size="m"
        variant="contained"
        color="error"
        data-testid="btn-agree"
        onClick={() => dispatch(closeDialog())}
      >
        ตกลง
      </Button>
    </StyledDialogContent>
  )
}

export default DialogContent
