import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import DragHandle from '@mui/icons-material/DragHandle'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  StyledAnswerContent,
  StyledAnswerList,
  StyledChoiceContent,
  StyledDragBox,
  StyledImgBox,
  StyledNumberBadge,
  StyledSequenceContainer,
} from './Styled'
import { onDragEnd } from './events'

const Sequence = ({ uuid, onViewState }) => {
  const dispatch = useDispatch()
  const { answerETesting, questionResult, correctAnswer } = useSelector(
    (state) => ({
      answerETesting: state.eTesting.answerETesting.find(
        (item) => item.questionUuid == uuid
      ),
      questionResult: state.eTesting.questionResult,
      correctAnswer: state.eTesting.correctAnswers.find(
        (item) => item.questionUuid == uuid
      ),
    }),
    shallowEqual
  )
  const answers = _.get(answerETesting, 'sequenceList', []).map(
    (item, index) => ({
      ...item,
      id: `id-${index + 1}`,
    })
  )

  return (
    <StyledSequenceContainer>
      <DragDropContext onDragEnd={(e) => dispatch(onDragEnd(e, answers, uuid))}>
        <Droppable droppableId="answers">
          {(provided) => (
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {answers.map((choice, index) => {
                const answer = _.get(correctAnswer, 'answers', [])
                const answerIndex = answer.findIndex(
                  (ans) => ans.uuid == choice.uuid
                )
                const isAnswer =
                  choice.sequence === index + 1 || answerIndex === index
                return (
                  <Draggable
                    key={choice.id}
                    draggableId={choice.id}
                    index={index}
                  >
                    {(provided) => (
                      <StyledAnswerList
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        key={index}
                        resultProps={{ onViewState, questionResult, isAnswer }}
                      >
                        <StyledAnswerContent
                          {...provided.dragHandleProps}
                          index={index}
                          onViewState={onViewState}
                        >
                          {!onViewState && (
                            <StyledDragBox img={choice.mediaUrl}>
                              <DragHandle />
                            </StyledDragBox>
                          )}
                          <StyledChoiceContent>
                            {choice.mediaUrl && (
                              <StyledImgBox onViewState={onViewState}>
                                <img
                                  src={`${window.__env__.REACT_APP_API_URL}/file${choice.mediaUrl}`}
                                />
                              </StyledImgBox>
                            )}
                            <Typography sx={{ wordBreak: 'break-word' }}>
                              {_.get(choice, 'title', '')}
                            </Typography>
                          </StyledChoiceContent>
                        </StyledAnswerContent>
                        {onViewState &&
                          questionResult.resultType == 'SHOW_TRUE_FALSE' && (
                            <StyledNumberBadge isAnswer={isAnswer}>
                              {answerIndex + 1 || _.get(choice, 'sequence', 0)}
                            </StyledNumberBadge>
                          )}
                      </StyledAnswerList>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </StyledSequenceContainer>
  )
}

export default Sequence
