import _ from 'lodash'
import { alphabets } from 'src/constants/eTesting'
import { setAnswered, setMobileProps } from 'src/redux/slices/eTesting'

export const handleDragEnd = (props, dragDropArr) => {
  const { over, active } = props
  const newArr = [...dragDropArr]
  if (active && over) {
    const oldIndex = newArr.findIndex((data) => data?.uuid === active.id)
    if (oldIndex !== -1) newArr[oldIndex] = null
    newArr[over.id] = _.get(active.data, 'current', null)
  } else if (active) {
    const oldIndex = newArr.findIndex((data) => data?.uuid === active.id)
    newArr[oldIndex] = null
  }
  return newArr
}

export const handleMobileSelect = (index, props) => (dispatch) => {
  const { dragDropArr, setDragDropArr, selectedItem } = props
  const newArr = [...dragDropArr]
  newArr[index] = selectedItem
  setDragDropArr(newArr)
  dispatch(setMobileProps({ selectedItem: null }))
}

export const handleDragDropChange = (value, uuid) => (dispatch) => {
  const realValue = value.map((item, index) => {
    if (_.isNil(item)) return null
    return {
      target: alphabets[index],
      questionUuid: uuid,
      answerUuid: item.uuid,
    }
  })
  dispatch(setAnswered({ type: 'FILL_IN_BLANK_DRAG', uuid, value: realValue }))
}

export const getPlainTextWithBlank = (value) => {
  const newValue = value
    .replaceAll('<span', '[<span')
    .replaceAll('</span>', '</span>]')
  const plainText = new DOMParser().parseFromString(newValue, 'text/html')
    .documentElement.textContent
  return plainText
}
