import React from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AppRegistration from '@mui/icons-material/AppRegistration'
import ArrowForward from '@mui/icons-material/ArrowForward'
import PeopleOutline from '@mui/icons-material/PeopleOutline'
import Typography from '@mui/material/Typography'
import { handleClickEnroll } from './events'
import { StyledButton, StyledLabelContent, StyledLoadingButton } from './Styled'

const ButtonAndAmount = ({ fixed, isScrolled, socketId }) => {
  const data = useSelector((state) => state.classDetail.data, shallowEqual)
  const trainingPlanData = _.get(data, 'trainingPlanData', null)
  const canRegister = _.get(trainingPlanData, 'canRegister', 'CLOSED')
  const availableSeats = _.get(trainingPlanData, 'availableSeats', 0)
  const isClosed = canRegister === 'CLOSED'
  const isButtonShow = !isClosed || availableSeats === 0

  return (
    <>
      {isButtonShow && (
        <EnrollButton
          data={data}
          fixed={fixed}
          isScrolled={isScrolled}
          socketId={socketId}
        />
      )}

      <StyledLabelContent fixed={fixed}>
        <PeopleOutline fontSize="small" />

        {availableSeats > 0 ? (
          <AvailableSeatText
            isClosed={isClosed}
            availableSeats={availableSeats}
          />
        ) : (
          <Typography variant="body2">เต็ม</Typography>
        )}
      </StyledLabelContent>
    </>
  )
}

export default ButtonAndAmount

export const EnrollButton = ({ data, fixed, isScrolled, socketId }) => {
  const isEnrollLoading = useSelector(
    (state) => state.classDetail.isEnrollLoading,
    shallowEqual
  )
  const { trainingPlanUuid } = useParams()
  const dispatch = useDispatch()
  const courseDetail = _.get(data, 'courseDetail', null)
  const courseVersionUuid = _.get(courseDetail, 'uuid', null)
  const trainingPlanData = _.get(data, 'trainingPlanData', null)
  const canRegister = _.get(trainingPlanData, 'canRegister', 'CLOSED')
  const availableSeats = _.get(trainingPlanData, 'availableSeats', 0)
  const isClosed = canRegister === 'CLOSED'
  const isRegistered =
    canRegister === 'WAITING_RESULT' || canRegister === 'REGISTERED'
  const isDisable = isClosed || availableSeats === 0
  const enrollProps = { courseDetail, trainingPlanUuid, courseVersionUuid }
  const availableText = availableSeats > 0 ? 'สมัครเข้าอบรม' : 'สมัครเต็มแล้ว'

  if (isRegistered) {
    return (
      <StyledButton
        data-testid="btn-class"
        isScrolled={isScrolled}
        fixed={fixed}
        onClick={() =>
          (window.location.href = `/my-class/registration-detail/${trainingPlanUuid}`)
        }
        endIcon={<ArrowForward />}
      >
        ไปที่หลักสูตรเรียน
      </StyledButton>
    )
  } else {
    return (
      <StyledLoadingButton
        data-testid="btn-enroll"
        isScrolled={isScrolled}
        fixed={fixed}
        disabled={isDisable}
        onClick={() => dispatch(handleClickEnroll(enrollProps, socketId))}
        startIcon={<AppRegistration />}
        loading={isEnrollLoading}
      >
        {availableText}
      </StyledLoadingButton>
    )
  }
}

export const AvailableSeatText = ({ isClosed, availableSeats }) => {
  if (isClosed) return <Typography variant="body2">-</Typography>
  return (
    <>
      <Typography variant="body2">ที่นั่งว่าง</Typography>
      <Typography variant="body2b">{availableSeats}</Typography>
    </>
  )
}
