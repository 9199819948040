import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'

export const formatBuddhistEra = (ref) => {
  if (ref.current && ref.current.children[0]) {
    const yearSelect =
      ref.current.children[0]
        .querySelector('.rdrYearPicker')
        .querySelector('select') || []
    for (let index = 0; index < yearSelect.length; index++) {
      const yearOpt = yearSelect[index]
      yearOpt.text = Number(yearOpt.text) + 543
    }
  }
}

export const getDisabledDates = (dates) => {
  const arr = []

  if (dates && dates.length > 0) {
    dates.map((disable) => {
      const { startDate, finishDate } = disable
      for (
        const date = new Date(startDate);
        date <= new Date(finishDate);
        date.setDate(date.getDate() + 1)
      ) {
        arr.push(new Date(date))
      }
    })
  }

  return arr
}

export const formatDateDisplay = (date) => {
  dayjs.extend(buddhistEra)
  return dayjs(new Date(date)).format(window.__env__.REACT_APP_DATE_SHOW)
}
