import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'
import useLocalStorage from '@rehooks/local-storage'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import TextInput from '../../../../../components/Input/TextInput'
import InputMask from '../../../../../components/Input/InputMask'
import Select from '../../../../../components/Input/Select'

import { BoxTextLabel } from '../'
import { optionPrefix, optionPrefixTH } from '../../model'
import { BoxCard, BoxContentLabel, BoxGroupLeft } from '../Styled'
import { handleChangeInfo, checkHeightLeft } from '../events'
import { handlePrefixChange } from './events'

const Index = () => {
  const dispatch = useDispatch()
  const [user] = useLocalStorage('user')
  const { errors } = useFormikContext()
  const { information, type } = useSelector(
    (state) => state.manageProspect,
    shallowEqual
  )
  const agentRole = user.agentRole
  const abbreviation = _.get(user, 'distributionChannel.abbreviation', '')
  const mobileNo = _.get(information, 'mobileNo', '')
  const mobileText = !_.isEmpty(mobileNo)
    ? mobileNo.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3').replace(/ /g, '-')
    : '-'

  return (
    <BoxCard sx={{ height: checkHeightLeft(type, agentRole) }}>
      <Box sx={{ m: 3 }}>
        <Typography variant="h6" color="primary.main" sx={{ mb: 2 }}>
          ข้อมูลส่วนตัว
        </Typography>

        {type == 'create' || type == 'edit' ? (
          <Box>
            <Select
              selectSx={{ height: 50 }}
              id="prefixTH"
              name="prefixTH"
              labelText="คำนำหน้าชื่อ"
              type="basic"
              required
              options={optionPrefixTH}
              handleChange={(e) => dispatch(handlePrefixChange(e))}
              textError={_.get(errors, 'information.prefixTH', '')}
              value={_.get(information, 'prefixTH', '')}
            />
            <BoxGroupLeft>
              <TextInput
                sx={{ height: 50 }}
                id="firstNameTH"
                name="firstNameTH"
                labelText="ชื่อ"
                type="basic"
                required
                onChange={(e) =>
                  dispatch(
                    handleChangeInfo({
                      isCheck: 'name',
                      key: 'firstNameTH',
                      value: _.get(e, 'target.value', ''),
                    })
                  )
                }
                textError={_.get(errors, 'information.firstNameTH', '')}
                value={_.get(information, 'firstNameTH', '')}
              />
              <TextInput
                sx={{ height: 50 }}
                id="lastNameTH"
                name="lastNameTH"
                labelText="นามสกุล"
                type="basic"
                required
                onChange={(e) =>
                  dispatch(
                    handleChangeInfo({
                      isCheck: 'name',
                      key: 'lastNameTH',
                      value: _.get(e, 'target.value', ''),
                    })
                  )
                }
                textError={_.get(errors, 'information.lastNameTH', '')}
                value={_.get(information, 'lastNameTH', '')}
              />
            </BoxGroupLeft>

            <Select
              selectSx={{ height: 50 }}
              id="prefix"
              name="prefix"
              labelText="Title"
              type="basic"
              required
              options={optionPrefix}
              handleChange={(e) => dispatch(handlePrefixChange(e))}
              textError={_.get(errors, 'information.prefix', '')}
              value={_.get(information, 'prefix', '')}
            />
            <BoxGroupLeft>
              <TextInput
                sx={{ height: 50 }}
                id="firstName"
                name="firstName"
                labelText="First Name"
                type="basic"
                required
                onChange={(e) =>
                  dispatch(
                    handleChangeInfo({
                      isCheck: 'name',
                      key: 'firstName',
                      value: _.get(e, 'target.value', ''),
                    })
                  )
                }
                textError={_.get(errors, 'information.firstName', '')}
                value={_.get(information, 'firstName', '')}
              />
              <TextInput
                sx={{ height: 50 }}
                id="lastName"
                name="lastName"
                labelText="Last Name"
                type="basic"
                required
                onChange={(e) =>
                  dispatch(
                    handleChangeInfo({
                      isCheck: 'name',
                      key: 'lastName',
                      value: _.get(e, 'target.value', ''),
                    })
                  )
                }
                textError={_.get(errors, 'information.lastName', '')}
                value={_.get(information, 'lastName', '')}
              />
            </BoxGroupLeft>

            <InputMask
              sx={{ height: 50 }}
              id="mobileNo"
              name="mobileNo"
              labelText="เบอร์โทรศัพท์"
              placeholder=""
              maskChar={null}
              mask="999-999-9999"
              required={abbreviation !== 'CS'}
              onChange={(e) =>
                dispatch(
                  handleChangeInfo({
                    key: 'mobileNo',
                    value: _.get(e, 'target.value', ''),
                  })
                )
              }
              value={_.get(information, 'mobileNo', '')}
              textError={_.get(errors, 'information.mobileNo', '')}
            />
            <TextInput
              sx={{ height: 50 }}
              id="email"
              name="email"
              labelText="อีเมล"
              type="basic"
              required
              onChange={(e) =>
                dispatch(
                  handleChangeInfo({
                    key: 'email',
                    value: _.get(e, 'target.value', ''),
                  })
                )
              }
              textError={_.get(errors, 'information.email', '')}
              value={_.get(information, 'email', '')}
            />
          </Box>
        ) : (
          <Box>
            <BoxContentLabel>
              <BoxTextLabel
                labelLeft="คำนำหน้าชื่อ"
                labelRight={_.get(information, 'prefixTH', '')}
              />
              <BoxTextLabel
                isFirst={true}
                labelLeft="ชื่อ - นามสกุล"
                labelRight={_.get(information, 'fullName', '')}
              />
            </BoxContentLabel>
            {agentRole !== 'Manager' && (
              <BoxContentLabel>
                <BoxTextLabel
                  isTop={true}
                  labelLeft="เลขบัตรประชาชน"
                  labelRight={_.get(information, 'idCardNo', null) ?? '-'}
                />
                <BoxTextLabel
                  isTop={true}
                  labelLeft="วัน เดือน ปี เกิด"
                  labelRight={_.get(information, 'dateOfBirth', null) ?? '-'}
                />
              </BoxContentLabel>
            )}

            <BoxContentLabel>
              <BoxTextLabel
                isTop={true}
                labelLeft="เบอร์โทรศัพท์"
                labelRight={mobileText}
              />
              <BoxTextLabel
                isTop={true}
                labelLeft="อีเมล"
                labelRight={_.get(information, 'email', '')}
              />
            </BoxContentLabel>
          </Box>
        )}
      </Box>
    </BoxCard>
  )
}

export default Index
