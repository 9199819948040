export const initialState = {
  isLoading: false,
  isLikeLoading: false,
  isDialogOpen: false,
  isScanAllow: true,
  error: null,
  data: null,
  camera: { isOpen: false, faceImage: null },
  failMaxTimes: 0,
  time: { mins: 0, secs: 0 },
  failLogUuid: null,
}
