import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { StyledDialog } from 'src/components/AlertDialog/Styled'
import { closeDialog } from 'src/redux/slices/eLearning/detail'
import CameraDialog from 'src/components/CameraDialog'
import DisallowDialog from '../DisallowDialog'
import {
  handleCheckAllowScan,
  handleDetectFace,
  handleOpenCamera,
  handleVerifyFail,
} from './events'

const AlertDialog = () => {
  const isDialogOpen = useSelector(
    (state) => state.eLearningDetail.isDialogOpen,
    shallowEqual
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(handleCheckAllowScan(isDialogOpen))
  }, [isDialogOpen])

  return (
    <Box>
      <StyledDialog
        maxWidth="sm"
        fullWidth={true}
        customWidth={532}
        sx={{ zIndex: '1298 !important' }}
        open={isDialogOpen}
        onClose={() => dispatch(closeDialog())}
      >
        <DisplayDialogContent />
      </StyledDialog>
    </Box>
  )
}

export default AlertDialog

export const DisplayDialogContent = () => {
  const { isLoading, isScanAllow, camera } = useSelector(
    (state) => ({
      isLoading: state.loading.isLoading,
      isScanAllow: state.eLearningDetail.isScanAllow,
      camera: state.eLearningDetail.camera,
    }),
    shallowEqual
  )
  const { isOpen, faceImage } = camera
  const dispatch = useDispatch()
  const history = useHistory()

  if (isLoading) return <></>

  if (isScanAllow) {
    return (
      <CameraDialog
        isLiveness
        isOpen={isOpen}
        image={faceImage}
        onCapture={(livenessData) =>
          dispatch(handleDetectFace(livenessData, history))
        }
        onOpenCamera={() => dispatch(handleOpenCamera())}
        onError={(error) => dispatch(handleVerifyFail(error))}
      />
    )
  } else return <DisallowDialog />
}
