import React, { useState, useMemo } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import FilterListIcon from '@mui/icons-material/FilterList'
import ScrollFixedTopContainer from 'src/components/ScrollFixedTopContainer'
import Search from '../../../components/Search'
import { checkOnFilter } from '../../../components/Filter/events'
import FilterDrawer from '../Filter/FilterDrawer'
import { StyledListHeader, StyledNotiDot } from './Styled'
import { handleChangeSearch } from './events'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchCourseList } from '../events'

const ListHeader = () => {
  const dispatch = useDispatch()
  const { filterBody, courseName, totalCourse } = useSelector(
    (state) => ({
      filterBody: _.get(state.courseList, 'filterBody', null),
      courseName: _.get(state.courseList.filterBody, 'courseName', []),
      totalCourse: _.get(state.courseList, 'totalCourses', 0),
    }),
    shallowEqual
  )
  const [isOpen, setOpen] = useState(false)
  const isOnFilter = useMemo(() => checkOnFilter(filterBody), [filterBody])

  return (
    <ScrollFixedTopContainer threshold={230}>
      <StyledListHeader>
        <Box>
          <Typography variant="h6" color="text.primary">
            {totalCourse} หลักสูตรเรียน
          </Typography>
          <IconButton
            sx={{ position: 'relative' }}
            data-testid="btn-filter"
            size="small"
            onClick={() => setOpen(true)}
          >
            {isOnFilter && <StyledNotiDot />}
            <FilterListIcon />
          </IconButton>
        </Box>
        <Search
          placeholder="ค้นหาชื่อหลักสูตร"
          text={courseName}
          setText={(e) => dispatch(handleChangeSearch(e))}
          handleSearch={() => dispatch(fetchCourseList(filterBody))}
        />
      </StyledListHeader>
      <FilterDrawer isOpen={isOpen} setOpen={setOpen} />
    </ScrollFixedTopContainer>
  )
}
export default ListHeader
