export const formatData = (field) => {
  let text = ''
  switch (field) {
    case 'ACTIVE':
      text = 'Active'
      break
    case 'INACTIVE':
      text = 'Inactive'
      break
    case 'DELETED':
      text = 'Deleted'
      break
    case 'STEAL':
      text = 'โอนย้าย'
      break
    case 'TERMINATED':
      text = 'Terminated'
      break
    default:
      text = '-'
      break
  }
  return text
}
