import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'

import {
  StyledDialog,
  StyledDialogActions,
  StyledButtonLeft,
  StyledButtonRight,
} from './Styled'
import _ from 'lodash'
import { closeDialog } from '../../redux/slices/alertDialog'

import ContentDialog from './Content'
import ErrorDetail from './ErrorDetail'

const Index = () => {
  const dialog = useSelector((state) => state.alertDialog)
  const {
    type,
    isOpen,
    isLoading,
    content,
    maxWidth,
    customWidth,
    disableButton,
    handleCancel,
    isCloseDialog,
    fullWidth,
    buttonLeft,
    buttonRight,
    isHiddenFooter,
    zIndex = 1299,
    // isHiddenButton,
  } = dialog

  const dispatch = useDispatch()
  return (
    <Box>
      <StyledDialog
        customWidth={customWidth}
        fullWidth={fullWidth}
        maxWidth={maxWidth ?? 'sm'}
        open={isOpen}
        onClose={() => {
          if (isCloseDialog && isOpen) {
            handleCancel && handleCancel()
            dispatch(closeDialog())
          }
        }}
        sx={{ zIndex: `${zIndex} !important` }}
      >
        {type == 'default' ? (
          <Box>
            <ContentDialog />
            {window.__env__.ENV == 'DEV' && dialog.errorCurl && (
              <ErrorDetail curl={dialog.errorCurl} />
            )}
            {!isHiddenFooter && (
              <StyledDialogActions>
                {!isLoading && !_.isEmpty(buttonLeft) && (
                  <StyledButtonLeft
                    data-testid="btn-disagree"
                    size="l"
                    variant="outlined"
                    disabled={_.get(buttonLeft, 'disabled', false)}
                    sx={_.get(buttonLeft, 'style', { width: '100%' })}
                    onClick={_.get(buttonLeft, 'handleClick', () =>
                      dispatch(closeDialog())
                    )}
                    startIcon={_.get(buttonLeft, 'startIcon', null)}
                    endIcon={_.get(buttonLeft, 'endIcon', null)}
                  >
                    <Typography
                      variant="button"
                      color={_.get(buttonLeft, 'colorText', 'auto')}
                    >
                      {_.get(buttonLeft, 'label', 'ยกเลิก')}
                    </Typography>
                  </StyledButtonLeft>
                )}
                <StyledButtonRight
                  data-testid="btn-agree"
                  size="l"
                  variant="contained"
                  loading={isLoading}
                  disabled={disableButton}
                  sx={_.get(buttonRight, 'style', { width: '100%' })}
                  color={_.get(buttonRight, 'color', 'primary')}
                  onClick={_.get(buttonRight, 'handleClick', () =>
                    dispatch(closeDialog())
                  )}
                  startIcon={_.get(buttonRight, 'startIcon', null)}
                  endIcon={_.get(buttonRight, 'endIcon', null)}
                >
                  <Typography
                    variant="button"
                    sx={{
                      color: !disableButton ? 'auto' : 'rgba(0, 0, 0, 0.26)',
                    }}
                    color={_.get(buttonRight, 'colorText', 'auto')}
                  >
                    {_.get(buttonRight, 'label', 'ตกลง')}
                  </Typography>
                </StyledButtonRight>
              </StyledDialogActions>
            )}
          </Box>
        ) : (
          content
        )}
      </StyledDialog>
    </Box>
  )
}

export default Index
