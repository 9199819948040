import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import FiberManualRecord from '@mui/icons-material/FiberManualRecord'
import Edit from '@mui/icons-material/Edit'

export const InputUpload = styled('input')({
  display: 'none',
})

export const BoxBreadcrumb = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}))

export const ImageProfile = styled('img')(({ theme }) => ({
  width: 160,
  height: 160,
  objectFit: 'cover',
  borderRadius: '50%',
  border: `2px solid ${theme?.palette?.blue?.deepDapphire}`,
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
}))

export const BoxImage = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(4),
    justifyContent: 'center',
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export const BoxButtonHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  marginLeft: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'unset',
    width: 'auto',
    marginLeft: 0,
  },
}))

export const BoxDetailIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
  },
}))

export const IconStatus = styled(FiberManualRecord)(({ theme, status }) => ({
  color:
    status === 'WAITING'
      ? theme.palette.other.ratingActive
      : theme.palette.success?.main,
}))

export const IconChangeImage = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  background: theme.palette.secondary?.main,
  borderRadius: '50%',
  position: 'static',
  marginLeft: theme.spacing(-6),
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}))

export const IconEdit = styled(Edit)(({ theme }) => ({
  color: theme.palette.text?.white,
}))

export const ContentText = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
}))
