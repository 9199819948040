export const initialState = {
  table: {
    rows: [],
    allCount: 0,
    headCells: [],
    tableHeadText: '',
    page: -1,
    tablePage: 0,
    order: 'desc',
    sort: 'updatedAt',
    defaultSortId: 'updatedAt',
    defaultSort: { state: 0, id: '', active: false },
    limit: '20',
    rowsPerPage: { label: '20', value: 20 },
    isShowPagination: true,
  },
  customStyle: {},
}
