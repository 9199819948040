import _ from 'lodash'
import { PREFIXS } from '../../model'
import { handleChangeInfo } from '../events'

export const handlePrefixChange = (e) => (dispatch) => {
  const value = _.get(e, 'target.value', '')
  const mappedValue = PREFIXS.find(
    (prefix) => prefix.th === value || prefix.en === value
  )
  dispatch(handleChangeInfo({ key: 'prefixTH', value: mappedValue.th }))
  dispatch(handleChangeInfo({ key: 'prefix', value: mappedValue.en }))
}
