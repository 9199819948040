import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2.5),
  },
}))

export const StyledSection = styled(Row)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  gap: theme.spacing(3),
  '& > .MuiBox-root:first-of-type': { minWidth: 240 },
  '& > .MuiBox-root:last-child': { width: 'calc(100% - 264px)' },
  '& > #group': {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    '& > .MuiBox-root:last-child': { width: '100%' },
  },
}))

export const StyledChipGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  flexWrap: 'wrap'
}))
