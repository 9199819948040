import React, { useMemo } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import { responsiveButton } from 'src/components/Preview/Footer/Styled'
import { handleIsAnswered } from '../events'
import { handleSubmitEEvaluation } from './events'

const SubmitBtn = ({ isModule }) => {
  const dispatch = useDispatch()
  const buttonSize = responsiveButton()
  const assessmentProps = useSelector(
    (state) => ({
      page: state.eEvaluation.page,
      assessmentList: state.eEvaluation.assessmentList,
      answerEEvaluation: state.eEvaluation.answerEEvaluation,
      displayQuestion: _.get(
        state.eEvaluation.setting,
        'displayQuestion',
        'ALL'
      ),
    }),
    shallowEqual
  )
  const { answerEEvaluation } = assessmentProps
  const isAnswered = useMemo(
    () => handleIsAnswered(assessmentProps),
    [answerEEvaluation]
  )

  return (
    <Button
      type="submit"
      variant="contained"
      size={buttonSize}
      data-testid="btn-submit"
      disabled={!isAnswered}
      onClick={() => dispatch(handleSubmitEEvaluation(isModule))}
    >
      ส่งแบบประเมิน
    </Button>
  )
}

export default SubmitBtn
