import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { postLearningPathList } from 'src/services/eLearning/learningPath'
import { getELearningFilter } from 'src/services/eLearning/list'
import { MY_LEARNING_PATH_LABEL, initialState } from './model'

const eLearningLearningPathList = createSlice({
  name: 'eLearningLearningPathList',
  initialState,
  reducers: {
    startLoadMore: (state) => {
      state.isLoadMore = true
    },
    stopLoadMore: (state) => {
      state.isLoadMore = false
    },
    setFilter: (state, { payload }) => {
      const { key, value } = payload
      state.filterBody[key] = value
      state.filterBody.page = 1
    },
    setLoadMore: (state) => {
      const nextPage = state.filterBody.page + 1
      const maxPage = _.ceil(state.totalCourses / 12)
      if (nextPage <= maxPage) state.filterBody.page = nextPage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getELearningFilter.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', {})
        state.filterData = {
          ...data,
          learningPathStatus: data.courseStatus,
          pathHourFrom: data.courseHourFrom,
          pathHourTo: data.courseHourTo,
          myLearningPath: data.myCourse.map((item) => ({
            ...item,
            label: MY_LEARNING_PATH_LABEL[item.value],
          })),
        }
      })
      .addCase(getELearningFilter.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postLearningPathList.fulfilled, (state, action) => {
        const { meta, payload } = action
        const learningPaths = _.get(payload, 'data.learningPaths', [])
        const isFilter = _.get(meta.arg, 'page', 1) == 1
        if (isFilter) state.learningPaths = learningPaths
        else state.learningPaths = _.concat(state.learningPaths, learningPaths)
        state.totalLearningPath = _.get(payload, 'data.totalLearningPath', [])
      })
      .addCase(postLearningPathList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const { startLoadMore, stopLoadMore, setFilter, setLoadMore } =
  eLearningLearningPathList.actions

export default eLearningLearningPathList.reducer
