export const headCells = [
  { id: 'event', label: 'รายการ', hideSortIcon: true },
  { id: 'historyPoint', label: 'คะแนนที่เปลี่ยนแปลง', hideSortIcon: true },
  { id: 'updatedAt', label: 'วันและเวลา', hideSortIcon: true },
]

export const historyEvent = {
  INCREASE_FROM_ADMIN: 'INCREASE_FROM_ADMIN',
  DECREASE_FROM_ADMIN: 'DECREASE_FROM_ADMIN',
  INCREASE_FROM_COURSE: 'INCREASE_FROM_COURSE',
  REDEEMED: 'REDEEMED',
}
