import _ from 'lodash'

export const handleAnswerAmount = (evaluation) => {
  const { totalAssessments, assessmentList, answerEEvaluation } = evaluation
  const rawValue = handleAnswerAmountForAll({
    assessmentList,
    answerEEvaluation,
  })
  const calValue = rawValue / totalAssessments || 0
  const percent = 100 * calValue
  return percent.toFixed()
}

export const handleAnswerAmountForAll = (props) => {
  const { answerEEvaluation } = props
  const assessmentList = _.get(props, 'assessmentList', [])
  const answerList = []
  assessmentList.forEach((assessment) => {
    const haveNoAnswer = handleIsNoAnswer(answerEEvaluation, assessment)
    answerList.push(!haveNoAnswer)
  })
  return answerList.filter((isAnswered) => isAnswered).length
}

export const handleIsAnswered = (props) => {
  const { page, answerEEvaluation, displayQuestion } = props
  const assessmentList = _.get(props, 'assessmentList', [])
  if (displayQuestion == 'ONE') {
    const assessment = _.get(assessmentList, page - 1, null)
    const haveNoAnswer = handleIsNoAnswer(answerEEvaluation, assessment)
    return !haveNoAnswer
  } else {
    const answerList = []
    assessmentList.forEach((assessment) => {
      const haveNoAnswer = handleIsNoAnswer(answerEEvaluation, assessment)
      answerList.push(!haveNoAnswer)
    })
    return !answerList.some((isAnswered) => !isAnswered)
  }
}

export const handleIsNoAnswer = (answerEEvaluation, assessment) => {
  if (_.isNil(assessment) || _.isNil(answerEEvaluation)) return
  let questions = answerEEvaluation.filter((question) => {
    if (question.mainUuid) {
      return question.mainUuid == assessment.uuid
    } else return question.questionUuid == assessment.uuid
  })
  const isLecturer = questions.some(
    (item) => item.answerSection == 'LECTURER_ASSESSMENTS'
  )
  if (isLecturer) {
    const staffs = _.keys(_.groupBy(questions, 'staffUuid'))
    const staffUuid = _.get(staffs, `${assessment.key}`, '')
    questions = questions.filter((item) => item.staffUuid == staffUuid)
  }
  const haveNoAnswer = handleCheckNoAnswer(assessment, questions)
  return haveNoAnswer
}

export const handleCheckNoAnswer = (question, questionList) => {
  if (questionList.length == 0) return true

  let haveNoAnswer = questionList.some((item) => !item.isAnswered)
  if (!haveNoAnswer && question.type == 'MULTIPLE') {
    const isNotMaximum = question.typeOfAnswers != 'MAXIMUM'
    if (question.setTypeOfAnswers && isNotMaximum) {
      haveNoAnswer = questionList.length < question.numberOfAnswers
    }
  }
  return haveNoAnswer
}
