import _ from 'lodash'
import { myClassFilter } from 'src/constants/course'

export const handleFilterBody = (state, tab) => {
  const classStatus = _.get(myClassFilter, tab, [])
  state.filterBody.classStatus = classStatus.map((item) => item.value)
}

export const handleFulfilled = (state, action) => {
  const { meta, payload } = action
  const classes = _.get(payload.data, 'trainingPlans', [])
  const isFilter = _.get(meta?.arg, 'page', 1) == 1
  if (isFilter) state.classes = classes
  else state.classes = _.concat(state.classes, classes)
  state.totalClasses = _.get(payload.data, 'totalTrainingPlan', 0)
}

export const handlePending = (state, { meta }) => {
  const isFilter = _.get(meta?.arg, 'page', 1) == 1
  state.classes = isFilter ? [] : state.classes
}

export const handleRejected = (state, action) => {
  state.classes = []
  state.error = action.meta?.message
}
