import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eLearningDetail,
  eLearningLikeCourse,
  eLearningBatchDetail,
  eLearningCourseBatchScanCount,
} from 'src/utils/apiPath'

export const getCourseDetail = createAsyncThunk(
  'eLearningDetail/getCourseDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postLikeCourse = createAsyncThunk(
  'eLearningDetail/postLikeCourse',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningLikeCourse, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getCourseBatchDetail = createAsyncThunk(
  'courseDetail/getCourseBatchDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningBatchDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getCheckBatchFailScanCount = createAsyncThunk(
  'eLearningEnroll/getCheckBatchFailScanCount',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourseBatchScanCount}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postBatchFailScanCount = createAsyncThunk(
  'eLearningEnroll/postBatchFailScanCount',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningCourseBatchScanCount, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putBatchFailScanCount = createAsyncThunk(
  'eLearningEnroll/putBatchFailScanCount',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourseBatchScanCount, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)