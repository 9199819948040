import _ from 'lodash'
import { setBatch } from 'src/redux/slices/eLearning/enroll'
import { convertFormatDateTime } from 'src/utils/lib'

export const handleBatchPeriod = (batch, key) => {
  if (batch) {
    const date = convertFormatDateTime({
      value: _.get(batch, key, ''),
      type: 'date',
    })
    const timeStr = key === 'periodStart' ? '00.00' : '23.59'
    return `${date} (เวลา ${timeStr} น.)`
  } else return ''
}

export const handleResetBatch = () => (dispatch) => {
  dispatch(setBatch(null))

  const dateEls = document.querySelectorAll('.fc-daygrid-day')
  dateEls.forEach((date) => {
    const dataDate = date.getAttribute('data-date')
    const dateElStyle = date.children[0].style
    const otherEvent = date.querySelector(`#e-${dataDate}`)
    if (otherEvent) otherEvent.style.background = '#ffffff'
    dateElStyle.background = 'transparent'
    dateElStyle.color = '#333333'
    dateElStyle.fontWeight = 400
    date.querySelectorAll('.body3b')?.forEach((el) => el.remove())
  })
}
