import _ from 'lodash'
import React from 'react'
import Typography from '@mui/material/Typography'

export const TEST_RESULT_STATUS_TEXT = {
  PASS: 'ผ่าน',
  NOT_PASS: 'ไม่ผ่าน',
  ABSENCE: 'ขาดสอบ',
}

const Index = ({ row }) => {
  const value = _.get(row, 'examResult', '')
  const result = _.isEmpty(value) ? '-' : TEST_RESULT_STATUS_TEXT[value]

  return <Typography variant="body2">{result}</Typography>
}

export default Index
