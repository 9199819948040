export const formatData = (field, isColor) => {
  let color = ''
  let text = ''
  switch (field) {
    case 'CONSENT':
      text = 'ยินยอม'
      color = 'success.main'
      break
    case 'WAITING':
      text = 'รอการยินยอม'
      color = 'other.ratingActive'
      break
    default:
      text = '-'
      break
  }
  return isColor ? color : text
}
