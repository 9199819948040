import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import styled from '@mui/system/styled'

export const StyledTabBox = styled(Box)(() => ({
  '& .MuiTabs-indicator': { height: 4 },
}))

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary?.main,
  borderBottom: 'solid 4px',
  borderColor: 'transparent',
  background: 'transparent',
  fontSize: '20px',
  fontWeight: 400,
  letterSpacing: '0.46px',
  padding: `${theme.spacing(1)} 22px`,
  '&.Mui-selected': { fontWeight: 700 },
  ':hover': { borderColor: theme.palette.secondary?.main },
}))

export const StyledContainerCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(4),
  margin: `${theme.spacing(5)} 0`,
}))
