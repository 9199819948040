import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import PatternBg from 'src/components/PatternBg'
import ShadowCard from 'src/components/ShadowCard'
import { StyledCardContent } from './Styled'

const ForgetPassword = () => {
  return (
    <PatternBg>
      <ShadowCard>
        <StyledCardContent>
          <Typography color="primary" variant="h4">
            ลืมรหัสผ่าน
          </Typography>
          <Box>
            <InputLabel required>เลขบัตรประชาชน</InputLabel>
            <TextField fullWidth variant="outlined" />
          </Box>
          <Box>
            <InputLabel required>วัน เดือน ปี เกิด</InputLabel>
            <TextField fullWidth variant="outlined" type="date" />
          </Box>
          <Button size="large" variant="contained" color="secondary">
            <Typography variant="buttonl" color="text.white">
              ยืนยัน
            </Typography>
          </Button>
          <Button size="large" color="primary">
            <Typography variant="buttonl" color="primary.main">
              ย้อนกลับ
            </Typography>
          </Button>
        </StyledCardContent>
      </ShadowCard>
    </PatternBg>
  )
}

export default ForgetPassword
