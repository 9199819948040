import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import { responsiveButton } from 'src/components/Preview/Footer/Styled'

const BackToMainBtn = () => {
  const isModule = useSelector((state) => state.eTesting.isModule, shallowEqual)
  const buttonSize = responsiveButton()

  if (isModule) return <></>
  return (
    <Button
      variant="contained"
      size={buttonSize}
      data-testid="btn-back-main"
      onClick={() => (window.location.href = '/')}
    >
      กลับสู่หน้าหลัก
    </Button>
  )
}

export default BackToMainBtn
