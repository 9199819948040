import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Column } from 'src/components/Layout/Styled'
import { SectionLabelIcon } from '../..'
import { SubLabel } from '..'
import { StyledButton, StyledPreview } from '../Styled'
import { handleOpenCameraDialog } from './events'
import { StyledContent, StyledWrapper } from './Styled'

const FaceCapture = () => {
  const { idCardImage, faceImage } = useSelector(
    (state) => ({
      idCardImage: state.eLearningEnroll.body.idCardImage,
      faceImage: state.eLearningEnroll.body.faceImage,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const imgSrc = `${window.__env__.REACT_APP_REKOGNITION_URL}${faceImage}`
  return (
    <Column gap={2}>
      <SectionLabelIcon label="ถ่ายรูปหน้าตรง" icon="ic_file_person" />
      <StyledWrapper>
        {faceImage && <StyledPreview src={imgSrc} width={87} height={87} />} 
        <StyledContent>
          <Column sx={{ gap: 0.5, width: 'auto' }}>
            <SubLabel label="ถ่ายรูปหน้าตรง" />
            <Typography>
              กรุณาเปิดกล้องแล้วถ่ายรูปในที่ที่มีแสงสว่างมากเพียงพอ
            </Typography>
          </Column>
          <StyledButton
            disabled={_.isEmpty(idCardImage)}
            onClick={() => dispatch(handleOpenCameraDialog())}
          >
            ถ่ายรูป
          </StyledButton>
        </StyledContent>
      </StyledWrapper>
    </Column>
  )
}

export default FaceCapture
