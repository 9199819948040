import React from 'react'
import Typography from '@mui/material/Typography'
import { Column } from '../Layout/Styled'

const ContentNoClass = ({text}) => {
  return (
    <Column sx={{ alignItems: 'center', py: 7.5, gap: 2.5 }}>
      <img width={120} height={120} src="/icon/ic_sleeping.svg" />
      <Typography color="text.lightGray"> { text && text != '' ? text : 'ไม่มีหลักสูตรเรียน'}</Typography>
    </Column>
  )
}

export default ContentNoClass