import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledListHeader = styled(Box)(({ theme }) => ({
  width: 955,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(2)} 0`,
  borderBottom: `solid 1px ${theme.palette?.other?.divider}`,
  '& .MuiBox-root:first-of-type': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .MuiBox-root:first-of-type > .MuiButtonBase-root': {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    margin: 'auto',
    flexDirection: 'column-reverse',
    gap: theme.spacing(2),
    alignItems: 'start',
    '& .MuiBox-root:first-of-type > .MuiButtonBase-root': {
      display: 'flex',
      color: theme.palette?.action?.active,
    },
  },
}))

export const StyledNotiDot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 5,
  top: 7,
  width: '6px !important',
  height: 6,
  background: theme.palette.secondary?.main,
  borderRadius: '100%',
}))
