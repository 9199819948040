import React from 'react'
import Filter from './Filter'
import Table from './Table'
import { StyledContainer, StyledContent } from './Styled'

const ClassList = ({ uuid }) => {
  return (
    <StyledContainer>
      <StyledContent>
        <Filter />

        <Table courseUuid={uuid} />
      </StyledContent>
    </StyledContainer>
  )
}

export default ClassList
