/* eslint-disable no-misleading-character-class */
/* eslint-disable no-useless-escape */
import * as yup from 'yup'
import _ from 'lodash'

export const validateSchema = (type) =>
  yup.object({
    information: yup.object().shape({
      prefixTH: yup.string('กรุณาเลือก').required('กรุณาเลือก'),
      firstNameTH: yup
        .string('กรุณากรอก')
        .required('กรุณากรอก')
        .test(
          'test-regex',
          'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาไทยเท่านั้น',
          function (value) {
            if (_.isEmpty(_.defaultTo(value, '').trim())) return false
            const regexTH = /[a-zA-Z]/g
            return !regexTH.test(value)
          }
        )
        .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      lastNameTH: yup
        .string('กรุณากรอก')
        .required('กรุณากรอก')
        .test(
          'test-regex',
          'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาไทยเท่านั้น',
          function (value) {
            if (_.isEmpty(_.defaultTo(value, '').trim())) return false
            const regexTH = /[a-zA-Z]/g
            return !regexTH.test(value)
          }
        )
        .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      prefix: yup.string('กรุณาเลือก').required('กรุณาเลือก'),
      firstName: yup
        .string('กรุณากรอก')
        .required('กรุณากรอก')
        .test('test-regex', 'กรุณากรอกให้ถูกต้อง', function (value) {
          if (_.isEmpty(_.defaultTo(value, '').trim())) return false
          const regex = /^[0-9a-zA-Z\s\][{}~`<>|,?'";:!@#$%^&*)(+=._-]+$/g
          return regex.test(value)
        })
        .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      lastName: yup
        .string('กรุณากรอก')
        .required('กรุณากรอก')
        .test('test-regex', 'กรุณากรอกให้ถูกต้อง', function (value) {
          if (_.isEmpty(_.defaultTo(value, '').trim())) return false
          const regex = /^[0-9a-zA-Z\s\][{}~`<>|,?'";:!@#$%^&*)(+=._-]+$/g
          return regex.test(value)
        })
        .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      mobileNo: yup
        .string('กรุณากรอกให้ถูกต้อง')
        .nullable(true)
        .test('mobileNo-value', 'กรุณากรอกให้ถูกต้อง', function (value) {
          if (type !== 'CS') {
            return String(value).replace(/[^0-9\.]+/g, '').length < 10
              ? false
              : true
          } else {
            return !_.isEmpty(String(value).replace(/[^0-9\.]+/g, '')) &&
              String(value).replace(/[^0-9\.]+/g, '').length < 10
              ? false
              : true
          }
        }),
      email: yup
        .string('กรุณากรอก')
        .required('กรุณากรอก')
        .test('email-value', 'กรุณากรอกให้ถูกต้อง', function (value) {
          const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
          return regex.test(value)
        })
        .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      institution: yup.string().max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      between: yup.string().max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      branch: yup.string().max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      gpa: yup.string().max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
    }),
  })
