import React, { useEffect } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Course from './Course'
import TrainingPlatform from './TrainingPlatform'
import RoadMap from './RoadMap'
import Trainer from './Trainer'
import { StyledDotBg, StyledHome } from './Styled'
import HomeHeader from './HomeHeader'
import { fetchContentHome } from './events'

const Home = () => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.home.data, shallowEqual)
  const showBanner = _.get(data, 'bannerSection', []).length > 0
  const showRoadMap = _.get(data, 'roadMapInformation.status', false)
  const showTrainer = _.get(data, 'trainerInformation.status', false)
  const showTraining = _.get(data, 'trainingPlatformInformation.status', false)
  const showCourse =
    _.get(data, 'coursesTitle.status', false) &&
    _.get(data, 'coursesInformation', []).length > 0

  useEffect(() => {
    dispatch(fetchContentHome())
  }, [])

  if (
    _.isNil(data) ||
    (!showBanner &&
      !showRoadMap &&
      !showTrainer &&
      !showTraining &&
      !showCourse)
  ) {
    return (
      <StyledHome>
        <Box sx={{ background: '#fff', width: '100%', height: '100vh' }} />
      </StyledHome>
    )
  }

  return (
    <StyledHome>
      {showBanner && <HomeHeader />}
      <Box sx={{ position: 'relative' }}>{showRoadMap && <RoadMap />}</Box>
      {showCourse && <Course />}
      <Box sx={{ position: 'relative' }}>
        {showTrainer && <Trainer />}
        {showRoadMap && showTrainer && (
          <StyledDotBg src="/image/home_dot_pattern.svg" />
        )}
      </Box>
      {showTraining && <TrainingPlatform />}
    </StyledHome>
  )
}

export default Home
