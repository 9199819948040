import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import {
  eExaminationGetAddress,
  eExaminationGetConsent,
  eExaminationGetFieldOption,
  eExaminationGetGender,
  eExaminationGetQualification,
  eExaminationPostScheduleFilter,
  eExaminationPostStudent,
  eExaminationUploadIdCard,
  eExaminationUserFilter,
  eExaminationAnnounceFilter,
  eExaminationAnnouncementDetail,
  eExaminationGetMyExaminerProfile,
  eExaminationCheckOtherDocConfig,
  eExaminationUploadOtherDocs,
  eExaminationCheckDuplicateData,
} from 'src/utils/apiPath'

export const getGender = createAsyncThunk(
  'eExamination/getGender',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eExaminationGetGender)
      const {
        data: { result },
      } = response
      const options = result.map((ele) => {
        return { label: ele.genderName, value: ele.uuid }
      })
      return options
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getQualification = createAsyncThunk(
  'eExamination/getQualification',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eExaminationGetQualification)
      const {
        data: { result },
      } = response
      const options = result.map((ele) => {
        return { label: ele.qualificationName, value: ele.uuid }
      })
      return options
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getAddress = createAsyncThunk(
  'eExamination/getAddress',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        eExaminationGetAddress.replace(':zipcode', payload.value)
      )
      const {
        data: { result },
      } = response
      const options = result.map((ele) => {
        const { tambonName, uuid: tambonUuid, zipCode } = ele
        const { amphureName, uuid: amphureUuid } = ele.amphure
        const { provinceName, uuid: provinceUuid } = ele.amphure.provinces
        return {
          zipCode: zipCode,
          tambonName: tambonName,
          tambonUuid: tambonUuid,
          amphureName: amphureName,
          amphureUuid: amphureUuid,
          provinceName: provinceName,
          provinceUuid: provinceUuid,
          label: `${zipCode} >> ${tambonName} >> ${amphureName} >> ${provinceName}`,
        }
      })
      return { options: options, index: payload.index }
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const uploadIdCard = createAsyncThunk(
  'eExamination/uploadIdCard',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eExaminationUploadIdCard, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getFieldOption = createAsyncThunk(
  'eExamination/getFieldOption',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eExaminationGetFieldOption, body)
      const {
        data: { result },
      } = response
      const options = result.map((ele) => {
        return {
          label: ele.key,
          value: ele.value,
          typeOf: ele.typeOf,
          postFix: ele.postFix,
        }
      })
      return options
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postScheduleFiter = createAsyncThunk(
  'eExamination/postScheduleFiter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eExaminationPostScheduleFilter, body)
      const { data } = response
      return { ...data, page: body.page }
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getEExaminationUserFilter = createAsyncThunk(
  'eExamination/getEExaminationUserFilter',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${eExaminationUserFilter}`, payload)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postApplyStudent = createAsyncThunk(
  'eExamination/postApplyStudent',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eExaminationPostStudent, body)
      const { data } = response
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getConsent = createAsyncThunk(
  'eExamination/getConsent',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eExaminationGetConsent)
      const { data } = response
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const cancelMySchedule = createAsyncThunk(
  'eExamination/cancelMySchedule',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${eExaminationPostStudent}/${uuid}`)
      const { data } = response
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getAnnounceFilter = createAsyncThunk(
  'eExamination/getAnnounceFilter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eExaminationAnnounceFilter, body)
      const { data } = response
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getAnnounceDetail = createAsyncThunk(
  'eExamination/getAnnounceDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${eExaminationAnnouncementDetail}/${uuid}`
      )
      const { data } = response
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
export const getMyExaminerProfile = createAsyncThunk(
  'eExamination/getMyExaminerProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eExaminationGetMyExaminerProfile)
      const { data } = response
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const checkOtherDocConfig = createAsyncThunk(
  'eExamination/checkOtherDocConfig',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eExaminationCheckOtherDocConfig)
      const { data } = response
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const uploadOtherDoc = createAsyncThunk(
  'eExamination/uploadOtherDoc',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eExaminationUploadOtherDocs, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const checkDuplicateData = createAsyncThunk(
  'eExamination/checkDuplicateData',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eExaminationCheckDuplicateData, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
