import CircularProgress from '@mui/material/CircularProgress'
import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'
import { Box } from '@mui/material'

export const StyledContainer = styled(Column)(({ theme }) => ({
  marginTop: theme.spacing(9),
  padding: `${theme.spacing(5)} 0`,
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  },
}))

export const StyledLoading = styled(CircularProgress)(() => ({
  width: 24,
  height: 24,
}))

export const StyleLoadingOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  background: theme.palette?.background?.paper,
  opacity: 0.5,
  zIndex: 998,
}))

export const StyledFilterButton = styled(Box)(({ theme }) => ({
  display: 'none',
  width: '100%',
  height: 'auto',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
  },
}))