import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  StyledImagePreview,
  StyledQuestionCard,
  StyledVideoPreview,
} from 'src/components/Preview/QuestionCard/Styled'
import RatingContent from './RatingContent'
import { handleSelector } from './events'

const RatingCard = ({ keyProps }) => {
  const { keyField } = keyProps
  const {
    url,
    mediaType,
    sequence,
    subSequence,
    title,
    name,
    staffUuid,
  } = useSelector((state) => handleSelector(state, keyProps), shallowEqual)

  return (
    <StyledQuestionCard variant="question">
      {keyField == 'lecturers' && name && (
        <TitleLecturer sequence={sequence} name={name} />
      )}
      {keyField == 'questions' && (
        <TitleQuestion
          sequence={sequence}
          subSequence={subSequence}
          title={title}
        />
      )}
      {url &&
        (mediaType == 'VIDEO' ? (
          <StyledVideoPreview controls url={url} />
        ) : (
          <StyledImagePreview
            src={`${window.__env__.REACT_APP_FILE_URL}${url}`}
          />
        ))}
      <RatingContent keyProps={{ ...keyProps, staffUuid }} />
    </StyledQuestionCard>
  )
}

export default RatingCard

export const TitleLecturer = ({ sequence, name }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6">
        วิทยากรคนที่ {sequence + 1} : {name}
      </Typography>
    </Box>
  )
}

export const TitleQuestion = ({ sequence, subSequence, title }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6">
        {sequence}.{subSequence ?? ''} {title}
      </Typography>
    </Box>
  )
}
