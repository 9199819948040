import React from 'react'
import _ from 'lodash'
import Link from '@mui/material/Link'

const ActionRegister = ({ row }) => {
  let data = {}
  const tempProspectProfile = _.get(row, 'tempProspectProfile', null)
  const prospectProfile = _.get(row, 'prospectProfile', null)
  const status = _.get(row, 'status', '')

  if (tempProspectProfile !== null) {
    data = tempProspectProfile
  } else if (prospectProfile !== null) {
    data = prospectProfile
  }

  const register = _.get(data, 'register', false)
  const viewUrl = `/manage-prospect/view/${row?.uuid}`

  return (
    <Link href={viewUrl}>
      {register && status !== 'TERMINATED' && status !== 'DELETED'
        ? 'คลิกเพื่อดูสถานะ'
        : ''}
    </Link>
  )
}

export default ActionRegister
