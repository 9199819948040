export const initialState = {
  progress: 0,
  examinerDrawer: false,
  examinationFilter: {
    startDate: '',
    endDate: '',
    type: [],
    fields: [],
    page: 1,
    hasMore: false,
    isFetch: false,
  },
  examiners: [],
  showFormError: false,
  examinersFormError: [],
  formError: [],
  duplicatedError: [],
  selectedRounds: [],
  notAvailableTime: {},
  examinerSearchText: '',
  examinerList: [],
  selectedExaminerList: [],
  roundList: [],
  selfRegister: false,
  options: {
    fieldOptions: [],
    genderOptions: [],
    qualificationOptions: [],
    addressOptions: [],
    tambonOptions: [],
    amphureOptions: [],
    provinceOptions: [],
  },
  consent: false,
  formNextButtonDisabled: [],
  nextButtonDisabled: true,
  error: null,
  examinationUserFilter: {
    unitNumber: '',
    search: '',
    page: 1,
    hasMore: false,
    isFetch: false,
  },
  windowWidth: 0,
  sideBarDrawer: false,
  drawerIsLoading: false,
  otherDocConfig: {},
}
