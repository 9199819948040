import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'

import UploadIcon from '@mui/icons-material/Upload'
import AddIcon from '@mui/icons-material/Add'
import useLocalStorage from '@rehooks/local-storage'

import {
  ButtonSection,
  Header,
  Container,
  TableSection,
  StyledDialogContent,
} from './Styled'
import Table from '../../components/Table'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchDataList, fetchRegister } from './event'
import FilterDrawer from './FilterDrawer'
import Template from '../../components/Template'
import { useHistory } from 'react-router-dom'

import { alertDialogUpload } from './UploadDialog/events'
import { setSelectActionType } from 'src/redux/slices/manageProspect'

export const ContentDialog = () => {
  const dispatch = useDispatch()
  const { selectActionType } = useSelector(
    (state) => state.manageProspect,
    shallowEqual
  )
  useEffect(() => {
    return () => {
      dispatch(setSelectActionType('enroll'))
    }
  }, [])
  return (
    <StyledDialogContent>
      <FormControl>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body2">รูปแบบการสมัคร</Typography>
          <Typography variant="body2" color="error" sx={{ ml: 0.5 }}>
            *
          </Typography>
        </Box>
        <RadioGroup sx={{ ml: 1 }} name="type" value={selectActionType}>
          <FormControlLabel
            value="enroll"
            control={<Radio />}
            variant="body1"
            label="สมัครเข้าอบรม"
            onChange={() => dispatch(setSelectActionType('enroll'))}
          />

          <FormControlLabel
            value="eExam"
            variant="body1"
            control={<Radio />}
            label="สมัครสอบ"
            onChange={() => dispatch(setSelectActionType('eExam'))}
          />
        </RadioGroup>
      </FormControl>
    </StyledDialogContent>
  )
}

const ManageProspect = () => {
  const dispatch = useDispatch()
  const [, setProspect] = useLocalStorage('prospect')
  const [, setProspectDetail] = useLocalStorage('prospectDetail')

  const history = useHistory()
  const createUrl = '/manage-prospect/create'

  const { table, selectedList } = useSelector(
    (state) => ({
      table: state.table,
      selectedList: state.manageProspect.selectedList,
    }),
    shallowEqual
  )
  const { limit, order, page, sort, selected } = table.table
  const { filterProp, isFilterDrawer } = table.filter

  useEffect(() => {
    dispatch(fetchDataList('fetch', table, '', filterProp))
  }, [limit, order, page, sort])

  useEffect(() => {
    dispatch(
      fetchRegister(selected, setProspect, selectedList, setProspectDetail)
    )
  }, [selected])

  return (
    <Template>
      <Container>
        <Header>
          <Typography variant="h4">โปรไฟล์ทีม</Typography>
          <ButtonSection>
            <Button
              variant="contained"
              startIcon={<UploadIcon />}
              onClick={() =>
                dispatch(alertDialogUpload(setProspect, setProspectDetail))
              }
              sx={{ width: '206px' }}
            >
              เพิ่มสมาชิกจำนวนมาก
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => history.push(createUrl)}
              sx={{ width: '151px' }}
            >
              เพิ่มสมาชิกในทีม
            </Button>
          </ButtonSection>
        </Header>
        <TableSection>
          <Table />
        </TableSection>
        <FilterDrawer open={isFilterDrawer} table={table} />
      </Container>
    </Template>
  )
}

export default ManageProspect
