import { defaultValue } from '../../../constants/eTesting'

export const answerETestingObj = {
  questionUuid: '',
  answerUuid: '',
  freetextAnswer: '',
  isAnswered: false,
}

export const initialState = {
  error: null,
  result: null,
  correctAnswers: [],
  isETestingOpen: false,
  isButtonLoading: false,
  onShowAnswer: false,
  onReviewAnswer: false,
  isTimeUp: false,
  section: defaultValue.defaultSection.testing,
  coverImage: '',
  coverText: '',
  description: '',
  descriptionPosition: defaultValue.position.left,
  name: '',
  title1: '',
  title1Position: defaultValue.position.left,
  title2: '',
  title2Position: defaultValue.position.left,
  displayQuestion: defaultValue.displayQuestion.all,
  questions: [],
  answerETesting: [],
  totalETesting: 0,
  testRoundAt: 0,
  page: 1,
  mobileProps: { isMobile: false, selectedItem: null },
  isModule: false,
}
