import React from 'react'
import _ from 'lodash'

import { StyledBox } from './Styled'

const ActionImage = ({ row }) => {
  const defaultImage = '/icon/img_empty.png'
  return (
    <StyledBox
      img={
        _.get(row, 'image', '')
          ? `${window.__env__.REACT_APP_FILE_URL}${_.get(row, 'image', '')}`
          : defaultImage
      }
    ></StyledBox>
  )
}

export default ActionImage
