import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { Row } from 'src/components/Layout/Styled'
import { StyledTooltip } from 'src/modules/ELearning/Course/Learning/Styled'
import { handleSelector } from '../events'
import {
  StyledCaption,
  StyledContent,
  StyledDisplayTraining,
  StyledTrainingContainer,
  StyledSquareBg,
  StyledTrainingWrapper,
  StyledWrapper,
} from './Styled'
import { handleRouterTrainingPlatform } from './events'

const TrainingPlatform = () => {
  const training = useSelector(
    (state) => handleSelector(state, 'trainingPlatformInformation'),
    shallowEqual
  )
  const platforms = _.get(training, 'platforms', []).filter(
    (item) => item.status
  )

  return (
    <StyledWrapper>
      <StyledTrainingContainer>
        <StyledSquareBg src="/image/home_square_pattern.svg" />
        <ContentHeader training={training} />
        <StyledContent>
          {platforms.map((item, index) => (
            <DisplayTraining key={index} data={item} index={index} />
          ))}
        </StyledContent>
      </StyledTrainingContainer>
    </StyledWrapper>
  )
}

export default TrainingPlatform

export const ContentHeader = ({ training }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <StyledWrapper>
      <Typography variant={isNotSm ? 'h2' : 'h5'} color="primary">
        {_.get(training, 'title', '')}
      </Typography>
    </StyledWrapper>
  )
}

export const DisplayTraining = ({ data, index }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const title = useMemo(() => {
    let title = ''
    const splittedTitle = _.get(data, 'title', '-').split('(')
    splittedTitle.forEach((text, index) => {
      if (index > 0) title += `\n(${text}`
      else title += text
    })
    return title
  }, [data])

  return (
    <StyledDisplayTraining>
      <StyledTooltip title={data.description}>
        <StyledTrainingWrapper
          onClick={() => dispatch(handleRouterTrainingPlatform(data, history))}
        >
          <img src={`/icon/home_tp_${index + 1}.svg`} width={56} height={56} />
          <Row
            sx={{
              minHeight: 48,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              color="primary"
              variant="h6"
              sx={{ lineHeight: '24px', textAlign: 'center' }}
            >
              {title}
            </Typography>
          </Row>
          <StyledCaption color="text.secondary" sx={{ textAlign: 'center' }}>
            {data.description}
          </StyledCaption>
        </StyledTrainingWrapper>
      </StyledTooltip>
      <Divider />
    </StyledDisplayTraining>
  )
}
