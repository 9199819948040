import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eLearningFilter,
  eLearningGetLikeCourse,
  eLearningLearnerList,
  eLearningList,
  eLearningOngoingCourse,
} from 'src/utils/apiPath'

export const getELearningFilter = createAsyncThunk(
  'eLearingList/getELearningFilter',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eLearningFilter)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postELearningList = createAsyncThunk(
  'eLearingList/postELearningList',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningList, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getELearningOngoingCourse = createAsyncThunk(
  'eLearningList/getELearningOngoingCourse',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eLearningOngoingCourse)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getELearningLikeCourse = createAsyncThunk(
  'eLearningList/getELearningLikeCourse',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eLearningGetLikeCourse)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postELearningLikeList = createAsyncThunk(
  'eLearingList/postELearningLikeList',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningList, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postELearningLearnerList = createAsyncThunk(
  'eLearingList/postELearningLearnerList',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningLearnerList, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
