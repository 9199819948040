import _ from 'lodash'

export const handleCourseFiltering = (e, filterBody) => {
  const name = _.get(e.target, 'name', '')
  const isChecked = _.get(e.target, 'checked', false)
  const value = _.get(e.target, 'value', '')
  let filterList = [...filterBody]
  if (isChecked) filterList.push(value)
  else filterList = filterList.filter((item) => item != value)
  return { key: name, value: filterList }
}
