import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Template from 'src/components/Template'
import {
  StyledFooterWrapper,
  StyledStepButton,
  StyledSubmitButton,
  StyledWrapper,
} from './Styled'
import {
  handleClickBack,
  handleClickNext,
  handleDisableBtn,
  handleSubmitEnroll,
} from './events'

const Footer = ({ isVerification }) => {
  const { activeStep, isDisableSubmit, batch, idCardImage, faceImage } =
    useSelector(
      (state) => ({
        activeStep: state.eLearningEnroll.activeStep,
        isDisableSubmit: state.eLearningEnroll.isDisableSubmit,
        batch: state.eLearningEnroll.body.batch,
        idCardImage: state.eLearningEnroll.body.idCardImage,
        faceImage: state.eLearningEnroll.body.faceImage,
      }),
      shallowEqual
    )
  const dispatch = useDispatch()
  const disabled = useMemo(
    () => handleDisableBtn(isVerification),
    [activeStep, batch, idCardImage, faceImage]
  )
  const isShowSubmit = (isVerification && activeStep === 1) || activeStep === 2

  return (
    <StyledFooterWrapper>
      <Template noMinHeight>
        <StyledWrapper>
          {activeStep !== 0 && (
            <StyledStepButton
              activeStep={activeStep}
              onClick={() => dispatch(handleClickBack())}
            >
              ย้อนกลับ
            </StyledStepButton>
          )}
          {isShowSubmit ? (
            <StyledSubmitButton
              disabled={isDisableSubmit}
              onClick={() => dispatch(handleSubmitEnroll(isVerification))}
            >
              {isVerification
                ? 'ยืนยันตัวตนเพื่อเข้าอบรม'
                : 'ลงทะเบียนเข้าอบรม'}
            </StyledSubmitButton>
          ) : (
            <StyledStepButton
              right={0}
              endIcon={<ArrowForward />}
              disabled={disabled}
              activeStep={activeStep}
              onClick={() => dispatch(handleClickNext())}
            >
              ถัดไป
            </StyledStepButton>
          )}
        </StyledWrapper>
      </Template>
    </StyledFooterWrapper>
  )
}

export default Footer
