import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { Row } from 'src/components/Layout/Styled'
import ButtonAndAmount from '../ButtonAndAmount'
import { stickNavbar } from './events'
import { StyledContainer, StyledFixedHeader, StyledHeaderText } from './Styled'

const FixedHeader = ({ socketId }) => {
  const [isScrolled, setScroll] = useState(false)
  const englishName = useSelector(
    (state) => _.get(state.classDetail.data, 'courseDetail.englishName', '-'),
    shallowEqual
  )

  useEffect(() => {
    window.addEventListener('scroll', () => stickNavbar(setScroll))

    return () => {
      window.removeEventListener('scroll', () => stickNavbar(setScroll))
    }
  }, [])

  return (
    <StyledFixedHeader isScrolled={isScrolled}>
      <StyledContainer>
        <StyledHeaderText>{englishName}</StyledHeaderText>
        <Row sx={{ width: 'auto', flexDirection: 'row-reverse', gap: 3 }}>
          <ButtonAndAmount isScrolled socketId={socketId} />
        </Row>
      </StyledContainer>
    </StyledFixedHeader>
  )
}

export default FixedHeader
