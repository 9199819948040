import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledFormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: { '& #remark': { marginTop: '-14px' } },
}))

export const StyledFormRow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: theme.spacing(2.5),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '&#name': { gap: theme.spacing(0.5) },
  },
}))
