import { setAnswered } from 'src/redux/slices/eTesting'

export const onDragEnd = (event, answers, uuid) => (dispatch) => {
  if (!event.destination) return
  if (event.destination.index === event.source.index) return
  const listAnswer = reorder(
    answers,
    event.source.index,
    event.destination.index
  )

  dispatch(
    setAnswered({ type: 'SEQUENCE', uuid, value: listAnswer })
  )
}

export const reorder = (list, startIndex, endIndex) => {
  const listAnswer = Array.from(list)
  const [removed] = listAnswer.splice(startIndex, 1)
  listAnswer.splice(endIndex, 0, removed)
  return listAnswer
}
