import Search from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase'
import styled from '@mui/system/styled'

export const BoxSearch = styled(Box)(({ theme }) => ({
  borderRadius: theme?.shape?.borderRadius,
  border: `1px solid ${theme.palette.text?.silver}`,
  backgroundColor: theme?.palette?.text?.white,
  width: '100%',
  minWidth: '250px',
  '&:hover': {
    backgroundColor: theme?.palette?.text?.wildSand,
  },
  marginLeft: 0,
  marginTop: 16,
  [theme.breakpoints.up('lg')]: {
    width: 'auto',
    maxHeight: '37.5px',
  },
}))

export const SearchInput = styled(InputBase)(({ theme }) => ({
  maxHeight: '37.5px',
  width: 'calc(100% - 36px)',
  marginLeft: theme.spacing(1),
}))

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  width: 20,
  height: 20,
  cursor: 'pointer',
  position: 'absolute',
  marginTop: theme.spacing(1),
}))
