import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledTabBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    marginLeft: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(2.5),
  },
}))

export const StyledTabContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background?.paper,
  borderTop: 'solid 1px',
  borderColor: theme.palette?.other?.divider,
  '& > .MuiBox-root': {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
  },
  [theme.breakpoints.down('lg')]: {
    borderBottom: 'solid 1px',
    borderColor: theme.palette?.other?.divider,
    '& > .MuiBox-root': {
      padding: theme.spacing(3),
    },
  },
  [theme.breakpoints.down('lg')]: {
    '& > .MuiBox-root': {
      padding: theme.spacing(2.5),
    },
  },
}))
