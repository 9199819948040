import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

const StyledPatternBg = styled(Box)(() => ({
  background: `url('/image/pattern_background.svg')`,
  height: `calc(100vh - 48px)`,
  position: 'relative',
}))

export default StyledPatternBg
