import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledBox = styled(Box)(({ img }) => ({
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundImage: `url(${img})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
}))
