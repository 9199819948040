import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { manageContentHome } from 'src/utils/apiPath'

export const getContentHome = createAsyncThunk(
  'home/getContentHome',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(manageContentHome)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
