import _ from 'lodash'
import { classTabLabel } from 'src/constants/course'

export const handleDisplayTab = (classDetail) => {
  let classTab = classTabLabel
  const courseDetail = _.get(classDetail, 'courseDetail', {})
  const trainingPlanData = _.get(classDetail, 'trainingPlanData', {})
  const coursePurpose = _.get(courseDetail, 'coursePurpose', '')
  const courseOutline = _.get(courseDetail, 'courseOutline', '')
  const coursePrerequisite = _.get(courseDetail, 'coursePrerequisiteData', [])
  const moreDetail = _.get(trainingPlanData, 'moreDetail', '')

  if (coursePurpose == '')
    classTab = classTab.filter((item) => item.value !== 1)
  if (courseOutline == '')
    classTab = classTab.filter((item) => item.value !== 2)
  if (coursePrerequisite.length == 0)
    classTab = classTab.filter((item) => item.value !== 3)
  if (moreDetail == '') classTab = classTab.filter((item) => item.value !== 4)

  return classTab
}
