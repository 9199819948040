import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledWrapper = styled(Row)(({ theme }) => ({
  width: 'calc(50% - 66px)',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: { width: '100%', justifyContent: 'start' },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
}))

export const StyledContent = styled(Row)(() => ({
  justifyContent: 'space-between',
}))
