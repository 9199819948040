import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ratingColor } from 'src/constants/eEvaluation'
import { StyledRatingData } from '../Styled'
import { handleDefaultValue, handleSelectRating } from './events'

const RatingRadio = ({ questionProps }) => {
  const { uuid, choices, title, staffUuid } = questionProps
  const dispatch = useDispatch()
  const answer = useSelector(
    (state) => handleDefaultValue(state.eEvaluation, uuid, staffUuid),
    shallowEqual
  )

  return (
    <RadioGroup
      style={{ width: '100%', flexDirection: 'row', flexWrap: 'nowrap' }}
      onChange={(e) => dispatch(handleSelectRating(e, uuid, staffUuid))}
    >
      {choices.map((item) => (
        <StyledRatingData
          key={item.sequence}
          color={ratingColor[item.sequence] || '#ff88b3'}
          boxHeight={title.length}
        >
          <FormControlLabel
            control={<Radio />}
            value={item.uuid}
            checked={item.uuid == answer}
            sx={{ margin: 0 }}
            data-testid={`choice-${item.sequence}`}
          />
        </StyledRatingData>
      ))}
    </RadioGroup>
  )
}

export default RatingRadio
