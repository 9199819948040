import _ from 'lodash'

export const handleSelector = (state, keyProps) => {
  const { keyField, keyAssessment, keySub } = keyProps
  const assessment = _.get(state.eEvaluation, keyField, []).find(
    (item) => item.key == keyAssessment
  )

  if (!keySub) {
    return {
      url: _.get(assessment, 'url', null),
      mediaType: _.get(assessment, 'mediaType', null),
      sequence: _.get(assessment, 'key', 0),
      title: _.get(assessment, 'title', ''),
      name: _.get(assessment, 'name', ''),
      staffUuid: _.get(assessment, 'staffUuid', ''),
    }
  }

  const choice = assessment.choices.find(
    (item) => item.nextSubQuestionSequence == keySub
  )
  const subQuestion = _.get(choice, 'subQuestions', {})
  return {
    url: _.get(subQuestion, 'url', null),
    mediaType: _.get(assessment, 'mediaType', null),
    sequence: _.get(assessment, 'key', 0),
    subSequence: _.get(subQuestion, 'subSequence', 0),
    title: _.get(subQuestion, 'title', ''),
    name: _.get(subQuestion, 'name', ''),
  }
}
