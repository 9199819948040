import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledBlueBg = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette?.background?.blue,
  padding: `${theme.spacing(4)} ${theme.spacing(0)}`,
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(2.5)}`,
  },
}))

export const StyledDivider = styled((props) => (
  <Divider {...props} orientation="vertical" variant="middle" flexItem />
))(({ theme }) => ({ borderColor: theme.palette?.other?.divider }))

export const StyledLabelIcon = styled(Row)(({ theme }) => ({
  width: 'auto',
  gap: theme.spacing(1),
  color: theme.palette.text?.secondary,
}))

export const StyledRowContent = styled(Row)(({ theme }) => ({
  width: '100%',
  maxWidth: 1146,
  textAlign: 'center',
  gap: theme.spacing(1),
  margin: 'auto',
  [theme.breakpoints.down('lg')]: {
    maxWidth: 768,
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& #training': { display: 'none' },
  },
}))

export const StyledRowTwo = styled(Row)(({ theme }) => ({
  width: '50%',
  gap: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& .MuiDivider-root': { display: 'none' },
  },
}))

export const StyledHalfColumn = styled(Column)(({ theme }) => ({
  width: '50%',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))
