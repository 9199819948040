import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledCourseContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${theme.spacing(7.5)} 0 ${theme.spacing(4)}`,
  textAlign: 'center',
  background: theme.palette.background?.default,
  [theme.breakpoints.down(769)]: {
    padding: `${theme.spacing(5)} 0`,
  },
}))

export const StyledHeaderText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(1),
  },
}))

export const StyledHeaderBody = styled(Typography)(({ theme }) => ({
  width: 742,
  wordBreak: 'break-word',
  [theme.breakpoints.down('lg')]: {
    width: 560,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: `0 ${theme.spacing(1)}`,
  },
}))

export const StyledGrayBg = styled('img')(({ theme }) => ({
  width: '100%',
  height: 460,
  position: 'absolute',
  objectFit: 'cover',
  top: 0,
  left: 0,
  zIndex: -1,
  [theme.breakpoints.down('sm')]: { height: 320 },
}))
