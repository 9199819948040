import React from 'react'
import { AccordionActions, IconButton, Typography } from '@mui/material'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { setExaminerDrawer } from 'src/redux/slices/eExamination'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import { StyledAccordion, StyledAccordionSummary } from '../../Styled'
import AddExaminerDrawer from '../AddExaminerDrawer'
import AddEaxminerDrawerMobile from '../AddEaxminerDrawerMobile'
import { Column } from 'src/components/Layout/Styled'
import ExaminerItem from './ExaminerItem'

const Examiner = () => {
  const dispatch = useDispatch()
  const { examiners, selfRegister, width } = useSelector(
    (state) => ({
      examiners: state.eExamination.examiners,
      selfRegister: state.eExamination.selfRegister,
      width: state.eExamination.windowWidth,
    }),
    shallowEqual
  )
  return (
    <StyledAccordion defaultExpanded>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">จำนวนผู้สอบ</Typography>
        <Typography variant="body1b"> {examiners.length} คน</Typography>
      </StyledAccordionSummary>
      <Column sx={{ mb: 2, rowGap: 2, maxHeight: 300, overflow: 'auto' }}>
        {examiners.map((ele, ind) => {
          return <ExaminerItem key={ind} index={ind} />
        })}
      </Column>
      {!selfRegister && (
        <AccordionActions sx={{ p: 0 }}>
          <IconButton
            size="small"
            sx={{ columnGap: 1, border: '1px solid #00008F', borderRadius: 0 }}
            onClick={() => {
              dispatch(setExaminerDrawer(true))
            }}
          >
            <AddIcon sx={{ fontSize: 18, color: '#00008F' }} />
            <Typography
              sx={{ fontWeight: 500, fontSize: 17 }}
              variant="body2"
              color="primary.main"
            >
              เพิ่มรายชื่อ
            </Typography>
          </IconButton>
        </AccordionActions>
      )}
      {width < 1280 ? <AddEaxminerDrawerMobile /> : <AddExaminerDrawer />}
    </StyledAccordion>
  )
}

export default Examiner
