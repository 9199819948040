import Box from '@mui/material/Box'
import Step from '@mui/material/Step'
import Stepper from '@mui/material/Stepper'
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector'
import styled from '@mui/system/styled'

export const StyledWrapper = styled(Box)(({ theme }) => ({
  '& #stepper': { padding: `${theme.spacing(5.5)} 0` },
  [theme.breakpoints.down('lg')]: { padding: `0 ${theme.spacing(5)}` },
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.spacing(2)}`,
    '& #stepper': { padding: `${theme.spacing(2)} 0` },
  },
}))

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  // justifyContent: 'space-between',
  // '& .MuiStep-root': { flex: 'unset' },
  // '& .MuiStepConnector-root': { top: '40px !important' },
  // '& .MuiStepConnector-root:first-of-type': {
  //   left: 'calc(-50% - 285px) !important',
  // },
  // '& .MuiStep-root:last-of-type .MuiStepConnector-root': {
  //   left: 'calc(-50% - 257px) !important',
  // },
  // '& .MuiStepConnector-line': { width: 350 },
  // [theme.breakpoints.down('lg')]: {
  //   '& .MuiStepConnector-root:first-of-type': {
  //     left: 'calc(-50% - 94px) !important',
  //   },
  //   '& .MuiStep-root:last-of-type .MuiStepConnector-root': {
  //     left: 'calc(-50% - 90px) !important',
  //   },
  //   '& .MuiStepConnector-line': { width: 150 },
  // },
  // [theme.breakpoints.down('sm')]: {
  //   '& .MuiStepConnector-root': { top: '29px !important' },
  //   '& .MuiStepConnector-root:first-of-type': {
  //     left: 'calc(-50% + 2px) !important',
  //   },
  //   '& .MuiStep-root:last-of-type .MuiStepConnector-root': {
  //     left: 'calc(-50% - 8px) !important',
  //   },
  //   '& .MuiStepConnector-line': { width: 48 },
  // },
}))

export const StyledStep = styled(Step)(({ theme }) => ({
  '& .MuiStepLabel-label': { marginTop: theme.spacing(1) },
  [theme.breakpoints.down('lg')]: {
    '&#step-0': { width: 106 },
    '&#step-2': { width: 138 },
  },
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    '& .MuiStepLabel-label': { fontSize: 14 },
    '&#step-0': { width: 61 },
    '&#step-1': { width: 108 },
    '&#step-2': { width: 83 },
  },
}))

export const StyledStepIcon = styled('div')(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 44,
  height: 44,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette?.other?.divider,
  ...(ownerState.inactive && {
    color: theme.palette?.text?.lightGray,
  }),
  ...(ownerState.active && {
    color: theme.palette?.text?.white,
    background: theme.palette?.primary?.main,
  }),
  ...(ownerState.completed && {
    color: theme.palette?.text?.white,
    background: theme.palette?.primary?.main,
  }),
  [theme.breakpoints.down('sm')]: { width: 30, height: 30 },
}))

export const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    left: 'calc(-50% + 60px)',
    right: 'calc(50% + 60px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette?.primary?.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette?.primary?.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette?.blue?.blueLine,
  },
}))
