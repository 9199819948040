import _ from 'lodash'
import React from 'react'
import { startTest } from '../../../../utils/lib'

import { StyledButton } from './Styled'

const Index = ({ row }) => {
  const isShowButton = _.get(row, 'isShowButton', false)
  const isCanSent = _.get(row, 'isCanSent', false)
  const courseTestAndEvaUuid = _.get(row, 'courseTestAndEvaUuid', '')
  const eEvaVersionUuid = _.get(row, 'eEvaluateVersionUuid', '')
  const trainingPlanUuid = _.get(row, 'trainingPlanUuid', '')
  let type = _.get(row, 'type', '')

  if (type === 'Test' || type === 'Assessment') type = 'E_TESTING'
  return (
    <>
      {isShowButton && (
        <StyledButton
          isCanSent={!isCanSent}
          onClick={() =>
            startTest(
              eEvaVersionUuid,
              trainingPlanUuid,
              type,
              courseTestAndEvaUuid
            )
          }
        >
          เริ่มทำ
        </StyledButton>
      )}
    </>
  )
}

export default Index
