import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eLearningAllLearningPath,
  eLearningEnrollLearningPath,
  eLearningLearningPathDetail,
  eLearningLikeLearningPath,
  eLearningMainLearningPath,
} from 'src/utils/apiPath'

export const getMainLearningPath = createAsyncThunk(
  'eLearningLearningPathList/getMainLearningPath',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eLearningMainLearningPath)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postLearningPathList = createAsyncThunk(
  'eLearningLearningPathList/postLearningPathList',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningAllLearningPath, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getLearningPathDetail = createAsyncThunk(
  'eLearningLearningPathList/getLearningPathDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningLearningPathDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postLikeLearningPath = createAsyncThunk(
  'eLearningLearningPathList/postLikeLearningPath',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningLikeLearningPath, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postEnrollLearningPath = createAsyncThunk(
  'eLearningLearningPathList/postEnrollLearningPath',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningEnrollLearningPath, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
