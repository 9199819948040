import { isMediaWidthMoreThan } from 'src/utils/lib'

export const handleClickBtn = (url) => {
  window.location.href = url
}

export const handleButtonSize = () => {
  const isLg = isMediaWidthMoreThan('lg')
  const isMd = isMediaWidthMoreThan('sm')

  if (isLg) return 'l'
  return isMd ? 's' : 'm'
}
