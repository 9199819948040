import _ from 'lodash'
import { store } from 'src/App'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getCourseDetail, postLikeCourse } from 'src/services/eLearning/detail'

export const checkScrollWidth = (setScroll, isLg, isNotSm) => {
  if (_.isNil(window)) return

  let isScroll = false
  const scrollThreshold = isLg ? 450 : 352
  if (isNotSm) isScroll = window.scrollY > scrollThreshold
  else isScroll = window.scrollY < 220
  setScroll(isScroll)
}

export const fetchCourseDetail = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  const response = await dispatch(getCourseDetail(uuid))
  dispatch(stopLoading())
  return response
}

export const getDetailState = () => {
  return store.getState().eLearningDetail
}

export const handleLikeCourse = () => (dispatch) => {
  const { data } = getDetailState()
  const payload = {
    eLearningCourse: { id: data.id, uuid: data.uuid },
    isLike: !_.get(data, 'isLike', false),
  }
  dispatch(postLikeCourse(payload))
}
