import React from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Typography } from '@mui/material'
import Search from '../../../components/Search'
import { StyledListHeader, StyledRightFlex } from './Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { setFilterValue } from 'src/redux/slices/eContent'

const ListHeader = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isNotSm = isMediaWidthMoreThan('sm')
  const { keyword } = useSelector(
    (state) => ({
      keyword: state.eContent.keyword,
    }),
    shallowEqual
  )

  return (
    <>
      <StyledListHeader>
        <Typography sx={{ minWidth: 112 }} variant={isNotSm ? 'h4' : 'h5'}>
          คลังความรู้
        </Typography>
        <StyledRightFlex>
          <Search
            boxSx={{ width: isNotSm ? 227 : 180 }}
            placeholder="ค้นหา"
            text={keyword}
            setText={(value) =>
              dispatch(setFilterValue({ key: 'keyword', value: value }))
            }
            handleSearch={() => history.push(`/e-content/search`)}
          />
        </StyledRightFlex>
      </StyledListHeader>
    </>
  )
}
export default ListHeader
