import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'

export const formatNowDateTime = () => {
  dayjs.extend(buddhistEra)

  return `${dayjs(new Date()).format(window.__env__.REACT_APP_DATE_SHOW)} ${dayjs(
    new Date()
  ).format(window.__env__.REACT_APP_DATE_TIME)}`
}
