import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import styled from '@mui/system/styled'

export const StyledTabContent = styled(Box)(({ theme }) => ({
  '& .MuiTab-root': { textTransform: 'none !important' },
}))

export const StyledTabBox = styled(Box)(({ theme }) => ({
  width: 1170,
  marginTop: 52,
  [theme.breakpoints.down(1170)]: {
    width: '100%',
    padding: `0 ${theme.spacing(2)}`,
  },
  [theme.breakpoints.down(769)]: { marginTop: theme.spacing(1) },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1.5),
    padding: `0 ${theme.spacing(2.5)}`,
  },
}))

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary?.main,
  border: 'solid 1px',
  borderColor: theme.palette.primary?.main,
  borderBottom: 'none',
  fontSize: '19px',
  fontWeight: 500,
  letterSpacing: '0.46px',
  padding: `${theme.spacing(1)} 22px`,
  '&.Mui-selected': {
    color: theme.palette.primary?.contrastText,
    backgroundColor: theme.palette.primary?.main,
  },
}))

export const StyledContainerCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: theme.spacing(3),
  rowGap: theme.spacing(5),
  margin: `${theme.spacing(5)} 0`,
  justifyContent: 'start',
  [theme.breakpoints.down('lg')]: {
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(5),
  },
  [theme.breakpoints.down(769)]: {
    columnGap: theme.spacing(4),
    rowGap: theme.spacing(3),
    margin: `${theme.spacing(3)} 0`,
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    margin: `12px 0 ${theme.spacing(3)}`,
  },
}))
