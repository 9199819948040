import _ from 'lodash'
import {
  getAssessmentPointer,
  getQuestionPointer,
} from 'src/redux/slices/eEvaluation/events'
import { answerEEvaluationObj } from 'src/redux/slices/eEvaluation/model'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { answerSection, assessmentField } from 'src/constants/eEvaluation'
import {
  setInititialAssessment,
  setInitialAnswer,
  setInitialPreview,
} from 'src/redux/slices/eEvaluation'

export const fetchEvaluate = (eEvaluation) => (dispatch) => {
  dispatch(startLoading())

  if (_.isNil(eEvaluation)) return

  const {
    uuid,
    information,
    setting,
    courseAssessments,
    lecturerAssessments,
    courseManagementAssessments,
    questions,
  } = eEvaluation
  const courseField = assessmentField.course
  const lecturerField = assessmentField.lecturer
  const courseManagementField = assessmentField.courseManagement

  dispatch(
    handleSetAssessmentList({ list: courseAssessments, section: courseField })
  )
  const courses = handleAssessmentList({ list: courseAssessments })

  let lecturers = []
  const isPersonal = _.get(lecturerAssessments, '[0].isPersonal', false)
  const staffs = _.get(lecturerAssessments, '[0].staff', [])
  if (isPersonal) {
    staffs.forEach((item, index) => {
      const name = `${item.firstNameTH} ${item.lastNameTH}`
      dispatch(
        handleSetAssessmentList({
          list: lecturerAssessments,
          section: lecturerField,
          key: index,
          staffUuid: item.uuid,
        })
      )
      const lecturer = handleAssessmentList({
        list: lecturerAssessments,
        name,
        key: index,
        staffUuid: item.uuid,
      })

      lecturers.push(...lecturer)
    })
  } else {
    dispatch(
      handleSetAssessmentList({
        list: lecturerAssessments,
        section: lecturerField,
      })
    )
    lecturers = handleAssessmentList({
      list: lecturerAssessments,
    })
  }

  dispatch(
    handleSetAssessmentList({
      list: courseManagementAssessments,
      section: courseManagementField,
    })
  )
  const courseManagements = handleAssessmentList({
    list: courseManagementAssessments,
  })

  const questionList = dispatch(handleQuestionList(questions))

  const pointerCourse = getAssessmentPointer(courses, courseField)
  const pointerLecturer = getAssessmentPointer(lecturers, lecturerField)
  const pointerCourseManagement = getAssessmentPointer(
    courseManagements,
    courseManagementField
  )
  const pointerQuestion = getQuestionPointer(questionList)

  const initialPayload = {
    uuid,
    information,
    setting,
    courses,
    lecturers,
    courseManagements,
    questions: questionList,
  }

  const initialAssesement = {
    pointerCourse,
    pointerLecturer,
    pointerCourseManagement,
    pointerQuestion,
  }

  dispatch(setInitialPreview(initialPayload))
  dispatch(setInititialAssessment(initialAssesement))
  dispatch(stopLoading())
}

export const handleAssessmentList = (props) => {
  const { staffUuid, name, key } = props
  return _.get(props, 'list', []).map((item) => {
    let obj = {
      ...item,
      key: key || 0,
      questions: handleRatingQuestion(item.questions),
    }
    if (name) obj = { ...obj, name, staffUuid }
    return obj
  })
}

export const handleSetAssessmentList = (props) => (dispatch) => {
  const { staffUuid, section, key } = props
  const list = _.get(props, 'list', [])
  list.forEach((item) => {
    dispatch(
      handleInitialAnswer({
        list: item.questions,
        uuid: item.uuid,
        section,
        staffUuid,
        key,
      })
    )
  })
}

export const handleQuestionList = (questionList) => (dispatch) => {
  dispatch(handleInitialAnswer({ list: questionList, section: 'questions' }))

  return questionList.map((question) => {
    const type = question.questionType
    const choiceArr =
      type == 'RATING'
        ? _.get(question, 'choices', [])
        : _.get(question, 'answers', [])

    const choices = handleAnswerList({
      sequence: question.sequence,
      list: choiceArr,
      subList: _.get(question, 'subQuestions', []),
    })

    const questions = question.questions
      ? handleRatingQuestion(question.questions)
      : []

    return {
      uuid: question.uuid,
      key: question.sequence,
      sequence: question.sequence,
      mediaType: question.mediaType,
      url: question.url,
      title: question.title,
      setNextQuestion: question.setNextQuestion,
      setTypeOfAnswers: question.setTypeOfAnswers,
      typeOfAnswers: question.typeOfAnswers,
      numberOfAnswers: question.numberOfAnswers,
      isSuggestion: question.isSuggestion,
      questions,
      type,
      choices,
    }
  })
}

export const handleAnswerList = (props) => {
  const { sequence, list, subList } = props
  return list.map((choice) => {
    const type = choice.answerType
    const isOther = type == 'OTHER'
    let obj = {
      uuid: choice.uuid,
      title: isOther ? 'อื่นๆ' : choice.title,
    }

    if (subList) {
      if (choice.nextQuestionType == 'NEXT_SUB') {
        subList.forEach((sub) => {
          if (sub.sequence == choice.nextSubQuestionSequence) {
            const choiceArr =
              sub.questionType == 'RATING'
                ? _.get(sub, 'choices', [])
                : _.get(sub, 'answers', [])
            const choices = handleAnswerList({ list: choiceArr })

            const subQuestions = {
              uuid: sub.uuid,
              key: sub.sequence,
              sequence: sequence,
              subSequence: sub.sequence,
              type: sub.questionType,
              mediaType: sub.mediaType,
              url: sub.url,
              title: sub.title,
              setTypeOfAnswers: sub.setTypeOfAnswers,
              typeOfAnswers: sub.typeOfAnswers,
              numberOfAnswers: sub.numberOfAnswers,
              questions: handleRatingQuestion(sub.questions),
              isSuggestion: sub.isSuggestion,
              choices,
            }

            obj = { ...obj, subQuestions }
          }
        })
      }
    }

    return { ...choice, ...obj }
  })
}

export const handleRatingQuestion = (list) => {
  if (list) {
    return list.map((question, key) => ({
      ...question,
      key,
    }))
  } else return null
}

export const handleInitialAnswer = (props) => (dispatch) => {
  const { section, uuid, staffUuid, key } = props
  const list = _.get(props, 'list', [])
  list.forEach((question) => {
    if (question.questionType == 'RATING') {
      dispatch(
        handleInitialAnswer({
          list: question.questions,
          section: 'questions',
          uuid: question.uuid,
          key: question.sequence,
        })
      )
    } else {
      let answerObj = {
        ...answerEEvaluationObj,
        staffUuid: staffUuid || '',
        questionUuid: question.uuid,
        answerSection: answerSection[section],
        key: key || question.sequence,
      }

      if (uuid) answerObj = { ...answerObj, mainUuid: uuid }

      if (question.questionType == 'MULTIPLE') {
        answerObj = {
          ...answerObj,
          setTypeOfAnswers: _.get(question, 'setTypeOfAnswers', false),
          typeOfAnswers: _.get(question, 'typeOfAnswers', 'EQUAL'),
          numberOfAnswers: _.get(question, 'numberOfAnswers', 0),
        }
      }
      dispatch(setInitialAnswer(answerObj))
    }
  })
}
