import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledTrainingContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: 1441,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 35,
  padding: '92px 0',
  [theme.breakpoints.down('lg')]: { gap: theme.spacing(4) },
  [theme.breakpoints.down('sm')]: { gap: theme.spacing(1), padding: '40px 0' },
}))

export const StyledSquareBg = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: -1,
  [theme.breakpoints.down('lg')]: { left: -50 },
  [theme.breakpoints.down('sm')]: { width: 220, left: 0 },
}))

export const StyledContent = styled(Row)(({ theme }) => ({
  maxWidth: 1171,
  gap: 50,
  alignItems: 'start',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    maxWidth: 510,
    flexWrap: 'wrap',
    gap: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    rowGap: theme.spacing(1),
  },
}))

export const StyledDisplayTraining = styled(Column)(({ theme }) => ({
  width: 153,
  alignItems: 'center',
  '& > hr': {
    width: 0,
    height: 4,
    background: 'transparent',
    transition: 'ease 0.1s all',
  },
  ':hover > hr': { width: '100%', background: theme.palette?.secondary?.main },
}))

export const StyledTrainingWrapper = styled(Column)(({ theme }) => ({
  gap: theme.spacing(1),
  padding: `${theme.spacing(1)} 0`,
  alignItems: 'center',
  cursor: 'pointer',
}))

export const StyledCaption = styled(Typography)(({ theme }) => ({
  maxWidth: 153,
  overflow: 'hidden',
  wordBreak: 'break-all',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  transition: 'all 0.3s ease',
}))
