import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { StyledBox, StyledButton } from './Styled'

const ActionResendRequest = ({ row }) => {
  const { onResendRequest } = useSelector((state) => state.table)
  const resendBtn = _.get(row, 'resendButton', false)

  return (
    <>
      {resendBtn && (
        <StyledBox>
          <StyledButton onClick={() => onResendRequest(row)}>
            ส่งคำขออีกครั้ง
          </StyledButton>
        </StyledBox>
      )}
    </>
  )
}

export default ActionResendRequest
