import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import FilterExpandedMenu from 'src/components/Filter/ExpandedMenu'
import { handleCheckboxChange } from './events'

const ExpandedMenu = ({ label, field, children }) => {
  const { list, filterBody } = useSelector(
    (state) => ({
      list: _.get(state.courseList.filterData, field, []),
      filterBody: _.get(state.courseList.filterBody, field, []),
    }),
    shallowEqual
  )
  const defaultExpand =
    field == 'trainingPlatform' || filterBody > 0 || filterBody.length > 0
  const dispatch = useDispatch()

  return (
    <FilterExpandedMenu
      field={field}
      label={label}
      list={list}
      filterBody={filterBody}
      defaultExpand={defaultExpand}
      onChange={(e) => dispatch(handleCheckboxChange(e, filterBody))}
    >
      {children}
    </FilterExpandedMenu>
  )
}

export default ExpandedMenu
