import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import styled from '@mui/system/styled'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import FilterListOutlined from '@mui/icons-material/FilterListOutlined'

export const StyledFullWidth = styled(Box)(() => ({
  width: '100%',
}))

export const StyledTable = styled((props) => (
  <Table aria-labelledby="tableTitle" {...props} />
))(() => ({}))

export const StyledTableBodyCell = styled((props) => (
  <TableCell key="no-data" align="center" {...props} />
))(({ theme }) => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      color: theme.palette.text?.silver,
      width: '27px',
      height: '27px',
    },
  },
}))

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: { sm: theme.spacing(2) },
  paddingRight: { xs: theme.spacing(0), sm: theme.spacing(0) },
  [theme.breakpoints.down('lg')]: {
    '& > .MuiBox-root': { flexDirection: 'column' },
  },
}))

export const StyledBox = styled(Box)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  '& > .MuiBox-root': { marginTop: theme.spacing(2.5) },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    alignItems: 'unset',
    '& > .MuiBox-root': { marginTop: theme.spacing(1) },
  },
}))

export const StyledDownloadButton = styled((props) => (
  <Button
    data-testid={'btn-download'}
    variant="text"
    startIcon={<FileDownloadOutlined />}
    {...props}
  />
))(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}))

export const StyledDeletedButton = styled((props) => (
  <Button
    data-testid={'btn-delete'}
    variant="text"
    startIcon={<DeleteTwoTone />}
    {...props}
  />
))(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}))

export const StyledSendEmailButton = styled((props) => (
  <Button
    data-testid={'btn-send-email'}
    variant="text"
    startIcon={<img src="/icon/ic_megaphone_primary.svg" />}
    {...props}
  />
))(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}))

export const StyledFilterButton = styled((props) => (
  <Button
    data-testid={'btn-filter'}
    variant="text"
    startIcon={<FilterListOutlined />}
    {...props}
  />
))(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}))

export const StyledTableHeader = styled(TableCell)(({ headcellwidth, headcellminWidth }) => ({
  width: headcellwidth ?? 'unset',
  minWidth: headcellminWidth ?? 'unset',
}))

export const StyledTableCell = styled(TableCell)(({ headcellwidth }) => ({
  width: headcellwidth ?? 'unset',
  borderBottom: 0,
  boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.04)',
}))

export const StyledTableSortLabel = styled(TableSortLabel)(({ headcell }) => ({
  margin: headcell.marginLabel ?? 0,
  cursor: !headcell.hideSortIcon ? 'pointer' : 'unset',
}))

export const StyledTableHeadText = styled(TableCell)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(1)}`,
  minWidth: 600,
  borderBottom: '0px',
}))

export const StyledButtonSection = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}))
