import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledRoundContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  textAlign: 'end',
  minWidth: 133,
}))

export const responsiveText = (isMediaLg) => (isMediaLg ? 'body1b' : 'body2b')
