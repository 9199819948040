import _ from 'lodash'

export const handleAnswerAmount = (props) => {
  const answerETesting = _.get(props, 'answerETesting', [])
  const questions = _.get(props, 'questions', [])
  const answerList = []
  questions.forEach((question) => {
    const haveNoAnswer = handleIsNoAnswer(answerETesting, question)
    answerList.push(!haveNoAnswer)
  })
  return answerList.filter((isAnswered) => isAnswered).length
}

export const handleIsNoAnswer = (answerETesting, question) => {
  if (_.isNil(question) || _.isNil(answerETesting)) return
  const questions = answerETesting.filter(
    (item) => item.questionUuid == question.uuid
  )
  const haveNoAnswer = questions.some((item) => {
    if (_.isUndefined(item.isVideoEnded)) return !item.isAnswered
    else return !item.isAnswered || !item.isVideoEnded
  })
  const notComplete = questions.length < question.maxAnswerNumber
  return questions.length == 0 || haveNoAnswer || notComplete
}
