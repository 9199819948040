import _ from 'lodash'
import {
  setChangeCamera,
  closeDialog as closeDetailDialog,
  setUpdateVerifyTimes,
  setVideoState,
} from 'src/redux/slices/eLearning/learning'
import { handleRelationObj } from 'src/redux/slices/eLearning/learning/events'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { closeDialog, openDialog } from 'src/redux/slices/alertDialog'
import {
  postEnrollRekognition,
  postVerifyFace,
} from 'src/services/eLearning/enroll'
import {
  ENROLL_REKOGNITION_STATUS,
  MODULE_TYPE,
  REKOGNITION_EVENT,
} from 'src/constants/eLearning'
import {
  postBatchFailScanCount,
  putBatchFailScanCount,
} from 'src/services/eLearning/detail'
import { ERROR_OBJ, ERROR_TYPE } from 'src/components/FaceLiveness/model'
import { getLearningState } from '../../events'
import { handleDisallowScan } from '../events'

export const handleOpenCamera = () => (dispatch) => {
  dispatch(setChangeCamera({ key: 'isOpen', value: true }))
  dispatch(setChangeCamera({ key: 'faceImage', value: null }))
}

export const handleDetectFace = (livenessData) => async (dispatch) => {
  const { course, eLearningCourseBatch, verifiedTimes } = getLearningState()
  const idCardEnroll = _.get(course, 'eLearningIdCardEnroll', {})
  const idCardImage = _.get(idCardEnroll, 'idCardImageKey', '')
  const payload = {
    sessionId: livenessData.sessionId,
    body: { targetImage: idCardImage },
  }
  dispatch(startLoading())
  const response = await dispatch(postVerifyFace(payload))

  if (_.isEmpty(response.error)) {
    const eLearningIdCardEnroll = handleRelationObj(idCardEnroll)
    const data = _.get(response.payload, 'data', {})
    const faceImageKey = _.get(data, 'faceImageKey', '')
    const status = _.get(data, 'status', '')
    const body = {
      eLearningCourseBatch,
      eLearningIdCardEnroll,
      event: REKOGNITION_EVENT.LEARN,
      faceImageKey,
      status,
      percent: _.get(data, 'percent', 0),
      times: verifiedTimes + 1,
    }

    const resEnroll = await dispatch(postEnrollRekognition(body))
    dispatch(stopLoading())

    if (
      _.isEmpty(resEnroll.error) &&
      status === ENROLL_REKOGNITION_STATUS.SUCCESS
    ) {
      dispatch(setUpdateVerifyTimes())
      dispatch(handleVerifySuccess())
    } else dispatch(handleVerifyFail(ERROR_TYPE.VERIFY))
  } else {
    dispatch(stopLoading())
    dispatch(handleVerifyFail(ERROR_TYPE.DETECT))
  }
}

export const handleVerifySuccess = () => (dispatch) => {
  dispatch(
    openDialog({
      iconType: 'success',
      title: 'ยืนยันตัวตนสำเร็จ',
      isCloseDialog: false,
      buttonRight: {
        handleClick: () => {
          const moduleType = _.get(
            getLearningState().selectedLesson,
            'moduleType',
            ''
          )
          if (moduleType === MODULE_TYPE.EMBED_CONTENT) {
            dispatch(setVideoState({ playing: true }))
          }
          dispatch(closeDialog())
          dispatch(closeDetailDialog())
        },
      },
    })
  )
}

export const handleVerifyFail = (type) => async (dispatch) => {
  const errorObj = _.get(ERROR_OBJ, type, ERROR_OBJ[ERROR_TYPE.SYSTEM])
  if (!errorObj.isCount) {
    dispatch(openScanErrorDialog(type))
    return
  }

  const { eLearningCourseBatchLearner, failLogUuid } = getLearningState()
  dispatch(startLoading())
  let response = {}
  if (_.isEmpty(failLogUuid)) {
    response = await dispatch(
      postBatchFailScanCount({ eLearningCourseBatchLearner })
    )
  } else {
    const payload = { uuid: failLogUuid, checkStatus: 'fail' }
    response = await dispatch(putBatchFailScanCount(payload))
  }
  dispatch(stopLoading())

  const expireDateTime = _.get(response.payload.data, 'registerBreakTime', '')
  if (!_.isEmpty(expireDateTime)) {
    dispatch(closeDialog())
    setTimeout(() => {
      dispatch(handleDisallowScan(response))
    }, 500)
  } else {
    dispatch(openScanErrorDialog(type))
  }
}

export const openScanErrorDialog = (type) => (dispatch) => {
  const { title, message } = ERROR_OBJ[type]
  dispatch(
    openDialog({
      isCloseDialog: false,
      iconType: 'error',
      title,
      message: _.concat(message),
      buttonRight: {
        label: 'ลองอีกครั้ง',
        handleClick: () => {
          dispatch(closeDialog())
          dispatch(setChangeCamera({ key: 'isOpen', value: false }))
          setTimeout(() => {
            dispatch(handleOpenCamera())
          }, 1000)
        },
      },
    })
  )
}
